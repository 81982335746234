import { currencyFormatter } from 'utils/strings/currency-formatter';
import { MainTable } from '.';
import { ECBox } from '../ECBox';
import {
  ECTableBody,
  ECTableCell,
  ECTableContainer,
  ECTableRow,
} from '../ECTable';
import { ECTypography } from '../ECTypography';
import ECCurrencyField from '../ECCurrencyField';
import { FormHelperText } from '@mui/material';
import { ECFormControl } from '../ECForm';
import { CostLaborItem } from 'types/CostLaborItem';
import { ECMenuItem, ECSelect } from '../ECSelect';
import { ECAlert } from '../ECAlert';
import { ServiceProviderRatesAndTerms } from 'types/ServiceProviderTypes';
import { useCustomerUser } from 'app/hooks';
import { ECButton } from '../ECButton';
import { useGetLaborItemsQuery } from 'services/lookupApi';
import { ECTextField } from '../ECTextField';
import { ECIconButton } from '../ECIconButton';
import { Close } from '@mui/icons-material';
import { useCallback } from 'react';

interface Props {
  name: string;
  allCharges: CostLaborItem[];
  charges: CostLaborItem[];
  ratesAndTerms: ServiceProviderRatesAndTerms;
  isEditing?: boolean;
  onChange: (value: any, label: string) => void;
}

const mapNameToKey = {
  Labor: 'laborChargeItems',
  Travel: 'travelTypeItems',
};

export const LaborItemsSubTableCharges = ({
  name,
  allCharges,
  charges,
  ratesAndTerms,
  isEditing,
  onChange,
}: Props) => {
  const isCustomer = useCustomerUser();
  const { data: laborItemsOptions } = useGetLaborItemsQuery();

  const options = laborItemsOptions?.[mapNameToKey[name]];

  const availableOptions =
    options?.filter(
      (option): option is { id: number } =>
        typeof option?.id === 'number' &&
        !charges?.map(charge => charge?.costType?.id).includes(option.id),
    ) || [];

  const getUnitCostFromRatesAndTerms = useCallback(
    (costType: string) => {
      return ratesAndTerms?.[`journeyman${costType?.replaceAll(' ', '')}`] || 0;
    },
    [ratesAndTerms],
  );

  const handleAddNew = useCallback(() => {
    const isRegularRateOptionAvailable = !!availableOptions?.find(
      option => option?.name === 'Regular',
    );

    const newCharge = isRegularRateOptionAvailable
      ? {
          costType: {
            name: 'Regular',
          },
          unitCost: getUnitCostFromRatesAndTerms('Regular'),
          unitCount: 0,
          totalCost: 0,
        }
      : {
          costType: availableOptions?.[0],
          unitCost: getUnitCostFromRatesAndTerms(availableOptions?.[0]?.name),
          unitCount: 0,
        };

    onChange([...allCharges, newCharge], 'Labor Charge');
  }, [allCharges, availableOptions, ratesAndTerms, onChange]);

  const handleChange = (item, value) => {
    onChange(
      allCharges?.map(i =>
        i?.costType?.name === item.costType?.name ? value : i,
      ),
      'Labor Charge',
    );
  };

  if (!charges?.length) {
    return null;
  }

  return (
    <>
      <ECTableRow
        sx={{
          bgcolor: theme => `${theme.palette.action.hover} !important`,
        }}
      >
        <ECTableCell colSpan={2} sx={{ border: 0, px: 2 }}>
          <ECBox
            display="flex"
            flexDirection="column"
            width="100%"
            borderColor={theme => theme.palette.divider}
            bgcolor={theme => theme.palette.common.white}
            pb={2}
          >
            <ECTableContainer>
              <MainTable>
                <ECTableBody>
                  <ECTableRow>
                    <ECTableCell sx={{ border: 0 }}>
                      <ECTypography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        {name} Charge
                      </ECTypography>
                    </ECTableCell>

                    <ECTableCell sx={{ border: 0 }}>
                      <ECTypography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        Rate
                      </ECTypography>
                    </ECTableCell>

                    <ECTableCell
                      sx={{
                        border: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ECTypography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        Hours
                      </ECTypography>
                    </ECTableCell>

                    <ECTableCell sx={{ border: 0 }}>
                      <ECTypography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        Amount
                      </ECTypography>
                    </ECTableCell>

                    {isEditing && (
                      <ECTableCell sx={{ border: 0 }}></ECTableCell>
                    )}
                  </ECTableRow>

                  {charges?.map((item, index) => {
                    const charge = getUnitCostFromRatesAndTerms(
                      item?.costType?.name,
                    );

                    const isFlatRateCharge =
                      item?.costType?.name === 'Flat Rate';

                    return (
                      <>
                        <ECTableRow
                          key={`${item.id}-labor`}
                          id={`${item.id}`}
                          sx={{
                            bgcolor: theme =>
                              `${theme.palette.common.white} !important`,
                          }}
                        >
                          <ECTableCell sx={{ border: 0 }}>
                            <ECFormControl fullWidth>
                              {isEditing ? (
                                <ECSelect
                                  displayEmpty
                                  hiddenLabel
                                  variant="filled"
                                  value={item?.costType?.name}
                                  onChange={e => {
                                    const value = e.target.value as string;
                                    const newCharge =
                                      getUnitCostFromRatesAndTerms(value);

                                    if (value === 'Flat Rate') {
                                      handleChange(item, {
                                        ...item,
                                        costType: {
                                          name: value,
                                          id: options?.find(
                                            option => option?.name === value,
                                          )?.id,
                                        },
                                        totalCost: newCharge,
                                      });
                                      return;
                                    }

                                    handleChange(item, {
                                      ...item,
                                      costType: {
                                        name: value,
                                        id: options?.find(
                                          option => option?.name === value,
                                        )?.id,
                                      },
                                      unitCost: newCharge,
                                    });
                                  }}
                                >
                                  {[item?.costType, ...availableOptions]?.map(
                                    option => (
                                      <ECMenuItem value={option?.name}>
                                        {option?.name}
                                      </ECMenuItem>
                                    ),
                                  )}
                                </ECSelect>
                              ) : (
                                <ECTypography
                                  variant="caption"
                                  sx={theme => ({
                                    color: theme.palette.text.secondary,
                                  })}
                                >
                                  {item?.costType?.name}
                                </ECTypography>
                              )}
                            </ECFormControl>
                          </ECTableCell>

                          <ECTableCell
                            sx={{
                              border: 0,
                              visibility: isFlatRateCharge
                                ? 'hidden'
                                : undefined,
                            }}
                          >
                            {isEditing ? (
                              <ECFormControl fullWidth>
                                <ECCurrencyField
                                  hiddenLabel
                                  value={item.unitCost}
                                  id={`${item.id}-unitCost`}
                                  variant="filled"
                                  onChange={(e, value) => {
                                    handleChange(item, {
                                      ...item,
                                      unitCost: Number(value),
                                      totalCost: Number(value) * item.unitCount,
                                    });
                                  }}
                                />
                                {isEditing &&
                                  item.unitCost !== 0 &&
                                  !item.unitCost && (
                                    <FormHelperText
                                      sx={theme => ({
                                        color: theme.palette.error.main,
                                      })}
                                    >
                                      This field is required
                                    </FormHelperText>
                                  )}
                              </ECFormControl>
                            ) : (
                              <ECTypography
                                variant="caption"
                                sx={theme => ({
                                  color: theme.palette.text.secondary,
                                })}
                              >
                                {currencyFormatter.format(item.unitCost)}
                              </ECTypography>
                            )}
                          </ECTableCell>

                          <ECTableCell
                            sx={{
                              border: 0,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              visibility: isFlatRateCharge
                                ? 'hidden'
                                : undefined,
                            }}
                          >
                            {isEditing ? (
                              <ECTextField
                                value={item.unitCount}
                                id={`${item.id}-unitCount`}
                                variant="filled"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                hiddenLabel
                                onChange={e => {
                                  const value = e.target.value;
                                  handleChange(item, {
                                    ...item,
                                    unitCount: Number(value),
                                    totalCost: item.unitCost * Number(value),
                                  });
                                }}
                              />
                            ) : (
                              <ECTypography
                                variant="caption"
                                sx={theme => ({
                                  color: theme.palette.text.secondary,
                                })}
                              >
                                {item.unitCount}
                              </ECTypography>
                            )}
                          </ECTableCell>

                          <ECTableCell sx={{ border: 0 }}>
                            {isEditing && isFlatRateCharge ? (
                              <ECCurrencyField
                                hiddenLabel
                                id={`${item.id}-totalCost`}
                                value={item.totalCost}
                                variant="filled"
                                onChange={(e, value) => {
                                  handleChange(item, {
                                    ...item,
                                    totalCost: Number(value),
                                  });
                                }}
                                readOnly={item?.costType?.name !== 'Flat Rate'}
                              />
                            ) : (
                              <ECTypography
                                variant="caption"
                                sx={theme => ({
                                  color: theme.palette.text.secondary,
                                })}
                              >
                                {currencyFormatter.format(
                                  item.unitCost * item.unitCount,
                                )}
                              </ECTypography>
                            )}
                          </ECTableCell>

                          {isEditing && (
                            <ECTableCell sx={{ border: 0, paddingX: 0 }}>
                              <ECIconButton
                                type="error"
                                onClick={() => {
                                  onChange(
                                    allCharges?.filter(
                                      i =>
                                        i?.costType?.name !==
                                        item.costType?.name,
                                    ),
                                    'Labor Charge',
                                  );
                                }}
                              >
                                <Close />
                              </ECIconButton>
                            </ECTableCell>
                          )}
                        </ECTableRow>

                        <ECTableRow
                          key={`${item.id}-labor-alert`}
                          id={`${item.id}-alert`}
                          sx={{
                            bgcolor: theme =>
                              `${theme.palette.common.white} !important`,
                          }}
                        >
                          <ECTableCell
                            colSpan={4}
                            sx={{ border: 0, py: isEditing ? 1 : 0 }}
                          >
                            {charge === item.totalCost ? (
                              <ECAlert
                                severity="info"
                                sx={{ whiteSpace: 'break-spaces' }}
                              >
                                The {item?.costType?.name} rate matches{' '}
                                {isCustomer ? `the Service Provider's` : 'your'}{' '}
                                rate of {currencyFormatter.format(charge)}
                              </ECAlert>
                            ) : (
                              <ECAlert
                                severity={isCustomer ? 'error' : 'warning'}
                                sx={{ whiteSpace: 'break-spaces' }}
                              >
                                The {item?.costType?.name} rate does not match{' '}
                                {isCustomer ? `the Service Provider's` : 'your'}{' '}
                                rate of {currencyFormatter.format(charge)}
                              </ECAlert>
                            )}
                          </ECTableCell>
                        </ECTableRow>
                      </>
                    );
                  })}

                  {isEditing && !!availableOptions?.length && (
                    <ECTableRow
                      sx={{
                        bgcolor: theme =>
                          `${theme.palette.common.white} !important`,
                      }}
                    >
                      <ECTableCell colSpan={4} sx={{ border: 0 }}>
                        <ECButton
                          variant="text"
                          color="primary"
                          onClick={handleAddNew}
                        >
                          + Add Item
                        </ECButton>
                      </ECTableCell>
                    </ECTableRow>
                  )}
                </ECTableBody>
              </MainTable>
            </ECTableContainer>
          </ECBox>
        </ECTableCell>
      </ECTableRow>
    </>
  );
};
