import { ECButton, ECEasyForm } from 'app/components';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { PlayArrow } from '@mui/icons-material';
import { ECModal } from 'app/components/ECModal';
import {
  useUpdateBranchMutation,
} from 'services/branchApi';
import { P } from 'types/Permission';

interface LiveModalButtonProps {
  branchId: number;
  onSave?: () => void;
  disabled?: boolean;
  statusToId?: number;
}

const fancyFormLive = require('./fancy_form_config_live.json');

export function LiveModalButton({
  onSave,
  disabled,
  branchId,
  statusToId,
}: LiveModalButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLiveModalOpen, setIsLiveModalOpen] = useState(false);

  const [
    doUpdateLive,
    { isLoading: isLoadingUpdateLive, isSuccess: isSuccessUpdateLive },
  ] = useUpdateBranchMutation();

  useEffect(() => {
    if (isSuccessUpdateLive) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Location was Successfully set to Live',
        }),
      );
      onSave?.();
      setIsLiveModalOpen(false);
    }
  }, [isSuccessUpdateLive, dispatch, onSave]);

  const handleSetLive = useCallback(
    (formData, fieldData) => {
      doUpdateLive({
        id: branchId,
        onHold: 0,
      });
    },
    [doUpdateLive, branchId],
  );

  return (
    <>
      <ECButton
        variant="outlined"
        startIcon={<PlayArrow />}
        scopes={[P.EditBranch]}
        onClick={() => setIsLiveModalOpen(true)}
        isLoading={isLoadingUpdateLive}
        disabled={disabled}
        sx={{
          marginRight: 1
        }}
      >
        Live
      </ECButton>
      <ECModal isOpen={isLiveModalOpen} onClose={() => onSave?.()} noPadding>
        <ECEasyForm
          pattern="modal"
          config={fancyFormLive.config}
          fields={fancyFormLive.fields}
          isSendingData={isLoadingUpdateLive}
          isLoading={isLoadingUpdateLive}
          isLoadingForm={false}
          onFormSubmit={handleSetLive}
          customDrawerTitle={`Confirm you want to change the Location Status to 'Live'`}
          additionalActions={
            <ECButton
              type="button"
              variant="text"
              onClick={() => setIsLiveModalOpen(false)}
            >
              {t('translation:dynamicForm.cancel')}
            </ECButton>
          }
        />
      </ECModal>
    </>
  );
}
