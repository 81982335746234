import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import {
  configureStore,
  isFulfilled,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { history } from 'utils/history';
import { removeOverrideUser, clearEverythingFromLogin } from 'services/utils';
import { findOverrideUser } from 'services/utils';
import { emptyApi } from 'services/emptyApi';
import { store } from 'index';
import { setSnackbar } from './slice/page';
import moment from 'moment';
import authReducer from './slice/auth';
import userReducer from './slice/user';
import pageReducer from './slice/page';

/**
 * Redirect to error page if any API call fails with 4xx status codes
 */
export const rtkQueryErrorHandler: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isFulfilled(action)) {
      // check if user profile returned job title, otherwise redirect to login page
      if (
        (action?.meta?.arg as any)?.endpointName === 'getUserProfile' &&
        !(action.payload as any)?.jobTitle
      ) {
        clearEverythingFromLogin();
        const message = 'Login Failed! The user is missing job title.';
        store.dispatch(
          setSnackbar({
            severity: 'error',
            message,
          }),
        );

        if (history?.navigate) {
          history?.navigate(`/login?message=${message}`);
        } else {
          window.location.href = `/login?message=${message}`;
        }

        return;
      }
      if (
        (action?.meta?.arg as any)?.endpointName === 'getUserProfile' &&
        !Boolean(Number(localStorage.getItem('userProfileFromCache')))
      ) {
        localStorage.setItem(
          'userProfileLastCallTimestamp',
          moment().toDate().getTime().toString(),
        );
        const userProfile = action.payload as any;
        localStorage.setItem('userProfile', JSON.stringify(userProfile));
      }

      if (
        (action?.meta?.arg as any)?.endpointName === 'getProfile' &&
        !Boolean(Number(localStorage.getItem('companyProfileFromCache')))
      ) {
        localStorage.setItem(
          'companyProfileLastCallTimestamp',
          moment().toDate().getTime().toString(),
        );
        localStorage.setItem('companyProfile', JSON.stringify(action.payload));
      }
      if ((action.payload as any)?.etErrorCode) {
        const errorMessage =
          (action.payload as any)?.message ||
          (action.payload as any)?.description ||
          'There was an error on the latest action. Please try again';
        store.dispatch(
          setSnackbar({
            severity: 'error',
            message: errorMessage,
          }),
        );
      }
    }

    if (isRejectedWithValue(action)) {
      const payload = action?.payload;
      const { status } = payload as any;
      const url = (action?.meta as any)?.baseQueryMeta?.request?.url;
      // Ignore user profile API call that is called on first login page (gives an infinite loop)
      if (
        url.includes('user/profile') ||
        url.includes('forgot') ||
        url.includes('/auth/admin/set/password') ||
        url.includes('bulletin') ||
        url.includes('login')
      ) {
        return next(action);
      }

      const statusToRedirect = [
        400,
        403,
        404,
        409,
        502,
        503,
        504,
        ...Array(100)
          .fill(0)
          .map((_, index) => index + 500),
      ];

      if (statusToRedirect.includes(status) && history?.navigate) {
        if (
          url.includes('serviceprovider/customer/') &&
          (action?.meta as any)?.baseQueryMeta?.request?.method === 'GET'
        ) {
          history.navigate('/panel/sp/customers');
          return next(action);
        }

        const overrideUser = findOverrideUser();
        const statusToRemoveOverrideUser = [408, 504, 503];
        if (overrideUser && statusToRemoveOverrideUser.includes(status)) {
          removeOverrideUser();
          window.location.reload();
          return next(action);
        }

        // Don't show snackbar error on MFA Page
        if (url.includes('auth/challenge')) return next(action);

        const payloadData = (payload as any)?.data;
        const isFormFieldError = payloadData?.fieldNameWithError;

        if (!isFormFieldError) {
          store.dispatch(
            setSnackbar({
              severity: 'error',
              message:
                (payload as any)?.data?.message ||
                'There was an error on the latest action. Please try again',
            }),
          );
        }
      }
    }

    return next(action);
  };

export function configureAppStore() {
  const store = configureStore({
    reducer: {
      [emptyApi.reducerPath]: emptyApi.reducer,
      page: pageReducer,
      auth: authReducer,
      user: userReducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware()
        .concat(rtkQueryErrorHandler)
        .concat(emptyApi.middleware),
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== 'production' ||
      process.env.PUBLIC_URL.length > 0,
  });

  return store;
}
