import { ECAutocompletePaginated } from '../ECAutocompletePaginated';
import { ECBox } from '../ECBox';
import {
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableContainer,
  ECTableHead,
  ECTableRow,
} from '../ECTable';
import { ECTextField } from '../ECTextField';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ECTypography } from '../ECTypography';
import { useGetPurchaseOrderItemsForDropdownQuery } from 'services/purchaseOrderApi';
import ECCurrencyField from '../ECCurrencyField';
import { useLazyGetValidatePartNumberQuery } from 'services/inventoryApi';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';

export interface PurchaseOrderItemValue {
  id?: number;
  name?: string;
  partNumber?: string;
  lastCostPerUnit?: number;
  quantity?: number;
  unitCost?: number;
  subTotal?: number;
  isNewItem?: boolean;
  isDuplicatedPartNumber?: boolean;
}

export interface ECPurchaseOrderItemProps {
  value?: PurchaseOrderItemValue;
  readOnly?: boolean;
  errorMessage?: string;
  filterOptionsIds?: any[];
  onChange: (value: PurchaseOrderItemValue) => void;
  onDelete: () => void;
}

export const ECPurchaseOrderItem = ({
  value,
  readOnly,
  filterOptionsIds,
  errorMessage,
  onChange,
  onDelete,
}: ECPurchaseOrderItemProps) => {
  const [trigger, result] = useLazyGetValidatePartNumberQuery();
  const { data: dataValidatePartNumber, isLoading } = result;

  const debouncedCheckDuplicate = useRef(
    debounce(partNumber => {
      trigger({ inputPartNumber: partNumber, fieldName: 'partNumber' });
    }, 500),
  ).current;

  useEffect(() => {
    if (!!dataValidatePartNumber && !isLoading) {
      onChange({
        ...value,
        isDuplicatedPartNumber: dataValidatePartNumber?.isDuplicate,
      });
    }
  }, [dataValidatePartNumber, isLoading]);

  return (
    <ECBox display="flex" width="100%">
      <ECBox display="flex" flexDirection="column" flex={1}>
        {value?.isNewItem ? (
          <ECTextField
            variant={readOnly ? 'standard' : 'filled'}
            sx={{ width: '100%', mb: 1 }}
            value={value?.name}
            placeholder="Item Name*"
            label="Item Name*"
            inputProps={{
              readOnly,
            }}
            onChange={e =>
              onChange({
                ...value,
                name: e.target.value,
              })
            }
            error={!!errorMessage && !value?.name}
            helperText={errorMessage && !value?.name ? errorMessage : undefined}
          />
        ) : (
          <ECAutocompletePaginated
            sx={{ width: '100%', mb: 1 }}
            fieldName="purchaseOrderItem"
            value={value}
            variant="filled"
            placeholder="Item Name*"
            label="Item Name*"
            filterOptionsIds={filterOptionsIds}
            readOnly={readOnly}
            useQuery={useGetPurchaseOrderItemsForDropdownQuery}
            queryParams={{
              sb: 'invntry.name',
              ob: 'invntry.name',
              o: 'a',
            }}
            onChange={newValue => {
              onChange(newValue);
            }}
            renderCustomOption={option => {
              return (
                <ECBox display="flex" flexDirection="column">
                  <ECTypography>{option?.name}</ECTypography>
                  <ECTypography variant="caption" color="text.secondary">
                    ID: {option?.viewId}
                  </ECTypography>
                </ECBox>
              );
            }}
            error={!!errorMessage && !value}
            validationMessage={
              !!errorMessage && !value ? errorMessage : undefined
            }
          />
        )}

        {(!!value?.name || value?.isNewItem) && (
          <ECTableContainer>
            <ECTable>
              <ECTableHead>
                <ECTableRow>
                  <ECTableCell>Part #</ECTableCell>
                  <ECTableCell>Quantity</ECTableCell>
                  <ECTableCell sx={{ minWidth: '150px' }}>
                    $ Pre Cost/Unit
                  </ECTableCell>
                  <ECTableCell>$ Unit Cost</ECTableCell>
                  <ECTableCell>Subtotal</ECTableCell>
                </ECTableRow>
              </ECTableHead>
              <ECTableBody>
                <ECTableRow>
                  <ECTableCell>
                    {value?.isNewItem ? (
                      <ECTextField
                        variant={readOnly ? 'standard' : 'filled'}
                        sx={{ py: 1 }}
                        value={value?.partNumber}
                        inputProps={{
                          readOnly,
                        }}
                        onChange={e => {
                          debouncedCheckDuplicate(e.target.value);
                          onChange({
                            ...value,
                            partNumber: e.target.value,
                          });
                        }}
                        error={value?.isDuplicatedPartNumber}
                        helperText={
                          value?.isDuplicatedPartNumber
                            ? 'Part # is already in use'
                            : ''
                        }
                      />
                    ) : (
                      value?.partNumber
                    )}
                  </ECTableCell>
                  <ECTableCell>
                    <ECTextField
                      variant={readOnly ? 'standard' : 'filled'}
                      sx={{ py: 1 }}
                      value={value?.quantity}
                      hiddenLabel
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      inputProps={{
                        readOnly,
                      }}
                      onKeyDown={e => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                      }}
                      onChange={e =>
                        onChange({
                          ...value,
                          quantity: Number(e.target.value),
                          subTotal: +(
                            Number(e.target.value) *
                            Number(value?.unitCost || 0)
                          ).toFixed(2),
                        })
                      }
                      error={!!errorMessage && !value?.quantity}
                      helperText={
                        errorMessage && !value?.quantity
                          ? errorMessage
                          : undefined
                      }
                    />
                  </ECTableCell>
                  <ECTableCell>
                    {value?.lastCostPerUnit || value?.isNewItem ? (
                      value?.lastCostPerUnit
                    ) : (
                      <ECTextField
                        variant={readOnly ? 'standard' : 'filled'}
                        sx={{ py: 1 }}
                        inputProps={{
                          readOnly,
                        }}
                        value={value?.lastCostPerUnit}
                        onChange={e =>
                          onChange({
                            ...value,
                            lastCostPerUnit: Number(e.target.value),
                          })
                        }
                      />
                    )}
                  </ECTableCell>
                  <ECTableCell>
                    <ECCurrencyField
                      id="unitCost"
                      variant={readOnly ? 'standard' : 'filled'}
                      style={{
                        width: '100%',
                        marginBottom: 0,
                      }}
                      readOnly={readOnly}
                      hiddenLabel
                      value={value?.unitCost}
                      onChange={(e, newValue) => {
                        onChange({
                          ...value,
                          unitCost: newValue as any,
                          subTotal: +(
                            Number(newValue) * (value?.quantity || 0)
                          ).toFixed(2),
                        });
                      }}
                      error={!!errorMessage && !value?.unitCost}
                      validationMessage={
                        errorMessage && !value?.unitCost
                          ? errorMessage
                          : undefined
                      }
                    />
                  </ECTableCell>
                  <ECTableCell>{value?.subTotal}</ECTableCell>
                </ECTableRow>
              </ECTableBody>
            </ECTable>
          </ECTableContainer>
        )}
      </ECBox>

      {readOnly ? null : (
        <ECBox display="flex" alignItems="center" justifyContent="center">
          <IconButton onClick={onDelete}>
            <Close color="error" />
          </IconButton>
        </ECBox>
      )}
    </ECBox>
  );
};
