import {
  ECBox,
  ECButton,
  ECEasyTable,
  ECStack,
  ECTypography,
} from 'app/components';
import { ECCheckbox } from 'app/components/ECCheckbox';
import { ECModal } from 'app/components/ECModal';
import { PurchaseOrderStatusType } from 'app/components/ECPurchaseOrderItemsTable';
import { useMemo } from 'react';
import { themes } from 'styles/theme/themes';

interface POConfirmationModalProps {
  purchaseOrder: Record<any, any>;
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  newPOSwitch?: boolean;
  setNewPOSwitch: (value: boolean) => void;
  POStatus: PurchaseOrderStatusType;
}

const tableConfig = require('./purchase_order_confirmation_table_config.json');

const updatedCols = tableConfig.cols.map(col => {
  if (col.fieldName === 'quantityReceived') {
    return {
      ...col,

      optionalSx: {
        color: '#F44336',
      },
    };
  }
  return col;
});

const tableConfig2 = {
  ...tableConfig,
  cols: updatedCols,
};

const MODAL_HEIGHTS = {
  'Partially Reconciled': '59.96%',
  Reconciled: '30%',
};

export const POConfirmationModal = ({
  purchaseOrder,
  isOpen,
  closeModal,
  onConfirm,
  newPOSwitch,
  setNewPOSwitch,
  POStatus,
}: POConfirmationModalProps) => {
  const { purchaseOrderItems } = purchaseOrder ?? {};

  const parsedPOItems = useMemo(() => {
    return purchaseOrderItems?.reduce(
      (acc, item) => {
        const quantity = Number(item.quantity);
        const quantityReceived = Number(item.quantityReceived);
        const allQuantityReceived = quantityReceived >= quantity;
        if (item.quantity <= 0) {
          return acc;
        }

        const unitCost = Number(item.costPerUnit);
        const notReceivedQuantity = quantity - quantityReceived;

        !allQuantityReceived &&
          acc.purchaseItemsNotReceived.push({
            ...item,
            quantityReceived: notReceivedQuantity,
            subTotal: Number((notReceivedQuantity * unitCost).toFixed(2)),
          });

        !!quantityReceived &&
          acc.purchaseItemsReceived.push({
            ...item,
            subTotal: Number((quantityReceived * unitCost).toFixed(2)),
          });

        return acc;
      },
      { purchaseItemsNotReceived: [], purchaseItemsReceived: [] },
    );
  }, [purchaseOrderItems]);

  const POPartiallyReconciledConfirmationModal = useMemo(() => {
    return (
      <ECBox display="flex" flexDirection="column" flexGrow={1} height="100%">
        <ECTypography fontWeight="500" fontSize="20px">
          Confirm you want to mark this Purchase order as Completed.
        </ECTypography>
        <ECTypography fontWeight="600" fontSize="18px" mt={3} mb={2}>
          Create a New Invoice with the following item(s):
        </ECTypography>
        <ECBox display="flex" width="100%">
          <ECEasyTable
            sx={{ width: '100%' }}
            headerBackgroundColor="#fff"
            config={tableConfig}
            data={parsedPOItems?.purchaseItemsReceived}
            isLoading={false}
            headerHeight={5}
            hideSearchHeadContent
            shouldNotUseActiveFilter
          />
        </ECBox>
        {/* // This will be needed to create checkbox confirmation for new PO
        invoice, hiding this for now */}
        {/* <ECStack direction="row" alignItems="center" py={2}>
          <ECCheckbox
            disabled
            checked={newPOSwitch}
            value={newPOSwitch}
            onChange={e => {
              setNewPOSwitch(e.target.checked);
            }}
          />
          <ECTypography fontSize="16px">
            Create a new purchase order for the items that are pending.
          </ECTypography>
        </ECStack> */}
        {!!parsedPOItems?.purchaseItemsNotReceived.length && (
          <ECStack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            fontSize="18px"
            fontWeight="600"
            mb={2}
            spacing={0.5}
          >
            {newPOSwitch ? (
              <>
                <ECTypography fontSize="18px" fontWeight="600">
                  Create a
                </ECTypography>
                <ECTypography
                  fontSize="18px"
                  fontWeight="600"
                  color={themes.light.palette.other.darkInfoWarranty}
                >
                  New purchase
                </ECTypography>
                <ECTypography fontSize="18px" fontWeight="600">
                  Order for the items that are pending.
                </ECTypography>
              </>
            ) : (
              <>
                <ECTypography fontSize="18px" fontWeight="600">
                  Items to be
                </ECTypography>
                <ECTypography
                  fontSize="18px"
                  fontWeight="600"
                  color={themes.light.palette.error.main}
                >
                  marked as not received
                </ECTypography>
                <ECTypography fontSize="18px" fontWeight="600">
                  from the purchase order:
                </ECTypography>
              </>
            )}
          </ECStack>
        )}
        {!!parsedPOItems?.purchaseItemsNotReceived.length && (
          <ECBox display="flex" height="100%" width="100%" flexGrow={1}>
            <ECEasyTable
              sx={{ width: '100%' }}
              headerBackgroundColor="#fff"
              config={tableConfig2}
              data={parsedPOItems?.purchaseItemsNotReceived}
              isLoading={false}
              headerHeight={5}
              hideSearchHeadContent
              shouldNotUseActiveFilter
            />
          </ECBox>
        )}
        <ECStack
          sx={{ position: 'sticky', bottom: 1, pt: 1, background: '#fff' }}
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          p={1}
        >
          <ECButton onClick={closeModal} variant="text">
            Cancel
          </ECButton>

          <ECButton onClick={onConfirm} variant="contained">
            {newPOSwitch ? 'Confirm' : 'Send'}
          </ECButton>
        </ECStack>
      </ECBox>
    );
  }, [closeModal, newPOSwitch, onConfirm, parsedPOItems, setNewPOSwitch]);

  const POReconciledConfirmationModal = useMemo(() => {
    return (
      <ECBox display="flex" flexDirection="column" flexGrow={1} height="100%">
        <ECTypography fontWeight="500" fontSize="20px" mb={2}>
          Confirm you want to Create a New Invoice with the following items:
        </ECTypography>
        <ECBox display="flex" width="100%" height="100%" flexGrow={1}>
          <ECEasyTable
            sx={{ width: '100%' }}
            headerBackgroundColor="#fff"
            config={tableConfig}
            data={parsedPOItems?.purchaseItemsReceived}
            isLoading={false}
            headerHeight={5}
            hideSearchHeadContent
            shouldNotUseActiveFilter
          />
        </ECBox>

        <ECStack
          sx={{ position: 'sticky', bottom: 1, pt: 1, background: '#fff' }}
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          p={1}
        >
          <ECButton onClick={closeModal} variant="text">
            Cancel
          </ECButton>

          <ECButton onClick={onConfirm} variant="contained">
            Confirm
          </ECButton>
        </ECStack>
      </ECBox>
    );
  }, [closeModal, onConfirm, purchaseOrderItems]);

  const renderModalContent = useMemo(() => {
    switch (POStatus) {
      case PurchaseOrderStatusType.PARTIALLY_RECONCILED:
        return POPartiallyReconciledConfirmationModal;
      case PurchaseOrderStatusType.RECONCILED:
        return POReconciledConfirmationModal;
      default:
        return null;
    }
  }, [
    POStatus,
    POPartiallyReconciledConfirmationModal,
    POReconciledConfirmationModal,
  ]);

  const useShortModal = useMemo(() => {
    return !!purchaseOrderItems
      ? purchaseOrderItems.length <= 2 &&
          !parsedPOItems?.purchaseItemsNotReceived.length
      : false;
  }, [parsedPOItems?.purchaseItemsNotReceived.length, purchaseOrderItems]);

  return (
    <ECModal
      sx={{
        width: '58.7%',
        height: useShortModal
          ? MODAL_HEIGHTS['Reconciled']
          : MODAL_HEIGHTS[POStatus],
      }}
      isOpen={isOpen}
      onClose={closeModal}
      noPaddingBottom
    >
      {renderModalContent}
    </ECModal>
  );
};
