import {
  ECDynamicPageTemplate,
  ECRenderCustomActionButton,
  ECTypography,
} from 'app/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setFilterEndpoint } from 'store/slice/page';
import {
  useLazyGetWorkorderDispatchQuery,
  workOrdersApi,
} from 'services/workOrdersApi';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import _ from 'lodash';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import RepeatIcon from '@mui/icons-material/Repeat';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { internalTechAssignedWoStatus } from '../../WorkOrderDetailsPage';
import { WorkflowStatus, WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';
import { ECModal } from 'app/components/ECModal';
import { reassignAction, assignTechAction } from '../../DashboardPage/actions';

interface DispatchTableViewProps {
  renderChangeViewModeButton: () => JSX.Element;
}

export const workflowStatusToHideOnDispatch = [
  WorkflowStatus.Unassigned,
]

export function DispatchTableView({
  renderChangeViewModeButton,
}: DispatchTableViewProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedWorkOrder, setSelectedWorkOrder] = useState<any>();

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/dispatch'));
  }, []);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('workorder');

  const [shouldTriggerLazyQuery, setShouldTriggerLazyQuery] = useState(false);

  const {
    data: priorities,
    isFetching: isFetchingPriorities,
    isSuccess: isSuccessPriorities,
  } = useGetPrioritiesListQuery({
    ob: 'prrt.name',
    o: 'desc',
  });

  useEffect(() => {
    setShouldTriggerLazyQuery(
      !isFetchingWorkflowStatuses &&
        !isFetchingPriorities &&
        isSuccessWorkflowStatuses &&
        isSuccessPriorities,
    );
  }, [
    isFetchingWorkflowStatuses,
    isFetchingPriorities,
    isSuccessWorkflowStatuses,
    isSuccessPriorities,
  ]);

  const customSimpleSelectFilterOptions = useMemo(() => {
    return (
      priorities?.data?.map(customer => ({
        id: customer.id,
        label: customer.name,
        fieldName: customer.name,
        isInitialSelected: true,
      })) || []
    );
  }, [priorities?.data]);

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? _.orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.groupId)
            ?.join(','),
          isInitialSelected: true,
        }))
        // Remove duplicates based on label
        ?.filter(
          (status, index, self) =>
            index === self.findIndex(t => t.label === status.label),
        )
        // Exclude 'Completed' and 'Cancelled' statuses
        ?.filter(
          status =>
            !workflowStatusToHideOnDispatch.includes(status.label as WorkflowStatus),
        )
      : undefined;
  }, [workflowStatuses]);

  const statusFilterOptionsWithDuplicatesRemoved = useMemo(() => {
    return statusFilterOptions?.filter(
      (status, index, self) =>
        index === self.findIndex(t => t.label === status.label),
    );
  }, [statusFilterOptions]);

  const [isOpenReassignModal, setIsOpenReassignModal] =
    useState<boolean>(false);
  const [isOpenAssignTechnModal, setIsOpenAssignTechModal] =
    useState<boolean>(false);

  const closeReassignModal = useCallback(() => {
    setIsOpenReassignModal(false);
  }, []);

  const closeAssignTechModal = useCallback(() => {
    setIsOpenAssignTechModal(false);
  }, []);

  const handleReassignModal = useCallback(row => {
    setSelectedWorkOrder(row);
    setIsOpenReassignModal(true);
  }, []);

  const handleAssignTech = useCallback(row => {
    setSelectedWorkOrder(row);
    setIsOpenAssignTechModal(true);
  }, []);

  const actionButtonsConfig = useMemo(
    () => [
      {
        label: t('translation:table.actions.reassignSP'),
        icon: <RepeatIcon />,
        onClick: (row: any) => handleReassignModal(row),
      },
      {
        label: t('translation:table.actions.assignTech'),
        icon: <EngineeringIcon />,
        onClick: (row: any) => handleAssignTech(row),
      },
    ],
    [],
  );

  const customSPReassignHeaderTitle = useMemo(() => {
    return (
      <ECTypography variant="h6">
        Confirm you want to{' '}
        <ECTypography
          variant="h6"
          color={theme => theme.palette.primary.main}
          sx={{ display: 'inline' }}
        >
          Reassign
        </ECTypography>{' '}
        Work Order ID #{selectedWorkOrder?.id}
      </ECTypography>
    );
  }, [selectedWorkOrder?.id]);

  const customAssignTechHeaderTitle = useMemo(() => {
    return (
      <ECTypography variant="h6">
        Confirm you want to Assign a Technician to Work Order ID #
        {selectedWorkOrder?.id}
      </ECTypography>
    );
  }, [selectedWorkOrder?.id]);

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:panelDrawer.dispatch')}
        useLazyGetListQuery={useLazyGetWorkorderDispatchQuery}
        useCreateMutation={() => false}
        useUpdateMutation={() => false}
        showStatusFilter
        marginTop={false}
        statusFilterAlias="wrkflwstts.groupNameId"
        statusFilterOptions={statusFilterOptionsWithDuplicatesRemoved}
        showCustomFilter
        shouldJoinCustomParams={false}
        customSimpleSelectFilterOptions={customSimpleSelectFilterOptions}
        customSimpleSelectFilterLabel="Priority"
        customSimpleSelectFilterName="prrt.id"
        showTechAssginedFilter
        showEtaFilter
        showTechNotAssignedToggleFilter
        withDrawer={false}
        shouldTriggerLazyQuery={shouldTriggerLazyQuery}
        renderCustomActionComponent={(row: any) => {
          if (
            internalTechAssignedWoStatus.includes(
              row?.workflowStatus?.group?.name as WorkflowStatusGroupName,
            )
          ) {
            return (
              <ECRenderCustomActionButton
                row={row}
                actionButtonConfig={actionButtonsConfig}
              />
            );
          }
        }}
        showHierarchyFilter
        isDraggableColumns
        searchBarCustomElements={renderChangeViewModeButton()}
        customPadding="0px 18.4px 18.4px 18.4px"
        shouldCacheLazyQuery
      />
      <ECModal
        isOpen={isOpenReassignModal}
        onClose={closeReassignModal}
        noPadding
      >
        <reassignAction.modalContent
          onClose={closeReassignModal}
          onCancel={closeReassignModal}
          module="workorder"
          customHeaderTitle={customSPReassignHeaderTitle}
          customInputLabel="Reassign Service Provider *"
          selectedRows={[selectedWorkOrder]}
          invalidateQuery={() => {
            dispatch(workOrdersApi.util.invalidateTags(['Dispatch']));
          }}
        />
      </ECModal>

      <ECModal
        isOpen={isOpenAssignTechnModal}
        onClose={closeAssignTechModal}
        noPadding
      >
        <assignTechAction.modalContent
          onClose={closeAssignTechModal}
          onCancel={closeAssignTechModal}
          module="workorder"
          customHeaderTitle={customAssignTechHeaderTitle}
          customInputLabel="Assign New Technician *"
          selectedRows={[selectedWorkOrder]}
          invalidateQuery={() => {
            dispatch(workOrdersApi.util.invalidateTags(['Dispatch']));
          }}
        />
      </ECModal>
    </>
  );
}
