import { currencyFormatter } from 'utils/strings/currency-formatter';
import { MainTable } from '.';
import { ECBox } from '../ECBox';
import {
  ECTableBody,
  ECTableCell,
  ECTableContainer,
  ECTableRow,
} from '../ECTable';
import { ECTypography } from '../ECTypography';
import { PurchaseOrderItem } from 'types/PurchaseOrderType';
import { truncate } from 'lodash';
import ECCurrencyField from '../ECCurrencyField';
import { FormHelperText } from '@mui/material';
import { ECFormControl } from '../ECForm';

interface Props {
  items: PurchaseOrderItem[];
  isEditing?: boolean;
  onChange: (value: any, label: string) => void;
}

export const PurchaseOrderItemsSubTable = ({
  items,
  isEditing,
  onChange,
}: Props) => {
  if (!items?.length) {
    return null;
  }

  const total = items.reduce((acc, item) => acc + +item.subTotal, 0);

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      border={1}
      borderRadius={2}
      my={1}
      borderColor={theme => theme.palette.divider}
    >
      <ECBox
        display="flex"
        p={2}
        alignItems="center"
        justifyContent="space-between"
        borderBottom={1}
        borderColor={theme => theme.palette.divider}
      >
        <ECTypography
          color={theme => theme.palette.text.secondary}
          variant="body2"
        >
          Subtotal of Parts
        </ECTypography>

        <ECTypography variant="h5" id="ec-costtable-inventory-total">
          {currencyFormatter.format(total)}
        </ECTypography>
      </ECBox>

      <ECTableContainer>
        <MainTable>
          <ECTableBody>
            <ECTableRow
              sx={{
                bgcolor: theme => `${theme.palette.action.hover} !important`,
              }}
            >
              <ECTableCell colSpan={3} sx={{ border: 0 }}>
                <ECTypography
                  color="rgba(0,0,0,0.6)"
                  variant="h6"
                  fontWeight="bold"
                  sx={{ overflowWrap: 'anywhere' }}
                >
                  Purchase Orders
                </ECTypography>
              </ECTableCell>
            </ECTableRow>

            {isEditing && (
              <ECTableRow
                sx={{
                  bgcolor: theme => `${theme.palette.action.hover} !important`,
                }}
              >
                <ECTableCell sx={{ border: 0 }}>
                  <ECTypography
                    color="rgba(0,0,0,0.6)"
                    sx={{ overflowWrap: 'anywhere' }}
                  >
                    Item
                  </ECTypography>
                </ECTableCell>

                <ECTableCell sx={{ border: 0 }}>
                  <ECTypography
                    color="rgba(0,0,0,0.6)"
                    sx={{ overflowWrap: 'anywhere' }}
                  >
                    Unit Cost
                  </ECTypography>
                </ECTableCell>

                <ECTableCell sx={{ border: 0 }}>
                  <ECTypography
                    color="rgba(0,0,0,0.6)"
                    sx={{ overflowWrap: 'anywhere' }}
                  >
                    Cost Total Units
                  </ECTypography>
                </ECTableCell>
              </ECTableRow>
            )}

            {items?.map((item, index) => {
              if (!item?.quantityReceived) {
                return null;
              }

              return (
                <ECTableRow
                  key={index}
                  id={`${item.id}`}
                  sx={{
                    bgcolor: theme =>
                      `${theme.palette.action.hover} !important`,
                  }}
                >
                  <ECTableCell sx={{ border: 0 }}>
                    <ECTypography
                      display="inline"
                      color="rgba(0,0,0,0.6)"
                      sx={{ overflowWrap: 'anywhere' }}
                    >
                      {truncate(item.itemName, { length: 50 })}
                    </ECTypography>
                    <ECTypography
                      display="inline"
                      fontWeight={500}
                      marginLeft={1}
                      color="rgba(0,0,0,0.7)"
                    >
                      ({item.quantityReceived} items)
                    </ECTypography>
                  </ECTableCell>
                  <ECTableCell align="right" sx={{ border: 0 }}>
                    {isEditing && (
                      <ECFormControl key={index} fullWidth>
                        <ECCurrencyField
                          value={item.costPerUnit}
                          error={item.costPerUnit !== 0 && !item.costPerUnit}
                          id={`${item.id}`}
                          onChange={(e, value) => {
                            onChange(
                              items?.map((i, idx) =>
                                idx === index
                                  ? {
                                      ...i,
                                      costPerUnit: value,
                                      subTotal:
                                        Number(value) *
                                        (i.quantityReceived || 0),
                                    }
                                  : i,
                              ),
                              'Purchase Order Items',
                            );
                          }}
                        />
                        {isEditing &&
                          item.costPerUnit !== 0 &&
                          !item.costPerUnit && (
                            <FormHelperText
                              sx={theme => ({
                                color: theme.palette.error.main,
                              })}
                            >
                              This field is required
                            </FormHelperText>
                          )}
                      </ECFormControl>
                    )}
                  </ECTableCell>
                  <ECTableCell align="right" sx={{ border: 0 }}>
                    {currencyFormatter.format(item.subTotal ?? 0)}
                  </ECTableCell>
                </ECTableRow>
              );
            })}
          </ECTableBody>
        </MainTable>
      </ECTableContainer>
    </ECBox>
  );
};
