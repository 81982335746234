import { ECButton, ECEasyForm } from 'app/components';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { Pause } from '@mui/icons-material';
import { ECModal } from 'app/components/ECModal';
import { useGetOnHoldReasonsQuery } from 'services/lookupApi';
import { useUpdateBranchMutation } from 'services/branchApi';
import { P } from 'types/Permission';
import moment from 'moment';

interface OnHoldModalButtonProps {
  branchId: number;
  onSave?: () => void;
  disabled?: boolean;
}

const fancyFormOnHold = require('./fancy_form_config_on_hold.json');

export function OnHoldModalButton({
  onSave,
  disabled,
  branchId,
}: OnHoldModalButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    // on hold reasons field
    const onHoldReasonsField = fancyFormOnHold.fields.find(
      field => field.fieldName === 'onHoldReason',
    );
    if (onHoldReasonsField) {
      onHoldReasonsField.useQuery = useGetOnHoldReasonsQuery;
    }
  }, []);

  const [isOnHoldModalOpen, setIsOnHoldModalOpen] = useState(false);

  const [
    doUpdateOnHold,
    {
      isLoading: isLoadingUpdateOnHold,
      isSuccess: isSuccessUpdateOnHold,
      isError: isErrorUpdateOnHold,
      error: errorUpdateOnHold,
    },
  ] = useUpdateBranchMutation();

  useEffect(() => {
    if (isSuccessUpdateOnHold) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Location was Successfully set to On-hold',
        }),
      );
      // after saving, reset the form fields so it does not load old data when opening the modal again
      fancyFormOnHold.fields = [
        {
          "type": "alert",
          "fieldName": "onHoldAlert",
          "severity": "warning",
          "color": "warning",
          "label": "Putting this location on hold will stop any new Work Orders or Preventive Maintenance Work Orders from being created.",
          "optionalSx": {
            "alignItems": "center"
          }
        },
        {
          "type": "select_paginated",
          "label": "Reason",
          "placeholder": "Reason",
          "value": "",
          "fieldName": "onHoldReason",
          "required": true,
          "optionalSx": {
            "marginBottom": "4px"
          }
        },
        {
          "type": "text_field",
          "label": "Other Reason",
          "placeholder": "Other Reason",
          "value": null,
          "fieldName": "otherReason",
          "optionalSx": {
            "marginBottom": "4px"
          },
          "visible": false
        },
        {
          "type": "date_picker",
          "label": "Location Active Date",
          "placeholder": "Location Active Date",
          "value": null,
          "fieldName": "onHoldExpirationDate",
          "optionalSx": {
            "marginBottom": "4px"
          }
        }
      ];
      onSave?.();
      setIsOnHoldModalOpen(false);
    }
  }, [isSuccessUpdateOnHold, dispatch, onSave]);

  useEffect(() => {
    if (isErrorUpdateOnHold) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message:
            (errorUpdateOnHold as any)?.data?.description?.[0] ||
            'Failed to set Location to On-hold',
        }),
      );
    }
  }, [isErrorUpdateOnHold, dispatch]);

  const handleSetOnHold = useCallback(
    (formData, fieldData) => {
      const onHoldExpirationDateFormValue = fieldData.find(
        f => f.fieldName === 'onHoldExpirationDate',
      )?.value;
      doUpdateOnHold({
        id: branchId,
        onHold: 1,
        onHoldReasonId: fieldData.find(f => f.fieldName === 'onHoldReason')
          ?.value?.id,
        onHoldExpirationDate: onHoldExpirationDateFormValue
          ? moment(onHoldExpirationDateFormValue)?.format('MM/DD/YYYY')
          : undefined,
        reason: fieldData.find(f => f.fieldName === 'otherReason')?.value,
      });
    },
    [doUpdateOnHold, branchId],
  );

  // on Change to show the Other Reason text field when user selects Other option
  const handleChange = useCallback((output, fieldname, value) => {
    if (fieldname === 'onHoldReason') {
      const otherReasonField = output.find(
        field => field.fieldName === 'otherReason',
      );
      if (otherReasonField) {
        otherReasonField.visible = value?.code === 'OTHER';
        otherReasonField.required = value?.code === 'OTHER';
        fancyFormOnHold.fields = [...output];
      }
    }
  }, []);

  return (
    <>
      <ECButton
        variant="outlined"
        startIcon={<Pause />}
        scopes={[P.EditBranch]}
        onClick={() => setIsOnHoldModalOpen(true)}
        isLoading={isLoadingUpdateOnHold}
        disabled={disabled}
        sx={{
          marginRight: 1,
        }}
      >
        On Hold
      </ECButton>
      {isOnHoldModalOpen && (
        <ECModal
          isOpen={isOnHoldModalOpen}
          onClose={() => onSave?.()}
          noPadding
        >
          <ECEasyForm
            pattern="modal"
            config={fancyFormOnHold.config}
            fields={fancyFormOnHold.fields}
            isSendingData={isLoadingUpdateOnHold}
            isLoading={isLoadingUpdateOnHold}
            isLoadingForm={false}
            onFormSubmit={handleSetOnHold}
            onChange={handleChange}
            customDrawerTitle={`Confirm you want to change the Location Status to 'On Hold'`}
            additionalActions={
              <ECButton
                type="button"
                variant="text"
                onClick={() => setIsOnHoldModalOpen(false)}
              >
                {t('translation:dynamicForm.cancel')}
              </ECButton>
            }
          />
        </ECModal>
      )}
    </>
  );
}
