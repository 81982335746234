import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  ECButton,
  ECDynamicPageTemplate,
  ECEasyFormFieldType,
  ECRequirePermission,
  FieldTypes,
  SelectionType,
  ServiceFrequencyValueType,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useOutletContext } from 'react-router-dom';
import {
  useEditPreventiveMaintenanceLocationMutation,
  useLazyGetPreventiveMaintenanceBranchListQuery,
  useDeletePreventiveMaintenanceLocationMutation,
  useAddSheduleLocationPreventiveMaintenanceMutation,
  preventiveMaintenanceApi,
  useGetMasterPmUnAssignedBranchesByIdQuery,
  useLazyGetPMNextServiceDateQuery,
} from 'services/preventiveMaintenanceApi';
import { useGetPriorityListQuery } from 'services/assetTypeApi';
import { useLazyGetTradeAssignmentByTradeIdQuery } from 'services/tradeApi';
import _, { isNil } from 'lodash';
import { themes } from 'styles/theme/themes';
import { MONTH_DAYS } from 'app/components';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import moment from 'moment';
import { useGetCompanyServiceProvidersDropdownListQuery } from 'services/serviceProviderApi';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { BulkEditDrawer } from './BulkEditDrawer';
import { P } from 'types/Permission';
import { Edit } from '@mui/icons-material';

const detailsForm = require('./details_form.json');
const editForm = require('./edit_form.json');
const createForm = require('./create_form.json');

export const bulkEditButtonStyle = {
  px: 2,
  position: 'absolute',
  top: '261px',
  left: '220px',
  height: '40px',
};

export function PMLocations() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const masterPmData: any = useOutletContext();

  const [row, setRow] = useState<any>();
  const [editClicked, setEditClicked] = useState<any>(false);
  const [customTitle, setCustomTitle] = useState<string>('');
  const [editFormFields, setEditFormFields] = useState(editForm.fields);
  const [createFormFields, setCreateFormFields] = useState(createForm.fields);
  const [detailsFormFields, setDetailsFormFields] = useState(
    detailsForm.fields,
  );

  const [rankOneSp, setRankOneSp] = useState<any>();

  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [clearAllSelectedRows, setClearAllSelectedRows] = useState(false);
  const [forceRefreshKey, setForceRefreshKey] = useState(Date.now().toString());

  const handleClose = useCallback(() => {
    setIsBulkEditDrawerOpen(false);
    setSelectedRows([]);
    setDisableEditButton(true);
    setClearAllSelectedRows(true);
  }, []);

  const handleRowClick = row => {
    setRow(row);
  };

  const handleSelectedRows = useCallback(rows => {
    const shouldDisable = rows.length === 0;
    setClearAllSelectedRows(shouldDisable);
    setDisableEditButton(shouldDisable);
    setSelectedRows(rows);
  }, []);

  const resetRow = useCallback(() => {
    setRow({});
    setEditClicked(false);
  }, []);

  const handleSelectedRowsChange = useCallback(updatedRows => {
    const shouldDisable = updatedRows.length === 0;
    setDisableEditButton(shouldDisable);
    setClearAllSelectedRows(shouldDisable);
    setSelectedRows(updatedRows);
    setForceRefreshKey(Date.now().toString());
  }, []);

  const [trigger, result] = useLazyGetTradeAssignmentByTradeIdQuery({});

  const {
    data: unAssignedBranchesData,
    isSuccess: isSuccessUnAssignedBranches,
  } = useGetMasterPmUnAssignedBranchesByIdQuery(masterPmData?.id, {
    skip: !masterPmData?.id,
  });

  const {
    data: tradeAssigmnents,
    isSuccess: isSuccessTradeAssigments,
    isFetching: isFetchingTradeAssigments,
  } = result;

  const {
    data: allServiceProviders,
    isSuccess: isSuccessAllSPs,
    isFetching: isFetchingAllSPs,
  } = useGetCompanyServiceProvidersDropdownListQuery({
    t: 1000,
    st: 1,
    ob: 'cmpny.name',
  });

  const locationOptions = useMemo(() => {
    return (
      _.orderBy(unAssignedBranchesData?.data, 'name')?.map(branch => ({
        id: branch?.id,
        orgId: branch?.organizationId,
        onHold: branch?.onHold,
        label: branch?.name,
      })) || []
    );
  }, [unAssignedBranchesData?.data]);

  useEffect(() => {
    if (isSuccessUnAssignedBranches && unAssignedBranchesData?.data) {
      const createElementsFields = [...createFormFields];
      const locationField = createElementsFields.find(
        field => field.fieldName === 'locations',
      );

      locationField.options = locationOptions;
      locationField.optionValues = locationOptions?.map(
        location => location.orgId,
      );

      setCreateFormFields(createElementsFields);
    }
  }, [unAssignedBranchesData, isSuccessUnAssignedBranches, locationOptions]);

  const { data: priorities, isSuccess: isSuccessPriorities } =
    useGetPrioritiesListQuery({
      ob: 'prrt.name',
      o: 'desc',
    });

  useEffect(() => {
    if (isSuccessPriorities) {
      const createElementsFields = [...createFormFields];
      const priorityField = createElementsFields.find(
        field => field.fieldName === 'priority.name',
      );

      const sortedPriorities = _.orderBy(priorities?.data, (priority: any) =>
        parseInt(priority.viewId),
      );

      priorityField.options =
        sortedPriorities?.map(d => ({
          label: d.name,
          colorName: d.color,
          id: d.id,
        })) || [];

      priorityField.optionValues = sortedPriorities?.map(d => d.id) || [];

      setCreateFormFields(createElementsFields);
    }

    if (masterPmData?.priorityId && isSuccessPriorities && priorities?.data) {
      const priorityField = createFormFields.find(
        field => field.fieldName === 'priority.name',
      );
      priorityField.value = masterPmData?.priorityId;
      setCreateFormFields([...createFormFields]);
    }
  }, [priorities, isSuccessPriorities, masterPmData]);

  useEffect(() => {
    if (row) {
      const localEditFormElements = [...editFormFields];

      const categoryFieldEdit = localEditFormElements.find(
        field => field.fieldName === 'category',
      );

      if (categoryFieldEdit) {
        categoryFieldEdit.value = masterPmData?.category?.name;
      }

      const startingDateWoInAdvParent = localEditFormElements?.find(
        field => field.fieldName === 'woInAdvStartingDate',
      )?.subFields;

      const serviceFrequencyField = localEditFormElements?.find(
        field => field.fieldName === 'serviceFrequency',
      );
      if (!isNil(serviceFrequencyField)) {
        serviceFrequencyField.serviceMonths = row.serviceMonths
          ?.split(', ')
          ?.map(monthAbbreviation => {
            return MONTH_DAYS[monthAbbreviation].value;
          });
        serviceFrequencyField.dayOfMonth = row.dayOfMonth;
        serviceFrequencyField.daysBetweenService = row.daysBetweenService;
      }

      const createInAdvanceField = startingDateWoInAdvParent?.find(
        field => field.fieldName === 'createInAdvance',
      );

      if (createInAdvanceField) {
        createInAdvanceField.value = row?.createInAdvance;
      }

      const item =
        row?.branch?.name +
        (row?.branch?.storeNumber ? ` - ${row?.branch?.storeNumber}` : '');
      editClicked
        ? setCustomTitle(`Edit ${item}`)
        : setCustomTitle(`Schedule ${item}`);

      setEditFormFields(localEditFormElements);
    }
  }, [row, editClicked, masterPmData]);

  useEffect(() => {
    if (masterPmData) {
      if (masterPmData.tradeId) {
        trigger({
          id: masterPmData?.tradeId,
          t: 0,
        });
      }

      const createElementsFields = [...createFormFields];
      const detailsElementsFields = [...detailsFormFields];
      const editElementsFields = [...editFormFields];

      const tradeFieldDetails = detailsElementsFields.find(
        field => field.fieldName === 'trade',
      );

      tradeFieldDetails.value = masterPmData?.trade?.name;

      const categoryField = detailsElementsFields.find(
        field => field.fieldName === 'category',
      );

      categoryField.value = masterPmData?.category?.name;

      const editAssetTradeFieldGroup = editElementsFields.find(
        field => field.fieldName === 'assetTradeGroup',
      )?.subFields;

      const tradeFieldEdit = editAssetTradeFieldGroup.find(
        field => field.fieldName === 'trade',
      );

      tradeFieldEdit.value = masterPmData?.trade?.name;

      const pmTitleField = createElementsFields.find(
        field => field.fieldName === 'title',
      );

      pmTitleField.value = masterPmData?.name;

      const assetGroupField = createElementsFields.find(
        field => field.fieldName === 'assetGroup',
      );

      assetGroupField.value = masterPmData?.assetType?.name;

      const tradeField = createElementsFields.find(
        field => field.fieldName === 'trade',
      );

      tradeField.value = masterPmData?.trade?.name;

      const categoryFieldCreate = createElementsFields.find(
        field => field.fieldName === 'category',
      );

      categoryFieldCreate.value = masterPmData?.category?.name;

      setCreateFormFields(createElementsFields);
      setDetailsFormFields(detailsElementsFields);
      setEditFormFields(editElementsFields);
    }
  }, [masterPmData]);

  useEffect(() => {
    if (
      isSuccessTradeAssigments &&
      !isFetchingTradeAssigments &&
      allServiceProviders &&
      !isFetchingAllSPs
    ) {
      const spRankGroup = editFormFields.find(
        field => field.fieldName === 'spRankGroup',
      );

      const servideProvidersField = spRankGroup?.subFields.find(
        field => field.fieldName === 'serviceProvider.name',
      );

      const assignedTradeSps = Array.from(
        new Map(
          tradeAssigmnents?.data
            ?.flatMap(d => d.sp)
            ?.map(sp => [
              sp['id'],
              {
                label: sp['name'],
                id: sp['id'],
                name: sp['name'],
                group: 'Assigned to this trade',
              },
            ]),
        ).values(),
      );

      const allServiceProvidersMapped = allServiceProviders?.data?.map(sp => ({
        label: sp.name,
        name: sp.name,
        id: sp.id,
        group: 'All service providers',
      }));

      const uniqueServiceProviders = _.uniqBy(
        [...assignedTradeSps, ...allServiceProvidersMapped],
        'id',
      );

      if (uniqueServiceProviders?.length) {
        servideProvidersField.options = uniqueServiceProviders;

        servideProvidersField.optionValues = uniqueServiceProviders?.map(
          sp => sp.id,
        );
      }

      const fieldIndex = editFormFields.findIndex(
        field => field.fieldName === 'spRankGroup',
      );

      spRankGroup.subFields = [...spRankGroup.subFields];

      editFormFields[fieldIndex] = spRankGroup;

      const rank1SP = tradeAssigmnents?.data.find(
        record => record.sp.length > 0,
      );

      if (rank1SP?.sp && rank1SP?.sp.length > 0) {
        setRankOneSp(rank1SP.sp[0]);
      }

      setEditFormFields([...editFormFields]);
    }
  }, [
    tradeAssigmnents,
    isSuccessTradeAssigments,
    isFetchingTradeAssigments,
    allServiceProviders,
    isSuccessAllSPs,
    isFetchingAllSPs,
  ]);

  useEffect(() => {
    if (
      isSuccessTradeAssigments &&
      !isFetchingTradeAssigments &&
      allServiceProviders &&
      !isFetchingAllSPs
    ) {
      const updatedCreateFormFields = [...createFormFields];
      const serviceProviderField = updatedCreateFormFields.find(
        field => field.fieldName === 'serviceProvider.name',
      );

      const assignedTradeSps = Array.from(
        new Map(
          tradeAssigmnents?.data
            ?.flatMap(d => d.sp)
            ?.map(sp => [
              sp['id'],
              {
                label: sp['name'],
                id: sp['id'],
                name: sp['name'],
                group: 'Assigned to this trade',
              },
            ]),
        ).values(),
      );

      const allServiceProvidersMapped = allServiceProviders?.data?.map(sp => ({
        label: sp.name,
        name: sp.name,
        id: sp.id,
        group: 'All service providers',
      }));

      const uniqueServiceProviders = _.uniqBy(
        [...assignedTradeSps, ...allServiceProvidersMapped],
        'id',
      );

      if (uniqueServiceProviders?.length) {
        serviceProviderField.options = uniqueServiceProviders;

        serviceProviderField.optionValues = uniqueServiceProviders?.map(
          sp => sp.id,
        );
      }

      setCreateFormFields(updatedCreateFormFields);
    }
  }, [
    tradeAssigmnents,
    isSuccessTradeAssigments,
    isFetchingTradeAssigments,
    allServiceProviders,
    isSuccessAllSPs,
    isFetchingAllSPs,
  ]);

  const { data: priorityData, isSuccess: isPrioritySuccess } =
    useGetPriorityListQuery({});

  useEffect(() => {
    if (isPrioritySuccess && priorityData && row) {
      const localEditFormElements = [...editFormFields];
      const priorityNteGroup = localEditFormElements?.find(
        field => field.fieldName === 'priorityNteGroup',
      )?.subFields;

      const sortedPriorities = _.orderBy(priorityData?.data, (priority: any) =>
        parseInt(priority.viewId),
      );

      const priorityField = priorityNteGroup?.find(
        field => field.fieldName === 'priority.name',
      );

      priorityField.options =
        sortedPriorities?.map(d => ({
          label: d.name,
          colorName: d.color,
          id: d.id,
        })) || [];

      priorityField.optionValues = sortedPriorities?.map(d => d.id) || [];

      priorityField.value = row?.priorityId;
      setEditFormFields(localEditFormElements);
    }
  }, [isPrioritySuccess, priorityData, row, editClicked]);

  useEffect(() => {
    if (row?.tradeId) {
      trigger({
        id: row?.tradeId,
        t: 0,
      });
    }
  }, [row?.tradeId]);

  const handleEditButtonClick = value => {
    setEditClicked(value);
  };

  const [getPMNextServiceDate] = useLazyGetPMNextServiceDateQuery();

  const updateNextServiceDate = useCallback(
    async (startDate, serviceFrequency, createInAdvance, field) => {
      field.disabled = true;

      const { data: nextServiceDate } = await getPMNextServiceDate({
        startDate,
        createInAdvance,
        ...serviceFrequency,
      });

      field.disabled = false;
      field.value = nextServiceDate;

      return nextServiceDate;
    },
    [getPMNextServiceDate],
  );

  const handleChange = useCallback(
    async (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (
        fieldName === 'serviceFrequency' ||
        fieldName === 'startDate' ||
        fieldName === 'createInAdvance'
      ) {
        const serviceFrequencyField = output.find(
          field => field.fieldName === 'serviceFrequency',
        );
        const woInAdvStartingParent = output.find(
          field => field.fieldName === 'woInAdvStartingDate',
        )?.subFields;

        const startingDateField = woInAdvStartingParent?.find(
          field => field.fieldName === 'startDate',
        );
        const woInAdvField = woInAdvStartingParent?.find(
          field => field.fieldName === 'createInAdvance',
        );

        const woInAdvValue = parseInt(woInAdvField?.value as string);
        const serviceFrequencyValue =
          serviceFrequencyField?.value as ServiceFrequencyValueType;

        let woMinDateAdditionOnDaysBetweenService = 0;

        if (serviceFrequencyValue.type === SelectionType.DAY) {
          woMinDateAdditionOnDaysBetweenService = woInAdvValue || 0;
        } else {
          woMinDateAdditionOnDaysBetweenService = 0;
        }

        if (startingDateField) {
          startingDateField.minDate = moment()
            .add(3 + woMinDateAdditionOnDaysBetweenService, 'days')
            .format('YYYY-MM-DD');

          if (editClicked && fieldName !== 'startDate') {
            startingDateField.value = moment()
              .add(3 + woMinDateAdditionOnDaysBetweenService, 'days')
              .format('YYYY-MM-DD');
          }
        }

        const updatedDate = moment(startingDateField?.value as Date);

        if (
          startingDateField &&
          woInAdvValue &&
          serviceFrequencyValue.type === SelectionType.DAY &&
          moment(updatedDate).isBefore(moment(startingDateField.minDate))
        ) {
          startingDateField.minDate = moment()
            .add(3 + woMinDateAdditionOnDaysBetweenService, 'days')
            .format('YYYY-MM-DD');
        }

        const startingDateValue = updatedDate.format('YYYY-MM-DD');

        if (
          serviceFrequencyValue.type === SelectionType.MONTH &&
          serviceFrequencyValue &&
          serviceFrequencyValue.serviceMonths &&
          serviceFrequencyValue.dayOfMonth &&
          startingDateValue &&
          !isNaN(woInAdvValue)
        ) {
          const nextServiceDateField = woInAdvStartingParent?.find(
            field => field.fieldName === 'nextServiceDate',
          );
          await updateNextServiceDate(
            moment(startingDateValue),
            serviceFrequencyValue,
            woInAdvValue,
            nextServiceDateField,
          );
        } else if (
          serviceFrequencyValue.type === SelectionType.DAY &&
          serviceFrequencyValue &&
          serviceFrequencyValue.daysBetweenService &&
          startingDateValue &&
          !isNaN(woInAdvValue)
        ) {
          if (
            serviceFrequencyValue.daysBetweenService <= woInAdvValue ||
            woInAdvValue <= 0
          ) {
            if (woInAdvField) {
              woInAdvField.helperText = t(
                'translation:pages.preventiveMaintenance.workOrderInAdvanceError',
              );
              woInAdvField.value = null;
            }
          } else {
            const newStartingDate = moment(startingDateField?.value as Date);
            const nextServiceDateField = woInAdvStartingParent?.find(
              field => field.fieldName === 'nextServiceDate',
            );
            await updateNextServiceDate(
              moment(newStartingDate),
              serviceFrequencyValue,
              woInAdvValue,
              nextServiceDateField,
            );

            if (woInAdvField) {
              woInAdvField.helperText = '';
            }
          }
        }
      }
      const locationFieldValue = output.find(
        field => field.fieldName === 'locations',
      )?.value;
      const itemsIdOnHold = locationFieldValue
        ?.filter(location => location.onHold === 1)
        .map(location => `${location.id}`);

      const indexPmOnHoldAlert = output.findIndex(
        field => field.fieldName === 'pmOnHoldAlert',
      );
      output[indexPmOnHoldAlert] =
        itemsIdOnHold?.length > 0
          ? {
              type: FieldTypes.Alert,
              fieldName: 'pmOnHoldAlert',
              title: 'On-Hold Location(s)',
              severity: 'warning',
              variant: 'standard',
              description:
                'The following Locations are On-Hold. You can still add the location(s) to this Preventive Maintenance, but Work Orders will not be created for these location(s) while they are On-Hold.',
              items: [...itemsIdOnHold],
            }
          : {
              type: FieldTypes.Group,
              fieldName: 'pmOnHoldAlert',
            };
      // do not use this - it makes re render
      // setEditFormFields(output);
    },
    [editClicked],
  );

  const [
    doUpdatePreventiveMaintenance,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useEditPreventiveMaintenanceLocationMutation();

  const useUpdatePreventiveMaintenance = useCallback(() => {
    const doUpdate = async data => {
      if (
        data.serviceFrequency.type === SelectionType.MONTH &&
        (!data.serviceFrequency.dayOfMonth ||
          !data.serviceFrequency.serviceMonths)
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Please Enter Day of Month and Service Months',
          }),
        );
        return;
      } else if (
        data.serviceFrequency.type === SelectionType.DAY &&
        !data.serviceFrequency.daysBetweenService
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Please Enter Days Between Service',
          }),
        );
        return;
      } else {
        data.id = row?._originalId;
        data.assetTypeId = row?.assetGroupId;
        data.tradeId = row?.tradeId;
        data.branchId = row?.branch?.id;
        data.organizationId = row?.branch?.organizationId;
        data.priorityId = data.priority?.id;
        data.daysBetweenService = data?.serviceFrequency?.daysBetweenService;
        data.startDate = moment(data.startDate).format('MM/DD/YYYY');
        // we send undefined when there is no change. We send null when user removes the option
        // for some reason when the user does not edit the SP, it does not have the ID
        data.spCompanyId =
          data.serviceProvider?.name?.id ||
          (data.serviceProvider.name ? undefined : null);

        const serviceMonthsArray: number[] =
          data.serviceFrequency?.serviceMonths || [];
        if (serviceMonthsArray.length > 0) {
          data.serviceMonths = serviceMonthsArray.join(',');
        } else {
          data.serviceMonths = null;
        }
        data.dayOfMonth = data?.serviceFrequency?.dayOfMonth;

        if (
          data.daysBetweenService &&
          data.daysBetweenService <= data.createInAdvance
        ) {
          dispatch(
            setSnackbar({
              severity: 'error',
              message:
                'Please Enter Generate WO in Advance value less than Days Between Service',
            }),
          );
        } else {
          doUpdatePreventiveMaintenance(data);
        }
      }
    };
    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isLoadingUpdate,
    isUpdateSuccess,
    resetUpdate,
    updateData,
    row,
  ]);

  const [
    doDeletePMLocation,
    {
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      reset,
    },
  ] = useDeletePreventiveMaintenanceLocationMutation();

  const handleAdditionalDeleteAction = () => {
    doDeletePMLocation({
      pmId: row?.pmId,
      pmScheduleId: row?._originalId,
      organizationId: row?.branch?.organizationId,
    });
  };

  const queryTag = 'PreventiveMaintenanceLocation';

  useEffect(() => {
    if (isDeleteSuccess && !isDeleteLoading) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Schedule Location successfully deleted.',
        }),
      );
      dispatch(preventiveMaintenanceApi.util.invalidateTags([queryTag]));
    } else if (isDeleteError && !isDeleteLoading) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'There is an error in deleting the scheduled location.',
        }),
      );
      dispatch(preventiveMaintenanceApi.util.invalidateTags([queryTag]));
    }
    if ((isDeleteSuccess || isDeleteError) && !isDeleteLoading) {
      reset();
    }
  }, [
    dispatch,
    isDeleteError,
    deleteError,
    isDeleteLoading,
    isDeleteSuccess,
    reset,
    row,
  ]);

  const [
    doCreateLocationSchedulePM,
    {
      data: createData,
      isError: isCreateError,
      error: createError,
      isLoading: isLoadingCreate,
      isSuccess: isCreateSuccess,
      reset: resetCreate,
    },
  ] = useAddSheduleLocationPreventiveMaintenanceMutation();

  const useCreateLocationSchedulePM = useCallback(() => {
    const doCreate = async data => {
      if (
        data.serviceFrequency.type === SelectionType.MONTH &&
        (!data.serviceFrequency.dayOfMonth ||
          !data.serviceFrequency.serviceMonths)
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Please Enter Day of Month and Service Months',
          }),
        );
        return;
      } else if (
        data.serviceFrequency.type === SelectionType.DAY &&
        !data.serviceFrequency.daysBetweenService
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Please Enter Days Between Service',
          }),
        );
        return;
      } else {
        data.pmId = masterPmData?.id;
        data.orgIds = data.locations?.map(location => location.orgId);
        data.priorityId = data.priority?.id;
        data.daysBetween = data?.serviceFrequency?.daysBetweenService;
        data.nte = parseFloat(data.nte);
        data.startDate = moment(data.startDate).format('MM/DD/YYYY');
        const serviceMonthsArray: number[] =
          data.serviceFrequency?.serviceMonths || [];
        if (serviceMonthsArray.length > 0) {
          data.serviceMonths = serviceMonthsArray;
        } else {
          data.serviceMonths = null;
        }
        data.dayOfMonth = data?.serviceFrequency?.dayOfMonth;
        if (data.daysBetween && data.daysBetween <= data.createInAdvance) {
          dispatch(
            setSnackbar({
              severity: 'error',
              message:
                'Please Enter Generate WO in Advance value less than Days Between Service',
            }),
          );
        } else {
          data.spCompanyId = data['serviceProvider.name']?.id || rankOneSp?.id;
          doCreateLocationSchedulePM(data);
        }
      }
    };

    return [
      doCreate,
      {
        data: createData,
        isError: isCreateError,
        error: createError,
        isLoading: isLoadingCreate,
        isSuccess: isCreateSuccess,
        reset: resetCreate,
      },
    ];
  }, [
    createData,
    isCreateError,
    createError,
    isLoadingCreate,
    isCreateSuccess,
    resetCreate,
    masterPmData,
  ]);

  return (
    <>
      <ECDynamicPageTemplate
        key={forceRefreshKey}
        idToGetListBy={id}
        marginTop={false}
        pageTitle={t('translation:pages.pmLocation.title')}
        useLazyGetListQuery={useLazyGetPreventiveMaintenanceBranchListQuery}
        useCreateMutation={useCreateLocationSchedulePM}
        useUpdateMutation={useUpdatePreventiveMaintenance}
        detailsFields={detailsFormFields}
        detailsConfig={detailsForm.config}
        editFormConfig={editForm.config}
        editFormFields={editFormFields}
        createFormConfig={createForm.config}
        createFormFields={createFormFields}
        onRowClick={handleRowClick}
        onDrawerClose={resetRow}
        customDrawerTitle={customTitle}
        onEditButtonClick={handleEditButtonClick}
        drawerTitleBarBGColor={themes.light.palette.other.divider}
        onChange={handleChange}
        showEditButton={true}
        additionalDeleteAction={handleAdditionalDeleteAction}
        multiSelectTable
        selectedRows={selectedRows}
        moduleName="preventivemaintenance"
        multiSelectedRows={handleSelectedRows}
        clearSelection={clearAllSelectedRows}
        hasDetails={true}
        showOnlyHistoryTab={true}
        historyTabIdAttribute={['pmId', 'id']}
        selectRowsMaxCount={50}
        showDrawerDetailTitle={false}
        customMultiSelectActionButton={() => (
          <ECRequirePermission scopes={[P.EditWorkOrder, P.GetWorkOrder]}>
            <ECButton
              variant="outlined"
              startIcon={<Edit />}
              disabled={disableEditButton}
              onClick={() => {
                if (!disableEditButton) {
                  setIsBulkEditDrawerOpen(true);
                }
              }}
              sx={bulkEditButtonStyle}
            >
              {t('translation:dynamicForm.bulkEdit')}
            </ECButton>
          </ECRequirePermission>
        )}
        shouldNotUseActiveFilter
      />
      <BulkEditDrawer
        masterPm={masterPmData}
        isOpen={isBulkEditDrawerOpen}
        onClose={handleClose}
        selectedRows={selectedRows}
        onSelectedRowsChange={handleSelectedRowsChange}
      />
    </>
  );
}
