import { TypographyOwnProps } from '@mui/material/Typography';
import { ECLink } from '../ECLink';
import { ECTypography } from '../ECTypography';

interface ECPoliceAndTermsProps extends Omit<TypographyOwnProps, 'align'> {}

export const ECPoliceAndTerms = ({
  color = 'white',
  variant = 'body2',
  ...props
}: ECPoliceAndTermsProps) => {
  return (
    <ECTypography variant={variant} align="center" color={color} {...props}>
      <ECLink
        color="inherit"
        href="https://ecotrak.com/privacy/"
        target="_blank"
      >
        Privacy Policy
      </ECLink>
      {' | '}
      <ECLink color="inherit" href="https://ecotrak.com/terms/" target="_blank">
        Terms of Service
      </ECLink>
    </ECTypography>
  );
};
