/* eslint-disable prettier/prettier */
import { isNil } from 'lodash';
import { emptyApi } from './emptyApi';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';
import { WorkflowNextStatus, WorkflowStatusUpdate } from 'types/WorkflowStatus';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'PurchaseOrder',
    'PurchaseOrderItemsForDropdown',
    'PurchaseOrderById',
    'PurchaseOrdertatusList',
  ],
});

export const purchaseOrderApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAllPurchaseOrderList: builder.query<BaseType<any[]>, QueryParams>({
      query: params => {
        const { st, ...restParams } = params;
        return {
          url: `purchaseorder`,
          params: {
            ...restParams,
            ob: restParams.ob || 'prchsordr.id',
          },
        };
      },
      providesTags: ['PurchaseOrder'],
    }),
    getPurchaseOrderById: builder.query<any, number>({
      query: id => {
        return {
          url: `purchaseorder/${id}`,
        };
      },
      providesTags: ['PurchaseOrderById'],
    }),
    getPurchaseOrderItemsForDropdown: builder.query<
      BaseType<any[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'purchaseorder/items/dropdown',
          params,
        };
      },
      providesTags: ['PurchaseOrderItemsForDropdown'],
    }),
    createPurchaseOrder: builder.mutation<void, any>({
      query: body => {
        return {
          url: 'purchaseorder',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['PurchaseOrder'],
    }),
    updatePurchaseOrder: builder.mutation<void, any>({
      query: ({ id, ...body }) => {
        return {
          url: `purchaseorder/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['PurchaseOrderById', 'PurchaseOrdertatusList'],
    }),

    getPurchaseOrderNextStatusList: builder.query<WorkflowNextStatus[], number>(
      {
        query: id => {
          return {
            url: `purchaseorder/${id}/status/next`,
          };
        },
        providesTags: ['PurchaseOrdertatusList'],
      },
    ),
    purchaseOrderActionClick: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({
        id,
        statusTo,
        declineTypeId,
        declineReason,
        note,
        recipientIds,
      }) => {
        let updatedWorkOrderStatus: any = {
          statusTo,
          declineTypeId,
          declineReason,
        };
        if (!isNil(note)) {
          updatedWorkOrderStatus.note = note;
        }
        if (!isNil(recipientIds)) {
          updatedWorkOrderStatus.recipientIds = recipientIds;
        }
        return {
          url: `purchaseorder/${id}/status/change`,
          method: 'put',
          body: updatedWorkOrderStatus,
        };
      },
      invalidatesTags: ['PurchaseOrderById', 'PurchaseOrdertatusList'],
    }),
  }),
});

export const {
  useGetAllPurchaseOrderListQuery,
  useLazyGetAllPurchaseOrderListQuery,
  useGetPurchaseOrderItemsForDropdownQuery,
  useLazyGetPurchaseOrderItemsForDropdownQuery,
  useGetPurchaseOrderByIdQuery,
  useLazyGetPurchaseOrderByIdQuery,
  useCreatePurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
  useGetPurchaseOrderNextStatusListQuery,
  useLazyGetPurchaseOrderNextStatusListQuery,
  usePurchaseOrderActionClickMutation,
} = purchaseOrderApi;
