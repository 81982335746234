import { ECBox, ECIconButton } from '../../components';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { DispatchTableView } from './DispatchTableView';
import { DispatchSwimlanesView } from './DispatchSwimlanesView';
import { useCallback, useState } from 'react';
import { ViewHeadline, ViewWeek } from '@mui/icons-material';
import { ECToggleButton } from 'app/components/ECToggleButton';
import { ToggleButtonGroup } from '@mui/material';

export function DispatchTabsPage() {
  const isCustomer = useCustomerUser();
  const { t } = useTranslation();

  // custom state and widget to change between table view and swimlanes in the click of a button
  const [viewMode, setViewMode] = useState<string | null>('swimlanes');

  const handleAlignment = useCallback((event, newAlignment) => {
    if (newAlignment !== null) {
      setViewMode(newAlignment);
    }
  }, []);

  const renderSwitchViewsButton = useCallback(() => {
    return (
      <ECBox display="flex" justifyContent="flex-end" alignItems="center" p={2}>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleAlignment}
          sx={{
            '& .MuiToggleButtonGroup-grouped': {
              borderRadius: '4px',
              '&.Mui-selected': {
                backgroundColor: 'rgb(233, 233, 233)',
                color: 'black',
              },
              '&.Mui-selected:hover': {
                backgroundColor: 'rgb(233, 233, 233)',
                color: 'black',
              },
            },
          }}
        >
          <ECToggleButton value={'swimlanes'} size="small">
            <ViewWeek />
          </ECToggleButton>
          <ECToggleButton value={'table'} size="small">
            <ViewHeadline />
          </ECToggleButton>
        </ToggleButtonGroup>
      </ECBox>
    );
  }, [viewMode]);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <ECBox pt={10} height="100%">
      <Helmet titleTemplate={`Dispatch`} defaultTitle={'Dispatch'} />
      {viewMode === 'swimlanes' ? (
        <DispatchSwimlanesView
          renderChangeViewModeButton={renderSwitchViewsButton}
        />
      ) : (
        <DispatchTableView
          renderChangeViewModeButton={renderSwitchViewsButton}
        />
      )}
    </ECBox>
  );
}
