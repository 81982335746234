import { ECBox, ECButton, ECGrid, ECTypography } from '..';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { currencyFormatter } from '../../../utils/strings/currency-formatter';
import { InventoryItemShape } from 'types/Inventory';
import { useMemo } from 'react';
import { P } from 'types/Permission';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';

interface ECAllocatedInventoryProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  label?: string;
  placeholder?: string;
  onButtonClicked?: () => void;
  allocatedItems?: InventoryItemShape[];
  shouldHideAllocateButton?: boolean;
}

export const ECAllocatedInventory: React.FC<ECAllocatedInventoryProps> = ({
  label,
  placeholder,
  onButtonClicked,
  allocatedItems,
  shouldHideAllocateButton,
}) => {
  const handleOpenDrawerClickButton = () => {
    onButtonClicked?.();
  };

  const renderAllocatedInventoryItems = useMemo(() => {
    return allocatedItems?.map?.(item => (
      <ECGrid
        display="flex"
        direction="row"
        justifyContent="space-between"
        mb={1}
        key={item.id}
      >
        <ECBox>
          <ECTypography
            display="inline"
            color="rgba(0,0,0,0.6)"
            sx={{ overflowWrap: 'anywhere' }}
          >
            {_.truncate(item.name, { length: 50 })}
          </ECTypography>
          <ECTypography
            display="inline"
            fontWeight={500}
            marginLeft={1}
            color="rgba(0,0,0,0.7)"
          >
            ({item.count} items)
          </ECTypography>
        </ECBox>
        <ECTypography>{currencyFormatter.format(item.total)}</ECTypography>
      </ECGrid>
    ));
  }, [allocatedItems]);

  const shouldShowInventoryItems = allocatedItems && !!allocatedItems.length;

  const hasInventoryPermission = useHasPermission([P.GetInventory]);

  return (
    <>
      {shouldShowInventoryItems ? (
        <ECBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: '8px 16px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '8px 8px 0 0',
            marginTop: -1,
          }}
        >
          <ECTypography>Total for Inventory</ECTypography>
          <ECTypography fontSize={24}>
            {currencyFormatter.format(_.sumBy(allocatedItems, i => i.total))}
          </ECTypography>
        </ECBox>
      ) : null}
      <ECBox
        display="flex"
        bgcolor={theme => theme.palette.grey[200]}
        flexDirection="column"
        height="100%"
        p={1}
      >
        <ECTypography
          variant="h6"
          sx={{ color: 'rgb(0, 0, 0, 0.5)', height: '40px' }}
        >
          {label}
        </ECTypography>
        {shouldShowInventoryItems ? (
          <ECGrid mb={1} mt={1} direction={'column'}>
            {renderAllocatedInventoryItems}
          </ECGrid>
        ) : null}
        {!shouldHideAllocateButton && (
          <ECGrid item xs={5} mt={1} mb={1}>
            <ECButton
              variant="outlined"
              style={{ background: 'transparent' }}
              startIcon={<AddIcon />}
              onClick={handleOpenDrawerClickButton}
              disabled={!hasInventoryPermission}
            >
              {placeholder}
            </ECButton>
          </ECGrid>
        )}
      </ECBox>
    </>
  );
};
