import { Checkbox, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECMenuItem, ECSelect } from '../ECSelect';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { ECChip } from '../ECChip';
import { ECOnlyMeFilter } from './ECOnlyMeFilter';

interface ECStatusFilterProps {
  statusFilterOptions: any[];
  endpoint: string;
  onFilterSubmit?: () => void;
}

export const ECStatusFilter = ({
  statusFilterOptions,
  endpoint,
  onFilterSubmit,
}: ECStatusFilterProps) => {
  const dispatch = useDispatch();
  const [statusFilterOpen, setStatusFilterOpen] = useState<boolean>(false);
  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const statusFilterOptionsMemoized = useMemo(() => {
    return statusFilterOptions.map(option => {
      return (
        <ECMenuItem key={option?.fieldName} value={option?.fieldName}>
          <ListItemIcon>
            <Checkbox
              checked={
                activeFilter?.statuses &&
                activeFilter?.statuses?.some(s => s === option?.fieldName)
              }
            />
          </ListItemIcon>
          <ListItemText
            sx={{
              '& span': {
                maxWidth: '170px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            primary={option?.label}
          />
          <ECOnlyMeFilter
            activeFilter={{
              statuses: [option?.fieldName],
              endpoint,
            }}
            closePopover={() => {
              onFilterSubmit?.();
              setStatusFilterOpen(false);
            }}
          />
        </ECMenuItem>
      );
    });
  }, [statusFilterOptions, activeFilter?.statuses]);

  const handleOpen = useCallback(() => {
    setStatusFilterOpen(true);
  }, []);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      {!!statusFilterOptions?.length && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
          >
            Status:
          </ECTypography>
          <ECSelect
            labelId="mutiple-select-label"
            multiple
            variant="standard"
            disableUnderline
            open={statusFilterOpen}
            onOpen={handleOpen}
            value={activeFilter?.statuses || []}
            MenuProps={{
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              anchorOrigin: {
                vertical: 40,
                horizontal: 90,
              },
            }}
            onClose={() => {
              if (activeFilter?.statuses?.length === 0) {
                dispatch(
                  setActiveFilter({
                    statuses:
                      statusFilterOptions?.map(option => option?.fieldName) ||
                      [],
                    endpoint,
                  }),
                );
              }
              onFilterSubmit?.();
              setStatusFilterOpen(false);
            }}
            onChange={e => {
              const value = e.target.value as any;
              if (value[value.length - 1] === 'all') {
                dispatch(
                  setActiveFilter({
                    statuses:
                      activeFilter?.statuses?.length ===
                      statusFilterOptions.length
                        ? []
                        : statusFilterOptions?.map(option => option?.fieldName),
                    endpoint,
                  }),
                );
                onFilterSubmit?.();
                return;
              }
              dispatch(
                setActiveFilter({
                  statuses: value,
                  endpoint,
                }),
              );
              onFilterSubmit?.();
            }}
            displayEmpty
            renderValue={(selected: any) => {
              if (
                !selected?.length ||
                selected?.length === statusFilterOptions?.length
              ) {
                return (
                  <ECChip
                    sx={{ width: '48px', height: '24px' }}
                    label="All"
                    color="Light Grey"
                  />
                );
              }

              return (
                <ECChip
                  sx={{ width: '48px', height: '24px' }}
                  label={selected?.length}
                  color="Dark Blue"
                />
              );
            }}
          >
            <ECMenuItem value="all">
              <ECTypography
                variant="body1"
                color={theme => theme.palette.primary.main}
              >
                {activeFilter?.statuses?.length === statusFilterOptions?.length
                  ? 'Deselect All'
                  : 'Select All'}
              </ECTypography>
            </ECMenuItem>
            {statusFilterOptionsMemoized}
          </ECSelect>
        </>
      )}
    </Stack>
  );
};
