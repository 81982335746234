import { Checkbox, CheckboxProps } from '@mui/material';
import { ECChip } from '../ECChip';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECAlert } from '../ECAlert';
import { ForwardedRef, forwardRef } from 'react';

interface ECCheckboxProps extends CheckboxProps {
  label?: string;
  withBorder?: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
  customComponent?: React.ReactNode;
}

export const ECCheckbox = forwardRef(
  (
    {
      readOnly,
      label,
      withBorder,
      checkedLabel = 'Yes',
      uncheckedLabel = 'No',
      customComponent,
      sx,
      ...props
    }: ECCheckboxProps,
    ref: ForwardedRef<any>,
  ) => {
    if (readOnly) {
      if (withBorder) {
        return (
          <ECAlert
            severity={props.checked ? 'success' : 'error'}
            variant="outlined"
            icon={false}
            sx={sx}
            ref={ref}
          >
            <ECBox display="flex" alignItems="center" gap={2}>
              <ECChip
                variant="filled"
                label={props.checked ? checkedLabel : uncheckedLabel}
                color={props.checked ? 'Light Green' : 'Red'}
                sx={{
                  bgcolor: theme =>
                    props.checked
                      ? theme.palette.success.outlinedHoverBackground
                      : theme.palette.error.outlinedHoverBackground,
                  color: theme =>
                    props.checked
                      ? theme.palette.success.dark
                      : theme.palette.error.dark,
                }}
              />
  
              <ECTypography>{label}</ECTypography>
              {customComponent && customComponent}
            </ECBox>
          </ECAlert>
        );
      }

      return (
        <ECBox display="flex" alignItems="center" gap={2}>
          <ECChip
            variant="filled"
            label={props.checked ? checkedLabel : uncheckedLabel}
            color={props.checked ? 'Light Green' : 'Red'}
            sx={{
              bgcolor: theme =>
                props.checked
                  ? theme.palette.success.outlinedHoverBackground
                  : theme.palette.error.outlinedHoverBackground,
              color: theme =>
                props.checked
                  ? theme.palette.success.dark
                  : theme.palette.error.dark,
            }}
          />

          <ECTypography>{label}</ECTypography>
          {customComponent && customComponent}
        </ECBox>
      );
    }

    if (withBorder) {
      return (
        <ECAlert
          severity="info"
          variant="outlined"
          icon={false}
          sx={sx}
          ref={ref}
        >
          <ECBox display="flex" alignItems="center">
            <Checkbox {...props} />

            {label && <ECTypography variant="subtitle1">{label}</ECTypography>}
            {customComponent && customComponent}
          </ECBox>
        </ECAlert>
      );
    }

    if (customComponent) {
      return (
        <ECBox display="flex" alignItems="center">
          <Checkbox {...props} />

          {label && <ECTypography variant="subtitle1">{label}</ECTypography>}
          {customComponent && customComponent}
        </ECBox>
      );
    }

    return <Checkbox {...props} ref={ref} />;
  },
);
