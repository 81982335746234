import { Close } from '@mui/icons-material';
import { Rank } from '.';
import { ECFormControl } from '../ECForm';
import { ECIconButton } from '../ECIconButton';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { ECCheckbox } from '../ECCheckbox';
import { ECBox } from '../ECBox';
import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { RankFieldQueryHookType } from './RankFieldOptionsHookMap';
import { ECAutocompletePaginated } from '../ECAutocompletePaginated';

interface ECRankItemFieldProps {
  position: number;
  id: UniqueIdentifier;
  rank?: Rank;
  label?: string;
  adminOnly?: boolean;
  shouldShowAdminOnly?: boolean;
  onChange?: (rank: Rank) => void;
  onDelete: (position: number) => () => void;
  renderRankText?: (position: number) => string;
  queryHookFn?: RankFieldQueryHookType;
  queryHookSt?: string;
  obAlias?: string;
  placeholder?: string;
  fieldName?: string;
  filterHook?: RankFieldQueryHookType;
  filterHookParams?: any;
  filterKey?: string;
  dataKey?: string;
  inGroupTitle?: string;
  outGroupTitle?: string;
}

export const ECRankItemField = memo(
  ({
    position,
    id,
    rank,
    label,
    adminOnly,
    shouldShowAdminOnly = true,
    onChange,
    onDelete,
    renderRankText,
    queryHookFn,
    queryHookSt,
    placeholder,
    fieldName,
    obAlias,
    filterHook,
    filterKey,
    inGroupTitle,
    outGroupTitle,
    dataKey,
    filterHookParams,
  }: ECRankItemFieldProps) => {
    const [selectedRank, setSelectedRank] = useState<any>({
      id: rank?.id,
      name: rank?.data,
    });

    const [adminOnlyFlag, setAdminOnlyFlag] = useState<boolean>(
      adminOnly || false,
    );

    const updateRank = useCallback(
      (newRank: any, adminOnlyFlag: boolean) => {
        onChange?.({
          ...rank,
          position: rank?.position ?? rank?.order,
          id: newRank ? newRank?.id : rank?.id,
          data: newRank ? newRank?.name : rank?.data,
          adminOnly: adminOnlyFlag,
        } as Rank);
      },
      [rank],
    );

    useEffect(() => {
      updateRank(selectedRank, adminOnlyFlag);
    }, [selectedRank, adminOnlyFlag]);

    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const filterHookData = filterHook?.({
      t: 0,
      ...filterHookParams,
    });
    const [filterOptions, setFilterOptions] = useState<any[]>([]);
    useEffect(() => {
      if (filterHookData && filterHookData.data) {
        if (filterHookData.data?.data) {
          setFilterOptions(filterHookData.data.data);
        } else {
          setFilterOptions(filterHookData.data);
        }
      }
    }, [filterHookData]);
    const { groupBy, groupByTransform } = useMemo(() => {
      if (
        filterKey &&
        dataKey &&
        inGroupTitle &&
        outGroupTitle &&
        filterOptions
      ) {
        const groupByFn = (item: { [key: string]: any }) => {
          const filterOption = filterOptions.find(option => {
            return option[filterKey] === item[dataKey];
          });
          if (filterOption) {
            return inGroupTitle;
          }
          return outGroupTitle;
        };

        const groupBySortFn = (options: any[]) => {
          const uniqAppendedOptions = [...filterOptions, ...options].filter(
            (item, index, self) => {
              if (item.id && rank?.id) {
                if (item.id === rank?.id || item[filterKey] === rank?.data) {
                  return false;
                }
              }
              return (
                self.findIndex(t => t[filterKey] === item[dataKey]) === index
              );
            },
          );
          return uniqAppendedOptions;
        };
        return {
          groupBy: groupByFn,
          groupByTransform: groupBySortFn,
        };
      }
      return {
        groupBy: undefined,
        groupByTransform: undefined,
      };
    }, [filterOptions]);

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <ECStack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ cursor: 'grab' }}
        >
          <ECTypography noWrap variant="body2">
            {renderRankText?.(position) || `Rank-${position}`}
          </ECTypography>

          <ECIconButton disableRipple sx={{ cursor: 'grab' }} size="large">
            <DragIndicatorIcon />
          </ECIconButton>

          <ECFormControl variant="filled" sx={{ flex: '1 1 60%' }}>
            <ECAutocompletePaginated
              sx={{
                width: '100%',
                '& .MuiAutocomplete-inputRoot': {
                  paddingRight: '9px !important',
                },
              }}
              value={{
                id: selectedRank?.id,
                name: selectedRank?.name,
              }}
              onChange={newValue => {
                setSelectedRank(newValue);
              }}
              useQuery={queryHookFn}
              st={queryHookSt}
              placeholder={placeholder}
              fieldName={fieldName || 'name'}
              obAlias={obAlias || 'name'}
              key={`rank-${rank?.position}`}
              label={label}
              groupBy={groupBy}
              groupByTransform={groupByTransform}
            />
          </ECFormControl>
          {shouldShowAdminOnly && (
            <ECBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ECCheckbox
                checked={adminOnly}
                onChange={event => {
                  setAdminOnlyFlag(event.target.checked);
                }}
              />
              <ECTypography noWrap variant="body2">
                Admin Only
              </ECTypography>
            </ECBox>
          )}
          <ECIconButton
            type="warning"
            size="small"
            onClick={onDelete(position)}
          >
            <Close />
          </ECIconButton>
        </ECStack>
      </div>
    );
  },
);
