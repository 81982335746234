import {
  ECBox,
  ECTypography,
  ECSPForm,
  ECPaper,
  ECSPHowItWorks,
} from 'app/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';
import { setTitle } from 'store/slice/page';
import { bgImage } from './assets';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

export function SPDirectorySearch() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch('/panel/*');

  const handleSearch = (trade, location, name) => () => {
    navigate(`${match?.pathnameBase}/service-providers/sp-directory/results`, {
      state: { trade, location, name },
    });
  };

  useEffect(() => {
    dispatch(setTitle(t('translation:panelDrawer.spDirectory')));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t('translation:panelDrawer.spDirectory')}</title>
      </Helmet>
      <ECBox display="flex" height="100%" width="100%">
        <img
          width="100%"
          style={{ position: 'absolute', marginTop: '130px' }}
          src={bgImage}
          alt="detail"
        />
        <ECBox
          display="flex"
          height="100%"
          width="100%"
          flexDirection="column"
          alignItems="flex-start"
          zIndex={1}
          overflow="hidden"
        >
          <ECBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
            sx={{
              width: '100%',
              background:
                'linear-gradient(90deg, #010D1D 14.82%, #051D3F 38.41%, #020E1E 100%)',
            }}
          >
            <ECPaper
              elevation={3}
              sx={{ width: '90%', margin: '2rem auto', p: 2, pt: 3 }}
            >
              <ECSPForm
                onSearch={handleSearch}
                variant="standard"
                sx={{ width: '100%' }}
              />
            </ECPaper>
          </ECBox>

          <ECBox
            sx={{
              width: '90%',
              margin: '1rem auto',
              padding: '0 2rem',
            }}
          >
            <ECTypography
              textAlign="left"
              fontWeight="bold"
              variant="h2"
              sx={{
                width: '50vw',
                color: 'white',
                textShadow: '0 0 75px rgba(3, 14, 37, 0.9)',
              }}
            >
              {t('translation:spDirectory.search.title')}
            </ECTypography>
          </ECBox>

          {/* <ECSPHowItWorks position="relative" bottom={0} /> */}
        </ECBox>
      </ECBox>
    </>
  );
}
