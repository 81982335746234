import { useState } from 'react';
import { ECPopover } from '../ECPopover';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECFormControl, ECFormControlLabel } from '../ECForm';
import { ECRadio, ECRadioGroup } from '../ECRadio';
import { ECButton } from '../ECButton';
import { ECSelect, ECMenuItem } from '../ECSelect';
import { InputLabel } from '@mui/material';
import { ECAlert } from '../ECAlert';
import { ECLink } from '../ECLink';

export enum ExportType {
  CURRENT = 'current',
  ALL = 'all',
}

export enum ExportFormat {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
}

interface ECExportPopoverProps {
  open: boolean;
  onClose?: () => void;
  anchorEl: any;
  loading: boolean;
  handleExportRequest: (exportType: ExportType, format: ExportFormat) => void;
}

export const ECExportPopover = ({
  open,
  onClose,
  anchorEl,
  loading,
  handleExportRequest,
}: ECExportPopoverProps) => {
  const [exportType, setExportType] = useState(ExportType.CURRENT);
  const [format, setFormat] = useState(ExportFormat.CSV);

  const handleChangeExportType = event => {
    setExportType(event.target.value);
  };

  const handleChangeFormat = event => {
    setFormat(event.target.value);
  };
  return (
    <ECPopover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={{ zIndex: 9999, marginTop: '1rem' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: 400,
          position: 'relative',
          overflow: 'visible',
        },
      }}
    >
      <ECBox
        sx={{
          position: 'absolute',
          top: -13,
          left: '85%',
          transform: 'translateX(-50%)',
          width: 0,
          height: 0,
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          borderBottom: '15px solid #fff',
          zIndex: -1,
          filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.3))',
        }}
      ></ECBox>
      <ECBox p={2} sx={{ width: '400px' }} borderRadius={20}>
        <ECTypography variant="h6" gutterBottom>
          Export
        </ECTypography>
        <ECFormControl component="fieldset">
          <ECRadioGroup
            aria-label="export-pages"
            name="export-pages"
            value={exportType}
            onChange={handleChangeExportType}
            row
          >
            <ECFormControlLabel
              value="current"
              control={<ECRadio />}
              label="Current Page"
            />
            <ECFormControlLabel
              value="all"
              control={<ECRadio />}
              label="All Pages"
            />
          </ECRadioGroup>
        </ECFormControl>
        {exportType === ExportType.ALL && (
          <ECAlert
            severity="info"
            variant="outlined"
            sx={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}
          >
            <ECTypography variant="body1">
              Only the first{' '}
              <ECTypography
                component="span"
                sx={theme => ({
                  color: theme.palette.primary.main,
                })}
              >
                100,000
              </ECTypography>{' '}
              records will be included in the export; if you need a larger file,
              please contact our{' '}
              <ECLink
                href="https://ecotrak.zendesk.com/hc/en-us"
                underline="hover"
                color="primary"
                target="_blank"
              >
                support team
              </ECLink>
              .
            </ECTypography>
          </ECAlert>
        )}
        <ECFormControl fullWidth sx={{ marginTop: '1rem' }}>
          <InputLabel
            sx={theme => ({
              color: theme.palette.grey[600],
              marginTop: '.75rem',
              marginLeft: '-.25rem',
            })}
            id="select-label"
          >
            Format
          </InputLabel>
          <ECSelect
            value={format}
            onChange={handleChangeFormat}
            displayEmpty
            variant="filled"
          >
            <ECMenuItem value={ExportFormat.CSV}>{ExportFormat.CSV}</ECMenuItem>
            <ECMenuItem value={ExportFormat.EXCEL}>
              {ExportFormat.EXCEL}
            </ECMenuItem>
          </ECSelect>
        </ECFormControl>
        <ECBox mt={2} display="flex" justifyContent="flex-end">
          <ECButton
            sx={{ marginRight: '1rem' }}
            variant="text"
            onClick={onClose}
          >
            Cancel
          </ECButton>
          <ECButton
            onClick={() => handleExportRequest(exportType, format)}
            variant="contained"
            color="primary"
            isLoading={loading}
          >
            Export
          </ECButton>
        </ECBox>
      </ECBox>
    </ECPopover>
  );
};

export default ECExportPopover;
