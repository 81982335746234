import { Method } from '../../../types/BaseType';
import _ from 'lodash';

export const processRfpBody = (bodyToRender, method) => {
  if (method === Method.POST) {
    const customOrder = [
      'rfpTitle',
      'rfpStatus',
      'workOrderId',
      'location',
      'storeNumber',
      'asset',
      'category',
      'area',
      'assetGroup',
      'problem',
      'customerAddress',
      'rfpDescription',
      'serviceProviders',
      'submittedDate',
    ];

    // Clone bodyToRender and apply modifications
    const updatedBody = {
      ...bodyToRender,
      rfpTitle: bodyToRender?.name,
    };
    delete updatedBody?.description;

    // Rename and order fields
    const renamedAndOrderedObject = customOrder.reduce((acc, key) => {
      if (updatedBody.hasOwnProperty(key)) {
        acc[key] = updatedBody[key];
      }
      return acc;
    }, {});

    // Deep clone the final object if necessary
    return _.cloneDeep(renamedAndOrderedObject);
  }

  if (method === Method.PUT && bodyToRender?.description) {
    const updatedBody = {
      ...bodyToRender,
      rfpDescription: bodyToRender?.description,
    };
    delete updatedBody?.description;
    return _.cloneDeep(updatedBody);
  }

  return bodyToRender;
};
