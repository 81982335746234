import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
  findToken,
  storeToken,
  findRefreshToken,
  findAccessToken,
  clearEverythingFromLogin,
} from './utils';
import { findOverrideUser } from './utils';
import moment from 'moment';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: headers => {
    const token = findToken();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    const overrideUser = findOverrideUser();
    if (overrideUser) {
      headers.set('override-username', overrideUser);
    }
    return headers;
  },
});

export const logoutUser = errorMessage => {
  clearEverythingFromLogin();
  window.location.replace(`/${errorMessage ? `?message=${errorMessage}` : ''}`);
};

const PROFILE_REQUEST_INTERVAL_IN_MIN = 15;

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const userProfileLastCallTimestamp = Number(
    localStorage?.getItem('userProfileLastCallTimestamp'),
  );

  const companyProfileLastCallTimestamp = Number(
    localStorage?.getItem('companyProfileLastCallTimestamp'),
  );

  const customSessionTimeoutTimestamp = Number(
    localStorage.getItem('customSessionTimeoutTimestamp'),
  );

  const customSSOSessionTimeoutTimestamp = Number(
    localStorage.getItem('customSSOSessionTimeoutTimestamp'),
  );

  if (
    customSessionTimeoutTimestamp > 0 ||
    customSSOSessionTimeoutTimestamp > 0
  ) {
    const usedTimeout =
      customSSOSessionTimeoutTimestamp || customSessionTimeoutTimestamp;
    if (moment().isAfter(moment(usedTimeout))) {
      logoutUser('Your session has expired');
    }
  }

  if (
    ((args as FetchArgs)?.url === 'user/profile' || args === 'user/profile') &&
    moment().diff(moment(userProfileLastCallTimestamp), 'minutes') <
      PROFILE_REQUEST_INTERVAL_IN_MIN
  ) {
    localStorage.setItem('userProfileFromCache', '1');
    const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');
    return {
      data: userProfile,
    };
  }
  localStorage.setItem('userProfileFromCache', '0');

  if (
    ((args as FetchArgs)?.url === 'company/profile' ||
      args === 'company/profile') &&
    moment().diff(moment(companyProfileLastCallTimestamp), 'minutes') <
      PROFILE_REQUEST_INTERVAL_IN_MIN &&
    localStorage.getItem('ignoreCacheOnceForCompanyProfile') === '0'
  ) {
    localStorage.setItem('companyProfileFromCache', '1');
    return {
      data: JSON.parse(localStorage.getItem('companyProfile') || '{}'),
    };
  }
  localStorage.setItem('ignoreCacheOnceForCompanyProfile', '0');
  localStorage.setItem('companyProfileFromCache', '0');

  let result = await baseQuery(args, api, extraOptions);

  // intercept for error 401 unauthorized
  if (result.error && result.error.status === 401) {
    const oldRefreshToken = findRefreshToken();
    const oldToken = findToken();
    const oldAccessToken = findAccessToken();

    // try to get a new token
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh',
        method: 'POST',
        body: {
          refresh_token: oldRefreshToken,
          id_token: oldToken,
          access_token: oldAccessToken,
        },
      },
      api,
      extraOptions,
    );

    const refreshResulData = refreshResult.data as any;

    const idToken = refreshResulData?.id_token;
    const accessToken = refreshResulData?.access_token;
    const refreshToken = refreshResulData?.refresh_token;

    if (idToken && accessToken && refreshToken) {
      // store the new token
      storeToken(idToken);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      // retry the initial query
      result = await baseQuery(args, api, extraOptions);

      // if it still fails, logout
      if (result.error && result.error.status === 401) {
        logoutUser((result.error?.data as any)?.message);
      }
    } else {
      logoutUser((result.error?.data as any)?.message);
    }
  }
  // end of intercept

  return result;
};
