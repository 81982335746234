import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react';

import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import {
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  InputLabel,
  FormLabelProps,
  FormGroup,
  FormGroupProps,
  AlertColor,
} from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ECBox } from '../ECBox';
import { ECCard } from '../ECCard';
import { ECTypography } from '../ECTypography';
import { FieldTypes } from './FieldTypes';
import { CostTableData, CostTableItem } from '../ECWorkflowCostTable';

import {
  ChipType,
  ECButton,
  ECGrid,
  ECStack,
  Chip,
  FailureWarrantyOutput,
  ChipSearchOptions,
  ServiceFrequencyValueType,
  TreeNode,
  ECEasyTableConfigType,
} from '..';
import { useMediaQuery, useTheme } from '@mui/material';
import { FilePickerTypes, ImagePreview } from '../ECFilePicker';
import { ECCircularProgress } from '../ECCircularProgress';
import { useTranslation } from 'react-i18next';
import { P } from 'types/Permission';
import { AsseTradeProblemOutput } from '../ECAssetTradeProblemCreate';
import _ from 'lodash';
import { ECHourETAValues } from '../ECHourETA';
import { Rank } from '../ECRankField';
import { ECFormCell } from './ECFormCell';
import { ECEasyFormGridItem } from './ECEasyFormGridItem';
import sumCurrencyFields from 'utils/sum-currency-fields';
import { validateFields } from 'utils/validate-fields';
import { ECModal } from '../ECModal';
import { AssetProblemTroubleshootOutput } from '../ECAssetProblemTroubleshootCreate';
import { ECMarkupMaterialValues } from '../ECMarkupMaterial';
import { Delete } from '@mui/icons-material';
import { ECPopover } from '../ECPopover';
import { Lookup } from 'types/Lookup';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { themes } from 'styles/theme/themes';
import { StyleConstants } from 'styles/StyleConstants';

export * from './FieldTypes';

export const ECFormGroup = styled(FormGroup)<FormGroupProps>(
  ({ theme }) => ({}),
);

export const ECFormControl = styled(FormControl)<FormControlProps>(
  ({ theme }) => ({}),
);

export const ECFormLabel = styled(FormLabel)<FormLabelProps>(
  ({ theme }) => ({}),
);

export const ECFormControlLabel = styled(
  FormControlLabel,
)<FormControlLabelProps>(({ theme }) => ({}));

export const ECInputLabel = styled(InputLabel)<FormControlLabelProps>(
  ({ theme }) => ({}),
);

export interface ECEasyFormConfigType {
  variant?: string;
  name?: string;
  title?: string;
  subtitle?: string;
  showDeleteButton?: boolean;
  deleteConfirmationText?: string;
  cols?: number;
  submitTitle?: string;
  submitSubtitle?: string;
  showSubmitSubtitleField?: string;
  titleStyle?: SxProps<Theme>;
  fieldAsSubtitle?: string;
  actionButtonsContainerStyle?: SxProps<Theme>;
  titleFromFieldname?: string;
  addNewText?: string;
}
export interface ECEasyFormFieldType {
  allowExceeding?: boolean;
  visible?: boolean;
  type: FieldTypes;
  label?: string;
  description?: string;
  confirmLabel?: string;
  placeholder?: string;
  value?:
    | string[]
    | string
    | boolean
    | Date
    | null
    | number
    | number[]
    | ChipType[]
    | AsseTradeProblemOutput
    | AssetProblemTroubleshootOutput
    | FailureWarrantyOutput[]
    | ImagePreview[]
    | Chip[]
    | ECHourETAValues
    | ECMarkupMaterialValues[]
    | Rank[]
    | any[]
    | ServiceFrequencyValueType
    | Lookup
    | any;

  fieldName: string;
  exactSearch?: boolean;
  searchGroup?: string;
  isViewId?: boolean;
  chipsNamePath?: string;
  options?: string[] | any[];
  optionValues?: string[] | number[];
  defaultSelectedOption?: number;
  required?: boolean;
  requiredFieldName?: string;
  maxLength?: number;
  minLength?: number;
  checkEmail?: boolean;
  checkUrl?: boolean;
  isValid?: boolean;
  isNumeric?: boolean;
  onlyNumbers?: boolean;
  validationMessage?: string;
  errorMessage?: string;
  orientation?: 'horizontal' | 'vertical';
  disabled?: boolean;
  noMoreDataToFetch?: boolean;
  chips?: string[];
  optionalSx?: any;
  readOnly?: boolean;
  fileType?: FilePickerTypes;
  onDuplicateChip?: () => void;
  onChange?: (
    currentOutput: ECEasyFormFieldType[],
    value?: any,
    valueChange?: any,
  ) => void;
  onBlur?: (value: any, field: any) => void;
  onValidatePassword?: (isValidPassword: boolean) => void;
  fullWidth?: boolean;
  clearFieldsOnChange?: string[];
  clearOptionsOnChange?: string[];
  variant?: any;
  scopes?: P[];
  subFields?: ECEasyFormFieldType[];
  groupColumns?: number;
  groupName?: string;
  alignItems?: string;
  hideIfEmpty?: boolean;
  hideIfOtherFieldPopulated?: string;
  disableIfOtherFieldPopulated?: string;
  enableIfOtherFieldPopulated?: string;
  optionValueField?: string;
  helperText?: string;
  helperTextOptionalSx?: any;
  borderless?: boolean;
  noSpacing?: boolean;
  showHelperTextWhenOptionSelected?: string | number;
  overrideRowValue?: boolean;
  startAdornment?: string;
  maxValue?: number;
  minValue?: number;
  shouldShowActiveInactiveStatusLabel?: boolean;
  switchActiveLabel?: string;
  switchInactiveLabel?: string;
  changeSwitchActiveLabelWhenOtherFieldPopulatedText?: string;
  changeSwitchActiveLabelWhenOtherFieldPopulatedFieldname?: string;
  switchSelectedWhenOtherFieldPopulated?: string;
  isLoading?: boolean;
  populateOtherFields?: any;
  originalOptions?: any;
  showNaIfEmpty?: boolean;
  countryCodeFromField?: string;
  altEmptyText?: string;
  endText?: string;
  queryParams?: any;
  getOtherFieldValueAsParam?: string;
  useQuery?: any;
  shouldUseOriginalQueryParams?: boolean;
  renderCustomOption?: (option: any) => React.ReactNode;
  renderCustomSelectedValue?: (value: any) => React.ReactNode;
  obAlias?: string;
  sbAlias?: string;
  autoSearch?: boolean;
  images?: any[];
  dayOfMonth?: number;
  daysBetweenService?: number;
  serviceMonths?: string[] | undefined;
  resetState?: boolean;
  onClickViewUsersTable?: (row: any, event: any) => void;
  selectedChipUsersTable?: string | number | boolean;
  freeSolo?: boolean;
  autoSelect?: boolean;
  openOnFocus?: boolean;
  boxType?: string;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  color?: any;
  moduleName?: string;
  moduleId?: number;
  moduleIds?: number[];
  useRichTextEditor?: boolean;
  isSendButtonVisible?: boolean;
  customTitle?: string;
  selectedUsers?: TreeNode[];
  comment?: string;
  showCopyToClipboard?: boolean;
  severity?: AlertColor;
  actionButton?: {
    label: string;
    variant: 'text' | 'contained' | 'outlined';
    width: string;
    icon: React.ReactNode;
    onClick: () => void;
  };
  actionButtonClicked?: () => void;
  warrantyPeriodOptions?: {
    periodOrder: number;
    duration: number;
    periodCode: string;
    periodName: string;
    warrantyTypeName: string;
  }[];
  forceShowLabel?: boolean;
  multiple?: boolean;
  disableUnderline?: boolean;
  mask?: string;
  filterOptionsIds?: string[];
  filterOptionsFn?: (option: any) => boolean;
  tableConfig?: ECEasyTableConfigType;
  extraSearchField?: string;
  extraSearchFieldGroup?: 'or' | 'and';
  extraSearchFieldsAlias?: string[];
  nte?: number;
  allocatedInventoryItems?: any;
  invoiceTotal?: number;
  hideTotalCostNTE?: boolean;
  hideAllocateButton?: boolean;
  onChangeAutoCompleteSearchText?: (value: any) => void;
  customValidationMessage?: string;
  optionNameAttribute?: string;
  showRankReadOnlyAsChips?: boolean;
  shouldShowRankText?: boolean;
  shouldShowAdminOnly?: boolean;
  queryFnLabel?: string;
  customParams?: any;
  showErrorIcon?: boolean;
  fieldButtonProps?: {
    label: string;
    variant: 'text' | 'contained' | 'outlined';
    size?: 'small' | 'medium' | 'large';
    fullWidth?: boolean;
    disabled?: boolean;
    icon: React.ReactNode;
    iconPosition?: 'start' | 'end';
    onClick: () => void;
  };
  fieldConfig?: {
    cols?: number;
  };
  alert?: {
    message?: string;
    type?: AlertColor;
  } | null;
  withIcon?: boolean;
  linkifyEmail?: boolean;
  title?: string;
  items?: any[];
  creationMode?: boolean;
}

export interface ECEasyFormContainerProps {
  config: ECEasyFormConfigType;
  children: React.ReactNode;
  toolbarChildren?: React.ReactNode;
  noPadding?: boolean;
  hideToolBar?: boolean;
  pattern?: string;
  showTitle?: boolean;
  fields?: ECEasyFormFieldType[];
  drawerTitleBarBGColor?: string;
  noTopMargin?: boolean;
  customDrawerTitle?: string;
}

const patternsWithTransparentBg = ['filled', 'modal'];

export const ECEasyFormContainer = (props: ECEasyFormContainerProps) => {
  const {
    config,
    children,
    toolbarChildren,
    noPadding,
    hideToolBar,
    pattern,
    showTitle,
    fields,
    drawerTitleBarBGColor,
    noTopMargin,
    customDrawerTitle,
  } = props;
  const { title, subtitle, titleStyle, titleFromFieldname } = config;

  const isHeaderTextAvailable = !!(
    title ||
    titleFromFieldname ||
    customDrawerTitle ||
    subtitle
  );

  return (
    <ECBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        backgroundColor: theme =>
          pattern === 'standard'
            ? theme.palette.marketingSuggestion.marketingSuggestion
            : null,
        color: theme =>
          pattern === 'standard' ? theme.palette.common.white : null,
      }}
    >
      {(showTitle || subtitle) && (
        <ECBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={pattern === 'modal' && !noPadding ? 3 : 0}
          pb={pattern !== 'standard' && subtitle ? 3 : 0}
          px={3}
          sx={{
            backgroundColor: drawerTitleBarBGColor
              ? drawerTitleBarBGColor
              : patternsWithTransparentBg.includes(pattern as string)
                ? 'transparent'
                : isHeaderTextAvailable
                  ? themes.light.palette.other.divider
                  : 'transparent',
            height: subtitle ? 'auto' : StyleConstants.DRAWER_TITLE_HEIGHT,
          }}
        >
          <ECBox style={{ width: '100%' }}>
            {showTitle &&
              (title || titleFromFieldname || customDrawerTitle) && (
                <ECTypography
                  variant={pattern === 'modal' ? 'h6' : 'h4'}
                  sx={{ ...titleStyle }}
                >
                  {customDrawerTitle
                    ? customDrawerTitle
                    : titleFromFieldname
                      ? (fields &&
                          (fields?.find(
                            field => field.fieldName === titleFromFieldname,
                          )?.value as string)) ||
                        title
                      : title}
                </ECTypography>
              )}
            {subtitle && (
              <ECBox display="flex" alignItems="center" lineHeight={0} mt={2}>
                <ECTypography ml={0} variant="body2" fontWeight="regular">
                  {subtitle}
                </ECTypography>
              </ECBox>
            )}
          </ECBox>
          {!hideToolBar && toolbarChildren}
        </ECBox>
      )}
      {children}
    </ECBox>
  );
};
export interface SubmissionConfirmationModal {
  title?: string;
  buttonText?: string;
  cancelButtonText?: string;
  bodyText?: string;
  isVisible: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}
export interface ECEasyFormProps {
  config: ECEasyFormConfigType;
  fields: ECEasyFormFieldType[];
  additionalElements?: React.ReactNode;
  additionalActions?: React.ReactNode;
  isSendingData: boolean;
  isLoadingForm: boolean;
  onFormSubmit?: (
    formData: FormData,
    fields: ECEasyFormFieldType[],
    clearOnSuccess?: () => void,
  ) => void;
  isReadOnlyForm?: boolean;
  saveButtonDisabled?: boolean;
  toolbarChildren?: React.ReactNode;
  hideActions?: boolean;
  hideToolBar?: boolean;
  noPadding?: boolean;
  disclaimer?: string;
  existingData?: any;
  pattern?: string;
  fullHeight?: boolean;
  costTable?: CostTableData;
  showSaveButton?: boolean;
  nteSaveAlertObject?: any;
  onLoadMoreData?: (fieldName: string, newValue?: string) => void;
  onDuplicateChip?: () => void;
  additionalDeleteAction?: () => void;
  onChange?: (
    output: ECEasyFormFieldType[],
    fieldName?: string,
    valueChange?: any,
  ) => void;
  onChangeSearch?: (value: string) => void;
  showTitle?: boolean;
  outsideData?: ECEasyFormFieldType[];
  noPaddingX?: boolean;
  noPaddingY?: boolean;
  submissionConfirmationModal?: SubmissionConfirmationModal;
  isLoading?: boolean;
  drawerTitleBarBGColor?: string;
  noTopMargin?: boolean;
  stickyFooter?: boolean;
  shouldShowSubmitSubtitle?: boolean;
  customDrawerTitle?: string;
  searchOptionsState?: ChipSearchOptions;
  saveButtonColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  formFooter?: React.ReactNode;
  id?: string;
  formRefFromParent?: React.RefObject<HTMLFormElement>;
}

export const isEmptyValue = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' &&
      !(value instanceof Date) &&
      Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (typeof value === 'number' && value === 0)
  );
};

export const ECEasyForm = forwardRef((props: ECEasyFormProps, ref) => {
  const { t } = useTranslation();
  const {
    config,
    fields,
    isSendingData,
    isLoadingForm,
    additionalDeleteAction,
    onFormSubmit,
    additionalElements,
    additionalActions,
    isReadOnlyForm = false,
    saveButtonDisabled = false,
    toolbarChildren,
    hideActions,
    hideToolBar,
    noPadding,
    disclaimer,
    existingData,
    pattern,
    costTable,
    fullHeight = true,
    showSaveButton = true,
    nteSaveAlertObject,
    onDuplicateChip,
    onLoadMoreData,
    onChange,
    onChangeSearch,
    showTitle = true,
    outsideData,
    noPaddingX,
    noPaddingY,
    submissionConfirmationModal,
    isLoading,
    drawerTitleBarBGColor,
    noTopMargin,
    stickyFooter = true,
    shouldShowSubmitSubtitle,
    customDrawerTitle,
    searchOptionsState,
    saveButtonColor = 'primary',
    formFooter,
    id,
    formRefFromParent,
  } = props;

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const internalFormRef = useRef<HTMLFormElement>(null);
  const formRef = formRefFromParent || internalFormRef;

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const [output, setOutput] = useState<ECEasyFormFieldType[]>([]);
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const handleDeleteDeclineClick = () => {
    setShowDeletePopover(false);
  };
  const handleDeleteConfirmationClick = () => {
    setShowDeletePopover(false);
    additionalDeleteAction?.();
  };

  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (outsideData && !_.isEqual(outsideData, output)) {
      setOutput(outsideData);
    }
  }, [outsideData]);

  const [costTableItems, setCostTableItems] = useState<CostTableItem[]>(
    costTable ? JSON.parse(JSON.stringify(costTable.itemsRepair)) : [],
  );

  const handleCloseConfirmationModal = useCallback(() => {
    submissionConfirmationModal?.onClose?.();
    setConfirmationModalOpen(false);
  }, [submissionConfirmationModal, formRef]);

  const handleConfirmSubmit = useCallback(() => {
    submissionConfirmationModal?.onSubmit?.();
    formRef?.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
    setConfirmationModalOpen(false);
  }, [submissionConfirmationModal, formRef]);

  const handleCostTableChange = (item: CostTableItem) => {
    const entry = costTableItems?.findIndex(i => i.label === item.label);
    const updated: CostTableItem[] = costTableItems;

    if (entry !== undefined && entry !== -1 && costTableItems) {
      updated[entry].value = item.value;

      if (updated[entry]?.label === 'Labor Charge') {
        const laborEntry = updated?.find(i => i.label === 'Labor');
        if (laborEntry) {
          laborEntry.value = item.value?.reduce(
            (acc, item) => acc + +item?.totalCost,
            0,
          );
        }
      }

      setCostTableItems([...updated]);
    } else {
      setCostTableItems([...costTableItems, item]);
    }
  };

  // reset table values when exiting editing
  useEffect(() => {
    if (isReadOnlyForm && costTable) {
      setCostTableItems(costTable.itemsRepair);
    }
  }, [costTable, isReadOnlyForm]);

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (field.type === FieldTypes.Select) {
        return {
          ...field,
          value: field.value
            ? field.value
            : _.get(existingData, field.fieldName, field.value)
              ? field.optionValues?.[
                  field.options?.findIndex(
                    option =>
                      option?.label === _.get(existingData, field.fieldName) ||
                      option === _.get(existingData, field.fieldName) ||
                      option?.value === _.get(existingData, field.fieldName),
                  ) ?? 0
                ] || field.value
              : undefined,
        };
      } else if (field.type === FieldTypes.ChipAutocomplete) {
        const { fieldName, chipsNamePath } = field || {};
        return {
          ...field,
          value: field.value
            ? field.value
            : _.get(existingData, fieldName, field.value)?.map(fieldValue => ({
                label: fieldValue[`${chipsNamePath}`] ?? fieldValue,
                color: fieldValue.color,
                id: fieldValue.id,
              })),
          chips: _.get(existingData, fieldName, field.value)?.map(
            fieldValue => fieldValue[`${chipsNamePath}`] ?? fieldValue,
          ),
        };
      } else if (field.type === FieldTypes.Switch) {
        return {
          ...field,
          value: field.value
            ? field.value
            : _.get(existingData, field.fieldName, field.value)
              ? true
              : false,
        };
      } else if (field.type === FieldTypes.RadioWarrantyStartDate) {
        return {
          ...field,
          options: field.options?.map(option => ({
            ...option,
            value: _.get(existingData, option.fieldName, option.value),
          })),
        };
      } else if (field.type === FieldTypes.Group) {
        return {
          ...field,
          subFields: field.subFields?.map(handleExistingData),
        };
      } else {
        return {
          ...field,
          value: field.value
            ? field.value
            : _.get(existingData, field.fieldName, field.value),
        };
      }
    },
    [existingData],
  );

  // Initial field states
  useEffect(() => {
    if (isReadOnlyForm) {
      setOutput(existingData ? fields.map(handleExistingData) : fields);
    } else {
      const isValidDefaultFilters =
        config.title === 'New Filter' &&
        !!activeFilter?.filterFields?.length &&
        activeFilter.isAdvanced;
      if (isValidDefaultFilters) {
        activeFilter?.filterFields?.forEach(filterField => {
          const fieldData = fields.find(
            field => filterField.label === field.label,
          );
          if (fieldData) {
            fieldData.value = filterField.value;
          }
        });
      }

      const dataToInitialize =
        outsideData && outsideData.length
          ? outsideData
          : existingData
            ? fields.map(handleExistingData)
            : fields;
      setOutput(dataToInitialize);
      if (outsideData) {
        onChange?.(dataToInitialize);
      }
    }
  }, [fields]);

  const updateField = useCallback(
    (
      f: ECEasyFormFieldType,
      fieldName: string,
      value: any,
      tableRowId?: number,
      tableRowField?: any,
    ) => {
      if (
        f.type === FieldTypes.EditableTable &&
        tableRowField &&
        f.fieldName === fieldName
      ) {
        const tableValues = f.value.map(row => {
          if (row.id === tableRowId) {
            return {
              ...row,
              // currently handles SELECT and regular fields
              [tableRowField.fieldName]:
                tableRowField.type === FieldTypes.Select
                  ? { [tableRowField.dataPath]: value }
                  : value,
            };
          }
          return row;
        });
        return {
          ...f,
          value: tableValues,
        };
      }
      if (f.fieldName === fieldName) {
        return {
          ...f,
          validationMessage: undefined,
          value,
        };
      } else if (
        f.type === FieldTypes.Group &&
        f.subFields &&
        Array.isArray(f.subFields)
      ) {
        return {
          ...f,
          validationMessage: undefined,
          subFields: f.subFields.map(sbf =>
            updateField(sbf, fieldName, value, tableRowId, tableRowField),
          ),
        };
      } else if (f.type === FieldTypes.RadioWarrantyStartDate && f.options) {
        return {
          ...f,
          validationMessage: undefined,
          options: f.options.map((option, index) =>
            updateField(option, fieldName, value),
          ),
        };
      }
      return f;
    },
    [],
  );

  const handleOutputChange = useCallback(
    (
      fieldName: string,
      value: any,
      tableRowId?: number,
      tableRowField?: string,
    ) => {
      setOutput(prevOutput => {
        const newOutput = prevOutput.map(f =>
          updateField(f, fieldName, value, tableRowId, tableRowField),
        );
        onChange?.(newOutput, fieldName, value);
        return newOutput;
      });
    },
    [updateField, onChange, setOutput],
  );

  const validateField = useCallback(
    (f, validation, formData) => {
      return validateFields(f, validation, formData, t);
    },
    [t],
  );

  const resetFieldValues = useCallback(() => {
    setOutput(output =>
      output.map(field => ({
        ...field,
        value: undefined,
      })),
    );
  }, []);

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    const isSubmittingFromNestedForm =
      !!(event?.nativeEvent as any)?.submitter?.id &&
      !(event?.nativeEvent as any)?.submitter?.id?.includes('undefined');

    if (
      (!id && isSubmittingFromNestedForm) ||
      (id && `${id}-submitter` !== (event?.nativeEvent as any)?.submitter?.id)
    ) {
      return;
    }

    event?.preventDefault?.();
    const formData = new FormData(event?.currentTarget);
    let validation = { isValid: true };
    const validatedOutput = output?.map(f =>
      validateField(f, validation, formData),
    );

    const total = costTable
      ? sumCurrencyFields(
          ...costTableItems.flatMap(entry =>
            Array.isArray(entry.value)
              ? (entry.value as any)?.map(item => item?.subTotal)
              : entry.value,
          ),
        )
      : 0;

    let costTableValidation =
      costTable && costTable.nte ? (costTable?.nte ?? 0) >= total : true;

    const isExceedingNteAllowed = fields.find(
      field => field.type === FieldTypes.CostTable,
    )?.allowExceeding;

    if (isExceedingNteAllowed && !costTableValidation) {
      costTableValidation = true;
    }

    const costTableRequiredFieldsNotFilledList = costTableItems
      .filter(i => i.required)
      .filter(i => !i.value);

    const costTableFields: ECEasyFormFieldType[] = costTableItems
      .filter(i => !!i.dataPath)
      .map(item => ({
        type: FieldTypes.Number,
        visible: false,
        label: item.label,
        value: item.value,
        placeholder: '',
        fieldName: item.dataPath as string,
        isValid: costTableValidation,
      }));

    const isCostTableVisible = fields.some(
      field => field.type === FieldTypes.CostTable && field.visible,
    );

    if (
      validation.isValid &&
      costTableValidation &&
      (costTableRequiredFieldsNotFilledList.length === 0 || !isCostTableVisible)
    ) {
      /// Send it out

      onFormSubmit?.(
        formData,
        [...output, ...costTableFields],
        resetFieldValues,
      );
    } else {
      //updating the state is needed only for displaying error state

      setOutput(validatedOutput);
      if (costTableRequiredFieldsNotFilledList.length != 0) {
        setCostTableItems(
          costTableItems.map(item => {
            if (costTableRequiredFieldsNotFilledList.includes(item)) {
              return {
                ...item,
                isValid: false,
              };
            }
            return item;
          }),
        );
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      handleSubmit();
    },
  }));

  const outputMapped = useMemo(() => {
    return output?.map(({ visible = true, ...field }, index) => {
      if (!visible) {
        return null;
      }

      if (field.type === FieldTypes.Group) {
        field.subFields = field.subFields?.filter(subField => {
          if (subField.visible === false) {
            return false;
          }

          if (subField.hideIfEmpty) {
            if (
              (Array.isArray(subField.value) && !subField.value.length) ||
              !subField.value
            )
              return false;
          }

          if (
            subField.hideIfOtherFieldPopulated &&
            output.find(x => x.fieldName === subField.hideIfOtherFieldPopulated)
              ?.value
          ) {
            return false;
          }

          return true;
        });
      }

      if (field.hideIfEmpty) {
        if (field.type === FieldTypes.Group && isReadOnlyForm) {
          if (!field.subFields?.some(sf => sf.value)) return <></>;
        } else {
          if (
            (Array.isArray(field.value) && !field.value.length) ||
            !field.value
          )
            return <></>;
        }
      }

      if (
        field.hideIfOtherFieldPopulated &&
        output.find(x => x.fieldName === field.hideIfOtherFieldPopulated)?.value
      ) {
        return <></>;
      }

      if (field.disableIfOtherFieldPopulated) {
        if (
          output.find(x => x.fieldName === field.disableIfOtherFieldPopulated)
            ?.value
        ) {
          field.disabled = true;
          field.readOnly = true;
        }
      }

      if (field.enableIfOtherFieldPopulated) {
        if (
          output.find(x => x.fieldName === field.enableIfOtherFieldPopulated)
            ?.value
        ) {
          field.disabled = false;
          field.readOnly = false;
        }
      }

      // this IF is for switch fields to change label based on other fields values
      if (
        field.changeSwitchActiveLabelWhenOtherFieldPopulatedText &&
        field.changeSwitchActiveLabelWhenOtherFieldPopulatedFieldname
      ) {
        if (
          output.find(
            x =>
              x.fieldName ===
              field.changeSwitchActiveLabelWhenOtherFieldPopulatedFieldname,
          )?.value
        ) {
          field.switchActiveLabel =
            field.changeSwitchActiveLabelWhenOtherFieldPopulatedText;
        }
      }

      // this is to ensure that switch fields are selected when other fields are populated
      if (field.switchSelectedWhenOtherFieldPopulated) {
        if (
          output.find(
            x => x.fieldName === field.switchSelectedWhenOtherFieldPopulated,
          )?.value
        ) {
          field.value = 1;
        }
      }
      return (
        <ECEasyFormGridItem
          config={
            field?.fieldConfig ? { ...config, ...field.fieldConfig } : config
          }
          scopes={field?.scopes}
          // using fieldname and field label broke pages, made fields duplicate
          key={index}
          optionalSx={{
            marginBottom: 2,
          }}
        >
          <ECFormCell
            field={field}
            index={index}
            output={output}
            fields={fields}
            costTable={costTable}
            costTableItems={costTableItems}
            pattern={pattern}
            isReadOnlyForm={isReadOnlyForm}
            config={config}
            handleCostTableChange={handleCostTableChange}
            setParentOutput={setOutput}
            setOutput={setOutput}
            onLoadMoreData={onLoadMoreData}
            existingData={existingData}
            onDuplicateChip={onDuplicateChip}
            onChangeValue={handleOutputChange}
            isLoading={isLoading}
            onChangeSearch={onChangeSearch}
            searchOptionsState={searchOptionsState}
          />
        </ECEasyFormGridItem>
      );
    });
  }, [
    output,
    costTableItems,
    pattern,
    isReadOnlyForm,
    config,
    fields,
    costTable,
    onLoadMoreData,
    existingData,
    onDuplicateChip,
    handleCostTableChange,
    handleOutputChange,
    isLoading,
    onChangeSearch,
    searchOptionsState,
  ]);

  if (isLoadingForm) {
    return (
      <ECCard>
        <ECGrid
          container
          spacing={2}
          sx={{
            p: 4,
            pt: 6,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ECCircularProgress />
          <ECTypography
            ml={4}
            variant={'subtitle1'}
            color={theme => theme.palette.text.disabled}
          >
            {t('translation:form.loading')}
          </ECTypography>
        </ECGrid>
      </ECCard>
    );
  }

  return (
    <form
      id={`${id}-form`}
      onSubmit={handleSubmit}
      ref={formRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: fullHeight ? '100%' : 'auto',
        marginTop: noTopMargin ? '-15px' : 0,
      }}
    >
      <ECBox
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        overflow="visible"
        justifyContent="space-between"
      >
        <ECEasyFormContainer
          config={config}
          toolbarChildren={toolbarChildren}
          hideToolBar={hideToolBar}
          noPadding={noPadding}
          pattern={pattern}
          showTitle={showTitle}
          customDrawerTitle={customDrawerTitle}
          fields={output}
          drawerTitleBarBGColor={drawerTitleBarBGColor}
        >
          <ECGrid
            container
            spacing={1}
            px={noPaddingX ? 0 : 3}
            py={noPaddingY ? 0 : pattern === 'modal' ? 0 : 3}
          >
            {outputMapped}
            {additionalElements && additionalElements}
          </ECGrid>
          {disclaimer && (
            <ECBox display="flex" px={2} pb={1}>
              <ECTypography variant="subtitle2">{disclaimer}</ECTypography>
            </ECBox>
          )}
          {formFooter && formFooter}
        </ECEasyFormContainer>
        <ECBox
          display="flex"
          flexDirection="column"
          position={stickyFooter ? 'sticky' : undefined}
          zIndex={99}
          bottom={0}
        >
          {nteSaveAlertObject}
          {!hideActions && (
            <ECBox
              display="flex"
              bgcolor={theme =>
                pattern === 'standard'
                  ? theme.palette.marketingSuggestion.marketingSuggestion
                  : pattern === 'modal'
                    ? 'transparent'
                    : theme.palette.marketingSuggestion.mainBackground
              }
              sx={{ ...config.actionButtonsContainerStyle }}
            >
              <ECGrid
                container
                spacing={2}
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'right',
                  p: 1,
                  padding: pattern === 'standard' ? 3 : 1,
                }}
              >
                {(additionalActions || showSaveButton) && (
                  <ECEasyFormGridItem
                    config={config}
                    optionalSx={{
                      padding: isMobileSize ? '0' : null,
                      marginBottom: pattern === 'modal' ? -4 : null,
                    }}
                  >
                    <ECStack
                      direction="row"
                      justifyContent="right"
                      spacing={2}
                      bgcolor={theme =>
                        pattern === 'modal'
                          ? theme.palette.other.background
                          : ''
                      }
                    >
                      {config.showDeleteButton && (
                        <>
                          <ECButton
                            variant="text"
                            color="error"
                            startIcon={<Delete color="error" />}
                            onClick={() => setShowDeletePopover(true)}
                            sx={{
                              height: 42,
                              width: 'auto',
                              marginRight: '68%',
                              border: 1,
                            }}
                          >
                            {t('translation:dynamicForm.delete')}
                          </ECButton>
                          <ECPopover
                            open={showDeletePopover}
                            onClose={handleDeleteDeclineClick}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            sx={{ Margin: '30%' }}
                          >
                            <ECBox display="flex" flexDirection="column" p={2}>
                              <ECTypography variant="body1" width={'300px'}>
                                {config.deleteConfirmationText}
                              </ECTypography>
                              <ECBox
                                mt={2}
                                display="flex"
                                justifyContent="flex-end"
                              >
                                <ECButton
                                  variant="text"
                                  onClick={handleDeleteDeclineClick}
                                >
                                  {t('translation:dynamicForm.cancel')}
                                </ECButton>
                                <ECButton
                                  variant="contained"
                                  color="error"
                                  onClick={handleDeleteConfirmationClick}
                                >
                                  {t('translation:dynamicForm.delete')}
                                </ECButton>
                              </ECBox>
                            </ECBox>
                          </ECPopover>
                        </>
                      )}
                      {config.submitSubtitle && shouldShowSubmitSubtitle && (
                        <ECTypography
                          variant="subtitle2"
                          display={'flex'}
                          alignItems={'center'}
                          fontSize={'16px'}
                        >
                          {config.submitSubtitle}
                        </ECTypography>
                      )}
                      {additionalActions && additionalActions}
                      {showSaveButton && (
                        <ECButton
                          id={`${id}-submitter`}
                          type={
                            submissionConfirmationModal?.isVisible
                              ? 'button'
                              : 'submit'
                          }
                          color={saveButtonColor}
                          variant="contained"
                          disabled={
                            isSendingData ||
                            isReadOnlyForm ||
                            saveButtonDisabled
                          }
                          sx={{
                            height: 42,
                            width: additionalActions ? 'auto' : '100%',
                          }}
                          onClick={() => {
                            submissionConfirmationModal?.isVisible &&
                              setConfirmationModalOpen(true);
                          }}
                        >
                          {isSendingData ? (
                            <ECCircularProgress
                              size={'2rem'}
                              sx={{ color: 'background.paper' }}
                            />
                          ) : (
                            config.submitTitle
                          )}
                        </ECButton>
                      )}
                    </ECStack>
                  </ECEasyFormGridItem>
                )}
              </ECGrid>
            </ECBox>
          )}
        </ECBox>
      </ECBox>
      {submissionConfirmationModal?.isVisible && (
        <ECModal
          isOpen={confirmationModalOpen}
          onClose={handleCloseConfirmationModal}
          noPadding
          noPaddingBottom
        >
          <ECBox pb={1} pt={2} pl={3} pr={1}>
            <ECTypography variant="h6">
              {submissionConfirmationModal?.title}
            </ECTypography>

            <ECTypography>{submissionConfirmationModal?.bodyText}</ECTypography>

            <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
              <ECButton variant="text" onClick={handleCloseConfirmationModal}>
                {submissionConfirmationModal?.cancelButtonText ?? 'Cancel'}
              </ECButton>
              <ECButton
                variant="contained"
                color="error"
                onClick={handleConfirmSubmit}
              >
                {isLoading ? (
                  <ECCircularProgress
                    size={'.5rem'}
                    sx={{ color: 'background.paper' }}
                  />
                ) : (
                  submissionConfirmationModal?.buttonText ?? 'Confirm'
                )}
              </ECButton>
            </ECBox>
          </ECBox>
        </ECModal>
      )}
    </form>
  );
});
