import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle, { AlertTitleProps } from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';

// Alert Props with DISABLED props
interface ECAlertProps extends AlertProps {
  disabled?: boolean;
  title?: string;
  items?: any[];
  description?: string;
  descriptionSx?: any;
}

export const ECAlert = styled(Alert)<ECAlertProps>(({ theme, disabled }) => ({
  opacity: disabled ? 0.5 : 1,
}));

export const ECAlertTitle = styled(AlertTitle)<AlertTitleProps>(
  ({ theme }) => ({}),
);

export const ECAlertWithTitle = (
  props: AlertProps | Pick<AlertTitleProps, 'title'>,
) => (
  <ECAlert {...props}>
    <ECAlertTitle>{props.title}</ECAlertTitle>
  </ECAlert>
);

export const ECAlertError = props => (
  <ECAlert severity="error">
    <ECAlertTitle>{props.title || 'Error'}</ECAlertTitle>
    <strong>{props.children}</strong>
  </ECAlert>
);

export const ECAlertSuccess = props => (
  <ECAlert severity="success">
    <ECAlertTitle>Success</ECAlertTitle>
    <strong>{props.children}</strong>
  </ECAlert>
);

export const ECCustomAlert = (props: ECAlertProps) => (
  <ECAlert severity={props.severity} variant={props.variant}>
    {props.title && <ECAlertTitle>{props.title}</ECAlertTitle>}
    {props.description && (
      <p style={{ marginTop: '-5px', ...props.descriptionSx }}>{props.description}</p>
    )}
    {props.items && (
      <ul
        style={{
          marginTop: '-10px',
          marginLeft: '-10px',
          marginBottom: '-5px',
        }}
      >
        {props.items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    )}
  </ECAlert>
);
