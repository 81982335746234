import React, { useEffect, useMemo, useState, useRef } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { ECBox, ECButton, ECTextField, ECTypography } from '..';
import { formatDateForTable } from 'utils/strings/formatDate';
import {
  DateCalendar,
  LocalizationProvider as XLocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns as XAdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { ECPopover } from '../ECPopover';
import { useQueueState } from 'rooks';
import moment from 'moment';
import _ from 'lodash';
import { ArrowDropDown } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export const ECDateTimePicker = styled(DateTimePicker)(({ theme }) => ({}));

export enum DateRangeFilterType {
  Before = 'before',
  After = 'after',
  Exact = 'exact',
  Between = 'between',
}

interface ECDateRangePickerProps {
  defaultSelectedOption?: string;
  defaultSelectedDates?: Date[];
  showTimePicker?: boolean;
  onChange?: (
    dateRange: Date[],
    dateRangeType?: DateRangeFilterType,
    optionLabel?: string,
  ) => void;
}

export const ECDateRangePicker = ({
  defaultSelectedOption,
  defaultSelectedDates = [],
  showTimePicker,
  onChange,
}: ECDateRangePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [anchorElDateCalendar, setAnchorElDateCalendar] =
    useState<HTMLButtonElement | null>(null);
  const [selectedDates, { enqueue, dequeue, length }] =
    useQueueState<any>(defaultSelectedDates);
  const [selectedDateRange, setSelectedDateRange] = useState<any>({});
  const [shouldClear, setShouldClear] = useState(false);

  useEffect(() => {
    if (length >= 3) {
      dequeue();
    }
  }, [length]);

  useEffect(() => {
    if (length && shouldClear) {
      dequeue();
    } else {
      setShouldClear(false);
    }
  }, [length, shouldClear]);

  useEffect(() => {
    if (shouldClear) {
      return;
    }

    if (selectedDateRange?.range?.length) {
      onChange?.(
        _.sortBy(selectedDateRange.range),
        selectedDateRange?.filter,
        selectedDateRange?.label,
      );
      return;
    }

    if (selectedDates.length) {
      onChange?.(
        _.sortBy(selectedDates.slice(length - 2, length), date =>
          new Date(date).getTime(),
        ),
        selectedDates.length === 1
          ? DateRangeFilterType.Exact
          : DateRangeFilterType.Between,
        'Custom',
      );
      return;
    }

    if (selectedDateRange?.length === 0) {
      onChange?.([], undefined, 'All Time');
      return;
    }
  }, [selectedDateRange, selectedDates, shouldClear]);

  const predefinedRangesOptions = useMemo(
    () => [
      {
        label: 'All Time',
        range: [],
      },
      {
        label: '1 Day',
        range: [moment().subtract(1, 'days').toDate()],
        filter: DateRangeFilterType.After,
      },
      {
        label: '7 Days',
        range: [moment().subtract(7, 'days').toDate(), new Date()],
        filter: DateRangeFilterType.Between,
      },
      {
        label: '30 Days',
        range: [moment().subtract(30, 'days').toDate(), new Date()],
        filter: DateRangeFilterType.Between,
      },
      {
        label: '90 Days',
        range: [moment().subtract(90, 'days').toDate(), new Date()],
        filter: DateRangeFilterType.Between,
      },
      {
        label: 'Over 90 Days',
        range: [moment().subtract(90, 'days').toDate()],
        filter: DateRangeFilterType.Before,
      },
      {
        label: 'Custom',
      },
    ],
    [],
  );

  useEffect(() => {
    const option = predefinedRangesOptions?.find(
      option => option.label === defaultSelectedOption,
    );

    if (option && option.label !== 'Custom') {
      setSelectedDateRange(option);
    }
  }, [defaultSelectedOption, predefinedRangesOptions]);

  const ref = useRef(null);
  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(ref?.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElDateCalendar(null);
  };

  const handleCloseDateCalendar = () => {
    setAnchorElDateCalendar(null);
  };

  const handleOpenDateCalendar = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElDateCalendar(event.currentTarget);
  };

  const handleDateRangeSelect = option => () => {
    setSelectedDateRange(option);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-range-popover' : undefined;

  const openDateCalendar = Boolean(anchorElDateCalendar);
  const idDateCalendar = open ? 'date-calendar-popover' : undefined;

  const DaySlot = (props: PickersDayProps<any> & { selectedDates?: any[] }) => {
    const { selectedDates = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected = selectedDates[1]
      ? moment(day).isBetween(selectedDates[0], selectedDates[1]) ||
        moment(day).isBetween(selectedDates[1], selectedDates[0]) ||
        selectedDates.some(
          selectedDate =>
            moment(selectedDate).format('MM/DD/YYYY') ===
            moment(day).format('MM/DD/YYYY'),
        )
      : moment(selectedDates[0]).format('MM/DD/YYYY') ===
        moment(day).format('MM/DD/YYYY');

    return (
      <PickersDay
        {...other}
        selected={isSelected}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    );
  };

  const dateRangeText = useMemo(() => {
    switch (selectedDateRange?.filter) {
      case DateRangeFilterType.After:
      case DateRangeFilterType.Before:
        return selectedDateRange?.label;
      case DateRangeFilterType.Exact:
        return moment(selectedDates[0]).format('MMM DD, YYYY');
      case DateRangeFilterType.Between:
        if (selectedDateRange?.range?.length) {
          return `${moment(selectedDateRange?.range[0]).format(
            'MMM DD, YYYY',
          )} - ${moment(selectedDateRange?.range[1]).format('MMM DD, YYYY')}`;
        }
        break;
      default:
        if (!selectedDates?.length) {
          return '';
        }

        if (!selectedDates[1]) {
          return moment(selectedDates[0]).format('MMM DD, YYYY');
        }

        if (moment(selectedDates[0]).isBefore(selectedDates[1])) {
          return `${moment(selectedDates[0]).format('MMM DD, YYYY')} - ${moment(
            selectedDates[1],
          ).format('MMM DD, YYYY')}`;
        }

        return `${moment(selectedDates[1]).format('MMM DD, YYYY')} - ${moment(
          selectedDates[0],
        ).format('MMM DD, YYYY')}`;
    }
  }, [selectedDates, selectedDateRange]);

  const clear = () => {
    setSelectedDateRange([]);
    dequeue();
    setShouldClear(true);
  };

  const handleChange = newValue => {};

  return (
    <ECBox display="flex" gap={3} alignItems="center">
      <ECBox display="flex" justifyContent="flex-end">
        <ECTextField
          value={dateRangeText}
          variant="standard"
          ref={ref}
          sx={{
            width: Math.max(dateRangeText.length * 9, 190),
            '& .MuiInputBase-input': {
              textAlign: 'center',
            },
            '& .MuiInput-underline:before': {
              display: 'none',
            },
            '& .MuiInput-underline:after': {
              display: 'none',
            },
          }}
          inputProps={{
            readOnly: true,
          }}
          placeholder="Choose date range"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClick} sx={{ p: 0 }}>
                  <ArrowDropDown />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ECPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 40,
            horizontal: 180,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ zIndex: 20 }}
        >
          <ECBox display="flex" flexDirection="column" padding={2} gap={2}>
            {predefinedRangesOptions.map((option, index) => (
              <ECButton
                key={`${option.label}`}
                id={`${option.label}`}
                sx={{
                  color: theme =>
                    `${
                      (option.label === 'Custom' && openDateCalendar) ||
                      ((selectedDateRange.label === option.label ||
                        (option.label === 'Custom' && selectedDates?.length) ||
                        (!selectedDateRange?.range?.length &&
                          !selectedDates?.length &&
                          option.label === 'All Time')) &&
                        !openDateCalendar)
                        ? theme.palette.primary.main
                        : theme.palette.text.secondary
                    } !important`,
                }}
                variant="text"
                onClick={
                  option.range
                    ? option.range?.length
                      ? handleDateRangeSelect(option)
                      : clear
                    : handleOpenDateCalendar
                }
              >
                {option.label}
              </ECButton>
            ))}
          </ECBox>
        </ECPopover>

        <ECPopover
          id={idDateCalendar}
          open={openDateCalendar}
          anchorEl={anchorElDateCalendar}
          onClose={handleCloseDateCalendar}
          sx={{
            marginLeft: 2,
            marginTop: 2,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <>
            <ECBox
              display="flex"
              bgcolor={theme => theme.palette.primary.main}
              p={1}
            >
              <ECTypography color={theme => theme.palette.common.white}>
                {dateRangeText || 'Choose date range'}
              </ECTypography>
            </ECBox>
            <XLocalizationProvider dateAdapter={XAdapterDateFns}>
              <DateCalendar
                slots={{
                  day: DaySlot,
                }}
                slotProps={
                  {
                    day: {
                      selectedDates,
                    },
                  } as any
                }
                onChange={date => {
                  setSelectedDateRange([]);
                  enqueue(date);
                }}
              />
            </XLocalizationProvider>
          </>
        </ECPopover>
      </ECBox>

      {showTimePicker && (
        <XLocalizationProvider dateAdapter={XAdapterDateFns}>
          <TimePicker value={new Date()} label="Time" onChange={handleChange} />
        </XLocalizationProvider>
      )}
    </ECBox>
  );
};

export interface ECBasicDateTimePickerProps {
  value: any;
  label: string;
  onChange: any;
  sx?: any;
  error?: boolean | null;
  helperText?: string | null;
  fieldName?: string;
  readOnly?: boolean;
  disabled?: boolean;
  variant?: any;
  minDate?: any;
}

export const ECBasicDateTimePicker = (props: ECBasicDateTimePickerProps) => {
  const {
    value,
    label,
    onChange,
    sx,
    error,
    helperText,
    fieldName,
    variant,
    readOnly,
    disabled,
    minDate,
  } = props;

  if (readOnly || disabled) {
    return (
      <ECTextField
        sx={sx}
        value={formatDateForTable(value)}
        disabled={disabled}
        variant={variant}
        inputProps={{
          readOnly: true,
        }}
        label={label}
      />
    );
  }

  return (
    <XLocalizationProvider dateAdapter={XAdapterDateFns}>
      <ECTextField
        type="datetime-local"
        error={error === true}
        id={fieldName}
        name={fieldName}
        helperText={helperText ? helperText : null}
        sx={sx}
        variant={variant}
        label={label}
        value={value || null}
        inputProps={{
          min: minDate,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
    </XLocalizationProvider>
  );
};
