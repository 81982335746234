import { styled } from '@mui/material/styles';
import { MenuItem, MenuItemProps, Select, SelectProps } from '@mui/material';
import { StyleConstants } from 'styles/StyleConstants';

interface ECSelectProps extends SelectProps {
  hiddenLabel?: boolean;
}

export const ECSelect = styled(({ ...props }: ECSelectProps) => (
  <Select
    {...props}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: StyleConstants.DROPDOWN_MENU_MAX_HEIGHT,
          width: '350px',
        },
      },
      ...props.MenuProps,
    }}
  />
))(({ hiddenLabel }) => ({
  '.MuiSelect-iconFilled': {
    color: 'gray',
  },
  '.MuiSelect-filled': {
    color: 'black',
  },
  '.MuiFilledInput-input': {
    paddingTop: hiddenLabel ? 16 : 25,
    paddingBottom: hiddenLabel ? 16 : 8,
  },
}));

export const ECMenuItem = styled(MenuItem)<MenuItemProps>(() => ({
  '&:hover': {
    '& .hoverText': {
      display: 'block',
      backgroundColor: '#0090FF29',
      color: '#0072CE',
      borderRadius: '16px',
      padding: '4px 10px',

      '&:hover': {
        backgroundColor: '#0090FF40',
      },
    },
  },
}));

export const ECSelectWhiteBorders = styled(Select)<SelectProps>(
  ({ theme }) => ({
    select: {
      '&:before': {
        borderColor: theme.palette.background.default,
      },
      '&:after': {
        borderColor: theme.palette.background.default,
      },
      '&:focused': {
        borderColor: theme.palette.background.default,
      },
    },
    icon: {
      fill: theme.palette.background.default,
    },
  }),
);
