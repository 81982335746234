import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState, Ref, forwardRef } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { ButtonGroup } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

import { setActiveFilter } from 'store/slice/page';
import {
  ECBox,
  ECGrid,
  ECStack,
  ECButton,
  ECIconButton,
  ECTypography,
} from '../';
import { ECTechAssignedFilter } from './ECTechAssignedFilter';
import { useSelector } from 'react-redux';
import { RootState } from 'index';

interface ECCalendarViewFilterProps {}

export const ECCalendarViewFilter: React.FC<ECCalendarViewFilterProps> = () => {
  const dispatch = useDispatch();

  const { activeFilter: activeFilterFromSelector } = useSelector(
    (state: RootState) => state.page.filter,
  );
  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const [selectedTimeFrame, setSelectedTimeFrame] = useState<'day' | 'week'>(
    activeFilter?.dateBefore &&
      activeFilter?.dateBefore === activeFilter?.dateAfter
      ? 'day'
      : 'week',
  );
  const [currentWeek, setCurrentWeek] = useState(
    moment(activeFilter?.dateBefore).startOf('isoWeek'),
  );
  const [currentDay, setCurrentDay] = useState(
    moment(activeFilter?.dateBefore),
  );

  const handleToday = () => {
    setCurrentWeek(moment().startOf('isoWeek'));
    setCurrentDay(moment().startOf('day'));
  };

  const handleBack = () => {
    if (selectedTimeFrame === 'week') {
      setCurrentWeek(currentWeek.clone().subtract(1, 'week'));
    } else {
      setCurrentDay(currentDay.clone().subtract(1, 'day'));
    }
  };

  const handleNext = () => {
    if (selectedTimeFrame === 'week') {
      setCurrentWeek(prevWeek => prevWeek.clone().add(1, 'week'));
    } else {
      setCurrentDay(prevDay => prevDay.clone().add(1, 'day'));
    }
  };

  const weekRange = useMemo(() => {
    const startDate = currentWeek;
    const endDate = currentWeek.clone().endOf('isoWeek');
    const startDay = startDate.format('D');
    const endDay = endDate.format('D');
    const month = startDate.format('MMM');
    const year = startDate.format('YYYY');

    return `${startDay + ' '}-${' ' + endDay} ${month}, ${year}`;
  }, [currentWeek]);

  const dayDisplay = useMemo(() => {
    return currentDay.format('D MMM, YYYY');
  }, [currentDay]);

  useEffect(() => {
    if (selectedTimeFrame === 'week') {
      const startDate = currentWeek.format('YYYY-MM-DD');
      const endDate = currentWeek.clone().endOf('isoWeek').format('YYYY-MM-DD');
      dispatch(
        setActiveFilter({
          dateBefore: startDate,
          dateAfter: endDate,
        }),
      );
    } else {
      const dayDate = currentDay.format('YYYY-MM-DD');
      dispatch(
        setActiveFilter({
          dateBefore: dayDate,
          dateAfter: dayDate,
        }),
      );
    }
  }, [currentDay, currentWeek, selectedTimeFrame]);

  return (
    <ECStack
      direction="row"
      alignItems="center"
      width="100%"
      height="56px"
      minWidth="682px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-between"
      bgcolor={theme => theme.palette.grey[200]}
    >
      <ECBox display="flex" alignItems="center" gap={1}>
        <ECButton
          variant="outlined"
          sx={{
            minWidth: '4rem !important',
            borderRadius: '4px !important',
            height: '30px',
            margin: 1,
            width: '63px',
          }}
          onClick={handleToday}
        >
          Today
        </ECButton>
        <ECTypography
          variant="subtitle1"
          whiteSpace="nowrap"
          color={theme => theme.palette.text.secondary}
          fontSize={'16px'}
          fontWeight={400}
          fontFamily={'Roboto'}
          letterSpacing={'0.15px'}
          lineHeight={'28px'}
        >
          {selectedTimeFrame === 'week' ? weekRange : dayDisplay}
        </ECTypography>
        <ECIconButton sx={{ p: 0, marginLeft: 1 }} onClick={handleBack}>
          <ArrowBackIos fontSize="small" />
        </ECIconButton>
        <ECIconButton sx={{ p: 0, marginLeft: 1 }} onClick={handleNext}>
          <ArrowForwardIos fontSize="small" />
        </ECIconButton>
      </ECBox>

      <ECBox display="flex" flexGrow={1} justifyContent="center">
        <ECTechAssignedFilter />
      </ECBox>
      <ButtonGroup>
        <ECGrid container direction="row" sx={{ margin: 1 }} minWidth="150px">
          <ECGrid item>
            <ECButton
              key="dayButton"
              sx={theme => ({
                bgcolor:
                  selectedTimeFrame === 'week'
                    ? theme.palette.background.default
                    : theme.palette.primary.outlinedHoverBackground,
                borderTopLeftRadius: '5px !important',
                borderBottomLeftRadius: '5px !important',
                borderTopRightRadius: '0 !important',
                borderBottomRightRadius: '0 !important',
                height: '30px',
                width: '46px',
                padding: '4px, 10px, 4px, 10px',
              })}
              variant="outlined"
              onClick={() => setSelectedTimeFrame('day')}
            >
              Day
            </ECButton>
            <ECButton
              key="weekButton"
              sx={theme => ({
                bgcolor:
                  selectedTimeFrame === 'day'
                    ? theme.palette.background.default
                    : theme.palette.primary.outlinedHoverBackground,
                borderTopLeftRadius: '0px !important',
                borderBottomLeftRadius: '0px !important',
                borderTopRightRadius: '5px !important',
                borderBottomRightRadius: '5px !important',
                height: '30px',
                width: '56px',
                padding: '4px, 10px, 4px, 10px',
              })}
              variant="outlined"
              onClick={() => setSelectedTimeFrame('week')}
            >
              Week
            </ECButton>
          </ECGrid>
        </ECGrid>
      </ButtonGroup>
    </ECStack>
  );
};
