import { useDispatch } from 'react-redux';
import { setActiveFilter } from 'store/slice/page';
import { ECTypography } from '../ECTypography';

interface ECOnlyMeFilterProps {
  activeFilter?: any;
  closePopover: () => void;
}

export const ECOnlyMeFilter = ({
  activeFilter,
  closePopover,
}: ECOnlyMeFilterProps) => {
  const dispatch = useDispatch();

  const handleOnlyMeClick = e => {
    e.stopPropagation();
    activeFilter &&
      dispatch(
        setActiveFilter({
          ...activeFilter,
        }),
      );
    closePopover();
  };

  return (
    <ECTypography
      onClick={handleOnlyMeClick}
      className="hoverText"
      sx={{ display: 'none' }}
    >
      Only me
    </ECTypography>
  );
};
