import { Close } from '@mui/icons-material';
import { ECButton } from 'app/components';
import { ECConfirmWorkflowStatusChangeModal } from 'app/components/ECConfirmWorkflowStatusChange';
import { useEffect, useState } from 'react';
import { usePurchaseOrderActionClickMutation } from 'services/purchaseOrderApi';

export const ECRejectPOButton = ({ id, rejectStatusId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [
    doUpdatePurchaseOrderStatus,
    { isLoading: isLoadingAction, isSuccess: isSuccessUpdateAction, reset },
  ] = usePurchaseOrderActionClickMutation();

  useEffect(() => {
    if (!isLoadingAction && isSuccessUpdateAction) {
      setIsOpen(false);
      reset();
    }
  }, [isLoadingAction, isSuccessUpdateAction]);

  return (
    <>
      <ECButton
        variant="outlined"
        color="error"
        startIcon={<Close sx={{ color: theme => theme.palette.error.main }} />}
        sx={theme => ({
          border: 1,
          borderColor: `${theme.palette.error.outlinedRestingBackground} !important`,
          color: `${theme.palette.error.main} !important`,
          marginRight: 1,
        })}
        onClick={() => setIsOpen(true)}
        isLoading={isLoadingAction}
      >
        Reject
      </ECButton>

      <ECConfirmWorkflowStatusChangeModal
        title={`Confirm you want to 'Reject' this Purchase Order #${id}`}
        onConfirm={reason => {
          doUpdatePurchaseOrderStatus({
            id: +(id || 0),
            declineReason: reason,
            statusTo: rejectStatusId,
          });
        }}
        onCancel={() => setIsOpen(false)}
        isDestructiveForm
        isOpen={isOpen}
        isLoading={isLoadingAction}
        withReasonTextField
      />
    </>
  );
};
