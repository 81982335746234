import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ECWorkflowTemplate } from 'app/components';
import {
  useGetRfpItemByIdQuery,
  useUpdateRequestForPricingMutation,
  useGetRFPNextStatusListQuery,
} from 'services/requestForPricingApi';
import {
  SummaryFieldsData,
  SummaryFieldTypes,
} from 'app/components/ECWorkflowSummaryField';
import { useGetAssetWarrantyQuery } from 'services/assetApi';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { concatAddress } from 'utils/strings/concat-address';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import moment from 'moment';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { RFPProposalsTable } from './RFPProposalsTable';
import { CancelRfpModalButton } from './CancelRfpModalButton';
import { WorkflowStatus } from 'app/components/ECWorkflowStatusBadge';
import { useGetCategoryListQuery } from 'services/categoryApi';

export const RfpDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const isDetailsTab = useMemo(() => {
    const url = window.location.href;
    return !url.includes('attachments') && !url.includes('activity');
  }, [window.location.href]);

  const { data: rfpItem, isSuccess } = useGetRfpItemByIdQuery(Number(id), {
    skip: !id,
  });

  const { data: flagsData } = useGetFlagsListQuery({});

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    setSummaryFieldsValues(prevSummaryFieldsValues => ({
      ...prevSummaryFieldsValues,
      [fieldName]: value,
    }));
  };

  const initializeFields = () => {
    if (rfpItem) {
      setSummaryFieldsValues({
        name: rfpItem.name,
        flags: rfpItem.flags,
        category: rfpItem.request?.category,
        priority: rfpItem.request?.priority,
        description: rfpItem.description,
      });
    }
  };

  useEffect(() => {
    if (rfpItem && isSuccess) {
      initializeFields();
    }
  }, [rfpItem, isSuccess]);

  const isFieldEditableByRFPMutableFields = useCallback(
    (fieldName: string) => {
      return rfpItem?.mutableFields?.includes(fieldName);
    },
    [rfpItem?.mutableFields],
  );

  const [validations, setValidations] = useState<any>([]);

  const summaryData = useMemo(() => {
    const mailingAddress = rfpItem?.request?.asset?.branch?.mailingAddress;
    return [
      {
        id: 'status',
        label: 'RFP Status',
        data: rfpItem?.workflowStatus?.name,
        type: SummaryFieldTypes.Status,
      },
      {
        id: 'id',
        label: 'RFP ID',
        data: rfpItem?.id,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'name',
        label: 'RFP Title',
        data: summaryFieldsValues.name,
        onChange: handleSummaryFieldChange('name'),
        type: SummaryFieldTypes.Text,
        isEditable: isFieldEditableByRFPMutableFields('name'),
        maxLength: 100,
        required: true,
        isValid: !validations?.some(v => v.field === 'name'),
        validationMessage: validations?.find(v => v.field === 'name')
          ?.validationMessage,
      },
      {
        id: 'location',
        label: 'Location',
        data: rfpItem?.request?.asset?.branch?.name ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'storeNumber',
        label: 'Store Number',
        data: rfpItem?.request?.asset?.branch?.storeNumber ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'asset',
        label: 'Asset',
        data:
          rfpItem?.request?.asset?.generalAsset?.name ??
          rfpItem?.request?.asset?.name ??
          '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'category',
        label: 'Category',
        data: summaryFieldsValues.category,
        onChange: handleSummaryFieldChange('category'),
        type: SummaryFieldTypes.SelectPaginated,
        isEditable: isFieldEditableByRFPMutableFields('requestCategoryId'),
        useQuery: useGetCategoryListQuery,
        filterOptionsFn: category => category?.name !== 'Inventory',
        obAlias: 'rqstctgry.name',
        sbAlias: 'rqstctgry.name',
        st: 1,
        required: true,
        isValid: !validations?.some(v => v.field === 'category'),
        validationMessage: validations?.find(v => v.field === 'category')
          ?.validationMessage,
      },
      {
        id: 'assetGroup',
        label: 'Asset Group',
        data: rfpItem?.request?.asset?.assetType?.name ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'problem',
        label: 'Problem',
        data: rfpItem?.request?.problem?.name,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'priority',
        label: 'Priority',
        type: SummaryFieldTypes.SelectPaginated,
        data: summaryFieldsValues?.priority,
        onChange: handleSummaryFieldChange('priority'),
        isEditable: isFieldEditableByRFPMutableFields('priorityId'),
        useQuery: useGetPrioritiesListQuery,
        obAlias: 'prrt.name',
        sbAlias: 'prrt.name',
        required: true,
        isValid: !validations?.some(v => v.field === 'priority'),
        validationMessage: validations?.find(v => v.field === 'priority')
          ?.validationMessage,
      },
      {
        id: 'address',
        label: 'Address',
        data: concatAddress(
          [
            mailingAddress?.line1,
            mailingAddress?.line2,
            mailingAddress?.cityName,
            mailingAddress?.stateProvinceCode,
            mailingAddress?.zipCodePostalCode,
            mailingAddress?.countryCode,
          ].filter(Boolean),
        ),
        type: SummaryFieldTypes.Address,
      },
      {
        id: 'createdDate',
        label: 'Created Date',
        data: rfpItem?.createdAt
          ? moment(rfpItem.createdAt).format('MMM DD, YYYY')
          : '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'awardedDate',
        label: 'Awarded Date',
        data: rfpItem?.awardedAt
          ? moment(rfpItem.awardedAt).format('MMM DD, YYYY')
          : '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'submittedBy',
        label: 'Submitted By',
        data: rfpItem?.createdByUser?.email ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'requestedBy',
        label: 'Requested By',
        data: rfpItem?.requestedBy ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'description',
        label: 'RFP Description',
        data: summaryFieldsValues.description,
        onChange: handleSummaryFieldChange('description'),
        type: SummaryFieldTypes.Text,
        isEditable: isFieldEditableByRFPMutableFields('description'),
        maxLength: 10000,
        multiline: true,
      },
      {
        id: 'flags',
        label: 'Flags',
        data: summaryFieldsValues?.flags,
        onChange: handleSummaryFieldChange('flags'),
        limitTags: 4,
        type: SummaryFieldTypes.AutocompleteChips,
        isEditable: isFieldEditableByRFPMutableFields('flagsIds'),
        options:
          flagsData?.data.map(d => ({
            label: d.label,
            color: d.color,
            id: d.id,
          })) || [],
      },
    ];
  }, [
    rfpItem,
    flagsData,
    summaryFieldsValues,
    validations,
    isFieldEditableByRFPMutableFields,
  ]);

  const [
    doUpdateRfp,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateRfp,
    },
  ] = useUpdateRequestForPricingMutation();

  const useUpdateRfp = useCallback(() => {
    const doUpdate = async data => {
      if (!rfpItem?.id) {
        return;
      }
      const rfpBody = {
        id: rfpItem.id,
        name: summaryFieldsValues.name ?? '',
        description: summaryFieldsValues.description,
        requestCategoryId: summaryFieldsValues.category.id,
        priorityId: summaryFieldsValues.priority.id,
        flagsIds: summaryFieldsValues.flags.map(flag => flag.id),
      };

      // validations to make sure required fields are present
      let validation: any = [];
      if (!rfpBody.name) {
        validation.push({
          field: 'name',
          validationMessage: 'RFP Name is required!',
        });
      } else if (rfpBody.name.length < 3) {
        validation.push({
          field: 'name',
          validationMessage: 'RFP Name must be at least 3 characters!',
        });
      }
      if (!rfpBody.requestCategoryId) {
        validation.push({
          field: 'category',
          validationMessage: 'Category is required!',
        });
      }
      if (!rfpBody.priorityId) {
        validation.push({
          field: 'priority',
          validationMessage: 'Priority is required!',
        });
      }

      if (validation.length > 0) {
        setValidations(validation);
        return false;
      } else {
        doUpdateRfp(rfpBody);
      }
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdateRfp,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    summaryFieldsValues,
    rfpItem?.id,
    doUpdateRfp,
    resetUpdateRfp,
  ]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(
    rfpItem?.request?.asset?.id ?? 0,
    {
      skip: !id || !rfpItem || !isDetailsTab,
    },
  );

  const isAsset = useMemo(
    () => _.isNil(rfpItem?.request?.asset?.generalAsset),
    [rfpItem?.request?.asset],
  );

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName,
      moduleId: isAsset
        ? rfpItem?.request?.asset?.id ?? 0
        : rfpItem?.request?.asset?.generalAsset?.id ?? 0,
      category: AttachmentCategory.All,
    },
    {
      skip: !isDetailsTab || !rfpItem?.request?.asset?.id,
    },
  );

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const rfpItemAsset = useMemo(() => {
    return !rfpItem?.request?.asset?.generalAsset?.id && rfpItem?.assetId
      ? {
          href: rfpItem?.assetId?.toString(),
          text: rfpItem?.request?.asset?.name || '',
        }
      : undefined;
  }, [rfpItem]);

  const rfpItemGeneralAsset = useMemo(() => {
    return rfpItem?.request?.asset?.generalAsset?.id
      ? {
          href: rfpItem?.request?.asset?.generalAsset?.id?.toString(),
          text: rfpItem?.request?.asset?.generalAsset?.name || '',
        }
      : undefined;
  }, [rfpItem]);

  const rfpItemWO = useMemo(() => {
    const workOrderId = rfpItem?.workOrderId?.toString();
    return workOrderId
      ? {
          href: workOrderId,
          text: `#${workOrderId}`,
        }
      : undefined;
  }, [rfpItem]);

  const rfpProposals = useMemo(() => {
    return rfpItem?.request?.proposals && rfpItem?.request?.proposals.length
      ? {
          content: rfpItem.request.proposals.map(proposal => {
            return {
              href: proposal.id.toString(),
              text: proposal.id.toString(),
            };
          }),
        }
      : undefined;
  }, [rfpItem?.request?.proposals]);

  const hasEditRfpPermission = useHasPermission([P.EditRFP]);

  const { data: rfpNextStatus } = useGetRFPNextStatusListQuery(
    Number(id) || 0,
    { skip: !id || !isDetailsTab },
  );

  const additionalActions = useMemo(() => {
    const statusToId = rfpNextStatus?.find(
      status => status.name === WorkflowStatus.Cancelled,
    )?.statusTo;
    if (!statusToId) return;
    return [
      <CancelRfpModalButton
        statusToId={statusToId}
        key="cancel"
        rfpId={Number(id)}
      />,
    ];
  }, [id, rfpNextStatus]);

  const locationSidebar = useMemo(() => {
    if (!rfpItem || !rfpItem?.request?.asset?.branch?.id) return undefined;

    return {
      href: rfpItem.request.asset.branch.id.toString(),
      text: rfpItem.request.asset.branch.name,
    };
  }, [
    rfpItem?.request?.asset?.branch?.id,
    rfpItem?.request?.asset?.branch?.name,
  ]);

  return (
    <>
      <ECWorkflowTemplate
        pageTitle={t('translation:pages.requestForPricing.detailPageTitle')}
        tabsAsLinks
        summaryData={summaryData as SummaryFieldsData[]}
        title={rfpItem?.name ?? ''}
        detailsData={{}}
        editConfig={{
          submitTitle: 'Save',
        }}
        editFields={[]}
        detailsConfig={{}}
        detailsFields={[]}
        imgSrc={assetProfileAttachment?.url}
        profileAttachment={assetProfileAttachment}
        moduleName={moduleName}
        moduleId={id}
        useUpdateMutation={useUpdateRfp}
        hideNoteTab
        warrantyBadgeType={assetWarranty?.[0]?.status}
        asset={rfpItemAsset}
        generalAsset={rfpItemGeneralAsset}
        workOrder={rfpItemWO}
        rfps={{
          content: [
            {
              href: rfpItem?.id?.toString() ?? '',
              text: rfpItem?.id?.toString() ?? '',
            },
          ],
        }}
        onEditButtonClick={setIsEditMode}
        proposals={rfpProposals}
        onExitEditModeWithoutSave={() => initializeFields()}
        isEditAllowed={hasEditRfpPermission[0]}
        additionalActions={additionalActions}
        location={locationSidebar}
      />

      {isDetailsTab && !isEditMode && rfpItem && (
        <RFPProposalsTable rfpItem={rfpItem} />
      )}
    </>
  );
};
