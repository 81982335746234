import {
  CreateRegionsDistrictsParams,
  Organization,
  OrganizationCreate,
  OrganizationDelete,
  OrganizationType,
  OrganizationUpdate,
  UpdateRegionsDistrictsParams,
} from 'types/Organization';

import { emptyApi } from './emptyApi';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'Organization',
    'RegionsDistricts',
    'RegionsDistrictsById',
    'Regions',
    'Districts',
  ],
});

export const organizationApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getOrganizationList: builder.query<Organization[], void>({
      query: () => {
        return {
          url: 'org',
        };
      },
      providesTags: ['Organization'],
    }),
    createOrganizationNode: builder.mutation<void, OrganizationCreate>({
      query: body => {
        return {
          url: 'org',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Organization', 'Regions', 'Districts'],
    }),
    updateOrganizationNode: builder.mutation<void, OrganizationUpdate>({
      query: body => {
        return {
          url: `org/${body.id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Organization', 'Regions', 'Districts'],
    }),
    deleteOrganizationNode: builder.mutation<void, OrganizationDelete>({
      query: body => ({
        url: `org/${body.id}`,
        method: 'delete',
      }),
      invalidatesTags: ['Organization', 'Regions', 'Districts'],
    }),
    getOrganizationTypeList: builder.query<OrganizationType[], void>({
      query: () => {
        return {
          url: 'org/types',
        };
      },
      providesTags: ['Organization'],
    }),
    getOrganizationParentList: builder.query<OrganizationType[], void>({
      query: () => {
        return {
          url: 'org/parent',
        };
      },
      providesTags: ['Organization'],
    }),
    getRegions: builder.query<
      BaseType<any[]>,
      { noDistrict?: boolean } & QueryParams
    >({
      query: ({ noDistrict, ...queryParams }) => {
        const params = { ...queryParams };
        if (noDistrict) {
          params.noDistrict = 1;
        }
        // at the moment, we don't need to send st param because it's not implemented on the backend
        delete params.st;
        return {
          url: 'org/regions',
          params,
        };
      },
      providesTags: ['Regions'],
    }),
    getOrganizationHierarchy: builder.query<Organization[], void>({
      query: () => {
        return {
          url: `org/user/org-hierarchy`,
        };
      },
    }),
    getDistricts: builder.query<
      BaseType<any[]>,
      { noRegionParent?: boolean } & QueryParams
    >({
      query: ({ noRegionParent, ...queryParams }) => {
        const params = { ...queryParams };
        if (noRegionParent) {
          params.noRegionParent = 1;
        }
        // at the moment, we don't need to send st param because it's not implemented on the backend
        delete params.st;
        return {
          url: 'org/districts',
          params,
        };
      },
      providesTags: ['Districts'],
    }),
    getRegionsDistricts: builder.query<BaseType<any[]>, QueryParams>({
      query: params => {
        return {
          url: 'org/regions-districts',
          params,
        };
      },
      providesTags: ['RegionsDistricts'],
    }),
    getRegionsDistrictsById: builder.query<BaseType<any[]>, number>({
      query: id => {
        return {
          url: `org/regions-districts/${id}`,
        };
      },
      providesTags: ['RegionsDistrictsById'],
    }),
    createRegionsDistricts: builder.mutation<
      void,
      CreateRegionsDistrictsParams
    >({
      query: body => ({
        url: 'org/region/district',
        method: 'post',
        body,
      }),
      invalidatesTags: ['RegionsDistricts'],
    }),
    updateRegionsDistricts: builder.mutation<
      void,
      UpdateRegionsDistrictsParams
    >({
      query: body => ({
        url: 'org/region/district',
        method: 'put',
        body,
      }),
      invalidatesTags: ['RegionsDistricts'],
    }),
  }),
});

export const {
  useGetOrganizationListQuery,
  useLazyGetOrganizationListQuery,
  useGetOrganizationHierarchyQuery,
  useCreateOrganizationNodeMutation,
  useUpdateOrganizationNodeMutation,
  useDeleteOrganizationNodeMutation,
  useGetOrganizationTypeListQuery,
  useGetOrganizationParentListQuery,
  useGetRegionsQuery,
  useLazyGetRegionsQuery,
  useGetDistrictsQuery,
  useLazyGetDistrictsQuery,
  useGetRegionsDistrictsQuery,
  useLazyGetRegionsDistrictsQuery,
  useGetRegionsDistrictsByIdQuery,
  useLazyGetRegionsDistrictsByIdQuery,
  useCreateRegionsDistrictsMutation,
  useUpdateRegionsDistrictsMutation,
} = organizationApi;
