import { ECTypography } from '../ECTypography';

export const HistoryActionLabels: React.FC<{
  label1: string;
  label2: string;
}> = ({ label1, label2 }) => (
  <>
    <ECTypography
      variant="subtitle2"
      width="fit-content"
      color={theme => theme.palette.text.primary}
      fontSize={13}
      sx={{ fontWeight: 'bold', mb: 1 }}
    >
      {label1}
    </ECTypography>

    <ECTypography
      variant="subtitle2"
      width="fit-content"
      color={theme => theme.palette.text.secondary}
      fontSize={13}
      sx={{
        background: theme => theme.palette.other.divider,
        fontWeight: 'bold',
        paddingX: 2,
        paddingY: 0.5,
        mb: 0.5,
      }}
    >
      {label2}
    </ECTypography>
  </>
);
