import { ECTypography } from '../ECTypography';
import {
  ECWorkflowStatusBadge,
  WorkflowStatus,
} from '../ECWorkflowStatusBadge';
import {
  ECWorkflowPriorityBadge,
  WorkflowPriority,
} from '../ECWorkflowPriorityBadge';
import { ECGrid } from '../ECGrid';
import { ECSwitch } from '../ECSwitch';
import { ECPopover } from '../ECPopover';
import { useCallback, useEffect, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECMenuItem, ECSelect } from '../ECSelect';
import { ECTextField } from '../ECTextField';
import { useTranslation } from 'react-i18next';
import { ECChip } from '../ECChip';
import { ECStack } from '../ECStack';
import ECCurrencyField from '../ECCurrencyField';
import { FormHelperText } from '@mui/material';
import { ECAutocompletePaginated } from '../ECAutocompletePaginated';
import { ECAutocomplete } from '../ECAutocomplete';
import { ECAvtImage } from '../ECAvtImage';
import { ECCoupaField } from '../ECCoupaField';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import * as _ from 'lodash';
import { ECRating } from '../ECRating';
import { ECLink } from '../ECLink';
import { Chip, ECChipAutocomplete } from '../ECChipAutocomplete';
import LanguageIcon from '@mui/icons-material/Language';
import { formatDateForTable } from 'utils/strings/formatDate';
import { ECChipListTableCell } from '../ECTable/ECChipTableCell';
import { ECPhoneField } from '../ECPhoneField';
import { formatPhoneNumber } from 'utils/strings/phone';
import { renderLinearProgress } from '../ECTable';
import { ECBasicDatePicker } from '../ECDatePicker';
import { ECCustomAlert } from '../ECAlert';

export enum SummaryFieldTypes {
  ProgressBar,
  Status,
  State,
  Priority,
  Text,
  Currency,
  Switch,
  Select,
  SelectPaginated,
  CoupaCode,
  Autocomplete,
  AutocompleteChips,
  TextArea,
  AvtDetails,
  AvtPlateImage,
  SingleChip,
  EmailField,
  Rating,
  Address,
  Hyperlink,
  Date,
  Phone,
  CustomAlert,
}

export interface SummaryFieldsData {
  id: string;
  name?: string;
  label: string;
  type: SummaryFieldTypes;
  data?: string | number | boolean | WorkflowStatus | WorkflowPriority | any;
  dataPath?: string;
  defaultData?: string | number | boolean | WorkflowStatus | WorkflowPriority;
  options?: { label: string; data: any; color?: string; id?: number }[];
  showModalWhenValueIsEqualTo?: any;
  confirmationModalText?: string;
  confirmationButtonText?: string;
  isValid?: boolean;
  isEditable?: boolean;
  isVisible?: boolean;
  isNumeric?: boolean;
  buttonText?: string;
  placeholder?: string;
  onChange?: (newValue: any) => void;
  onClick?: () => void;
  onIconClick?: () => void;
  useQuery?: any;
  filterOptionsFn?: (option: any) => boolean;
  obAlias?: string;
  st?: string;
  validationMessage?: string;
  maxLength?: number;
  minLength?: number;
  showAddedLabel?: boolean;
  required?: boolean;
  href?: string;
  icon?: any;
  actionTitle?: string;
  freeSolo?: boolean;
  docType?: string;
  isLoaded?: boolean;
  retryCoupa?: boolean;
  shouldUseOriginalQueryParams?: boolean;
  renderCustomOption?: (option: any) => React.ReactNode;
  renderCustomSelectedValue?: (value: any) => React.ReactNode;
  queryParams?: any;
  limitTags?: number;
  coupaLinkText?: string;
  showErrorIcon?: boolean;
  extraComponent?: React.ReactNode;
  color?: string;
  minDate?: string | Date | null;
  maxDate?: string | Date | null;
  dateValueFormat?: string;
  dataCustomText?: string;
  hideIcon?: boolean;
}

interface ECWorkflowSummaryFieldProps {
  field: SummaryFieldsData;
  isEditMode?: boolean;
}

export const ECWorkflowSummaryField = ({
  field,
  isEditMode,
}: ECWorkflowSummaryFieldProps) => {
  const { t } = useTranslation();
  const { isVisible = true } = field;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [newData, setNewData] = useState<any>(field.data);

  useEffect(() => {
    setNewData(field.data);
  }, [field.data]);

  const open = Boolean(anchorEl);
  const idPopover = open ? 'simple-popover' : undefined;

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  const handleConfirmModal = () => {
    field.onChange?.(newData);
    handleCloseModal();
  };

  const renderData = useCallback(() => {
    const {
      id,
      name,
      type,
      data,
      defaultData,
      isEditable,
      options,
      isValid,
      isNumeric,
      buttonText,
      onChange,
      onClick,
      onIconClick,
      useQuery,
      filterOptionsFn,
      obAlias,
      st,
      validationMessage,
      maxLength,
      showAddedLabel,
      placeholder,
      href,
      icon,
      actionTitle,
      freeSolo,
      retryCoupa,
      coupaLinkText,
      shouldUseOriginalQueryParams,
      renderCustomOption,
      renderCustomSelectedValue,
      queryParams,
      limitTags = 0,
      showErrorIcon,
      extraComponent,
      dataPath,
      color,
      dataCustomText,
      hideIcon,
    } = field;

    const baseSx = {
      width: '100%',
    };

    switch (type) {
      case SummaryFieldTypes.ProgressBar:
        if (!dataPath) {
          return null;
        }

        return (
          <ECBox display="flex" gap={1} alignItems="center">
            <ECBox width="50%">
              {renderLinearProgress(data?.[dataPath], data, true)}
            </ECBox>

            <ECTypography variant="body2" color="text.secondary">{`${Math.round(
              data?.[dataPath],
            )}%`}</ECTypography>
          </ECBox>
        );
      case SummaryFieldTypes.Status:
        return <ECWorkflowStatusBadge status={data as WorkflowStatus} />;
      case SummaryFieldTypes.Priority:
        return (
          <ECWorkflowPriorityBadge
            priority={data as WorkflowPriority}
            priorityColor={color}
          />
        );
      case SummaryFieldTypes.Currency:
        return (
          <ECCurrencyField
            id={id}
            name={name}
            placeholder={placeholder}
            style={{
              width: '100%',
            }}
            readOnly={isEditable ? !isEditMode : true}
            value={data as number}
            onChange={(event, value) => onChange?.(value)}
            hiddenLabel
            variant={
              isEditable ? (!isEditMode ? 'standard' : 'filled') : 'standard'
            }
            validationMessage={isValid ? '' : validationMessage}
            error={!isValid}
            InputProps={{
              disableUnderline: isEditable ? !isEditMode : true,
            }}
          />
        );
      case SummaryFieldTypes.Switch:
        if (!(isEditMode || isEditable)) {
          return (
            <ECChip
              label={data ? 'Active' : 'Inactive'}
              variant="filled"
              sx={{
                bgcolor: theme =>
                  data
                    ? theme.palette.success.outlinedHoverBackground
                    : theme.palette.error.outlinedHoverBackground,
                color: theme =>
                  data ? theme.palette.success.dark : theme.palette.error.dark,
              }}
            />
          );
        }

        return (
          <>
            <ECSwitch
              id={id}
              checked={data as boolean}
              value={data}
              onChange={e => {
                if (
                  field.confirmationModalText &&
                  e.target.checked === field.showModalWhenValueIsEqualTo
                ) {
                  setAnchorEl(e.currentTarget);
                  setNewData(e.target.checked);
                } else {
                  onChange?.(e.target.checked);
                }
              }}
              disabled={!isEditMode}
            />
            <ECPopover
              id={idPopover}
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseModal}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{ maxWidth: '50%' }}
            >
              <ECBox p={2}>
                <ECTypography>{field.confirmationModalText}</ECTypography>

                <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
                  <ECButton variant="text" onClick={handleCloseModal}>
                    Cancel
                  </ECButton>
                  <ECButton variant="contained" onClick={handleConfirmModal}>
                    {field.confirmationButtonText
                      ? field.confirmationButtonText
                      : 'Confirm'}
                  </ECButton>
                </ECBox>
              </ECBox>
            </ECPopover>
          </>
        );
      case SummaryFieldTypes.Select:
        if (!isEditMode || !isEditable) {
          if (extraComponent) {
            return (
              <ECStack direction="row" spacing={1} alignItems="center">
                <ECTypography variant="body1">
                  {
                    options?.find(
                      option =>
                        option.data === defaultData || option.data === data,
                    )?.label
                  }
                </ECTypography>
                {extraComponent}
              </ECStack>
            );
          }
          return (
            <ECTypography variant="body1">
              {
                options?.find(
                  option => option.data === defaultData || option.data === data,
                )?.label
              }
            </ECTypography>
          );
        }

        return (
          <ECBox sx={baseSx}>
            <ECSelect
              id={id}
              displayEmpty={true}
              sx={baseSx}
              error={isValid === false}
              defaultValue={defaultData}
              value={defaultData ? undefined : data}
              hiddenLabel
              variant="filled"
              renderValue={value => {
                const optionIndex =
                  options?.findIndex(option => option.data === value) || 0;

                const optionLabel = options?.[optionIndex]?.label;

                return (
                  <ECBox display="flex" alignItems="center">
                    <ECTypography>{optionLabel}</ECTypography>
                  </ECBox>
                );
              }}
              onChange={event => {
                onChange?.(
                  options?.find(option => option.data === event.target.value),
                );
              }}
            >
              {!field.required && (
                <ECMenuItem value={-1}>
                  <ECBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ECTypography>{'Unselected'}</ECTypography>
                  </ECBox>
                </ECMenuItem>
              )}
              {options?.map((option, index) => {
                return (
                  <ECMenuItem value={option.data}>
                    <ECBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ECTypography>{option.label}</ECTypography>
                    </ECBox>
                  </ECMenuItem>
                );
              })}
            </ECSelect>

            {buttonText && onClick && (
              <ECButton variant="text" onClick={onClick}>
                {buttonText}
              </ECButton>
            )}
            {validationMessage && (
              <FormHelperText
                sx={theme => ({
                  color: theme.palette.error.main,
                })}
              >
                {validationMessage}
              </FormHelperText>
            )}
          </ECBox>
        );
      case SummaryFieldTypes.SelectPaginated:
        if (!isEditMode || !isEditable) {
          return (
            <ECTypography variant="body1">
              {data?.name ?? data?.label ?? ''}
            </ECTypography>
          );
        }

        return (
          <ECBox sx={baseSx}>
            <ECAutocompletePaginated
              sx={baseSx}
              fieldName={id}
              value={data}
              variant="filled"
              useQuery={useQuery}
              filterOptionsFn={filterOptionsFn}
              obAlias={obAlias}
              st={st}
              onChange={onChange}
              error={isValid === false}
              validationMessage={isValid ? '' : validationMessage}
            />

            {buttonText && onClick && (
              <ECButton variant="text" onClick={onClick}>
                {buttonText}
              </ECButton>
            )}
          </ECBox>
        );
      case SummaryFieldTypes.Text:
        if (isEditable && isEditMode) {
          return (
            <ECTextField
              id={id}
              sx={baseSx}
              hiddenLabel
              error={isValid === false}
              variant="filled"
              defaultValue={data || '-'}
              value={data}
              onChange={event => {
                let v: number | string = event.target.value;
                // If it is numeric and the value is not a number, don't change the value
                if (isNumeric) {
                  v = v.replace(/[^0-9]/g, '');
                }
                onChange?.(v);
              }}
              helperText={isValid ? null : validationMessage}
              inputProps={{
                maxLength,
              }}
              InputProps={{
                endAdornment: showErrorIcon && (
                  <ErrorOutlineIcon color="error" />
                ),
              }}
            />
          );
        }
        return (
          <ECStack direction="row" spacing={1} alignItems="center">
            <ECTypography variant="body1">{data as string}</ECTypography>
            {extraComponent}
            {icon ? (
              <ECButton
                startIcon={icon}
                onClick={onIconClick}
                sx={{ minWidth: '150px', height: 24, textTransform: 'none' }}
              >
                {actionTitle}
              </ECButton>
            ) : null}
          </ECStack>
        );
      case SummaryFieldTypes.State:
        const inactiveLabel = showAddedLabel
          ? t('translation:switch.notAdded')
          : t('translation:switch.inactive');
        const activeLabel = showAddedLabel
          ? t('translation:switch.added')
          : t('translation:switch.active');
        if (isEditMode) {
          const valueLabel = (newData !== undefined ? newData : data)
            ? activeLabel
            : inactiveLabel;
          return (
            <ECStack direction="row" spacing={1} alignItems="center">
              <ECSwitch
                checked={newData as boolean}
                onChange={e => {
                  onChange?.(e.target.checked);
                  setNewData(e.target.checked);
                }}
              />
              <ECTypography
                sx={theme => ({
                  color: theme.palette.text.primary,
                })}
              >
                {valueLabel}
              </ECTypography>
            </ECStack>
          );
        }

        const valueLabel = data ? activeLabel : inactiveLabel;
        return (
          <ECStack direction="row" spacing={1} alignItems="center">
            <ECBox width="100%" display="flex" flexDirection="column" gap={1}>
              <ECBox>
                <ECChip
                  label={valueLabel}
                  variant="filled"
                  sx={{
                    bgcolor: theme =>
                      data
                        ? theme.palette.success.outlinedHoverBackground
                        : theme.palette.error.outlinedHoverBackground,
                    color: theme =>
                      data
                        ? theme.palette.success.dark
                        : theme.palette.error.dark,
                  }}
                />
              </ECBox>
            </ECBox>
          </ECStack>
        );
      case SummaryFieldTypes.CoupaCode:
        return (
          <ECCoupaField
            href={href}
            retryCoupa={retryCoupa}
            requestId={data}
            linkLabel={coupaLinkText}
          />
        );
      case SummaryFieldTypes.Autocomplete:
        if (!isEditMode || !isEditable) {
          let label;
          if (typeof data === 'string') {
            label = data;
          } else {
            label = data?.name ?? data?.label ?? '';
          }
          return (
            <ECStack direction="row" spacing={1} alignItems="center">
              <ECTypography variant="body1">{label}</ECTypography>
              {extraComponent}
            </ECStack>
          );
        }
        return (
          <ECBox sx={baseSx}>
            <ECAutocomplete
              id={id}
              placeholder={placeholder}
              disablePortal
              sx={{
                '.MuiFilledInput-root': {
                  paddingTop: '10px !important',
                  paddingBottom: '10px !important',
                  paddingRight: '8px !important',
                },
                ...baseSx,
              }}
              value={
                data?.name ?? data?.label ?? typeof data === 'string'
                  ? data
                  : ''
              }
              options={options ?? []}
              errorMessage={
                isValid || data?.name || data?.label ? '' : validationMessage
              }
              getOptionLabel={option => option?.label || option?.text || option}
              onChange={(event, value) => {
                onChange?.(value);
              }}
              onInputChange={(event, value) => {
                if (value && typeof value === 'string') {
                  onChange?.(value);
                }
              }}
              freeSolo={freeSolo}
              autoSelect
            />
          </ECBox>
        );
      case SummaryFieldTypes.AutocompleteChips:
        if (!isEditMode || !isEditable) {
          return (
            <ECChipListTableCell
              key="chipsItem"
              col={{ dataPath: 'chips', chipTitleDataPath: 'label' }}
              row={{ chips: data }}
              renderAsTableCell={false}
              limitTags={limitTags}
              clickable
            />
          );
        }
        if (!useQuery && options) {
          return (
            <ECChipAutocomplete
              sx={baseSx}
              options={options as Chip[]}
              values={_.flatten(_.compact([data]))}
              getOptionLabel={option => option?.label || option?.text || option}
              placeholder={placeholder}
              variant="filled"
              onChange={newValue => {
                onChange?.(newValue);
              }}
              readOnly={!isEditMode || !isEditable}
              errorMessage={field.validationMessage}
            />
          );
        }

        return (
          <ECBox sx={baseSx}>
            <ECAutocompletePaginated
              sx={baseSx}
              fieldName={id}
              value={_.flatten(_.compact([data]))}
              multiple
              variant="filled"
              useQuery={useQuery}
              obAlias={obAlias}
              st={st}
              onChange={onChange}
              shouldUseOriginalQueryParams={shouldUseOriginalQueryParams}
              renderCustomOption={renderCustomOption}
              renderCustomSelectedValue={renderCustomSelectedValue}
              queryParams={queryParams}
              error={isValid === false}
              validationMessage={isValid ? '' : validationMessage}
              isReadOnlyForm={!isEditMode || !isEditable}
              limitTags={limitTags}
            />

            {buttonText && onClick && (
              <ECButton variant="text" onClick={onClick}>
                {buttonText}
              </ECButton>
            )}
          </ECBox>
        );
      case SummaryFieldTypes.TextArea:
        if (!isEditMode || !isEditable) {
          const label = data?.name ?? data?.label ?? data ?? '';
          return <ECTypography variant="body1">{label}</ECTypography>;
        }

        return (
          <ECTextField
            id={id}
            sx={baseSx}
            hiddenLabel
            error={isValid === false}
            variant="filled"
            multiline
            minRows={4}
            defaultValue={data}
            value={data}
            onChange={event => {
              let v: number | string = event.target.value;
              // If it is numeric and the value is not a number, don't change the value
              if (isNumeric) {
                v = v.replace(/[^0-9]/g, '');
              }
              onChange?.(v);
            }}
            helperText={isValid ? null : validationMessage}
            inputProps={{
              maxLength,
            }}
          />
        );
      case SummaryFieldTypes.AvtDetails:
        return (
          <ECStack direction="column" spacing={1} alignItems="start" mb={3}>
            <ECTypography variant="h6">{field.label}</ECTypography>
            <ECBox
              display="flex"
              width="100%"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {field?.options?.map((option, index) => (
                <ECBox key={index} display="flex" width="50%" mb={2}>
                  <ECGrid item xs={3} mb={1} maxWidth={300}>
                    <ECTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {option.label}:
                    </ECTypography>
                  </ECGrid>
                  <ECTypography variant="body1">{option.data}</ECTypography>
                </ECBox>
              ))}
            </ECBox>
          </ECStack>
        );
      case SummaryFieldTypes.AvtPlateImage:
        return (
          <ECStack direction="column" spacing={1} alignItems="start">
            <ECTypography variant="h6">{field.label}</ECTypography>
            <ECBox display="flex" width="100%" flexWrap="wrap">
              {field?.data?.map((item, index) => {
                return (
                  <ECAvtImage
                    src={item?.url}
                    alt={item?.originalName}
                    handleOnChange={field?.onChange}
                    id={item?.id}
                    key={item?.id}
                    helperText={item?.helperText}
                    docType={item?.documentCoreType?.name}
                  />
                );
              })}
            </ECBox>
          </ECStack>
        );
      case SummaryFieldTypes.SingleChip:
        return (
          <ECChip
            label={data?.label || data?.name || ''}
            sx={{
              bgcolor: theme =>
                theme.palette[data.severity || 'error'].outlinedHoverBackground,
              color: theme => theme.palette[data.severity || 'error'].dark,
            }}
            variant="filled"
          />
        );
      case SummaryFieldTypes.EmailField:
        if (isEditable && isEditMode) {
          return (
            <ECTextField
              id={id}
              sx={baseSx}
              hiddenLabel
              error={isValid === false}
              variant="filled"
              defaultValue={data}
              type="email"
              value={data}
              onChange={event => {
                let value = event.target.value;
                onChange?.(value);
              }}
              helperText={isValid ? null : validationMessage}
            />
          );
        }
        return (
          <ECStack direction="row" spacing={1} alignItems="center">
            <ECTypography variant="body1">{data as string}</ECTypography>
            {icon ? (
              <ECButton
                startIcon={icon}
                onClick={onIconClick}
                sx={{ minWidth: '150px', textTransform: 'none' }}
              >
                {actionTitle}
              </ECButton>
            ) : null}
          </ECStack>
        );
      case SummaryFieldTypes.Rating:
        return <ECRating value={data} readOnly precision={0.5} />;
      case SummaryFieldTypes.Address:
        return (
          <ECLink id="address" href={`http://maps.google.com/?q=${data}`}>
            {data as string}
          </ECLink>
        );
      case SummaryFieldTypes.Hyperlink:
        if (isEditable && isEditMode) {
          return (
            <ECTextField
              id={id}
              sx={baseSx}
              hiddenLabel
              error={isValid === false}
              variant="filled"
              defaultValue={data}
              value={data}
              onChange={event => {
                let value = event.target.value;
                onChange?.(value);
              }}
              helperText={isValid ? null : validationMessage}
            />
          );
        }

        return (
          <ECLink
            id="hyperlink"
            target="_blank"
            href={
              data
                ? data.startsWith('http://') || data.startsWith('https://')
                  ? data
                  : `http://${data}`
                : ''
            }
          >
            {!hideIcon && <LanguageIcon sx={{ marginRight: '1rem' }} />}{' '}
            {dataCustomText ?? (data as string)}
          </ECLink>
        );

      case SummaryFieldTypes.Date:
        if (isEditable && isEditMode) {
          return (
            <ECBasicDatePicker
              fieldName={id}
              readOnly={false}
              label={field.label}
              sx={{ ...baseSx }}
              minDate={field.minDate}
              maxDate={field.maxDate}
              onChange={date => {
                const eventValue = date;
                onChange?.(eventValue);
              }}
              error={isValid === false}
              value={data}
              errorMessage={isValid ? null : validationMessage}
            />
          );
        }
        return (
          <ECTypography variant="body1">
            {formatDateForTable(data, field.dateValueFormat)}
          </ECTypography>
        );
      case SummaryFieldTypes.Phone:
        if (isEditable && isEditMode) {
          return (
            <ECPhoneField
              id={id}
              name={id}
              sx={baseSx}
              error={isValid === false}
              validationMessage={isValid ? undefined : validationMessage}
              variant="filled"
              value={data as string}
              readOnly={!isEditable || !isEditMode}
              onChange={event => {
                let v: number | string = event.target.value;
                if (isNumeric) {
                  v = v.replace(/[^0-9]/g, '');
                }
                onChange?.(v);
              }}
            />
          );
        }

        return (
          <ECTypography variant="body1">
            {formatPhoneNumber(data as string) || '-'}
          </ECTypography>
        );
      case SummaryFieldTypes.CustomAlert:
        return (
          <ECCustomAlert
            title={data?.title}
            severity={data?.severity}
            description={data?.description}
            descriptionSx={{ marginBottom: 0}}
          >
          </ECCustomAlert>
        )
      default:
        return <ECTypography variant="body1">{data as string}</ECTypography>;
    }
  }, [field, isEditMode, newData]);

  if (!isVisible) {
    return <></>;
  }

  return (
    <>
      <ECGrid item container direction={'row'}>
        {field.label &&
          field.type !== SummaryFieldTypes.AvtDetails &&
          field.type !== SummaryFieldTypes.AvtPlateImage && (
            <ECGrid item mb={1} xs="auto">
              <ECTypography
                variant="body1"
                sx={{ width: '300px !important', fontWeight: 'bold' }}
              >
                {field.label}:
              </ECTypography>
            </ECGrid>
          )}

        <ECGrid item xs mb={1} sx={{ flex: 1 }}>
          {renderData()}
        </ECGrid>
      </ECGrid>
    </>
  );
};
