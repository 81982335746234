import { BaseType } from 'types/BaseType';
import { emptyApi } from './emptyApi';
import { AccountingGeneralLedgerCodes } from 'types/Accounting';
import { QueryParams } from 'types/QueryParams';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Accounting', 'AccountingGeneralLedgerCodes'],
});

// Define a service using a base URL and expected endpoints
export const accountingApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAccountingGLCodes: builder.query<
      BaseType<AccountingGeneralLedgerCodes[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: `accounting/general-ledger-codes`,
          params: {
            ...params,
            o: params.ob ? params.o : 'a',
            ob: params.ob ? params.ob : 'assttp.name',
          },
        };
      },
    }),
  }),
});

export const {
  useGetAccountingGLCodesQuery,
  useLazyGetAccountingGLCodesQuery,
} = accountingApi;
