import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider as XLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';

export const ECDatePicker = styled(DatePicker)<any>(({ theme }) => ({}));

export interface ECBasicDatePickerProps {
  value: any;
  label: string;
  onChange: (value: any) => void;
  sx?: any;
  error?: boolean | null;
  helperText?: string | null;
  fieldName?: string;
  readOnly?: boolean;
  errorMessage?: string | null;
  required?: boolean | null;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  dateValueFormat?: string;
  disabled?: boolean;
}

export const ECBasicDatePicker = (props: ECBasicDatePickerProps) => {
  const {
    value,
    label,
    onChange,
    readOnly = false,
    error,
    errorMessage,
    required,
    minDate,
    maxDate,
    dateValueFormat,
    disabled,
  } = props;

  const [showError, setShowError] = useState(error);
  const [dateErrorMessage, setDateErrorMessage] = useState(errorMessage);

  useEffect(() => {
    if (error) {
      setShowError(error);
    }
  }, [error]);

  useEffect(() => {
    if (errorMessage) {
      setDateErrorMessage(errorMessage);
    }
  }, [errorMessage]);

  const handleDateChange = (date: any) => {
    onChange(date);
    setShowError(false);
    if (date === null) {
      if (required) {
        setDateErrorMessage(errorMessage);
        setShowError(true);
      }
    } else {
      const parsedDate = moment(date);
      if (!parsedDate.isValid()) {
        setDateErrorMessage('Date is incorrect!');
        setShowError(true);
      }
    }
  };

  return (
    <XLocalizationProvider dateAdapter={AdapterMoment}>
      <ECDatePicker
        slotProps={{
          textField: {
            variant: readOnly ? 'standard' : 'filled',
            sx: { width: '100%' },
            error: showError,
            helperText: showError ? dateErrorMessage : null,
          },
        }}
        minDate={
          minDate
            ? moment.utc(
                moment(minDate, dateValueFormat).format('YYYY-MM-DD'),
                'YYYY-MM-DD',
              )
            : undefined
        }
        maxDate={
          maxDate
            ? moment.utc(
                moment(maxDate, dateValueFormat).format('YYYY-MM-DD'),
                'YYYY-MM-DD',
              )
            : undefined
        }
        label={label}
        value={
          value
            ? moment.utc(
                moment(value, dateValueFormat).format('YYYY-MM-DD'),
                'YYYY-MM-DD',
              )
            : null
        }
        onChange={handleDateChange}
        readOnly={readOnly}
        disabled={disabled}
      />
    </XLocalizationProvider>
  );
};
