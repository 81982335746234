import { ECButton, ECEasyForm } from 'app/components';
import { useCallback, useEffect } from 'react';
import { useDeclineRfpProposalMutation } from 'services/proposalApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { moduleApi } from 'services/moduleApi';
import { requestForPricingApi } from 'services/requestForPricingApi';

interface ServiceProviderDeclineProposalModalProps {
  proposal: any;
  onCloseModal?: () => void;
  statusToId: number;
  onSuccess?: () => void;
  companyName?: string;
}

const fancyFormDeclineProposal = require('./fancy_form_config_decline.json');

export function ServiceProviderDeclineProposalModal({
  proposal,
  onCloseModal,
  statusToId,
  onSuccess,
  companyName,
}: ServiceProviderDeclineProposalModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customerName = companyName || proposal?.companyName;

  useEffect(() => {
    // needs to insert value into moduleId field of Decline form to load the notifier users
    if (proposal?.id) {
      const addCommentField = fancyFormDeclineProposal.fields.find(
        f => f.fieldName === 'declineReason',
      );

      if (addCommentField) {
        addCommentField.moduleId = proposal?.id;
      }
    }
  }, [proposal?.id, fancyFormDeclineProposal]);

  const [
    doUpdateStatus,
    { isLoading: isLoadingActions, isSuccess: isSuccessUpdateAction },
  ] = useDeclineRfpProposalMutation();

  const handleDeclineProposal = useCallback(
    (formData, fieldData) => {
      const declineReasonField = fieldData.find(
        data => data.fieldName === 'declineReason',
      );
      const declineReason = declineReasonField?.comment;

      const recipientIds = declineReasonField?.selectedUsers?.map(
        user => user.nodeId,
      );

      doUpdateStatus({
        statusTo: statusToId,
        id: proposal.id,
        declineReason: declineReason,
        recipientIds,
      });
    },
    [doUpdateStatus, proposal.id, statusToId],
  );

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(requestForPricingApi.util.invalidateTags(['RfpItem']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Proposal status updated successfully',
        }),
      );
      onCloseModal?.();
      onSuccess?.();
    }
  }, [isSuccessUpdateAction]);

  return (
    <ECEasyForm
      pattern="modal"
      config={fancyFormDeclineProposal.config}
      fields={fancyFormDeclineProposal.fields}
      isSendingData={isLoadingActions}
      isLoading={isLoadingActions}
      isLoadingForm={false}
      onFormSubmit={handleDeclineProposal}
      saveButtonColor="error"
      customDrawerTitle={
        customerName
          ? `Confirm you want to Decline Proposal #${proposal?.id} from ${customerName}`
          : `Confirm you want to Decline Proposal #${proposal?.id}`
      }
      additionalActions={
        <ECButton type="button" variant="text" onClick={onCloseModal}>
          {t('translation:dynamicForm.cancel')}
        </ECButton>
      }
    />
  );
}
