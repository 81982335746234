import { useCallback, useEffect, useState } from 'react';
import { ECEasyFormCreate, ECEasyFormFieldType } from 'app/components';
import moment from 'moment';
import _ from 'lodash';

interface Step2Props {
  existingData?: any;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step2_create.json');

export const Step2 = ({ existingData, onClose, onSubmit }: Step2Props) => {
  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      if (field.type === 'group') {
        return {
          ...field,
          subFields: field?.subFields?.map(handleExistingData),
        };
      }
      let fieldValue = _.get(existingData, field.fieldName);
      if (
        field.fieldName === 'leaseOption1PeriodCode' ||
        field.fieldName === 'leaseOption2PeriodCode'
      ) {
        fieldValue = fieldValue?.id;
      }
      return {
        ...field,
        value: fieldValue || '',
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    () => fancyFormElementsCreate.fields?.map(handleExistingData) || [],
  );

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [existingData, handleExistingData]);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      if (
        data?.leaseOption1PeriodCode === -1 ||
        _.isNil(data?.leaseOption1PeriodCode) ||
        _.isNil(data?.leaseOption1PeriodCode?.id) ||
        _.isNil(data?.leaseOption1Value) ||
        data?.leaseOption1Value === '0' ||
        data?.leaseOption1Value === ''
      ) {
        data.leaseOption1PeriodCode = null;
        data.leaseOption1Value = null;
      }
      if (
        data?.leaseOption2PeriodCode === -1 ||
        _.isNil(data?.leaseOption2PeriodCode) ||
        _.isNil(data?.leaseOption2PeriodCode?.id) ||
        _.isNil(data?.leaseOption2Value) ||
        data?.leaseOption2Value === '0' ||
        data?.leaseOption2Value === ''
      ) {
        data.leaseOption2PeriodCode = null;
        data.leaseOption2Value = null;
      }
      if (moment(data?.leaseStartDate).isSameOrAfter(data?.leaseEndDate)) {
        data.leaseEndDate = moment(data.leaseStartDate).add(1, 'day').clone();
      }
      if (!data?.leaseStartDate || !data?.leaseEndDate) {
        data.leaseStartDate = null;
        data.leaseEndDate = null;
      }

      onSubmit?.({ ...existingData, ...data });
    };
    return [doSubmit, {}];
  }, [onSubmit]);

  return (
    <ECEasyFormCreate
      useCreateMutation={submit}
      onClose={onClose}
      formConfig={fancyFormElementsCreate.config}
      formFields={formFields}
      existingData={existingData}
    />
  );
};
