import { Fragment, useMemo } from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ArrowForward } from '@mui/icons-material';
import { camelCaseToWords } from 'utils/common';
import { isEqual } from 'lodash';

enum AllottedTimeMap {
  D = 'Day(s)',
  H = 'Hour(s)',
  W = 'Week(s)',
  Y = 'Year(s)',
  M = 'Month(s)',
}
export interface CompanyWOMaintenanceAllocatedTimeProps {
  field: string;
  beforeData?: any;
  bodyData?: any;
  parseValue: (value: any, bodyKey: string, otherValue?: any) => any;
}

export const CompanyPaidOptionsWOMaintenanceAllocatedTime = ({
  field,
  beforeData,
  bodyData,
  parseValue,
}: CompanyWOMaintenanceAllocatedTimeProps) => {
  const { bodyFields, beforeFields } = useMemo(() => {
    if (field === 'paidOptions') {
      return { beforeFields: beforeData, bodyFields: bodyData };
    }
    const filteredBeforeData = beforeData?.filter(
      datum => datum.allottedTime && datum.code && datum.name,
    );
    const filteredBodyData = bodyData?.filter(
      datum => datum.allottedTime && datum.code && datum.name,
    );
    return (
      filteredBodyData?.reduce(
        (acc, item) => {
          const beforeItem = filteredBeforeData.find(
            filteredItem =>
              filteredItem.name === item.name || filteredItem.id === item.id,
          );
          if (beforeItem && isEqual(item, beforeItem)) return acc;
          item.name &&
            (acc.bodyFields[item.name] =
              `${item.allottedTime} ${AllottedTimeMap[item.code] ?? ''}`);

          if (beforeItem) {
            beforeItem.name &&
              (acc.beforeFields[beforeItem.name] =
                beforeItem.allottedTime && beforeItem.code
                  ? `${beforeItem.allottedTime} ${AllottedTimeMap[beforeItem.code] ?? ''}`
                  : undefined);
          } else {
            item.name && (acc.beforeFields[item.name] = undefined);
          }

          return acc;
        },
        {
          beforeFields: {},
          bodyFields: {},
        },
      ) ?? {}
    );
  }, [beforeData, bodyData]);

  const isFieldAvailable = useMemo(() => {
    return (
      Object.keys(bodyFields ?? {}).length ||
      Object.keys(beforeFields ?? {}).length
    );
  }, [beforeFields, bodyFields]);

  const renderActivityWithArrayField = useMemo(() => {
    const loopThroughField = Object.keys(bodyFields).length
      ? bodyFields
      : beforeFields;
    const isBodyFieldAvailable = !!Object.keys(bodyFields).length;
    const isBeforeFieldAvailable = !!Object.keys(beforeFields).length;
    const arrowAppearIndex =
      Object.keys(loopThroughField).length === 1
        ? 0
        : Math.ceil(Object.keys(loopThroughField).length / 2) - 1;

    return Object.keys(loopThroughField ?? {}).map((bodyKey, index) => {
      return (
        <Fragment key={`${bodyKey}_${index}`}>
          <ECBox display="flex" width="100%">
            <ECBox display="flex" flex={1} width={0}>
              <ECTypography
                variant="subtitle2"
                minWidth="20vw"
                width="23vw"
                maxWidth="26vw"
                color={theme => theme.palette.text.secondary}
              >
                {bodyKey}
                :&nbsp;
              </ECTypography>

              <ECBox display="flex" flex={1} justifyContent="center">
                <ECBox
                  display="flex"
                  flex={1}
                  width={0}
                  visibility={
                    isBeforeFieldAvailable &&
                    beforeFields?.[bodyKey] !== bodyFields?.[bodyKey]
                      ? 'visible'
                      : 'hidden'
                  }
                  marginLeft={2}
                >
                  <ECTypography
                    variant="subtitle2"
                    maxWidth="100%"
                    color={theme => theme.palette.error.dark}
                    sx={{
                      textDecoration: 'line-through',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {parseValue(beforeFields?.[bodyKey], bodyKey)}
                  </ECTypography>
                </ECBox>

                <ECBox
                  display="flex"
                  visibility={isBodyFieldAvailable ? 'visible' : 'hidden'}
                >
                  <ArrowForward
                    sx={theme => ({
                      color:
                        index === arrowAppearIndex
                          ? theme.palette.action.active
                          : 'transparent',
                      mx: 1,
                    })}
                    fontSize="small"
                  />
                </ECBox>

                <ECBox
                  display="flex"
                  flex={1}
                  width={0}
                  justifyContent={'flex-start'}
                >
                  <ECTypography
                    variant="subtitle2"
                    maxWidth="100%"
                    color={theme =>
                      bodyKey?.toLowerCase().includes('removed')
                        ? theme.palette.error.dark
                        : theme.palette.success.dark
                    }
                  >
                    {parseValue(bodyFields?.[bodyKey], bodyKey)}
                  </ECTypography>
                </ECBox>
              </ECBox>
            </ECBox>
          </ECBox>
        </Fragment>
      );
    });
  }, [beforeFields, bodyFields]);

  return (
    <>
      {isFieldAvailable ? (
        <ECTypography
          variant="subtitle2"
          minWidth="15vw"
          width="15vw"
          maxWidth="20vw"
          color={theme => theme.palette.text.secondary}
        >
          {camelCaseToWords(field)}
          :&nbsp;
        </ECTypography>
      ) : null}
      {renderActivityWithArrayField}
    </>
  );
};
