import { Method } from '../../../types/BaseType';
import _ from 'lodash';

export const processAssetBody = (bodyToRender, method) => {
  if (method === Method.PUT) {
    const customOrder = ['note'];
    const updatedBody = { ...bodyToRender };
    const renamedAndOrderedObject = customOrder.reduce((acc, key) => {
      if (updatedBody.hasOwnProperty(key)) {
        acc[key] = updatedBody[key];
      }
      return acc;
    }, {});

    Object.keys(updatedBody).forEach(key => {
      if (!renamedAndOrderedObject.hasOwnProperty(key)) {
        renamedAndOrderedObject[key] = updatedBody[key];
      }
    });

    return renamedAndOrderedObject;
  }
  return bodyToRender;
};
