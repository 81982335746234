import { ECBox } from '../../../components/ECBox';
import { ECTypography } from '../../../components/ECTypography';
import { useGetWorkOrderByIdQuery } from 'services/workOrdersApi';
import { ECModal } from 'app/components/ECModal';
import { ECWorkflowSummary } from 'app/components/ECWorkflowSummary';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import {
  ECIconButton,
  ECLink,
  ECRequirePermission,
  ECStack,
} from 'app/components';
import { useMatch } from 'react-router';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import { P } from 'types/Permission';

interface DispatchWorkOrderModalProps {
  workOrderId: number;
  isOpen: boolean;
  onClose: (event?: any, reason?: any) => void;
  openCopyWoDrawer?: (value: boolean, workOrder: any) => void;
  priorityColor?: string;
}

export const DispatchWorkOrderModal = ({
  workOrderId,
  isOpen,
  onClose,
  openCopyWoDrawer,
  priorityColor,
}: DispatchWorkOrderModalProps) => {
  const match = useMatch('/panel/*');
  const navigate = useNavigate();

  const { data: workOrder, isLoading: isLoadingWoById } =
    useGetWorkOrderByIdQuery(
      { id: workOrderId, includeLastCommentUserInfo: 1 },
      {
        skip: !workOrderId,
      },
    );

  return (
    <ECRequirePermission scopes={[P.GetWorkOrder, P.GetDispatch]}>
      <ECModal
        showCloseButton
        isOpen={isOpen}
        onClose={onClose}
        sx={{ width: '769px' }}
        p={3}
        header={
          <ECStack
            direction="row"
            alignItems="center"
            width="672px"
            height="50px"
            borderRadius="4px"
            display="flex"
            justifyContent="space-between"
          >
            <ECBox display="flex" alignItems="center" width="600px">
              <ECTypography mr={1} pl="14px" variant="h6">
                WO ID: {workOrderId}
              </ECTypography>
              <ECLink
                href={`${match?.pathnameBase}/workorder/${workOrderId}`}
                fontSize="12px"
              >
                View details
              </ECLink>
            </ECBox>
            {!isLoadingWoById && (
              <ECStack
                gap={1}
                direction="row"
                display="flex"
                justifyContent="flex-end"
                width="100%"
                alignItems="center"
              >
                <ECIconButton
                  size="medium"
                  withBorder
                  onClick={() => {
                    navigate(
                      `${match?.pathnameBase}/workorder/${workOrderId}`,
                      {
                        state: { from: 'dispatch' },
                      },
                    );
                  }}
                >
                  <EditIcon
                    sx={theme => ({ color: theme.palette.grey[600] })}
                  />
                </ECIconButton>
                {workOrder?.locationOnHold !== 1 && (
                  <ECIconButton
                    onClick={() => {
                      onClose();
                      openCopyWoDrawer?.(true, workOrder);
                    }}
                    size="medium"
                    withBorder
                  >
                    <ContentCopyIcon
                      sx={theme => ({ color: theme.palette.grey[600] })}
                    />
                  </ECIconButton>
                )}
              </ECStack>
            )}
          </ECStack>
        }
        footer={
          workOrder?.lastComment &&
          workOrder?.lastCommentUser &&
          workOrder?.lastCommentEmail && (
            <ECBox display="flex" flexDirection="column" width="100%">
              <ECBox display="flex" flex={1} width={0}>
                <ECStack
                  direction="row"
                  spacing={1}
                  sx={{ marginLeft: '14px' }}
                >
                  <ECTypography
                    variant="body1"
                    color={theme => theme.palette.text.primary}
                    fontWeight={700}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Last Comment
                  </ECTypography>
                  <ECTypography
                    variant="subtitle2"
                    sx={{ whiteSpace: 'nowrap' }}
                    color={theme => theme.palette.text.secondary}
                  >
                    ({workOrder?.lastCommentUser}
                  </ECTypography>
                  <ECTypography
                    variant="body1"
                    sx={{
                      position: 'relative',
                      top: '0.8rem',
                      fontSize: '1.0rem',
                      lineHeight: '0',
                    }}
                  >
                    •
                  </ECTypography>
                  <ECTypography
                    variant="subtitle2"
                    sx={{ whiteSpace: 'nowrap' }}
                    color={theme => theme.palette.text.secondary}
                  >
                    {workOrder?.lastCommentEmail})
                  </ECTypography>
                </ECStack>
              </ECBox>
              <ECBox display="flex" sx={{ marginLeft: '14px' }}>
                <ECTypography
                  variant="body1"
                  color={theme => theme.palette.text.primary}
                  fontWeight={400}
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                >
                  {`"${workOrder?.lastComment
                    ?.replace(/<\/?[^>]+(>|$)/g, '')
                    ?.replace(/&nbsp;|&amp;|&quot;|&lt;|&gt;|&#39;/g, ' ')
                    ?.replace(/\s+/g, ' ')
                    ?.trim()}"`}
                </ECTypography>
              </ECBox>
            </ECBox>
          )
        }
        showDivider
      >
        {isLoadingWoById ? (
          <ECBox display="flex" justifyContent="center" mt={3}>
            <ECCircularProgress />
          </ECBox>
        ) : (
          <ECWorkflowSummary
            showWarrantyAndLogo={false}
            fieldsOptionalSx={{
              minWidth: '100% !important',
            }}
            summaryFieldsData={[
              {
                id: 'status',
                label: 'WO Status',
                data: workOrder?.workflowStatus,
                type: SummaryFieldTypes.Status,
              },
              {
                id: 'location',
                label: 'Location',
                data: workOrder?.locationName,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'storeNumber',
                label: 'Store Number',
                data: workOrder?.locationStoreNumber,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'serviceProvider',
                label: 'Service Provider',
                data: workOrder?.serviceProviderName || 'Unassigned',
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'asset',
                label: 'Asset',
                data: workOrder?.assetName,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'category',
                label: 'Category',
                data: workOrder?.category,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'createdDate',
                label: 'Created Date',
                data: workOrder?.workOrderDate,
                type: SummaryFieldTypes.Date,
              },
              {
                id: 'priority',
                label: 'Priority',
                data: workOrder?.priorityName,
                type: SummaryFieldTypes.Priority,
                color: priorityColor,
              },
            ]}
          />
        )}
      </ECModal>
    </ECRequirePermission>
  );
};
