/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Edit, Close, Link, Print } from '@mui/icons-material';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory } from 'types/Attachment';
import { P } from 'types/Permission';
import { ECBox } from '../ECBox';
import { AttachmentTab } from '../ECDynamicPageTemplate/AttachmentTab';
import { HistoryTab } from '../ECDynamicPageTemplate/HistoryTab';
import { NoteTab } from '../ECDynamicPageTemplate/NoteTab';
import { StepsTab } from '../ECDynamicPageTemplate/steps/StepsTabs';
import { ECIconButton } from '../ECIconButton';
import { ECStack } from '../ECStack';
import { ECTabContainer } from '../ECTabs';
import { ECToggleButton } from '../ECToggleButton';
import { ECTypography } from '../ECTypography';
import { ECButton } from '../ECButton';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { ECEasyFormFieldType } from '../ECForm';
import { FieldTypes } from '../ECForm/FieldTypes';
import { useTranslation } from 'react-i18next';
import { addErrorToFieldsConfig, validateFields } from 'utils/validate-fields';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useLazyGetValidateDuplicateApprovalQuery } from 'services/approvalApi';
import _ from 'lodash';
import { HTTPError } from 'types';
import { Outlet } from 'react-router';
import { Lookup } from 'types/Lookup';
import { ECRequirePermission } from '../ECRequirePermission';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECModal } from '../ECModal';
import parse from 'html-react-parser';
import { ECApprovalWorkflowActivities } from '../ECApprovalWorkflowActivity';
import { StepsLkpModule } from 'types/Approval';
import { FeedbackTab } from '../ECDynamicPageTemplate/FeedbackTab';
import { themes } from 'styles/theme/themes';
import { StyleConstants } from 'styles/StyleConstants';
import { ECConfirmationDialog } from '../ECDialog';

export interface TabsType {
  value: string;
  label: string;
  content: ReactElement;
  scopes?: any;
  additionalTabFieldnameData?: string;
  data?: any;
  link?: string;
  editable?: boolean;
  disabled?: boolean;
}

export interface SaveConfirmationModal {
  shouldShowConfirmationModal: boolean;
  title?: string;
  buttonText?: string;
  cancelButtonText?: string;
  bodyText?: string;
  onClose?: () => void;
  onSubmit?: () => void;
}
interface ECDrawerDetailsContentProps {
  data: any;
  EditForm?: ReactElement;
  SummaryDataForm?: ReactElement;
  editSelected?: boolean;
  showOnlyHistoryTab?: boolean;
  showOnlyDetailsTab?: boolean;
  showImageAttachment?: boolean;
  showSteps?: boolean;
  hideHeader?: boolean;
  title?: string;
  onClose?: () => void;
  onEditClick?: () => void;
  onLinkClick?: () => void;
  onPrintClick?: () => void;
  selectedTab?: string;
  onSelectedTabChange?: (tabValue: string) => void;
  useUpdateMutation?: any;
  useCreateMutation?: any;
  showEditButton?: boolean;
  isCreateNewStep?: boolean;
  showRatesAndTerms?: boolean;
  hasTopPadding?: boolean;
  notesTabname?: string;
  historyTabname?: string;
  additionalTabs?: TabsType[];
  outsideOnChangeEditForm?: (output: ECEasyFormFieldType[]) => void;
  disableTabs?: boolean;
  showOnlyadditionalTabs?: boolean;
  showOnlyAttachmentsTab?: boolean;
  isCreateForm?: boolean;
  module?: string;
  historyTabIdAttribute?: string | string[];
  attachmentsTitle?: string;
  attachmentTabIdAttribute?: string;
  tabsAsLinks?: boolean;
  hideNoteTab?: boolean;
  hideActivityTab?: boolean;
  hideAttachmentTab?: boolean;
  hideFeedbackTab?: boolean;
  saveConfirmationModal?: SaveConfirmationModal;
  customDetailsTabLabel?: string;
  initialApprovalData?: any;
  setInitialApprovalData?: any;
  detailTabsOrder?: any;
  disableChangeTab?: boolean;
  drawerTitleBarBGColor?: string;
}

export const ECDrawerDetailsContent = ({
  data,
  EditForm = <></>,
  SummaryDataForm,
  editSelected: initialEditSelected,
  showOnlyHistoryTab,
  showOnlyDetailsTab,
  showOnlyAttachmentsTab,
  showImageAttachment,
  showSteps,
  hideHeader,
  title,
  onClose,
  onEditClick,
  onLinkClick,
  onPrintClick,
  onSelectedTabChange,
  useUpdateMutation,
  useCreateMutation,
  showEditButton = true,
  isCreateNewStep = false,
  hasTopPadding = true,
  notesTabname = 'Comments',
  historyTabname,
  additionalTabs,
  outsideOnChangeEditForm,
  disableTabs,
  showOnlyadditionalTabs,
  isCreateForm,
  module,
  historyTabIdAttribute,
  attachmentsTitle,
  attachmentTabIdAttribute = 'id',
  tabsAsLinks = false,
  hideNoteTab,
  hideActivityTab,
  hideAttachmentTab,
  hideFeedbackTab = true,
  saveConfirmationModal,
  customDetailsTabLabel = 'Details',
  initialApprovalData,
  setInitialApprovalData,
  detailTabsOrder,
  disableChangeTab,
  drawerTitleBarBGColor,
}: ECDrawerDetailsContentProps) => {
  const { canEdit } = useSelector((state: RootState) => state.page.scopes);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [nextCliecked, setNextClicked] = useState(false);

  const handleCloseConfirmationModal = useCallback(() => {
    saveConfirmationModal?.onClose?.();
    setIsConfirmationModalOpen(false);
  }, [saveConfirmationModal]);

  const handleOnClickSave = () => {
    setIsOpenApprovalLimitConfirmationDialog(
      hasApprovalLimitBeenEnabled || hasApprovalLimitBeenUpdated,
    );
    if (hasApprovalLimitBeenEnabled || hasApprovalLimitBeenUpdated) {
      return;
    }

    if (!saveConfirmationModal) {
      handleClickSave();
      return;
    }
    saveConfirmationModal?.shouldShowConfirmationModal &&
      setIsConfirmationModalOpen(true);
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTab = useMemo(() => {
    if (!tabsAsLinks) return 'details';
    const pathsWithoutDetailsTabAsDefault = [
      'attachments',
      'notes',
      'activity',
      'warranty',
      'history',
      'location-assignment',
      'lease',
      'trades-and-regions',
      'rates-and-terms',
      'pmlocation',
      'assets',
      'allocated-to',
      'feedback',
    ];
    const path = _.last(window.location.href.split('/'));

    const pathWithoutParams = path?.split('?')[0];
    if (
      path &&
      (+path ||
        !pathsWithoutDetailsTabAsDefault.includes(pathWithoutParams || path))
    ) {
      return 'details';
    }
    return pathWithoutParams || path;
  }, [window.location.href]);

  const [editSelected, setEditSelected] = useState(initialEditSelected);
  const [editFormChanges, setEditFormChanges] = useState<ECEasyFormFieldType[]>(
    [],
  );
  const [stepsChanges, setStepsChanges] = useState<any[]>([]);
  const [requireStepsInvoice, setRequireStepsInvoice] = useState(false);
  const [requireStepsProposal, setRequireStepsProposal] = useState(false);
  const [selectedTabSteps, setSelectedTabSteps] = useState('0');
  const [autoApprovalLimitInvoice, setAutoApprovalLimitInvoice] = useState(0);
  const [autoApprovalLimitProposal, setAutoApprovalLimitProposal] = useState(0);
  const [autoApprovalOnProposal, setAutoApprovalOnProposal] = useState(0);
  const [
    isOpenApprovalLimitConfirmationDialog,
    setIsOpenApprovalLimitConfirmationDialog,
  ] = useState(false);

  const hasApprovalLimitBeenEnabled = useMemo(() => {
    return (
      (!data?.autoApprovalLimitInvoice && +autoApprovalLimitInvoice > 0) ||
      (!data?.autoApprovalLimitProposal && +autoApprovalLimitProposal > 0)
    );
  }, [
    autoApprovalLimitInvoice,
    autoApprovalLimitProposal,
    data?.autoApprovalLimitInvoice,
    data?.autoApprovalLimitProposal,
  ]);

  const hasApprovalLimitBeenUpdated = useMemo(() => {
    return (
      !!data &&
      !hasApprovalLimitBeenEnabled &&
      ((+autoApprovalLimitInvoice !== 0 &&
        data?.autoApprovalLimitInvoice !== +autoApprovalLimitInvoice) ||
        (+autoApprovalLimitProposal !== 0 &&
          data?.autoApprovalLimitProposal !== +autoApprovalLimitProposal))
    );
  }, [
    autoApprovalLimitInvoice,
    autoApprovalLimitProposal,
    data?.autoApprovalLimitInvoice,
    data?.autoApprovalLimitProposal,
    hasApprovalLimitBeenEnabled,
  ]);

  useEffect(() => {
    setEditSelected(initialEditSelected);
  }, [initialEditSelected]);

  const handleSelectedTabChange = (tabValue: string) => {
    onSelectedTabChange?.(tabValue);
  };

  const handleEditClick = () => {
    setEditFormChanges([]);
    setStepsChanges([]);
    setEditSelected(!editSelected);
    onEditClick?.();
  };

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: data?.moduleName,
      moduleId: data ? data[attachmentTabIdAttribute] : null,
      category: AttachmentCategory.All,
    },
    {
      skip: !showImageAttachment || showOnlyDetailsTab || showOnlyHistoryTab,
    },
  );

  const tabs = useMemo(() => {
    if (showOnlyDetailsTab) {
      return [
        {
          value: 'details',
          label: customDetailsTabLabel,
          content: <>{EditForm}</>,
          disabled: disableTabs,
        },
      ];
    }

    if (showOnlyHistoryTab) {
      return [
        {
          value: 'details',
          label: customDetailsTabLabel,
          content: <>{EditForm}</>,
          disabled: disableTabs,
        },
        {
          value: 'activity',
          label: 'Activity',
          content: (
            <>
              {tabsAsLinks ? (
                <Outlet />
              ) : (
                <HistoryTab
                  row={data}
                  moduleName={data?.moduleName}
                  idAttributeName={historyTabIdAttribute}
                />
              )}
            </>
          ),
          disabled: disableTabs,
        },
      ];
    }

    if (showOnlyAttachmentsTab) {
      return [
        {
          value: 'details',
          label: customDetailsTabLabel,
          content: <>{EditForm}</>,
          disabled: disableTabs,
        },
        {
          value: 'attachments',
          label: 'Attachments',
          content: (
            <>
              {tabsAsLinks ? (
                <Outlet />
              ) : (
                <AttachmentTab
                  row={data}
                  attachments={attachments}
                  idAttributeName={attachmentTabIdAttribute}
                />
              )}
            </>
          ),
          disabled: disableTabs,
          scopes: [P.AddAttachment],
          link: 'attachments',
        },
      ];
    }

    if (showSteps) {
      return [
        {
          value: 'details',
          label: customDetailsTabLabel,
          content: <>{EditForm}</>,
          disabled: disableTabs,
        },
        {
          value: '1',
          label: t('translation:dynamicForm.approvalConfiguration'),
          content: (
            <StepsTab
              row={data}
              initialApprovalData={initialApprovalData}
              setInitialApprovalData={setInitialApprovalData}
            />
          ),
          scopes: [P.AddAttachment],
          disabled: disableTabs,
        },
        {
          value: '2',
          label: t('translation:dynamicForm.activity'),
          content: <ECApprovalWorkflowActivities id={data?.id} />,
          scopes: [P.AddAttachment],
          disabled: disableTabs,
        },
      ];
    }

    if (additionalTabs && additionalTabs.length) {
      const tabsArray: any = [];
      if (showOnlyadditionalTabs) {
        tabsArray.push({
          value: 'details',
          label: customDetailsTabLabel,
          content: <>{EditForm}</>,
          disabled: disableTabs,
        });
        tabsArray.push(...additionalTabs);
      } else {
        tabsArray.push({
          value: 'details',
          label: customDetailsTabLabel,
          content: <>{EditForm}</>,
          disabled: disableTabs,
          link: 'details',
        });
        tabsArray.push(...additionalTabs);
        if (!hideAttachmentTab) {
          tabsArray.push({
            value: 'attachments',
            label: attachmentsTitle || 'Attachments',
            content: (
              <>
                {tabsAsLinks ? (
                  <Outlet />
                ) : (
                  <AttachmentTab
                    row={data}
                    attachments={attachments}
                    idAttributeName={attachmentTabIdAttribute}
                  />
                )}
              </>
            ),
            disabled: disableTabs,
            scopes: [P.AddAttachment],
            link: 'attachments',
          });
        }
        if (!hideNoteTab) {
          tabsArray.push({
            value: 'notes',
            label: notesTabname,
            content: (
              <>
                {tabsAsLinks ? (
                  <Outlet />
                ) : (
                  <NoteTab row={data} moduleName={data.moduleName} />
                )}
              </>
            ),
            disabled: disableTabs,
            scopes: [P.GetComment],
            link: 'notes',
          });
        }
        if (!hideActivityTab) {
          tabsArray.push({
            value: 'activity',
            label: historyTabname || 'Activity',
            content: (
              <>
                {tabsAsLinks ? (
                  <Outlet />
                ) : (
                  <HistoryTab
                    row={data}
                    moduleName={data?.moduleName}
                    idAttributeName={historyTabIdAttribute}
                  />
                )}
              </>
            ),
            disabled: disableTabs,
            link: 'activity',
          });
        }
      }
      if (detailTabsOrder) {
        if (tabsArray.length === detailTabsOrder?.length) {
          tabsArray.sort(
            (a, b) =>
              detailTabsOrder.indexOf(a.value) -
              detailTabsOrder.indexOf(b.value),
          );
        }
      }
      return tabsArray;
    }

    let defaultTabs = [
      {
        value: 'details',
        label: customDetailsTabLabel,
        content: <>{EditForm}</>,
        disabled: disableTabs,
        link: 'details',
      },
      {
        value: 'feedback',
        label: 'Feedback',
        content: (
          <>
            {tabsAsLinks ? (
              <Outlet />
            ) : (
              <FeedbackTab
                row={data}
                moduleName={data?.moduleName}
                idAttributeName={historyTabIdAttribute as string}
              />
            )}
          </>
        ),
        disabled: disableTabs,
        link: 'feedback',
      },
      {
        value: 'attachments',
        label: attachmentsTitle || 'Attachments',
        content: (
          <>
            {tabsAsLinks ? (
              <Outlet />
            ) : (
              <AttachmentTab
                row={data}
                attachments={attachments}
                idAttributeName={attachmentTabIdAttribute}
              />
            )}
          </>
        ),
        scopes: [P.AddAttachment],
        disabled: disableTabs,
        link: 'attachments',
      },
      {
        value: 'notes',
        label: notesTabname,
        content: (
          <>
            {tabsAsLinks ? (
              <Outlet />
            ) : (
              <NoteTab row={data} moduleName={data.moduleName} />
            )}
          </>
        ),
        scopes: [P.GetComment],
        disabled: disableTabs,
        link: 'notes',
      },
      {
        value: 'activity',
        label: 'Activity',
        content: (
          <>
            {tabsAsLinks ? (
              <Outlet />
            ) : (
              <HistoryTab
                row={data}
                moduleName={data?.moduleName}
                idAttributeName={historyTabIdAttribute}
              />
            )}
          </>
        ),
        disabled: disableTabs,
        link: 'activity',
      },
    ];

    if (hideNoteTab) {
      defaultTabs = defaultTabs.filter(tab => tab.value !== 'notes');
    }

    if (hideActivityTab) {
      defaultTabs = defaultTabs.filter(tab => tab.value !== 'activity');
    }

    if (hideAttachmentTab) {
      defaultTabs = defaultTabs.filter(tab => tab.value !== 'attachments');
    }

    if (hideFeedbackTab) {
      defaultTabs = defaultTabs.filter(tab => tab.value !== 'feedback');
    }

    return defaultTabs;
  }, [
    showOnlyHistoryTab,
    attachments,
    data,
    EditForm,
    disableTabs,
    hideFeedbackTab,
  ]);

  const onChangeSteps = useCallback(
    (change: any, checkboxes: any) => {
      const {
        isAllJobTitleMustReviewSelected,
        isAutoApproveSelected,
        isAutoApproveLimitSelected,
        autoApprovalLimit,
      } = checkboxes;
      const stepChangeModuleName = change?.[0]?.lkpModuleName;
      if (stepChangeModuleName === StepsLkpModule.Invoice) {
        setRequireStepsInvoice(!!isAllJobTitleMustReviewSelected);
        setAutoApprovalLimitInvoice(
          isAutoApproveLimitSelected ? autoApprovalLimit : 0,
        );
      } else if (stepChangeModuleName === StepsLkpModule.Proposal) {
        setRequireStepsProposal(!!isAllJobTitleMustReviewSelected);
        setAutoApprovalLimitProposal(
          isAutoApproveLimitSelected ? autoApprovalLimit : 0,
        );
      }
      setAutoApprovalOnProposal(isAutoApproveSelected ? 1 : 0);
      setStepsChanges(prev => {
        const filteredSteps = prev?.filter(
          step => step.lkpModuleName !== stepChangeModuleName,
        );
        return [...filteredSteps, ...change];
      });
    },
    [stepsChanges],
  );

  const [
    doCreate,
    {
      error: createError,
      isLoading: isLoadingCreate,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateMutation?.(data) || [undefined, {}];

  const [
    doUpdate,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateMutation,
    },
  ] = useUpdateMutation?.(data) || [undefined, {}];

  const [triggerValidateDuplicateApproval, resultValidateDuplicateApproval] =
    useLazyGetValidateDuplicateApprovalQuery();

  const resetQuery = () => {
    triggerValidateDuplicateApproval({
      branchTypeIds: [],
      categoryIds: [],
      amount: 1000,
      approvalId: 0,
    });
  };

  useEffect(() => {
    if (nextCliecked && !resultValidateDuplicateApproval.isLoading) {
      if (
        resultValidateDuplicateApproval &&
        resultValidateDuplicateApproval.isSuccess &&
        !resultValidateDuplicateApproval.data?.isDuplicate
      ) {
        setSelectedTabSteps('1');
      } else if (resultValidateDuplicateApproval.data?.isDuplicate) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message:
              'A configuration already exists for this amount, categories and concepts.',
          }),
        );
      }
      setNextClicked(false);
    }
    if (
      resultValidateDuplicateApproval.isError &&
      resultValidateDuplicateApproval.error &&
      'data' in resultValidateDuplicateApproval.error
    ) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: (resultValidateDuplicateApproval.error.data as any)?.message,
        }),
      );
    }
  }, [resultValidateDuplicateApproval, setNextClicked, nextCliecked]);

  const validateField = useCallback(
    (f, validation, formData) => {
      return validateFields(f, validation, formData, t);
    },
    [t, validateFields],
  );

  const handleClickNext = useCallback(() => {
    const newData = !editFormChanges.length
      ? isCreateNewStep
        ? [...EditForm.props.formFields]
        : [
            ...EditForm.props.formFields.map(field => {
              if (field.type === FieldTypes.Switch) {
                return {
                  ...field,
                  value: !!EditForm.props.row[field.fieldName],
                };
              } else if (field.type === FieldTypes.ChipAutocomplete) {
                const { fieldName, chipsNamePath } = field || {};
                return {
                  ...field,
                  value: _.get(EditForm.props.row, fieldName)?.map(
                    fieldValue => ({
                      label: fieldValue[`${chipsNamePath}`] || fieldValue,
                      color: fieldValue['color'],
                      id: fieldValue['id'],
                    }),
                  ),
                  chips: _.get(EditForm.props.row, fieldName)?.map(
                    fieldValue => fieldValue[`${chipsNamePath}`] ?? fieldValue,
                  ),
                  variant: field.readOnly ? 'standard' : 'filled',
                };
              } else if (field.type === FieldTypes.Select) {
                return {
                  ...field,
                  value:
                    field.optionValues?.[
                      field.options.findIndex(
                        option =>
                          option === _.get(EditForm.props.row, field.fieldName),
                      )
                    ],
                };
              } else {
                return {
                  ...field,
                  value: _.get(EditForm.props.row, field.fieldName) ?? '',
                  variant: field.readOnly ? 'standard' : 'filled',
                };
              }
            }),
          ]
      : editFormChanges;

    const validation = { isValid: true };
    const validatedOutput = newData.map(f =>
      validateField(f, validation, newData),
    );

    if (validation.isValid) {
      /// Send it out
      const branchTypeIds = (
        validatedOutput?.find(o => o.fieldName === 'branchTypes')?.value || []
      )?.map(v => v.id) as number[];
      const categories = validatedOutput.find(
        o => o.fieldName === 'requestCategory',
      )?.value;
      const categoryIds =
        (Array.isArray(categories) && categories.map(chip => chip.id)) || [];
      const amount = validatedOutput.find(o => o.fieldName === 'amount')
        ?.value as number;

      if (!editFormChanges.length) {
        setEditFormChanges(validatedOutput);
      }

      triggerValidateDuplicateApproval({
        branchTypeIds,
        categoryIds,
        amount,
        approvalId: isCreateNewStep ? 0 : data.id,
      });
    } else {
      //updating the state is needed only for displaying error state
      setEditFormChanges(validatedOutput);
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'Error validating some fields',
        }),
      );
    }
    setNextClicked(true);
  }, [editFormChanges, resultValidateDuplicateApproval, setNextClicked]);

  const onChangeEditForm = useCallback(
    (change: ECEasyFormFieldType[] | any, fieldname?: string) => {
      if (!change) return;
      // this is breaking some pages and returning [object Object] on text fields
      // I think this first IF never triggered because we never sent the fieldname, now we do and it broke
      // commenting it out
      /* if (fieldname) {
        setEditFormChanges(prev => {
          //if (!prev) return prev;
          const editFormFields = [...prev];
          const index = editFormFields.findIndex(
            x => x.fieldName === fieldname,
          );
          editFormFields[index].value = change;
          return editFormFields;
        });
      } else { */
      if (resultValidateDuplicateApproval.data?.isDuplicate) {
        setSelectedTabSteps('0');
        resetQuery();
        setNextClicked(false);
      }
      setEditFormChanges(change);
      //}
    },
    [
      editFormChanges,
      resultValidateDuplicateApproval.data?.isDuplicate,
      resetQuery,
      setNextClicked,
    ],
  );

  const handleClickSave = useCallback(() => {
    const validation = { isValid: true };
    const validatedOutput = editFormChanges.map(f =>
      validateField(f, validation, editFormChanges),
    );

    if (!validation.isValid) {
      setEditFormChanges(validatedOutput);
      return false;
    }

    const updated = {
      requireStepsInvoice: requireStepsInvoice ? 1 : 0,
      requireStepsProposal: requireStepsProposal ? 1 : 0,
      autoApprovalLimitInvoice,
      autoApprovalLimitProposal,
      autoApprovalOnProposal,
    };
    editFormChanges.forEach(field => {
      if (field.type === FieldTypes.Switch) {
        updated[field.fieldName] = field.value === true ? 1 : 0;
      } else if (field.fieldName === 'status') {
        updated['status'] = field.value === 'Active' ? 1 : 0;
      } else {
        const fieldName = field.fieldName?.split('.')?.[0] ?? field.fieldName;
        const nestedFieldName = field.fieldName?.split('.')?.[1];

        if (
          (field.type === FieldTypes.Text ||
            field.type === FieldTypes.ChipAutocomplete ||
            field.type === FieldTypes.ChipsList ||
            field.type === FieldTypes.AddressAutocomplete ||
            field.type === FieldTypes.FilePicker ||
            field.type === FieldTypes.BannerFilePicker ||
            field.type === FieldTypes.ColorPicker ||
            field.type === FieldTypes.RichText ||
            field.type === FieldTypes.Video ||
            field.type === FieldTypes.ZipCode ||
            field.type === FieldTypes.Autocomplete) &&
          field.fieldName
        ) {
          if (nestedFieldName) {
            updated[fieldName] = {
              ...updated[fieldName],
              [nestedFieldName]: field.value,
            };
          } else {
            updated[fieldName] = field.value;
          }
        } else if (field.type === FieldTypes.Country) {
          if (nestedFieldName) {
            updated[fieldName] = {
              ...updated[fieldName],
              [nestedFieldName]: (field.value as Lookup)?.value || field?.value,
            };
          } else {
            updated[fieldName] = (field.value as Lookup).value || field?.value;
          }
        } else if (field.type === FieldTypes.Select) {
          updated[fieldName] = {
            ...updated[fieldName],
            id: field.value,
          };
        } else {
          updated[fieldName] = field.value;
        }
      }
    });

    const stepsUpdateBody = {
      approval: {
        ...updated,
        id: data?.id,
      },
      steps: [...stepsChanges],
    };

    updated['id'] = data?.['id'];
    if (isCreateNewStep) {
      doCreate(stepsUpdateBody);
    } else {
      if (isCreateForm) {
        doCreate(updated);
      } else {
        doUpdate(showSteps ? stepsUpdateBody : updated);
      }
    }
  }, [
    editFormChanges,
    stepsChanges,
    requireStepsInvoice,
    requireStepsProposal,
    autoApprovalLimitInvoice,
    autoApprovalLimitProposal,
    doCreate,
    doUpdate,
    updateError,
    createError,
    isUpdateSuccess,
    isCreateSuccess,
    isUpdateError,
    resetUpdateMutation,
    isLoadingUpdate,
    updateData,
    hasApprovalLimitBeenEnabled,
    hasApprovalLimitBeenUpdated,
  ]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${module || ''} ${t(
            'translation:dynamicForm.updateSuccess',
          )}`,
        }),
      );
      setEditFormChanges([]);
      setStepsChanges([]);
      onClose?.();
      resetUpdateMutation?.();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (updateError) {
      const { data: errorData } = updateError as HTTPError;
      const message = Array.isArray(errorData?.description)
        ? errorData?.description?.[0]
        : errorData?.description || errorData?.message;

      if (message && errorData.fieldNameWithError) {
        const newFieldsState = addErrorToFieldsConfig(
          editFormChanges,
          errorData.fieldNameWithError,
          message,
        );
        setEditFormChanges(newFieldsState);
      } else {
        dispatch(
          setSnackbar({
            severity: 'error',
            message:
              (updateError as any)?.data?.message ||
              t('translation:dynamicForm.updateError', {
                name: module,
              }),
          }),
        );
      }
      resetUpdateMutation?.();
    }
  }, [updateError]);

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${module || ''} ${t(
            'translation:dynamicForm.saveSuccess',
          )}`,
        }),
      );
      setEditFormChanges([]);
      setStepsChanges([]);
      onClose?.();
      resetUpdateMutation?.();
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (createError) {
      const { data: errorData } = createError as HTTPError;
      const message = Array.isArray(errorData?.description)
        ? errorData?.description?.[0]
        : errorData?.description || errorData.message;

      if (message && errorData.fieldNameWithError) {
        const newFieldsState = addErrorToFieldsConfig(
          editFormChanges,
          errorData.fieldNameWithError,
          message,
        );
        setEditFormChanges(newFieldsState);
      } else {
        dispatch(
          setSnackbar({
            severity: 'error',
            message:
              (createError as any)?.data?.message ||
              t('translation:dynamicForm.updateError', {
                name: module,
              }),
          }),
        );
      }
      resetUpdateMutation?.();
    }
  }, [createError]);

  return (
    <ECBox
      height="100%"
      display="flex"
      flexDirection="column"
      pt={hasTopPadding ? 3 : 0}
    >
      <>
        {!editSelected && !hideHeader && (
          <ECBox display="flex" flexDirection="row">
            {attachments?.[0]?.url && showImageAttachment && (
              <img
                width={260}
                height={260}
                src={attachments?.[0].url}
                alt="detail"
              />
            )}
            <ECBox display="flex" width="100%">
              <ECBox
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                px={3}
                sx={{
                  backgroundColor:
                    drawerTitleBarBGColor || themes.light.palette.other.divider,
                  height: StyleConstants.DRAWER_TITLE_HEIGHT,
                }}
              >
                <ECTypography variant="h4">{title ?? data.name}</ECTypography>

                <ECStack direction="row" spacing={1} alignItems="center">
                  {showEditButton && (
                    <ECRequirePermission
                      scopes={canEdit ? [canEdit] : undefined}
                    >
                      <ECToggleButton
                        value="edit"
                        selected={editSelected}
                        onChange={handleEditClick}
                      >
                        <Edit />
                      </ECToggleButton>
                    </ECRequirePermission>
                  )}

                  {onLinkClick && (
                    <ECIconButton withBorder squared onClick={onLinkClick}>
                      <Link />
                    </ECIconButton>
                  )}

                  {onPrintClick && (
                    <ECIconButton withBorder squared onClick={onPrintClick}>
                      <Print />
                    </ECIconButton>
                  )}

                  <ECIconButton withBorder squared onClick={onClose}>
                    <Close />
                  </ECIconButton>
                </ECStack>
              </ECBox>
            </ECBox>
          </ECBox>
        )}

        {editSelected ? (
          showSteps ? (
            <>
              <ECBox display="flex" width="100%">
                <ECBox
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  sx={{
                    backgroundColor:
                      drawerTitleBarBGColor ||
                      themes.light.palette.other.divider,
                    height: StyleConstants.DRAWER_TITLE_HEIGHT,
                  }}
                >
                  <ECTypography variant="h4">{title ?? data.name}</ECTypography>
                  <ECBox>
                    <ECStack direction="row" spacing={1} alignItems="center">
                      {showEditButton && (
                        <ECToggleButton
                          value="edit"
                          selected={editSelected}
                          onChange={handleEditClick}
                        >
                          <Edit />
                        </ECToggleButton>
                      )}

                      {onLinkClick && (
                        <ECIconButton squared onClick={onLinkClick}>
                          <Link />
                        </ECIconButton>
                      )}
                      {onPrintClick && (
                        <ECIconButton squared onClick={onPrintClick}>
                          <Print />
                        </ECIconButton>
                      )}
                      <ECIconButton squared onClick={onClose}>
                        <Close />
                      </ECIconButton>
                    </ECStack>
                  </ECBox>
                </ECBox>
              </ECBox>
              <ECTabContainer
                renderAllTabs={true}
                selectedTab={selectedTabSteps}
                onChangeSelectedTab={setSelectedTabSteps}
                SummaryDataForm={SummaryDataForm}
                tabs={[
                  {
                    value: '0',
                    label: t('translation:dynamicForm.details'),
                    content: (
                      <>
                        {React.cloneElement(EditForm, {
                          onChange: onChangeEditForm,
                          showTitle: false,
                          outsideData: editFormChanges,
                        })}
                      </>
                    ),
                  },
                  {
                    disabled: selectedTabSteps !== '1',
                    value: '1',
                    label: t('translation:dynamicForm.approvalConfiguration'),
                    content: (
                      <StepsTab
                        editSelected={editSelected}
                        row={data}
                        onChange={onChangeSteps}
                        isCreateNewStep={isCreateNewStep}
                        initialApprovalData={initialApprovalData}
                        setInitialApprovalData={setInitialApprovalData}
                      />
                    ),
                  },
                  {
                    disabled: selectedTabSteps !== '2',
                    value: '2',
                    label: t('translation:dynamicForm.activity'),
                    content: <h2>Activity</h2>,
                  },
                ]}
                noPadding={true}
              />
              <ECBox
                display="flex"
                width="100%"
                justifyContent="flex-end"
                alignItems="start"
                px={3}
                pb={3}
              >
                <ECStack direction="row" justifyContent="right" spacing={2}>
                  <ECButton
                    type="button"
                    variant="text"
                    sx={{ height: 42 }}
                    onClick={
                      isCreateNewStep || isCreateForm
                        ? onClose
                        : handleEditClick
                    }
                  >
                    {t('translation:dynamicForm.cancel')}
                  </ECButton>
                  <ECButton
                    variant="contained"
                    disabled={isLoadingUpdate || isLoadingCreate}
                    sx={{
                      height: 42,
                      width: 'auto',
                    }}
                    onClick={
                      selectedTabSteps === '1'
                        ? handleOnClickSave
                        : handleClickNext
                    }
                  >
                    {isLoadingUpdate || isLoadingCreate ? (
                      <ECCircularProgress
                        size={'2rem'}
                        sx={{ color: 'background.paper' }}
                      />
                    ) : selectedTabSteps === '1' ? (
                      t('translation:dynamicForm.save')
                    ) : (
                      'Next'
                    )}
                  </ECButton>
                </ECStack>
              </ECBox>
              {saveConfirmationModal?.shouldShowConfirmationModal && (
                <ECModal
                  isOpen={isConfirmationModalOpen}
                  onClose={handleCloseConfirmationModal}
                >
                  <ECBox p={2}>
                    <ECTypography variant="h6">
                      {saveConfirmationModal.title}
                    </ECTypography>

                    <ECTypography>
                      {parse(saveConfirmationModal.bodyText ?? '')}
                    </ECTypography>

                    <ECBox
                      mt={2}
                      display="flex"
                      gap={2}
                      justifyContent="flex-end"
                    >
                      <ECButton
                        variant="text"
                        onClick={handleCloseConfirmationModal}
                      >
                        {saveConfirmationModal.cancelButtonText ?? 'Cancel'}
                      </ECButton>
                      <ECButton
                        variant="contained"
                        onClick={() => {
                          setIsConfirmationModalOpen(false);
                          handleClickSave();
                        }}
                        isLoading={isLoadingUpdate || isLoadingCreate}
                      >
                        {saveConfirmationModal.buttonText ?? 'Confirm'}
                      </ECButton>
                    </ECBox>
                  </ECBox>
                </ECModal>
              )}
            </>
          ) : additionalTabs ? (
            <>
              <ECBox display="flex" width="100%">
                <ECBox
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  sx={{
                    backgroundColor:
                      drawerTitleBarBGColor ||
                      themes.light.palette.other.divider,
                    height: StyleConstants.DRAWER_TITLE_HEIGHT,
                  }}
                >
                  <ECTypography variant="h4">{title ?? data.name}</ECTypography>
                  <ECBox>
                    <ECStack direction="row" spacing={1} alignItems="center">
                      {!isCreateForm && (
                        <ECToggleButton
                          value="edit"
                          selected={editSelected}
                          onChange={handleEditClick}
                        >
                          <Edit />
                        </ECToggleButton>
                      )}
                      <ECIconButton squared onClick={onClose}>
                        <Close />
                      </ECIconButton>
                    </ECStack>
                  </ECBox>
                </ECBox>
              </ECBox>
              <ECTabContainer
                renderAllTabs={false}
                selectedTab={selectedTab}
                tabsAsLinks={tabsAsLinks}
                tabs={[
                  {
                    value: 'details',
                    label: t('translation:dynamicForm.details'),
                    hasError: editFormChanges.some(x => x.validationMessage),
                    content: (
                      <>
                        {React.cloneElement(EditForm, {
                          onChange: onChangeEditForm,
                          showEditButton: false,
                          showCloseButton: false,
                          showSaveButton: false,
                          showTitle: false,
                          hideToolBar: true,
                          pattern: 'modal',
                          noPaddingX: true,
                          noPaddingY: true,
                          noPading: true,
                          outsideData: editFormChanges,
                          context: {
                            onChange: onChangeEditForm,
                            showEditButton: false,
                            showCloseButton: false,
                            showSaveButton: false,
                            showTitle: false,
                            hideToolBar: true,
                            pattern: 'modal',
                            noPaddingX: true,
                            noPaddingY: true,
                            noPading: true,
                            outsideData: editFormChanges,
                          },
                        })}
                      </>
                    ),
                  },
                  ...additionalTabs?.map(tab => {
                    return {
                      ...tab,
                      content: React.cloneElement(tab.content, {
                        onChange: onChangeEditForm,
                        data: tab.additionalTabFieldnameData
                          ? editFormChanges?.find(
                              efc =>
                                efc.fieldName ===
                                tab.additionalTabFieldnameData,
                            )?.value
                          : tab.data,
                        context: {
                          ...tab.content.props.context,
                          onChange: onChangeEditForm,
                          data: tab.additionalTabFieldnameData
                            ? editFormChanges?.find(
                                efc =>
                                  efc.fieldName ===
                                  tab.additionalTabFieldnameData,
                              )?.value
                            : tab.data,
                        },
                      }),
                    };
                  }),
                ]}
              />
              <ECBox
                display="flex"
                width="100%"
                justifyContent="flex-end"
                alignItems="start"
                px={3}
                pb={3}
              >
                <ECStack direction="row" justifyContent="right" spacing={2}>
                  <ECButton
                    type="button"
                    variant="text"
                    sx={{ height: 42 }}
                    onClick={
                      isCreateNewStep || isCreateForm
                        ? onClose
                        : handleEditClick
                    }
                  >
                    {t('translation:dynamicForm.cancel')}
                  </ECButton>
                  <ECButton
                    variant="contained"
                    disabled={isLoadingUpdate || isLoadingCreate}
                    sx={{
                      height: 42,
                      width: 'auto',
                    }}
                    onClick={handleClickSave}
                  >
                    {isLoadingUpdate || isLoadingCreate ? (
                      <ECCircularProgress
                        size={'2rem'}
                        sx={{ color: 'background.paper' }}
                      />
                    ) : (
                      t('translation:dynamicForm.save')
                    )}
                  </ECButton>
                </ECStack>
              </ECBox>
            </>
          ) : (
            <>
              {!outsideOnChangeEditForm
                ? EditForm
                : React.cloneElement(EditForm, {
                    onChange: outsideOnChangeEditForm,
                  })}
            </>
          )
        ) : (
          <ECTabContainer
            renderAllTabs={false}
            tabs={tabs}
            tabsAsLinks={tabsAsLinks}
            selectedTab={selectedTab}
            onChangeSelectedTab={handleSelectedTabChange}
            noPadding={true}
            SummaryDataForm={SummaryDataForm}
            disableChangeTab={disableChangeTab}
          />
        )}
      </>

      <ECConfirmationDialog
        open={isOpenApprovalLimitConfirmationDialog}
        title={
          hasApprovalLimitBeenUpdated
            ? 'Confirm Auto-Approval Limit Change'
            : 'Confirm Enabling Auto-Approval'
        }
        text={`The ${hasApprovalLimitBeenUpdated ? 'update' : 'feature'} will only apply to newly created Invoices or Proposals under the auto-approval limit. Existing Invoices or Proposals will not be affected.`}
        acceptButtonTitle="Confirm"
        cancelButtonTitle="Cancel"
        onAcceptClick={() => {
          setIsOpenApprovalLimitConfirmationDialog(false);
          handleClickSave();
        }}
        onClose={() => setIsOpenApprovalLimitConfirmationDialog(false)}
        onCancelClick={() => setIsOpenApprovalLimitConfirmationDialog(false)}
        sx={{ zIndex: 1400 }}
        disableScrollLock
      />
    </ECBox>
  );
};
