import { TypographyOwnProps } from '@mui/material/Typography';
import { ECLink } from '../ECLink';
import { ECTypography } from '../ECTypography';

interface ECCopyrightProps extends Omit<TypographyOwnProps, 'align'> {}

export const ECCopyright = ({
  color = 'white',
  variant = 'body2',
  ...props
}: ECCopyrightProps) => {
  return (
    <ECTypography variant={variant} align="center" color={color} {...props}>
      {`© 2018-${new Date().getFullYear()}, `}
      <ECLink color="inherit" href="https://ecotrak.com">
        Ecotrak, LLC
      </ECLink>
      {'. All rights reserved'}
    </ECTypography>
  );
};
