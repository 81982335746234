export interface AccountingGeneralLedgerCodes {
  assetTypeName: string;
  branchName?: string;
  [key: string]: string | undefined;
}

export enum AccountingViewsEnum {
  DEFAULT = 'default',
  LOCATIONS = 'locations',
  CONCEPTS = 'concepts',
  ALL = 'all',
}
