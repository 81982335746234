import React, { useEffect, useState } from 'react';
import { ECTextField } from '..';
import { CirclePicker } from 'react-color';
import { InputAdornment } from '@mui/material';
import { ECBox } from '../ECBox';
import { ECPopover } from '../ECPopover';
import { ECChip } from '../ECChip';

export interface ECColorPickerProps {
  label: string;
  placeholder?: string;
  onChange: any;
  defaultColor?: string;
  error?: boolean | null;
  helperText?: string | null;
  fieldName?: string;
  presetColors?: PresetColors[];
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
}

interface PresetColors {
  title: string;
  color: string;
}

export const ECColorPicker = ({
  label,
  placeholder,
  onChange,
  defaultColor,
  error,
  helperText,
  fieldName,
  presetColors,
  disabled,
  variant,
}: ECColorPickerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [colorHex, setColorHex] = useState<string | undefined>(
    presetColors?.find(presetColor => presetColor.title === defaultColor)
      ?.color,
  );
  const [colorName, setColorName] = useState<string | undefined>(defaultColor);

  useEffect(() => {
    setColorName(defaultColor);
    setColorHex(
      presetColors?.find(presetColor => presetColor.title === defaultColor)
        ?.color,
    );
  }, [defaultColor, presetColors]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeColor = color => {
    const colorName = presetColors?.find(
      presetColor =>
        presetColor.color.toLowerCase() === color.hex.toLowerCase(),
    )?.title;
    setColorHex(color.hex);
    setColorName(colorName);
    onChange?.(colorName);
  };

  return (
    <ECBox display="flex" sx={{ width: '100%' }}>
      <ECTextField
        aria-describedby={id}
        variant={variant}
        label={label}
        value=""
        onClick={handleClick}
        sx={{
          color: colorHex,
          width: '100%',
          '.MuiFilledInput-input': {
            height: '32px',
          },
          '.MuiInputBase-input': {
            height: '32px',
          },
        }}
        inputProps={{
          readOnly: disabled,
        }}
        InputProps={
          colorName
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    {colorHex && (
                      <ECChip
                        variant="outlined"
                        label={colorName}
                        color={colorName}
                        sx={{
                          height: 30,
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
      {!disabled && (
        <ECPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ECBox padding={2}>
            <CirclePicker
              color={colorHex}
              onChange={handleChangeColor}
              colors={presetColors?.map(presetColor => presetColor.color)}
            />
          </ECBox>
        </ECPopover>
      )}
    </ECBox>
  );
};
