import React from 'react';
import { ECPopover } from '../ECPopover';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECCheckbox } from '../ECCheckbox';
import { ECButton } from '../ECButton';

const ModuleName = {
  workorder: 'Work Order',
  invoice: 'Invoice',
};

const ECPrintCard = props => {
  const { module, handleClose, open, anchorEl, handlePrintActionClick } = props;

  const [checked, setChecked] = React.useState(false);

  return (
    <ECPopover
      id={'proposal-action-modal'}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={handleClose}
    >
      <ECBox
        p={2}
        sx={{
          width: '30rem',
        }}
      >
        <ECTypography variant="h6" textTransform="capitalize">
          Print {ModuleName[module]} Record
        </ECTypography>
        <ECBox mt={2} display="flex">
          <ECCheckbox
            checked={checked}
            onChange={e => setChecked(e.target.checked)}
            inputProps={{
              'aria-label': 'Include Comments',
            }}
          />
          <ECTypography
            variant="subtitle1"
            mt={1}
            sx={{
              cursor: 'pointer',
              fontWeight: 'regular',
            }}
            onClick={() => setChecked(!checked)}
          >
            Include Comments
          </ECTypography>
        </ECBox>
        <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
          <ECButton variant="text" onClick={handleClose}>
            Cancel
          </ECButton>
          <ECButton
            variant="contained"
            onClick={() => handlePrintActionClick(checked)}
          >
            Print
          </ECButton>
        </ECBox>
      </ECBox>
    </ECPopover>
  );
};

export default React.memo(ECPrintCard);
