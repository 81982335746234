import {
  useLazyGetAssetTypesListQuery,
  useCreateAssetTypeMutation,
  useUpdateAssetTypeMutation,
  useGetCoreAssetTypesListQuery,
  useGetPeriodEULListQuery,
  useGetPriorityListQuery,
} from 'services/assetTypeApi';
import { ECDynamicPageTemplate, ECEasyFormFieldType } from 'app/components';
import { populateDropDownFields } from 'utils/pageUtils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';

import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';

const fancyFormElementsDetail = require('./fancy_form_config_details.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

const EUL_TYPE_FIELDNAME = 'period';
const EUL_TYPE_FIELDNAME_EDIT = 'period.description';

export function AssetTypePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: eulPeriodData, isSuccess: isEulPeriodSuccess } =
    useGetPeriodEULListQuery();
  const [createFormFields] = useState<ECEasyFormFieldType[]>(
    fancyFormElementsCreate.fields,
  );
  const [editFormFields] = useState<ECEasyFormFieldType[]>(
    fancyFormElementsEdit.fields,
  );

  useEffect(() => {
    loadPageScopes('assetType');
  }, []);

  useEffect(() => {
    const coreAssetGroupFieldIndexCreate =
      fancyFormElementsCreate.fields.findIndex(
        field => field.fieldName === 'coreAssetType',
      );
    const coreAssetGroupFieldIndexEdit = fancyFormElementsEdit.fields.findIndex(
      field => field.fieldName === 'coreAssetType',
    );

    fancyFormElementsCreate.fields[coreAssetGroupFieldIndexCreate].useQuery =
      useGetCoreAssetTypesListQuery;
    fancyFormElementsCreate.fields[coreAssetGroupFieldIndexCreate].obAlias =
      'coreAssetType.name';
    fancyFormElementsCreate.fields[coreAssetGroupFieldIndexCreate].sbAlias =
      'coreAssetType.name';

    fancyFormElementsEdit.fields[coreAssetGroupFieldIndexEdit].useQuery =
      useGetCoreAssetTypesListQuery;
    fancyFormElementsEdit.fields[coreAssetGroupFieldIndexEdit].obAlias =
      'coreAssetType.name';

    fancyFormElementsEdit.fields[coreAssetGroupFieldIndexEdit].sbAlias =
      'coreAssetType.name';
  }, []);

  const { data: priorityData, isSuccess: idPrioritySuccess } =
    useGetPriorityListQuery({});

  const { data: assets } = useGetCoreAssetTypesListQuery({});

  const [
    doAddAssetType,
    {
      data: createAssetTypeData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useCreateAssetTypeMutation();

  const [
    doUpdateAssetType,
    {
      data: updateAssetTypeData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateAssetTypeMutation();

  useEffect(() => {
    if (idPrioritySuccess && priorityData) {
      populateDropDownFields({
        responseData: priorityData.data,
        createFormFields,
        editFormFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'priority.name',
        isPopulateForChipsComponent: true,
      });
    }
  }, [createFormFields, editFormFields, idPrioritySuccess, priorityData]);

  useEffect(() => {
    if (isEulPeriodSuccess && eulPeriodData) {
      const periodValues = eulPeriodData?.map(period => ({
        label: period.description,
        id: period.code,
      }));
      const eulGroupFieldCreate = fancyFormElementsCreate.fields.find(
        field => field.type === 'group',
      );
      if (eulGroupFieldCreate) {
        const periodFieldCreate = eulGroupFieldCreate.subFields.find(
          subField => subField.fieldName === EUL_TYPE_FIELDNAME,
        );
        periodFieldCreate.options = periodValues;
        periodFieldCreate.value = 'M';
      }

      const eulGroupFieldEdit = fancyFormElementsEdit.fields.find(
        field => field.type === 'group',
      );
      if (eulGroupFieldEdit) {
        const periodFieldEdit = eulGroupFieldEdit.subFields.find(
          subField => subField.fieldName === EUL_TYPE_FIELDNAME_EDIT,
        );
        periodFieldEdit.options = periodValues;
      }
    }
  }, [eulPeriodData, isEulPeriodSuccess, editFormFields, createFormFields]);

  const useCreateAssetType = useCallback(() => {
    const doCreate = async data => {
      const periodCode =
        eulPeriodData?.find(period => period.code === data.period)?.code ?? '';
      data.periodCode = periodCode;

      doAddAssetType(data);
    };

    return [
      doCreate,
      {
        data: createAssetTypeData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [
    eulPeriodData,
    createAssetTypeData,
    addError,
    isAddError,
    isAddLoading,
    isAddSuccess,
    resetAdd,
    doAddAssetType,
  ]);

  const useUpdateAssetType = useCallback(() => {
    const doUpdate = async data => {
      const periodCode = data.periodCode || data.period?.id;
      data.periodCode = periodCode;
      doUpdateAssetType(data);
    };

    return [
      doUpdate,
      {
        data: updateAssetTypeData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    eulPeriodData,
    updateAssetTypeData,
    updateError,
    isUpdateError,
    isUpdateLoading,
    isUpdateSuccess,
    resetUpdate,
    doUpdateAssetType,
  ]);

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/assetType'));
  }, [dispatch]);

  const handleChange = (output: ECEasyFormFieldType[], fieldName: any) => {
    if (fieldName !== 'refrigerantTracking') return;

    const refrigerantTrackingField = output.find(
      field => field.fieldName === 'refrigerantTracking',
    );
    const coreAssetGroupField = output.find(
      field => field.fieldName === 'coreAssetType',
    );

    if (coreAssetGroupField) {
      if (refrigerantTrackingField?.value) {
        coreAssetGroupField.value = (assets as any)?.data?.find(
          asset => asset.name === 'Refrigeration',
        );
        coreAssetGroupField.disabled = true;
      } else {
        coreAssetGroupField.disabled = false;
      }
    }
  };

  return (
    <ECDynamicPageTemplate
      useLazyGetListQuery={useLazyGetAssetTypesListQuery}
      showStatusActiveFilter={true}
      pageTitle={t('translation:pages.assetTypes.title')}
      useCreateMutation={useCreateAssetType}
      useUpdateMutation={useUpdateAssetType}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={fancyFormElementsCreate.fields}
      editFormConfig={fancyFormElementsEdit.config}
      editFormFields={fancyFormElementsEdit.fields}
      detailsConfig={fancyFormElementsDetail.config}
      detailsFields={fancyFormElementsDetail.fields}
      onChange={handleChange}
      downloadFileName="asset-type.csv"
      enableExport={true}
      enableTableCsvExport
      marginTop={false}
      shouldNotUseActiveFilter
    />
  );
}
