import React from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';

interface TextDisplayProps {
  justifyContent: 'flex-start' | 'center';
  visibility: 'visible' | 'hidden';
  formattedBodyText: string;
  bodyKey: string;
}

const CurrentDisplayingText: React.FC<TextDisplayProps> = ({
  justifyContent,
  visibility,
  formattedBodyText,
  bodyKey,
}) => (
  <ECBox
    display="flex"
    flex={1}
    width={0}
    justifyContent={justifyContent}
    visibility={visibility}
  >
    <ECTypography
      variant="subtitle2"
      maxWidth="100%"
      color={theme =>
        bodyKey?.toLowerCase().includes('removed')
          ? theme.palette.error.dark
          : theme.palette.success.dark
      }
      sx={{
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
      }}
    >
      {formattedBodyText}
    </ECTypography>
  </ECBox>
);

export default CurrentDisplayingText;
