import {
  useGetAssetByIdQuery,
  useGetAssetWarrantyQuery,
} from 'services/assetApi';
import {
  ECBox,
  ECButton,
  ECEasyFormCreate,
  ECWorkflowTemplate,
} from 'app/components';
import { useParams } from 'react-router';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { StyleConstants } from 'styles/StyleConstants';
import { useCreateProposalMutation } from 'services/proposalApi';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import { populateDropDownFields } from 'utils/pageUtils';
import { useGetProblemListByCompanyIdAndAssetTypeIdQuery } from 'services/problemApi';
import { useGetPrioritiesListForSPQuery } from 'services/prioritiesApi';
import { useGetCompletionTimeUnitQuery } from 'services/lookupApi';
import _ from 'lodash';

const formElementsDetails = require('./fancy_form_config_details.json');
const formElementsProposalManagedAsset = require('./fancy_form_config_proposal_managed_asset.json');

export function SPAssetDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const { data: asset, isSuccess: isSuccessAsset } = useGetAssetByIdQuery(
    id ? Number(id) : 0,
  );

  useEffect(() => {
    if (isSuccessAsset && asset) {
      if (!!asset?.requiredFields?.proposalDocumentRequired) {
        const attachmentField = formElementsProposalManagedAsset?.fields?.find(
          field => field.fieldName === 'files',
        );
        attachmentField.required = true;
      }
    }
  }, [asset, isSuccessAsset]);

  const isAsset = useMemo(
    () => _.isNil(asset?.generalAssetId),
    [asset?.generalAssetId],
  );

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const { data: attachments } = useGetAttachmentQuery({
    module: moduleName,
    moduleId: asset?.generalAssetId || asset?.id,
    category: AttachmentCategory.Photo,
  });

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(asset?.id || null);

  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleOpen = useCallback(() => setIsOpen(true), []);

  const costTableData = useMemo(
    (): CostTableData | undefined =>
      asset && {
        showIncurred: true,
        itemsRepair: [
          {
            label: 'Labor',
            dataPath: 'costLabor',
          },
          {
            label: 'Material',
            dataPath: 'costMaterial',
          },
          {
            label: 'Freight',
            dataPath: 'costFreight',
          },
          {
            label: 'Tax',
            dataPath: 'costTax',
          },
          {
            label: 'Other',
            dataPath: 'costOther',
          },
          {
            label: 'Incurred',
            dataPath: 'Incurred',
            hidden: true,
          },
        ],
      },
    [asset],
  );

  const { data: problems, isSuccess: isSuccessProblems } =
    useGetProblemListByCompanyIdAndAssetTypeIdQuery(
      {
        companyId: asset?.companyId ?? 0,
        assetTypeId: asset?.assetTypeId ?? 0,
      },
      {
        skip: !asset?.companyId || !asset?.assetTypeId,
      },
    );

  useEffect(() => {
    if (problems && isSuccessProblems) {
      populateDropDownFields({
        responseData: problems,
        createFormFields: formElementsProposalManagedAsset.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problem',
      });
    }
  }, [problems, isSuccessProblems]);

  const { data: priorities, isSuccess: isSuccessPriorities } =
    useGetPrioritiesListForSPQuery(
      {
        moduleName: 'asset',
        moduleId: asset?.id ?? 0,
      },
      {
        skip: !asset?.id,
      },
    );

  useEffect(() => {
    if (priorities && isSuccessPriorities) {
      populateDropDownFields({
        responseData: priorities,
        createFormFields: formElementsProposalManagedAsset.fields?.find(
          field => field.fieldName === 'group2',
        )?.subFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'priority',
      });
    }
  }, [priorities, isSuccessPriorities]);

  const { data: period, isSuccess: isSuccessPeriod } =
    useGetCompletionTimeUnitQuery();

  useEffect(() => {
    if (period && isSuccessPeriod) {
      populateDropDownFields({
        responseData: period,
        createFormFields: formElementsProposalManagedAsset.fields?.find(
          field => field.fieldName === 'group2',
        )?.subFields,
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: 'completionTimeUnit',
        defaultSelectedOption: 0,
      });
    }
  }, [period, isSuccessPeriod]);

  const existingData = useMemo(() => {
    return {
      ...asset,
      description: undefined,
    };
  }, [asset]);

  return (
    <>
      {asset && (
        <ECWorkflowTemplate
          tabsAsLinks
          title={asset.name}
          summaryData={[
            {
              id: 'asset',
              label: 'Asset',
              data: asset?.name,
              type: SummaryFieldTypes.Text,
            },
            {
              id: 'state',
              label: 'State',
              data: asset?.status,
              type: SummaryFieldTypes.State,
            },
            {
              id: 'assetId',
              label: 'Asset ID',
              data: asset?.id ?? '',
              type: SummaryFieldTypes.Text,
            },
            {
              id: 'assetGroup',
              label: 'Asset Group',
              data: asset?.assetType?.name,
              type: SummaryFieldTypes.Text,
            },
            {
              id: 'location',
              label: 'Location',
              data: asset?.branch?.name,
              type: SummaryFieldTypes.Text,
            },
            {
              id: 'area',
              label: 'Area',
              data: asset?.area?.name,
              type: SummaryFieldTypes.Text,
            },
            {
              id: 'priority',
              label: 'Priority',
              data: asset?.priority?.name,
              type: SummaryFieldTypes.Text,
            },
            {
              id: 'nte',
              label: 'NTE',
              data: asset?.nte,
              type: SummaryFieldTypes.Currency,
            },
            {
              id: 'warrantyNte',
              label: 'Warranty NTE',
              data: asset?.warrantyNte,
              type: SummaryFieldTypes.Currency,
            },
          ]}
          warrantyBadgeType={assetWarranty?.[0]?.status}
          editConfig={{}}
          editFields={{}}
          detailsConfig={formElementsDetails.data.config}
          detailsFields={formElementsDetails.data.fields}
          detailsData={asset}
          imgSrc={assetProfileAttachment?.url}
          profileAttachment={assetProfileAttachment}
          moduleName={asset?.moduleName ?? ''}
          moduleId={id}
          disableTabsOnEdit={true}
          isEditAllowed={false}
          disableSidebarOnEdit={true}
          disableAditionalActionWhenEditing={true}
          additionalActions={
            asset?.branch?.status === 1 && asset?.branch?.onHold !== 1
              ? [
                  <ECButton
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleOpen}
                  >
                    {t('translation:managedAsset.createProposal')}
                  </ECButton>,
                ]
              : []
          }
          /* additionalTabs={[
            {
              value: 'warranty',
              label: 'Warranty',
              scopes: [P.GetAllAssets],
              content: <Outlet />,
              link: 'warranty',
              editable: true,
            },
            {
              value: 'history',
              label: t('translation:pages.serviceHistory.title'),
              scopes: [P.GetAsset],
              content: <Outlet />,
              link: 'history',
              editable: false,
            },
          ]} */
          asset={{
            href: asset.id.toString(),
            text: asset.name,
          }}
          workOrder={
            asset.workorderId && asset.workorderName
              ? {
                  href: asset.workorderId.toString(),
                  text: asset.workorderName,
                }
              : undefined
          }
          showEditTitleStartAdornment
        />
      )}
      <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
        <ECBox display="flex" pt={StyleConstants.NAV_BAR_HEIGHT}>
          <ECEasyFormCreate
            useCreateMutation={useCreateProposalMutation}
            formConfig={formElementsProposalManagedAsset.config}
            formFields={formElementsProposalManagedAsset.fields}
            existingData={existingData}
            costTableData={costTableData}
            onClose={handleClose}
          />
        </ECBox>
      </ECDrawerDetails>
    </>
  );
}
