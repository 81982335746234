import { Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECMenuItem } from '../ECSelect';
import { ECTextField } from '../ECTextField';
import { ECBox } from '../ECBox';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECPopover } from '../ECPopover';
import { ArrowDropDown } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { ECPopper } from '../ECPopper';
import { ECIconButton } from '../ECIconButton';
import { limitStringMaxCharacters } from 'utils/strings/limit-string-max-characters';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';

export const ECAssetGroupSingleFilter = () => {
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  useEffect(() => {
    if (
      activeFilter?.assetTypes &&
      activeFilter.assetTypes[0] &&
      activeFilter.assetTypes[0].id !== selectedFilter?.id
    ) {
      setSelectedFilter(activeFilter.assetTypes[0]);
    }
  }, [activeFilter?.assetTypes]);

  const [anchorEl, setAnchorElAssetGroups] = useState<HTMLDivElement | null>(
    null,
  );
  const [options, setOptions] = useState<any>([]);

  const idPopover = useMemo(() => {
    return anchorEl ? 'popover' : undefined;
  }, [anchorEl]);

  const inputRef = useRef(null);

  const handleClickAssetGroup = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorElAssetGroups(inputRef?.current);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorElAssetGroups(null);
    if (activeFilter?.assetTypes?.length === 0) {
      dispatch(
        setActiveFilter({
          assetTypes: options?.map(v => ({
            id: v.id,
            name: v.fieldName || v.name,
          })),
          isSelectAllAssetTypes: false,
        }),
      );
    }
  }, [activeFilter?.assetTypes, options]);

  const { data: assetGroups, isFetching } = useGetAssetTypesListQuery({
    t: 0,
  });

  useEffect(() => {
    const assetGroupsData = assetGroups?.data;
    if (assetGroupsData && !isFetching) {
      setOptions(prev => {
        return _.uniqBy(
          [
            ...prev,
            ...(assetGroupsData.map(aGroup => ({
              id: aGroup?.id,
              label: aGroup?.name,
              fieldName: aGroup?.name,
            })) || []),
          ],
          'id',
        );
      });
      if (activeFilter?.assetTypes?.length === 0 || !activeFilter?.assetTypes) {
        dispatch(
          setActiveFilter({
            assetTypes: [
              {
                id: assetGroupsData?.[0]?.id,
                name: assetGroupsData?.[0]?.name,
              },
            ],
            isSelectAllAssetTypes: false,
          }),
        );
      }
    }
  }, [assetGroups, isFetching]);

  const handleChangeAssetGroup = useCallback(
    (_, value) => {
      if (value?.id === -1 || !value) {
        return;
      }
      dispatch(
        setActiveFilter({
          assetTypes: [
            {
              id: value?.id,
              name: value?.fieldName || value?.name,
            },
          ],
          isSelectAllAssetTypes: false,
        }),
      );
      setAnchorElAssetGroups(null);
    },
    [activeFilter?.assetTypes, options],
  );

  const renderPopper = useCallback(props => {
    return (
      <ECPopper
        {...props}
        sx={{
          transform: 'translate3d(8px, 53px, 0px) !important',
          minWidth: 'fit-content !important',
          mt: '8px !important',
          '& .MuiPopover-paper': {
            boxShadow: 'none',
          },
          '.MuiAutocomplete-listbox': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation1': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation': {
            boxShadow: 'none',
          },
        }}
      />
    );
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      {options && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
          >
            Asset Group:
          </ECTypography>
          <ECBox
            display="flex"
            alignItems="center"
            onClick={handleClickAssetGroup}
            ref={inputRef}
            sx={{
              color: theme => theme.palette.text.primary,
            }}
          >
            <ECTypography fontSize={16}>
              {limitStringMaxCharacters(selectedFilter?.name || '', 8)}
            </ECTypography>
            <ECIconButton
              type="default"
              size="small"
              noPadding
              sx={{ marginLeft: '5px' }}
            >
              <ArrowDropDown />
            </ECIconButton>
          </ECBox>
          <ECPopover
            id={idPopover}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              bgcolor: 'transparent',
              '& .MuiPopover-paper': {
                minWidth: '350px',
                bgcolor: 'transparent',
              },
            }}
          >
            <ECBox
              display="flex"
              bgcolor="white !important"
              height="470px"
              width="350px"
            >
              <ECAutocompleteStyled
                open
                disablePortal
                sx={{
                  width: '350px',
                  '&.MuiAutocomplete-popper': {
                    transform: 'translate3d(8px, 53px, 0px) !important',
                  },
                }}
                options={options}
                ListboxProps={{
                  sx: {
                    height: '400px',
                    bgcolor: 'white',
                    '.MuiAutocomplete-listbox': {
                      height: '400px',
                      bgcolor: 'white',
                    },
                  },
                }}
                onChange={handleChangeAssetGroup}
                renderInput={params => {
                  return (
                    <ECBox display="flex" padding={1} bgcolor="white">
                      <ECTextField
                        {...params}
                        placeholder="Search"
                        sx={{
                          minWidth: 'fit-content !important',
                          padding: '0px !important',
                          '.MuiFilledInput-root': {
                            paddingRight: '0px !important',
                          },
                        }}
                        hiddenLabel
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <></>,
                        }}
                      />
                    </ECBox>
                  );
                }}
                PopperComponent={renderPopper}
                renderOption={(props, option) => {
                  return (
                    <ECMenuItem
                      {...props}
                      data-name={option?.id === options?.[0]?.id ? 'first' : ''}
                      sx={{ width: '100%' }}
                      key={option?.id || option}
                      id={option?.fieldName || option}
                      disabled={option?.disabled}
                      aria-selected={option?.id === selectedFilter?.id}
                      // color='red !important'
                    >
                      {option?.label}
                    </ECMenuItem>
                  );
                }}
              />
            </ECBox>
          </ECPopover>
        </>
      )}
    </Stack>
  );
};
