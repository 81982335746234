import { useCallback, useEffect } from 'react';
import { ECEasyFormCreate } from 'app/components';
import { setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useCreateRequestForPricingMutation } from 'services/requestForPricingApi';

interface Step3Props {
  existingData?: any;
  onClose?: () => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step3_create.json');

export const Step3 = ({ existingData, onClose }: Step3Props) => {
  const dispatch = useDispatch();

  const [doCreateRFP, { data, isError, error, isSuccess, reset, isLoading }] =
    useCreateRequestForPricingMutation();

  const useCreateaMutation = useCallback(() => {
    const create = (RFP: any) => {
      const body = {
        branchId: existingData.branch.id,
        assetId: existingData.asset.id,
        problemTypeId: existingData.problemId.id,
        priorityId: existingData.priority.id,
        categoryTypeId: existingData.category.id,
        requestedBy: RFP.requestedBy,
        description: RFP.description,
        name: RFP.title,
        spCompanyIds: RFP.serviceProvider,
        files: RFP.files,
        isGeneralAsset: existingData.asset.isGeneralAsset ? 1 : 0,
        generalAssetId: existingData.asset.isGeneralAsset ? existingData.asset.id : 0,
      };
      doCreateRFP(body);
    };

    return [
      create,
      {
        data,
        isError,
        error,
        isSuccess,
        reset,
        isLoading,
      },
    ];
  }, [
    data,
    isError,
    error,
    isSuccess,
    reset,
    isLoading,
    existingData,
    doCreateRFP,
  ]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setSnackbar({
          message: 'Request for Pricing was successfully created',
          severity: 'success',
        }),
      );
    }
  }, [isSuccess, dispatch, onClose]);

  return (
    <ECEasyFormCreate
      useCreateMutation={useCreateaMutation}
      onClose={onClose}
      formConfig={fancyFormElementsCreate.config}
      formFields={fancyFormElementsCreate.fields}
      existingData={existingData}
      isLoading={isLoading}
      showSuccessSnackBar={false}
    />
  );
};
