import { ECDynamicPageTemplate } from 'app/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  useAddRepairTypeMutation,
  useEditRepairTypeMutation,
  useLazyGetRepairsListQuery,
} from 'services/repairApi';
import { setFilterEndpoint } from 'store/slice/page';
import { loadPageScopes } from 'utils/pageScopes';

const tableConfig = require('./tableConfig.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const searchFormElements = require('./search_form_config.json');

const detailsForm = require('./details_form.json');

export function Failure() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('failure');
  }, []);

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/failure'));
  }, [dispatch]);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.failures.title')}
      useLazyGetListQuery={useLazyGetRepairsListQuery}
      showStatusActiveFilter={true}
      useCreateMutation={useAddRepairTypeMutation}
      useUpdateMutation={useEditRepairTypeMutation}
      tableConfig={tableConfig.data.config}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      detailsFields={detailsForm.fields}
      detailsConfig={detailsForm.config}
      searchFormConfig={searchFormElements.config}
      searchFormFields={searchFormElements.fields}
      downloadFileName="failures.csv"
      exportModuleName="failure"
      enableExport
      marginTop={false}
      shouldNotUseActiveFilter
    />
  );
}
