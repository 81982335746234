import { useCallback, useLayoutEffect, useState } from 'react';
import {
  ECEasyFormCreate,
  ECEasyFormFieldType,
  FieldTypes,
} from 'app/components';
import { useGetInventoryStorageLocationsListForDropdownQuery } from 'services/inventoryApi';
import { useGetUsersListForDropdownQuery } from 'services/userApi';
import { compact, get } from 'lodash';

interface Step2Props {
  existingData?: any;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step2_create.json');

export const Step2 = ({ existingData, onSubmit, onClose }: Step2Props) => {
  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (field.type === FieldTypes.Group) {
        return {
          ...field,
          subFields: field.subFields?.map(handleExistingData),
        };
      }

      const value = get(existingData, field.fieldName, field.value);
      return {
        ...field,
        value,
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields?.map(handleExistingData),
  );

  useLayoutEffect(() => {
    const storageLocationField = formFields.find(
      field => field.fieldName === 'storageLocation',
    );
    storageLocationField.useQuery =
      useGetInventoryStorageLocationsListForDropdownQuery;
    storageLocationField.queryParams = {
      sb: 'invntrystrg.name',
      ob: 'invntrystrg.name',
      o: 'a',
    };

    const assignedToField = formFields.find(
      field => field.fieldName === 'assignedTo',
    );
    assignedToField.useQuery = useGetUsersListForDropdownQuery;
    assignedToField.queryParams = {
      sb: 'usr.firstName',
      ob: 'usr.firstName',
      o: 'a',
    };
    assignedToField.extraSearchFieldsAlias = ['usr.lastName'];

    const isSameAsAssignedUserCheckboxChecked = compact(
      formFields?.find(f => f.fieldName === 'sameAsAssignedUser')?.value,
    )?.length;

    const shipDetailsGroup = formFields?.find(
      f => f.groupName === 'shipDetails',
    );
    shipDetailsGroup?.subFields?.forEach(field => {
      field.disabled = isSameAsAssignedUserCheckboxChecked;
      field.required = !isSameAsAssignedUserCheckboxChecked;
    });

    const shipToUserEmailField = formFields?.find(
      f => f.fieldName === 'shipToUserEmail',
    );
    shipToUserEmailField.disabled = isSameAsAssignedUserCheckboxChecked;
    shipToUserEmailField.required = !isSameAsAssignedUserCheckboxChecked;

    setFormFields([...formFields]);
  }, [existingData]);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      onSubmit?.({
        ...existingData,
        ...data,
      });
    };

    return [doSubmit, {}];
  }, [onSubmit]);

  const handleChange = useCallback((output, fieldName) => {
    const isSameAsAssignedUserCheckboxChecked = compact(
      output?.find(f => f.fieldName === 'sameAsAssignedUser')?.value,
    )?.length;

    if (fieldName === 'sameAsAssignedUser') {
      const shipDetailsGroup = output?.find(f => f.groupName === 'shipDetails');
      shipDetailsGroup?.subFields?.forEach(field => {
        field.disabled = isSameAsAssignedUserCheckboxChecked;
        field.required = !isSameAsAssignedUserCheckboxChecked;
      });

      const shipToUserEmailField = output?.find(
        f => f.fieldName === 'shipToUserEmail',
      );
      shipToUserEmailField.disabled = isSameAsAssignedUserCheckboxChecked;
      shipToUserEmailField.required = !isSameAsAssignedUserCheckboxChecked;
    }

    if (isSameAsAssignedUserCheckboxChecked) {
      const assignedToField = output?.find(f => f.fieldName === 'assignedTo');
      const shipDetailsGroup = output?.find(f => f.groupName === 'shipDetails');

      shipDetailsGroup?.subFields?.forEach(field => {
        if (field.fieldName === 'shipToUser') {
          field.value = `${assignedToField?.value?.firstName ? assignedToField?.value?.firstName : ''}${assignedToField?.value?.lastName ? ` ${assignedToField?.value?.lastName}` : ''}`;
        }

        if (field.fieldName === 'shipToUserPhone') {
          field.value = assignedToField?.value?.phone;
        }
      });

      const shipToUserEmailField = output?.find(
        f => f.fieldName === 'shipToUserEmail',
      );
      shipToUserEmailField.value = assignedToField?.value?.email;
    }

    setFormFields([...output]);
  }, []);

  return (
    <ECEasyFormCreate
      useCreateMutation={submit}
      onClose={onClose}
      formConfig={fancyFormElementsCreate.config}
      formFields={formFields}
      isLoading={false}
      onChange={handleChange}
      hideToolBar
      showTitle={false}
      showSuccessSnackBar={false}
    />
  );
};
