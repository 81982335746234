import {
  ECButton,
  ECEasyForm,
  ECEasyFormFieldType,
  ECTypography,
  FieldTypes,
} from 'app/components';
import { ECModal } from 'app/components/ECModal';
import { t } from 'i18next';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCopyAssetsToLocationMutation } from 'services/assetApi';
import { useGetBranchesListQuery } from 'services/branchApi';
import { setSnackbar } from 'store/slice/page';
type CopyAssetsProps = {
  isCopyAssetsModalOpen: boolean;
  handleCloseCopyAssets: () => void;
  branchId: string | undefined;
};
export function CopyAssets({
  isCopyAssetsModalOpen,
  handleCloseCopyAssets,
  branchId,
}: CopyAssetsProps) {
  const dispatch = useDispatch();
  const [
    doCopyAssetToLocation,
    {
      isLoading: isLoadingCopyAssetsToLocation,
      isSuccess: isCopyAssetsToLocationSuccess,
    },
  ] = useCopyAssetsToLocationMutation();

  const handleSubmitCopyAssets = useCallback(
    (data: FormData, fields: ECEasyFormFieldType[]) => {
      const selectedLocation = fields.find(
        field => field.fieldName === 'location',
      )?.value;

      doCopyAssetToLocation({
        fromBranchId: Number(branchId),
        toBranchId: selectedLocation?.id,
      });
    },
    [branchId],
  );

  useEffect(() => {
    if (!isLoadingCopyAssetsToLocation && isCopyAssetsToLocationSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `Assets for Location ${t(
            'translation:dynamicForm.updateSuccess',
          )}`,
        }),
      );
      handleCloseCopyAssets();
    }
  }, [isLoadingCopyAssetsToLocation, isCopyAssetsToLocationSuccess]);

  return (
    <ECModal
      isOpen={isCopyAssetsModalOpen}
      onClose={handleCloseCopyAssets}
      p={0}
      sx={{ minWidth: '50vw', overflow: 'visible' }}
    >
      <ECEasyForm
        config={{
          cols: 1,
          submitTitle: 'Copy',
          title: 'Copy Location Assets',
          titleStyle: {
            fontSize: '20px',
          },
          actionButtonsContainerStyle: {
            bgcolor: 'transparent',
          },
        }}
        fields={[
          {
            type: FieldTypes.Custom,
            fieldName: 'label',
            value: (
              <ECTypography variant="body2">
                Select a Location to Copy Assets to
              </ECTypography>
            ),
          },
          {
            fieldName: 'location',
            type: FieldTypes.SelectPaginated,
            label: 'Location',
            useQuery: useGetBranchesListQuery,
            filterOptionsIds: branchId ? [branchId] : [],
            queryParams: { st: 1 },
            obAlias: 'brnch.name',
            placeholder: 'Location',
            variant: 'filled',
            required: true,
          },
        ]}
        isSendingData={isLoadingCopyAssetsToLocation}
        isLoadingForm={false}
        onFormSubmit={handleSubmitCopyAssets}
        additionalActions={
          <ECButton
            type="button"
            variant="text"
            sx={{ height: 42 }}
            onClick={handleCloseCopyAssets}
          >
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
        drawerTitleBarBGColor="transparent"
      />
    </ECModal>
  );
}
