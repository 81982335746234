import { CSSProperties, ChangeEvent, useState, useEffect } from 'react';
import { phonePattern, formatPhoneNumber, validatePhoneNumber } from 'utils/strings/phone';
import { ECTextField } from '../ECTextField';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface Props {
  onFocus?: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onChange?: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  id: string;
  name: string;
  value?: string;
  variant?: 'standard' | 'filled';
  disabled?: boolean;
  label?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  minimumValue?: number | string;
  error?: boolean;
  sx?: any;
  validationMessage?: string;
  maxLength?: number;
  maxValue?: number;
  hiddenLabel?: boolean;
  defaultValue?: number | string;
}

export const ECPhoneField = ({ onChange, onBlur, ...props }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    variant = 'standard',
    error,
    value,
    style,
    sx,
    validationMessage,
    readOnly,
    hiddenLabel = false,

    //I'm adding those below to remove all props being passed
    // it throws console errors when props are passed to the DOM
    onFocus,
    name,
    disabled,
    label,
    minimumValue,
    maxLength,
    maxValue,
    defaultValue,
  } = props;

  useEffect(() => {
    const formattedInput = formatPhoneNumber(value || '');
    setPhoneNumber(formattedInput);
  }, [value]);

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/\D/g, '');
    const formattedInput = formatPhoneNumber(input);

    if (input.length > phonePattern.length) {
      return;
    }
    onChange?.(event);
    const isValid = validatePhoneNumber(input);
    setPhoneNumber(formattedInput);
    setIsValid(isValid);
    setIsFocused(event.target.value.length > 0);
  };

  const handlePhoneBlur = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (
      phoneNumber?.length === 0 ||
      phoneNumber?.length < phonePattern.length
    ) {
      setIsFocused(false);
      setPhoneNumber('');
    }
  };

  return (
    <ECTextField
      name={name}
      disabled={disabled}
      label={label}
      defaultValue={defaultValue}
      value={phoneNumber}
      placeholder={phonePattern}
      helperText={
        validationMessage
          ? validationMessage
          : !isValid &&
              phoneNumber.length > 0 &&
              phoneNumber.length !== phonePattern.length
            ? 'Must be in ' + phonePattern + ' format!'
            : null
      }
      variant={variant}
      error={
        (!isValid &&
          !!phoneNumber?.length &&
          phoneNumber.length !== phonePattern.length) ||
        !!validationMessage
      }
      InputProps={{
        endAdornment: (
          <>
            {isFocused ? (
              isValid ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <ErrorOutlineIcon color="error" />
              )
            ) : null}
          </>
        ),
        readOnly,
      }}
      sx={{
        ...sx,
        ...style,
        cursor: readOnly ? 'pointer !important' : 'auto',
      }}
      InputLabelProps={value ? { shrink: true } : {}}
      hiddenLabel={hiddenLabel}
      onFocus={() => {
        if (!readOnly) {
          setIsFocused(true);
          setIsValid(phoneNumber.length === phonePattern.length);
        }
      }}
      onChange={handlePhoneChange}
      onBlur={handlePhoneBlur}
    />
  );
};
