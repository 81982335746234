import {
  ECBox,
  ECButton,
  ECGrid,
  ECTypography,
  ECTextField,
} from 'app/components';
import { Helmet } from 'react-helmet-async';
import Logo from '../../../assets/techtrak-logo.svg';
import { Help } from '@mui/icons-material';
import { Link, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyValidateInvitationCodeQuery } from 'services/spAuthApi';
import backgroundImage from '../../../assets/sp-signup-bg-image.jpeg';

export function CreateSPPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [code, setCode] = useState(searchParams.get('code') || '');
  const [errorMessage, setErrorMessage] = useState('');

  const [validateCode, { data, isSuccess, isError, error, isLoading }] =
    useLazyValidateInvitationCodeQuery();

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      setErrorMessage('');
      navigate('/sp-self-signup/register', {
        state: { code, ...data },
      });
    }
  }, [isSuccess, isLoading, data]);

  useEffect(() => {
    if (!isLoading && isError) {
      setErrorMessage((error as any)?.data?.message);
    }
  }, [isError, isLoading]);

  const validateInvitationCode = (code: string) => {
    if (_.isEmpty(code)) {
      setErrorMessage('Required');
      return;
    }

    if (code?.length < 8) {
      setErrorMessage('Invalid code');
      return;
    }

    validateCode(code);
  };

  const handleCreateCompanyClick = () => {
    validateInvitationCode(code);
  };

  const isMobileSize = useMediaQuery('(max-width: 743px)');

  return (
    <>
      <Helmet>
        <title>Sign In Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECGrid
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
        sx={{
          flexDirection: {
            mobile: 'column',
            laptop: 'row',
          },
        }}
      >
        <ECGrid
          item
          mobile={12}
          tablet={12}
          laptop={7}
          sx={{
            width: {
              mobile: '100%',
              laptop: 'auto',
            },
          }}
        >
          <ECBox
            px={10}
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: {
                mobile: 'top',
                laptop: 'center',
              },
              boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.6)', // overlay to cover background image
              height: {
                mobile: '224px',
                tablet: '400px',
                laptop: '100vh',
              },
              alignItems: {
                mobile: 'center',
                laptop: 'flex-start',
              },
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <ECTypography
              variant={isMobileSize ? 'h4' : 'h3'}
              color={theme => theme.palette.common.white}
              sx={{
                marginBottom: {
                  mobile: 2,
                  laptop: 0,
                },
              }}
            >
              Welcome to
            </ECTypography>

            <img
              src={Logo}
              alt="logo"
              style={{
                height: isMobileSize ? '53px' : '88px',
                width: isMobileSize ? '238px' : '402px',
                zIndex: 1,
              }}
            />
          </ECBox>
        </ECGrid>

        <ECGrid
          item
          mobile={12}
          tablet={12}
          laptop={5}
          sx={{
            width: {
              mobile: '100%',
              laptop: 'auto',
            },
            paddingTop: {
              mobile: 10,
              tablet: 5,
              laptop: 0,
            },
          }}
        >
          <ECBox
            px={10}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{
              justifyContent: {
                mobile: 'flex-start',
                laptop: 'center',
              },
              marginTop: {
                mobile: 2,
                tablet: 5,
                laptop: 0,
              },
              maxWidth: {
                mobile: '488px',
                laptop: '584px',
              },
              gap: {
                mobile: 1,
                laptop: 4,
              },
              px: {
                mobile: 2,
                laptop: 10,
              },
            }}
            marginLeft="auto"
            marginRight="auto"
            height="100vh"
          >
            <ECTypography
              variant={isMobileSize ? 'h5' : 'h4'}
              sx={{
                mb: {
                  mobile: '2px',
                  laptop: 0,
                },
              }}
            >
              Add Invitation Code
            </ECTypography>

            <ECTextField
              variant="filled"
              placeholder="xxx-xxxx"
              label="Invitation Code"
              required
              mask="###-####"
              error={!!errorMessage}
              helperText={errorMessage}
              value={code}
              onChange={e => setCode(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleCreateCompanyClick();
                }
              }}
            />

            <ECButton
              variant="contained"
              disabled={!code}
              onClick={handleCreateCompanyClick}
              isLoading={isLoading}
              sx={{
                mt: {
                  mobile: 2,
                  laptop: 0,
                },
              }}
            >
              Create Company
            </ECButton>

            <ECTypography
              sx={{
                mt: {
                  mobile: 2,
                  laptop: 0,
                },
              }}
            >
              <Help /> Need help with Ecotrak?{' '}
              <Link href="https://ecotrak.zendesk.com/hc/en-us/categories/8295898460820-Service-Provider-Getting-Started">
                Click here
              </Link>
            </ECTypography>
          </ECBox>
        </ECGrid>
      </ECGrid>
    </>
  );
}
