import {
  useClearCacheByUserIdMutation,
  useClearCacheByCompanyIdMutation,
  useLazyGetUsersAdminListQuery,
  useGetAllCompaniesQuery,
} from 'services/userApi';
import {
  ECBox,
  ECDynamicPageTemplate,
  ECEasyForm,
  FieldTypes,
  ECImage,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { ECButton } from 'app/components';
import { Login, LockReset } from '@mui/icons-material';
import { storeOverrideUser } from 'services/utils';
import { useNavigate } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { ECModal } from 'app/components/ECModal';
import { useUpdatePasswordMutation } from 'services/authApi';
import { useDispatch } from 'react-redux';
import PasswordChecklist from 'react-password-checklist';
import { setSnackbar, setFilterEndpoint } from 'store/slice/page';
import { CompanyTypeId } from 'types/Company';
import { profileApi } from 'services/profileApi';
import { userProfileApi } from 'services/userProfileApi';
import ChecklistError from '../../../assets/password-checklist-error.svg';
import ChecklistSuccess from '../../../assets/password-checklist-success.svg';
import { persistedDashboardKey } from '../DashboardPage/DashboardHooks';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');

export function ImpersonatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpenUpdatePasswordModal, setIsOpenUpdatePasswordModal] =
    useState(false);

  const [passwordFirst, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isValidChecklistPassword, setChecklistPassword] = useState(false);

  const [clearCache, clearCacheResult] = useClearCacheByUserIdMutation();
  const {
    isError: isErrorClearCache,
    isSuccess: isSuccessClearCache,
    isLoading: isLoadingClearCache,
  } = clearCacheResult;

  useEffect(() => {
    if (isSuccessClearCache && !isLoadingClearCache) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Cache cleared!',
        }),
      );
    }
  }, [isSuccessClearCache, isLoadingClearCache]);

  useEffect(() => {
    if (isErrorClearCache && !isLoadingClearCache) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'It was not possible to clear cache for this user',
        }),
      );
    }
  }, [isErrorClearCache, isLoadingClearCache]);

  const [row, setRow] = useState<any>(null);

  useEffect(() => {
    loadPageScopes('user');
  }, []);

  const impersonateUser = async (row: any) => {
    storeOverrideUser(row.userName);
    localStorage.removeItem('userProfile');
    localStorage.removeItem('userProfileFromCache');
    localStorage.removeItem('userProfileLastCallTimestamp');
    localStorage.removeItem('companyProfile');
    localStorage.removeItem('companyProfileFromCache');
    localStorage.removeItem('companyProfileLastCallTimestamp');
    window.location.href = '/home';
  };

  const [resetPassword, { data, isError, error, isLoading, isSuccess }] =
    useUpdatePasswordMutation();

  const closeUpdatePasswordModal = useCallback(() => {
    setPassword('');
    setPasswordAgain('');
    setChecklistPassword(false);
    setIsOpenUpdatePasswordModal(false);
  }, []);

  const openUpdatePasswordModal = useCallback(() => {
    setIsOpenUpdatePasswordModal(true);
  }, []);

  const handleClickUpdatePasswordButton = row => {
    openUpdatePasswordModal();
    setRow(row);
  };

  const handleClickClearCache = row => {
    clearCache(row?.id);
  };

  const ImpersonateUserActionButton = (row: any) => (
    <ECBox display="flex" flexWrap="wrap" gap="10px" py={1}>
      <ECButton
        variant="contained"
        color="primary"
        startIcon={<Login />}
        onClick={() => {
          impersonateUser(row);
        }}
        key={`${row.id}-impersonate`}
      >
        {'LOG IN AS'}
      </ECButton>
      <ECButton
        variant="contained"
        color="primary"
        startIcon={<LockReset />}
        onClick={() => handleClickUpdatePasswordButton(row)}
        key={`${row.id}-update-password`}
      >
        {'UPDATE PASSWORD'}
      </ECButton>
      <ECButton
        variant="contained"
        color="primary"
        onClick={() => handleClickClearCache(row)}
        key={`${row.id}-clear-cache`}
      >
        CLEAR CACHE
      </ECButton>
    </ECBox>
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `Password Updated!`,
        }),
      );
      closeUpdatePasswordModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const errorObject = error as any;
      const errorMessage =
        errorObject?.data?.message === 'User does not exist.'
          ? 'User doesn’t exist in Cognito, make sure to select a valid e-mail.'
          : errorObject?.data?.message || errorObject?.data?.message;
      dispatch(
        setSnackbar({
          severity: 'error',
          message: errorMessage || `Error updating password`,
        }),
      );
    }
  }, [isError]);

  const onChangeSetValues = e => {
    setPassword(e[0]?.value);
    setPasswordAgain(e[1]?.value);
  };

  useEffect(() => {
    const companyField = fancyFormElementsCreate.data.fields.find(
      field => field.fieldName === 'company',
    );

    companyField.useQuery = useGetAllCompaniesQuery;
  }, []);

  function checkIfTrailingSpace(str: string) {
    return str?.startsWith(' ') || str?.endsWith(' ');
  }

  const hasTrailingSpacePassword = checkIfTrailingSpace(passwordFirst);

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={'Impersonate'}
        useLazyGetListQuery={useLazyGetUsersAdminListQuery}
        showStatusActiveFilter={true}
        statusFilterInitialSelected
        useCreateMutation={useClearCacheByCompanyIdMutation}
        createFormConfig={fancyFormElementsCreate.data.config}
        createFormFields={fancyFormElementsCreate.data.fields}
        renderCustomActionComponent={ImpersonateUserActionButton}
        marginTop={true}
        withDrawer={false}
        shouldNotUseActiveFilter
      />

      <ECModal
        isOpen={isOpenUpdatePasswordModal}
        onClose={closeUpdatePasswordModal}
        noPadding
      >
        <ECBox
          display="flex"
          flexDirection="column"
          sx={{
            width: '500px',
          }}
          p={1}
        >
          <ECEasyForm
            pattern="filled"
            fullHeight={false}
            config={{
              variant: 'filled',
              title: `Update Password`,
              subtitle: row?.email,
              cols: 1,
              submitTitle: 'Update',
            }}
            fields={[
              {
                type: FieldTypes.Password,
                label: 'New Password',
                placeholder: 'New password',
                value: passwordFirst,
                isNumeric: false,
                required: true,
                fieldName: 'passwordFirst',
              },
              {
                type: FieldTypes.Password,
                label: 'Re-enter Password',
                placeholder: 'Re-enter Password',
                value: passwordAgain,
                isNumeric: false,
                required: true,
                fieldName: 'passwordAgain',
              },
            ]}
            onChange={e => {
              onChangeSetValues(e);
            }}
            isSendingData={isLoading}
            isLoadingForm={false}
            onFormSubmit={formData => {
              const passwordFirst = formData.get('passwordFirst');
              if (isValidChecklistPassword && !hasTrailingSpacePassword) {
                resetPassword({
                  username: row?.email,
                  password: passwordFirst,
                });
              }
            }}
          />
          <PasswordChecklist
            rules={[
              'number',
              'specialChar',
              'capital',
              'lowercase',
              'minLength',
              'match',
            ]}
            minLength={8}
            value={passwordFirst}
            valueAgain={passwordAgain}
            onChange={isValid => {
              setChecklistPassword(isValid);
            }}
            style={{ fontSize: 16, columns: 2 }}
          />
          <ECBox className={``} sx={{ maxWidth: '90%', fontSize: '16px' }}>
            {hasTrailingSpacePassword ? (
              <ECImage src={ChecklistError} alt="Checklist Error" />
            ) : (
              <ECImage src={ChecklistSuccess} alt="Checklist Success" />
            )}
            <span
              style={{
                opacity: !hasTrailingSpacePassword ? '1' : '0.5',
                marginLeft: '5px',
              }}
            >
              No Trailing or Leading Spaces
            </span>
          </ECBox>
        </ECBox>
      </ECModal>
    </>
  );
}
