import { QueryParams } from 'types/QueryParams';
import {
  Branch,
  BranchDetailFlat,
  BranchTypeLookup,
  BranchTypeDetails,
  BranchUpdateMutate,
} from 'types/Branch';
import { flatToNested } from 'utils/common';
import { BaseType } from 'types/BaseType';
import { AssetFlat, AssetQueryParams } from 'types/Asset';
import { CompanyBranchesForSpQueryParams } from 'types/Branch';

import { emptyApi } from './emptyApi';
import { Lookup } from 'types/Lookup';
import _ from 'lodash';
import { convertJsonToFormData } from 'utils/form';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'Branch',
    'BranchType',
    'AssetsByBranchIdAndAssetGroupId',
    'BranchSP',
    'BranchBySpAndTrade',
    'BranchesByCustomerId',
    'BranchById',
  ],
});

// Define a service using a base URL and expected endpoints
export const branchApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getBranchesList: builder.query<BaseType<Branch[]>, QueryParams>({
      query: params => {
        const { o, ob, ...restParams } = params;
        const defaultParams = {
          ...restParams,
          o: o !== undefined && o !== '' ? o : 'a',
          ob: ob !== undefined && ob !== '' ? ob : 'brnch.id',
        };

        if (
          params?.regionParentIds?.length ||
          params?.districtParentIds?.length
        ) {
          defaultParams['regionParentIds'] = params?.regionParentIds?.join(',');
          defaultParams['districtParentIds'] =
            params?.districtParentIds?.join(',');
        }

        return {
          url: 'branch',
          params: defaultParams,
        };
      },
      transformResponse: (response: BaseType<Branch[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'branch',
          branchType: { name: row.branchType?.name, id: row.branchTypeId },
          organization: {
            ...row.organization,
            label: row.organization.parent.name,
          },
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['Branch'],
    }),
    getBranchesByGeneralAssetIdList: builder.query<BaseType<Branch[]>, number>({
      query: generalAssetId => {
        return {
          url: `branch/general-asset/${generalAssetId}/all`,
        };
      },
      providesTags: ['Branch'],
    }),
    getServiceRequestBranchesList: builder.query<
      BaseType<Branch[]>,
      number | QueryParams
    >({
      query: params => {
        if (typeof params === 'number') {
          return {
            url: `branch/general-asset/${params}/all`,
            params: { withoutOnHold: 1 },
          };
        }
        return {
          url: 'branch',
          params,
        };
      },
      transformResponse: (response: any) => {
        return response.data ? response : { data: response };
      },
      providesTags: ['Branch'],
    }),
    createBranch: builder.mutation<void, any>({
      query: body => {
        body.branchTypeId = body.branchType?.id;
        body.organizationId = body.organization?.id;
        body.mailingEqualBilling = body.isBillingSameAsAddress?.includes(
          'Use same for Billing Address',
        )
          ? 1
          : 0;
        const nestedBody = flatToNested(body);
        const {
          organizationId,
          mailingAddress,
          name,
          branchTypeId,
          storeNumber,
          openDate,
          phone,
          phoneExt,
          averageWeeklySales,
          industryId = 1,
          costCenter,
          copyTradeAssignmentLocationId,
          legalEntityName,
          billingAddress,
          leaseLandlordAddress,
          leaseLandlordName,
          leaseContactName,
          leaseContactPhone,
          leaseContactPhoneExt,
          leaseContactEmail,
          leaseEngineerName,
          leaseEngineerPhone,
          leaseEngineerPhoneExt,
          leaseEngineerEmail,
          leaseStartDate,
          leaseEndDate,
          leaseOption1PeriodCode,
          leaseOption1Value,
          leaseOption2PeriodCode,
          leaseOption2Value,
          responsibilities,
          mailingEqualBilling,
          status,
        } = nestedBody as BranchUpdateMutate;
        const newBranch: BranchUpdateMutate = {
          organizationId,
          mailingAddress: {
            ...mailingAddress,
            countryCode:
              (mailingAddress?.countryCode as any)?.value ||
              mailingAddress?.countryCode,
            zipCodePostalCode: mailingAddress?.zipCodePostalCode || null,
          } as any,
          billingAddress: {
            ...billingAddress,
            countryCode:
              (billingAddress?.countryCode as any)?.value ||
              billingAddress?.countryCode,
            zipCodePostalCode: billingAddress?.zipCodePostalCode || null,
          } as any,
          name,
          branchTypeId,
          storeNumber,
          openDate,
          phone,
          phoneExt,
          averageWeeklySales,
          industryId,
          costCenter,
          copyTradeAssignmentLocationId:
            (copyTradeAssignmentLocationId as any)?.id || null,
          legalEntityName,
          leaseLandlordAddress,
          leaseLandlordName,
          leaseContactName,
          leaseContactPhone,
          leaseContactPhoneExt,
          leaseContactEmail,
          leaseEngineerName,
          leaseEngineerPhone,
          leaseEngineerPhoneExt,
          leaseEngineerEmail,
          leaseStartDate,
          leaseEndDate,
          leaseOption1PeriodCode: (leaseOption1PeriodCode as any)?.id || null,
          leaseOption1Value,
          leaseOption2PeriodCode: (leaseOption2PeriodCode as any)?.id || null,
          leaseOption2Value,
          responsibilities,
          mailingEqualBilling,
          status,
        };
        if (
          newBranch.leaseLandlordAddress?.line1?.trim() === '' ||
          _.isNil(newBranch.leaseLandlordAddress?.line1)
        ) {
          delete newBranch.leaseLandlordAddress;
        }
        if (
          newBranch.billingAddress?.line1?.trim() === '' ||
          _.isNil(newBranch.billingAddress?.line1)
        ) {
          delete newBranch.billingAddress;
        }

        const formData = convertJsonToFormData(newBranch);

        body.files?.forEach(file => {
          formData.append('files', file.rawFile);
        });

        return {
          url: 'branch/withAttachment',
          method: 'post',
          body: formData,
        };
      },
      invalidatesTags: ['Branch'],
    }),
    updateBranch: builder.mutation<void, any>({
      query: body => {
        return {
          url: `branch/${body.id}`,
          method: 'put',
          body: body,
        };
      },
      invalidatesTags: ['BranchById'],
    }),
    deleteBranch: builder.mutation({
      query: id => {
        return {
          url: `branch/${id}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['Branch'],
    }),
    getBranchById: builder.query<BranchDetailFlat, Pick<Branch, 'id'>>({
      query: row => {
        const { id } = row;
        const numberId: number = Number(id);
        return {
          url: `branch/${numberId}`,
        };
      },
      transformResponse: (response: any) => {
        return {
          ...response,
          moduleName: 'branch',
          org: {
            id: response.organizationId,
            name: response.organization?.parent?.name,
          },
          mailingEqualBilling: response.mailingEqualBilling
            ? ['Use same for Billing Address']
            : null,
        };
      },
      keepUnusedDataFor: 180,
      providesTags: ['BranchById'],
    }),
    getUsersByBranchId: builder.query<Lookup[], number>({
      query: id => {
        return {
          url: `branch/${id}/users`,
        };
      },
      transformResponse: (response: any) => {
        return response?.data?.map(d => ({
          id: d.id,
          name: `${d.firstName} ${d.lastName}`,
        }));
      },
    }),
    // Branch Types (Concepts) (BranchCategories on backend)
    getBranchTypes: builder.query<BaseType<BranchTypeLookup[]>, QueryParams>({
      query: params => {
        return {
          url: 'branch/category',
          params,
        };
      },
      providesTags: ['BranchType'],
    }),
    // TODO: finish CRUD for concepts
    getBranchTypeById: builder.query<BranchTypeDetails, { id: number }>({
      query: params => ({ url: `branch/category/${params.id}` }),
    }),
    createBranchType: builder.mutation<void, any>({
      query: body => ({ url: 'branch/category', method: 'post', body }),
      invalidatesTags: ['BranchType'],
    }),
    updateBranchType: builder.mutation<void, any>({
      query: body => ({
        url: `branch/category/${body.id}`,
        method: 'put',
        body,
      }),
      invalidatesTags: ['BranchType'],
    }),

    getAssetsByBranchIdAndAssetGroupId: builder.query<
      BaseType<AssetFlat[]>,
      AssetQueryParams
    >({
      query: ({ id, ...params }) => {
        return {
          url: `branch/${id}/assets`,
          params,
        };
      },
      providesTags: ['AssetsByBranchIdAndAssetGroupId'],
    }),

    getCustomersBranchListForSp: builder.query<BaseType<Branch[]>, QueryParams>(
      {
        query: params => {
          return {
            url: 'branch/sp',
            params,
          };
        },
        providesTags: ['BranchSP'],
      },
    ),

    getCustomersBranchListByGeneralAssetForSp: builder.query<
      Branch[],
      { customerId: number; generalAssetId: number; withoutOnHold?: number }
    >({
      query: ({ customerId, generalAssetId, withoutOnHold }) => {
        return {
          url: `branch/sp/customer/${customerId}/general-asset/${generalAssetId}/all`,
          params: { withoutOnHold },
        };
      },
      providesTags: ['BranchSP'],
    }),

    getCompanyBranchesListFroSp: builder.query<
      BaseType<Branch[]>,
      CompanyBranchesForSpQueryParams
    >({
      query: ({ companyId, ...params }) => {
        return {
          url: `branch/sp/customer/${companyId}`,
          params,
        };
      },
      providesTags: ['BranchSP'],
    }),

    getBranchListBySpAndTrade: builder.query<
      BaseType<Branch[]>,
      QueryParams & { spId: number; tradeId: number }
    >({
      query: ({ orgIds, spId, tradeId, ...params }) => {
        return {
          url: `/branch/organization?spId=${spId}&tradeId=${tradeId}`,
          params: { ...params, orgIds },
        };
      },
      keepUnusedDataFor: 15,
      providesTags: ['BranchBySpAndTrade'],
    }),
  }),
});

export const {
  useGetBranchesListQuery,
  useLazyGetBranchesListQuery,
  useGetBranchesByGeneralAssetIdListQuery,
  useLazyGetBranchesByGeneralAssetIdListQuery,
  useGetServiceRequestBranchesListQuery,
  useCreateBranchMutation,
  useGetBranchByIdQuery,
  useLazyGetBranchByIdQuery,
  useUpdateBranchMutation,
  useDeleteBranchMutation,
  // branch types
  useGetBranchTypesQuery,
  useLazyGetBranchTypesQuery,
  useGetBranchTypeByIdQuery,
  useLazyGetBranchTypeByIdQuery,
  useCreateBranchTypeMutation,
  useUpdateBranchTypeMutation,
  useGetAssetsByBranchIdAndAssetGroupIdQuery,
  useLazyGetAssetsByBranchIdAndAssetGroupIdQuery,

  useGetUsersByBranchIdQuery,
  useLazyGetUsersByBranchIdQuery,

  //sp
  useGetCustomersBranchListForSpQuery,
  useLazyGetCustomersBranchListForSpQuery,
  useGetCustomersBranchListByGeneralAssetForSpQuery,
  useLazyGetCustomersBranchListByGeneralAssetForSpQuery,
  useGetCompanyBranchesListFroSpQuery,
  useLazyGetCompanyBranchesListFroSpQuery,

  // trade assignment
  useLazyGetBranchListBySpAndTradeQuery,
} = branchApi;
