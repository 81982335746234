const CANADIAN_ZIP_CODE = /\w{3} \w{3}/;
export function normalizeZipcode(zipcode: string) {
  if (!zipcode) return zipcode;
  let normalizedZipcode = zipcode;
  // For Canada get only first 3 characters
  if (CANADIAN_ZIP_CODE.test(zipcode)) {
    normalizedZipcode = zipcode.split(' ')[0];
  }
  return normalizedZipcode.trim();
}

export function formatZipCode(
  zipCode?: string,
  countryCodeRaw?: string | null,
) {
  if (!zipCode) return '';
  let formattedZipCode = zipCode;

  const countryCode = countryCodeRaw?.toUpperCase();

  // If the zip code is from Canada, it should follow the format <char><number><char>.
  if (countryCode === 'CA') {
    if (formattedZipCode.length > 3) {
      formattedZipCode = formattedZipCode.slice(0, 3);
    }

    if (
      formattedZipCode.length === 3 &&
      !/([a-zA-Z])(\d{1})([a-zA-Z])/.test(formattedZipCode)
    ) {
      return formattedZipCode.slice(0, 2);
    }

    if (
      formattedZipCode.length === 2 &&
      !/([a-zA-Z])(\d{1})/.test(formattedZipCode)
    ) {
      return formattedZipCode.slice(0, 1);
    }

    if (formattedZipCode.length === 1 && !/([a-zA-Z])/.test(formattedZipCode)) {
      return '';
    }

    return formattedZipCode;
  }

  if (countryCode === 'US' || countryCode === 'PR' || countryCode === 'MX') {
    formattedZipCode = formattedZipCode.replace(/\D/g, '');

    if (formattedZipCode.length > 5) {
      formattedZipCode = formattedZipCode.slice(0, 5);
    }
  }

  return formattedZipCode;
}
