import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  FilledInput,
  InputLabel,
  FormHelperText,
  InputAdornment,
  FilledInputProps,
} from '@mui/material';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { isNumeric } from 'utils/is_numeric';
import { ECFormControl, FieldTypes } from '../ECForm';
import { ECIconButton } from '../ECIconButton';
import _ from 'lodash';

interface Props extends Omit<FilledInputProps, 'onChange'> {
  type: FieldTypes;
  label: string;
  fieldName: string;
  sx?: any;
  inputProps?: any;
  error?: boolean;
  onChange?: (value: string | number) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: any;
  helperText?: string | null;
  variant?: string;
  isNumber?: boolean;
  maxValue?: number;
  minValue?: number;
}

export const ECInputTextField = ({
  type,
  fieldName,
  label,
  inputProps,
  error,
  helperText,
  sx,
  value: valueFromProps,
  onChange,
  onBlur,
  maxValue,
  minValue,
  ...props
}: Props) => {
  const [value, setValue] = useState(valueFromProps);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setValue(valueFromProps);
  }, [valueFromProps]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;

      if (type === FieldTypes.Number && isNumeric(inputValue)) {
        let numericValue = Number(inputValue);

        if (numericValue === 0) {
          setValue(0);
          onChange && onChange(0);
        } else {
          const safeMin = typeof minValue === 'number' ? minValue : 0;
          const safeMax =
            typeof maxValue === 'number' ? maxValue : Number.MAX_VALUE;
          numericValue = _.clamp(numericValue, safeMin, safeMax);
          setValue(numericValue);
          onChange && onChange(numericValue);
        }
      } else {
        setValue(inputValue);
        onChange && onChange(inputValue);
      }
    },
    [onChange, type, minValue, maxValue],
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const passwordAdornment = useMemo(() => {
    if (type === FieldTypes.Password) {
      return (
        <InputAdornment position="end">
          <ECIconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </ECIconButton>
        </InputAdornment>
      );
    }
  }, [showPassword, type]);

  return (
    <ECFormControl variant="filled" sx={sx}>
      <InputLabel htmlFor={fieldName}>{label}</InputLabel>
      <FilledInput
        {...props}
        id={fieldName}
        name={fieldName}
        type={showPassword ? 'text' : (type as string)}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={inputProps}
        error={error}
        endAdornment={passwordAdornment}
      />
      {error && helperText && (
        <FormHelperText
          sx={theme => ({
            bgcolor: 'transparent',
            color: theme.palette.error.main,
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </ECFormControl>
  );
};
