import { ArrowBackIos } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  ECBox,
  ECButton,
  ECCopyright,
  ECMainBox,
  ECNotLoggedInBar,
  ECPoliceAndTerms,
  ECLoginBanner,
  ECLink,
  ECLoginFooterLinks,
} from '..';
import Banner from '../../../assets/banner.svg';
import Logo from '../../../assets/bluelogowhitebackground.svg';

export interface ECMfaTemplateProps {
  children: React.ReactNode;
}

export const ECMfaTemplate = ({ children }: ECMfaTemplateProps) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ECMainBox
      component={'main'}
      sx={theme => ({
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.marketingSuggestion.marketingSuggestion,
        overflowX: 'hidden',
        position: 'relative',
      })}
    >
      <ECNotLoggedInBar />

      <ECBox
        display="flex"
        mt={'3.75rem'}
        width="100%"
        p={2}
        position="absolute"
      ></ECBox>
      <img
        src={Banner}
        alt="mfa banner"
        style={{
          width: '100%',
          objectFit: 'cover',
          marginTop: isMobileSize ? '4rem' : '0',
        }}
      />
      <ECLink href="https://ecotrak.com/">
        <img
          src={Logo}
          alt="logo"
          style={{
            height: isMobileSize ? '5rem' : '205px',
            zIndex: 1,
            marginTop: isMobileSize ? '-2.5rem' : '-105px',
          }}
        />
      </ECLink>
      <ECBox sx={{ width: '474px', maxWidth: '90%', marginTop: '25px' }}>
        {children}
      </ECBox>
      <ECLoginBanner />
      <ECCopyright sx={{ mt: 8, mb: 0, bottom: 0, marginTop: 'auto' }} />
      <ECPoliceAndTerms sx={{ mb: 4 }} />

      <ECLoginFooterLinks visible={isMobileSize} />
    </ECMainBox>
  );
};
