import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const ECBox = styled(Box)<BoxProps>(() => ({}));

export const ECBgBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  maxHeight: '100vh',
}));

export const ECMainBox = styled(Box)<BoxProps>(({ theme }) => ({
  minWidth: 0,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
}));

export const ECMainTabBox = props => {
  return (
    <ECStyledMainTabBox {...props}>
      {/*<ECToolbar />*/}
      {props.children}
    </ECStyledMainTabBox>
  );
};

export const ECStyledMainTabBox = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  p: 3,
}));

export const ECChartTitleContainerBox = props => {
  return (
    <ECBox mt={props.icon.component ? -2 : 0} ml={props.icon.component ? 2 : 0}>
      {props.children}
    </ECBox>
  );
};

export const ECMainPageBox = props => {
  return (
    <ECStyledMainPageBox sx={props.sx}>{props.children}</ECStyledMainPageBox>
  );
};

export const ECStyledMainPageBox = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  p: 3,
}));
