import { QueryParams } from 'types/QueryParams';
import {
  AssetTypeToRepairCreate,
  AssetTypeToRepairList,
} from 'types/AssetTypeToRepair';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['AssetTypeToRepair'],
});

export const assetTypeToRepairApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAssetTypesToRepairList: builder.query<
      BaseType<AssetTypeToRepairList[]>,
      QueryParams
    >({
      query: params => {
        const { ob, o, ...rest } = params;

        const defaultParams = {
          ...rest,
          ob: ob !== undefined && ob !== '' ? ob : 'assttp.name',
          o: 'a',
        };

        return {
          url: 'asset-group/repair',
          params: defaultParams,
        };
      },
      providesTags: ['AssetTypeToRepair'],
    }),
    updateAssetTypeToRepair: builder.mutation<void, AssetTypeToRepairCreate>({
      query: body => {
        return {
          url: `asset-group/${body.assetTypeId}/repair`,
          method: 'post',
          body: body.repairIds,
        };
      },
      invalidatesTags: ['AssetTypeToRepair'],
    }),
    deleteAssetTypeToRepair: builder.mutation<void, AssetTypeToRepairCreate>({
      query: body => ({
        url: `asset-group/${body.assetTypeId}/repair`,
        method: 'delete',
        body: body.repairIds,
      }),
      invalidatesTags: ['AssetTypeToRepair'],
    }),
  }),
});

export const {
  useGetAssetTypesToRepairListQuery,
  useLazyGetAssetTypesToRepairListQuery,
  useUpdateAssetTypeToRepairMutation,
  useDeleteAssetTypeToRepairMutation,
} = assetTypeToRepairApi;
