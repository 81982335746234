import { ECCalendarViewUnassignedTechPanel } from 'app/components/ECTable/ECCalendarViewUnassignedTechPanel';
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
  DropAnimation,
  defaultDropAnimation,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { DispatchWorkOrderCard } from './DispatchWorkOrdersCard';
import moment from 'moment';
import { ECBox } from 'app/components';
import { ECCalendarViewFilter } from 'app/components/ECTable/ECCalendarViewFilter';
import { DispatchSwimlaneBoard } from './DispatchSwimlaneBoard';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { setSnackbar } from 'store/slice/page';
import {
  useUpdateWorkOrderMutation,
  workOrdersApi,
} from 'services/workOrdersApi';

function getDestinationTechnician(
  techniciansWorkorders: Array<{
    id: number;
    assigneeFullName: string;
    dates: Array<{ date: string; workorders: Array<{ id: number }> }>;
  }>,
  overId,
): any | null {
  if (overId.toString().includes('|')) {
    const [technicianId] = overId.toString().split('|');
    return techniciansWorkorders.find(t => t.id === Number(technicianId));
  }

  const workOrderId = Number(overId);
  return (
    techniciansWorkorders.find(technician =>
      technician.dates.some(date =>
        date.workorders.some(workorder => workorder.id === workOrderId),
      ),
    ) || null
  );
}

function getOriginalContainerByWorkOrderId(
  techniciansWorkorders: Array<{
    id: number;
    assigneeFullName: string;
    dates: Array<{ date: string; workorders: Array<{ id: number }> }>;
  }>,
  unassignPanelData: any[],
  workOrderId: number,
): string | null {
  const technician = techniciansWorkorders.find(technician =>
    technician.dates.some(date =>
      date.workorders.some(workorder => workorder.id === workOrderId),
    ),
  );

  if (technician) {
    const date = technician.dates.find(date =>
      date.workorders.some(workorder => workorder.id === workOrderId),
    );

    if (date) {
      return `${technician.id}|${technician.assigneeFullName}|${date.date}`;
    }
  }
  const unassignedPanelWO = unassignPanelData.find(
    wo => Number(wo.id) === workOrderId,
  );
  if (unassignedPanelWO) {
    return unAssignedPanelName;
  }
  return null;
}

function getBeforeWOId(
  techniciansWorkorders: Array<{
    id: number;
    assigneeFullName: string;
    dates: Array<{ date: string; workorders: Array<{ id: number }> }>;
  }>,
  workOrderId: number,
): number | null {
  const technician = techniciansWorkorders?.find(technician =>
    technician?.dates?.some(date =>
      date?.workorders?.some(workorder => workorder.id === workOrderId),
    ),
  );

  if (technician) {
    const date = technician?.dates?.find(date =>
      date.workorders.some(workorder => workorder.id === workOrderId),
    );

    if (date) {
      const workorderIndex = date?.workorders?.findIndex(
        workorder => workorder.id === workOrderId,
      );
      if (workorderIndex > 0) {
        return date.workorders[workorderIndex - 1].id;
      }
    }
  }
  return null;
}

export const getWOCardById = (
  techniciansWorkorders: any,
  unassignPanelData: any[],
  id: number,
) => {
  const workorder = techniciansWorkorders
    ?.flatMap(technician =>
      technician?.dates?.flatMap(date => date.workorders || []),
    )
    ?.find(wo => Number(wo.id) === Number(id));

  if (!workorder) {
    return unassignPanelData?.find(wo => Number(wo.id) === Number(id));
  }

  return workorder;
};

const getMidpointEta = (eta1, eta2) => {
  const date1 = new Date(eta1);
  const date2 = new Date(eta2);

  const midpointTime = new Date((date1.getTime() + date2.getTime()) / 2);
  return moment(midpointTime).format('YYYY-MM-DD HH:mm');
};

const getNewEtaAfter = (eta, minutesToAdd = 30) => {
  const etaDate = new Date(eta);
  etaDate.setMinutes(etaDate.getMinutes() + minutesToAdd);

  return moment(etaDate).format('YYYY-MM-DD HH:mm');
};

const getNewEtaBefore = (eta, minutesToSubtract = 30) => {
  const etaDate = new Date(eta);
  etaDate.setMinutes(etaDate.getMinutes() - minutesToSubtract);
  return moment(etaDate).format('YYYY-MM-DD HH:mm');
};

const unAssignedPanelName = 'unAssignedPanel';

interface DispatchSwimlanesDNDProps {
  columnViewData: any;
  isSuccessColumnViewData: boolean;
  isLoadingColumnViewData: boolean;
}

export function DispatchSwimlanesDND({
  columnViewData,
  isSuccessColumnViewData,
  isLoadingColumnViewData,
}: DispatchSwimlanesDNDProps): JSX.Element {
  const [unassignPanelData, setUnassignPanelData] = useState<any[]>([]);
  const [activeWOId, setActiveWOId] = useState<number | undefined>(undefined);
  const [activeWOTechnicianId, setActiveWOTechnicianId] = useState<
    number | undefined
  >(undefined);
  const [destinationWOTechnicianId, setDestinationWOTechnicianId] = useState<
    number | undefined
  >(undefined);
  const [activePanel, setActivePanel] = useState<string | undefined>(undefined);
  const [preventDND, setPreventDND] = useState<boolean | undefined>(false);
  const [techniciansWorkorders, setTechniciansWorkorders] = useState(
    columnViewData?.data,
  );
  const [overPanelContainer, setOverPanelContainer] = useState<
    string | undefined
  >(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    if (columnViewData?.data) {
      setTechniciansWorkorders(columnViewData?.data);
    }
  }, [columnViewData?.data]);

  const [
    doUpdateWorkOrder,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateWorkOrder,
    },
  ] = useUpdateWorkOrderMutation();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleUnassignedWorkOrderDataUpdate = useCallback(
    (newData: any[]) => {
      setUnassignPanelData(newData);
    },
    [setUnassignPanelData],
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (isUpdateLoading || preventDND) return;

    const acrivePanel = getOriginalContainerByWorkOrderId(
      techniciansWorkorders ?? [],
      unassignPanelData,
      Number(active.id),
    );
    if (acrivePanel?.includes('|')) {
      const technicianId = acrivePanel.split('|')[0];
      setActiveWOTechnicianId(Number(technicianId));
    }
    setActivePanel(acrivePanel as string);
    setActiveWOId(active.id as number);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    if (isUpdateLoading || preventDND) return;
    if (
      over?.id?.toString().includes('|') ||
      over?.id?.toString() === unAssignedPanelName
    ) {
      const cleanedOverPanelId = over.id.toString().replace(/##/g, '');
      const [technicianId, , etaDateFromId] =
        cleanedOverPanelId?.toString().split('|') || [];
      const container = techniciansWorkorders.find(
        item => item.id === Number(technicianId),
      );
      if (container) {
        const date = container.dates.find(date => date.date === etaDateFromId);
        const overSameWorkorder =
          date?.workorders?.[0]?.id === Number(activeWOId);
        setOverPanelContainer(
          overSameWorkorder ? undefined : cleanedOverPanelId,
        );
      } else {
        setOverPanelContainer(cleanedOverPanelId);
      }
    } else {
      const container = unassignPanelData.find(
        item => item.id === Number(over?.id),
      );
      if (container) {
        setOverPanelContainer(unAssignedPanelName);
      } else {
        if (
          Number(over?.id) !== Number(previousWOId) &&
          Number(over?.id) !== Number(activeWOId)
        ) {
          setOverPanelContainer(over?.id as string);
        } else {
          setOverPanelContainer(undefined);
        }
      }
    }
  };

  const activeWO = activeWOId
    ? getWOCardById(techniciansWorkorders, unassignPanelData, activeWOId)
    : null;
  const previousWOId = activeWOId
    ? getBeforeWOId(techniciansWorkorders, Number(activeWOId))
    : null;

  const exitDNDEnd = () => {
    setOverPanelContainer(undefined);
    setActivePanel(undefined);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const cleanedOverId = over?.id.toString().replace(/##/g, '');
    const workOrderId = Number(activeWOId);
    if (
      isUpdateLoading ||
      !over?.id ||
      preventDND ||
      over.id.toString() === unAssignedPanelName ||
      isNaN(workOrderId) ||
      active.id === cleanedOverId
    ) {
      exitDNDEnd();
      return;
    }

    const destinationTechnician = getDestinationTechnician(
      techniciansWorkorders,
      cleanedOverId,
    );

    const activeWOEta = new Date(activeWO?.eta);
    const hour = activeWOEta.getHours();
    const minute = activeWOEta.getMinutes();
    const second = activeWOEta.getSeconds();

    if (cleanedOverId?.toString().includes('|')) {
      const [technicianId, , etaDateFromId] =
        cleanedOverId?.toString().split('|') || [];

      let updatedDateTime = moment(etaDateFromId)
        .set({ hour, minute, second })
        .format('YYYY-MM-DD HH:mm');

      const destinationTechnicianDate = destinationTechnician.dates.find(
        date => date.date === etaDateFromId,
      );
      const overSameWorkorder =
        destinationTechnicianDate?.workorders?.[0]?.id === Number(activeWOId);
      if (overSameWorkorder) {
        exitDNDEnd();
        return;
      }

      if (
        destinationTechnicianDate &&
        destinationTechnicianDate.workorders.length > 0
      ) {
        const dateFirstWOEta = new Date(
          destinationTechnicianDate.workorders[0]?.eta,
        );
        const activeWOEta = new Date(updatedDateTime);

        if (dateFirstWOEta < activeWOEta) {
          updatedDateTime = getNewEtaBefore(dateFirstWOEta, 29);
        }
      }

      setTechniciansWorkorders(prev => {
        const filteredTechnicians = prev.map(technician => ({
          ...technician,
          dates: technician.dates.map(date => ({
            ...date,
            workorders: date.workorders.filter(
              workorder => workorder.id !== Number(activeWOId),
            ),
          })),
        }));

        return filteredTechnicians.map(technician => {
          if (technician.id === Number(technicianId)) {
            return {
              ...technician,
              dates: technician.dates.map(date => {
                if (date.date === etaDateFromId) {
                  const existingWorkorderIds = date.workorders.map(
                    workorder => workorder.id,
                  );
                  if (!existingWorkorderIds.includes(Number(activeWOId))) {
                    return {
                      ...date,
                      workorders: [activeWO, ...date.workorders],
                    };
                  }
                }
                return date;
              }),
            };
          }
          return technician;
        });
      });
      setDestinationWOTechnicianId(destinationTechnician.id);

      if (activeWOTechnicianId === destinationTechnician.id) {
        doUpdateWorkOrder({
          id: Number(activeWOId),
          eta: updatedDateTime,
        });
      } else {
        doUpdateWorkOrder({
          id: Number(activeWOId),
          spAssigneeUserId: destinationTechnician.id,
          spCompanyId: destinationTechnician.serviceProviderID,
          eta: updatedDateTime,
        });
      }
    } else {
      const destinationWOId = Number(over.id);
      const technicianWithDestinationWO = techniciansWorkorders.find(
        technician =>
          technician.dates.some(date =>
            date.workorders.some(workorder => workorder.id === destinationWOId),
          ),
      );
      if (!technicianWithDestinationWO) {
        exitDNDEnd();
        return;
      }

      let destinationWO = null;
      let nextWO = null;

      techniciansWorkorders.map(technician => {
        const dateWithDestinationWO = technician.dates.find(date => {
          const destinationIndex = date.workorders.findIndex(
            workorder => workorder.id === destinationWOId,
          );

          if (destinationIndex !== -1) {
            destinationWO = date.workorders[destinationIndex];
            if (destinationIndex < date.workorders.length - 1) {
              nextWO = date.workorders[destinationIndex + 1];
            }
            return true;
          }
          return false;
        });

        return dateWithDestinationWO;
      });

      const nextWOId = (nextWO as any)?.id;

      if (nextWOId && Number(activeWOId) === Number(nextWOId)) {
        exitDNDEnd();
        return;
      }

      setTechniciansWorkorders(prev => {
        const filteredTechnicians = prev.map(technician => ({
          ...technician,
          dates: technician.dates.map(date => ({
            ...date,
            workorders: date.workorders.filter(
              workorder => workorder.id !== Number(activeWOId),
            ),
          })),
        }));

        return filteredTechnicians.map(technician => {
          return {
            ...technician,
            dates: technician.dates.map(date => {
              const destinationIndex = date.workorders.findIndex(
                workorder => workorder.id === destinationWOId,
              );

              // Store the destination work order
              const destinationWO =
                destinationIndex !== -1
                  ? date.workorders[destinationIndex]
                  : null;

              // Check if there is a next work order
              const nextWO =
                destinationIndex !== -1 &&
                destinationIndex < date.workorders.length - 1
                  ? date.workorders[destinationIndex + 1]
                  : null;

              if (destinationWO) {
                const filteredWorkorders = date.workorders.filter(
                  workorder => workorder.id !== Number(activeWOId),
                );
                const newWorkorders = [...filteredWorkorders];
                newWorkorders.splice(destinationIndex + 1, 0, activeWO);

                return {
                  ...date,
                  workorders: newWorkorders,
                };
              }

              return date;
            }),
          };
        });
      });

      let newEta = '';
      if (destinationWO && nextWO) {
        newEta = getMidpointEta(
          (destinationWO as any)?.eta,
          (nextWO as any)?.eta,
        );
      }

      if (destinationWO && !nextWO) {
        const destinationWOEta = new Date((destinationWO as any)?.eta);
        const activeDateTime = moment(destinationWOEta)
          .set({ hour, minute, second })
          .format('YYYY-MM-DD HH:mm');

        if (
          destinationWOEta < moment(activeDateTime, 'YYYY-MM-DD HH:mm').toDate()
        ) {
          newEta = moment(activeDateTime).format('YYYY-MM-DD HH:mm');
        } else {
          newEta = getNewEtaAfter((destinationWO as any)?.eta, 29);
        }
      }

      setDestinationWOTechnicianId(destinationTechnician.id);

      doUpdateWorkOrder({
        id: Number(activeWOId),
        spAssigneeUserId: technicianWithDestinationWO.assigneeID,
        spCompanyId: destinationTechnician.serviceProviderID,
        eta: newEta,
      });
    }
    setUnassignPanelData(prev => {
      return prev.filter(wo => wo.id !== Number(activeWOId));
    });
    setPreventDND(true);
    exitDNDEnd();
  };

  const handleSuccess = useCallback(
    (message: string, resetFunction: () => void) => {
      dispatch(
        setSnackbar({
          severity: 'success',
          message,
        }),
      );
      dispatch(
        workOrdersApi.util.invalidateTags([
          'DispatchColumn',
          'WorkOrderById',
          'DispatchColumnUnassigned',
        ]),
      );
      setActiveWOId(undefined);
      setPreventDND(false);
      resetFunction();
    },
    [],
  );

  useEffect(() => {
    if (isUpdateSuccess) {
      if (activeWOTechnicianId === destinationWOTechnicianId) {
        handleSuccess('ETA was successfully updated.', resetUpdateWorkOrder);
      } else {
        handleSuccess(
          'Work Order was successfully reassigned.',
          resetUpdateWorkOrder,
        );
      }
    }
  }, [isUpdateSuccess, handleSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      dispatch(
        workOrdersApi.util.invalidateTags([
          'DispatchColumn',
          'WorkOrderById',
          'DispatchColumnUnassigned',
        ]),
      );
      resetUpdateWorkOrder();
      setOverPanelContainer(undefined);
      setActiveWOId(undefined);
      setTechniciansWorkorders(columnViewData?.data);
      setPreventDND(false);
    }
  }, [isUpdateError]);

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <ECBox
        display="flex"
        flexDirection="row"
        width={'100%'}
        mt={2}
        height="calc(100% - 120px)"
      >
        <ECBox minWidth={'calc(100% - 240px)'} height={'100%'}>
          <ECCalendarViewFilter />
          <DispatchSwimlaneBoard
            technicians={techniciansWorkorders || []}
            originalContainerId={activePanel}
            dragPanelId={overPanelContainer}
            isLoading={isLoadingColumnViewData && !isSuccessColumnViewData}
            setPreventDND={setPreventDND}
            preventDND={preventDND}
          />
        </ECBox>
        <ECCalendarViewUnassignedTechPanel
          onUnassignedWorkOrderDataUpdate={handleUnassignedWorkOrderDataUpdate}
          dragPanelId={overPanelContainer}
          isRenderDisabled={isUpdateLoading}
          isDraggingFinished={isUpdateSuccess || isUpdateError}
          unAssignedPanelData={unassignPanelData}
        />
      </ECBox>
      <DragOverlay dropAnimation={dropAnimation}>
        {activeWO ? (
          <DispatchWorkOrderCard
            workOrder={activeWO}
            dragPanelId={overPanelContainer}
            setPreventDND={setPreventDND}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
