import { isEmptyValue } from 'app/components';
import { useEffect, useState } from 'react';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { P, CompanyConfigurationFields } from 'types/Permission';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';

export interface RequirePermissionWrapperProps {
  scopes?: P[];
  scopesOptional?: P[];
  companyConfigScopes?: CompanyConfigurationFields[];
}

export const hasRequiredPermission = ({ userPermissions, scopes }): boolean => {
  return (
    isEmptyValue(scopes) ||
    scopes.every(scope => scope === P.Unset || userPermissions?.includes(scope))
  );
};

export const hasOptionalPermission = ({ userPermissions, scopes }): boolean => {
  return (
    isEmptyValue(scopes) ||
    scopes.some(scope => scope === P.Unset || userPermissions.includes(scope))
  );
};

export const hasCompanyConfiguration = ({
  companyConfiguration,
  configs,
}): boolean => {
  // if configs is empty it means no configuration is being checked
  // if companyConfiguration is empty it means the value from query is not available
  // if there is a config parameter but no company configuration, should return false
  return (
    isEmptyValue(configs) ||
    (companyConfiguration &&
      configs.every(config => companyConfiguration[config] === 1))
  );
};

export const requirePermissionWrapper =
  <T extends object>(WrappedComponent) =>
  ({
    scopes = [],
    scopesOptional = [],
    companyConfigScopes = [],
    ...props
  }: RequirePermissionWrapperProps & T) => {
    const { data, isSuccess } = useGetUserProfileQuery(undefined, {
      pollingInterval: 1000 * 60 * 15, // 15 minutes
      skip: !window.location.pathname.includes('panel'),
    });
    const [userPermissions, setUserPermissions] = useState<string[]>([]);

    const companyConfiguration = useCompanyCustomerProfile();

    useEffect(() => {
      if (isSuccess && Array.isArray(data?.permissions)) {
        setUserPermissions(data.permissions.map(p => p.action));
      }
    }, [data, isSuccess]);

    const isPermitted =
      hasRequiredPermission({
        userPermissions,
        scopes,
      }) &&
      hasOptionalPermission({
        userPermissions,
        scopes: scopesOptional,
      }) &&
      hasCompanyConfiguration({
        companyConfiguration,
        configs: companyConfigScopes,
      });

    return isPermitted ? <WrappedComponent {...props} /> : <></>;
  };
