import moment from 'moment';

export function formatHoursWithAmOrPm(hour: number, minutes: number) {
  const formatedHour = `${hour > 12 ? hour - 12 : hour}`.padStart(2, '0');
  const formatedMinutes = `${minutes}`.padStart(2, '0');
  return `${formatedHour}:${formatedMinutes} ${hour >= 12 ? 'PM' : 'AM'}`;
}

export function formatHoursAndMinutes(hour: number, minutes: number) {
  const formatedHour = `${hour}`.padStart(2, '0');
  const formatedMinutes = `${minutes}`.padStart(2, '0');
  return `${formatedHour}:${formatedMinutes}`;
}

/**
 * This function converts a decimal representation of hours into a human-readable string format
 * @param {number} hours - The decimal representation of time (e.g., 3.5 for 3 hours and 30 minutes).
 * @returns {string} The formatted string representing the time (e.g., "3 hr(s) 30 min(s)").
 */
export function formatDecimalHours(hours) {
  if (hours < Number.EPSILON) return '0 min';
  const duration = moment.duration(hours, 'hours');
  const hrs = Math.floor(duration.asHours());
  const mins = Math.round(duration.asMinutes() % 60);

  return `${hrs ? `${hrs} hr(s)` : ''} ${mins ? `${mins} min(s)` : ''}`.trim();
}

// also ignores system timezone difference;
export function convertToISOString(date?: Date | string) {
  if (!date) return;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return moment(date).utc(true).toISOString();
}
