import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import parse from 'html-react-parser';
import { formatDateForComment } from 'utils/strings/formatDate';
import { ECConfirmationDialog } from '../ECDialog';
import { useCallback, useMemo, useState } from 'react';
import { ECLink } from '../ECLink';
import { Chip, Popover } from '@mui/material';
import { ECStack } from '../ECStack';
import { UserBase } from 'types/User';
import { CompanyTypeCode } from 'types/Company';
import { startCase } from 'lodash';

export interface ECCommentProps {
  id: string;
  key: string;
  text: string;
  date: number;
  author: UserBase;
  recipients: UserBase[];
  module: string;
  moduleId: number;
  onEdit?: (commentId: string, body: string) => () => void;
  onDelete?: (commentId: string) => () => void;
}

export const ECComment = ({
  id,
  key,
  text,
  date,
  author,
  recipients,
  module,
  moduleId,
  onEdit,
  onDelete,
}: ECCommentProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDelete = useCallback(() => {
    onDelete?.(id)();
    setIsDeleteDialogOpen(false);
  }, [id]);

  const formattedText = useMemo(() => parse(text), [text]);
  const fullName = useMemo(
    () => (author ? `${author.firstName} ${author.lastName}` : 'Unavailable'),
    [author],
  );

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  const NotifiedUserItem = ({ key, index, name, companyName }) => {
    return (
      <ECBox
        bgcolor={theme =>
          index % 2 === 0
            ? theme.palette.common.white
            : theme.palette.other.background
        }
        sx={{
          '&:hover': {
            bgcolor: theme => theme.palette.primary.outlinedHoverBackground,
          },
        }}
        px={2}
        py={1}
        key={key}
        display="flex"
        flexDirection="column"
      >
        <ECTypography variant="body1">{name}</ECTypography>
        <ECTypography
          variant="body2"
          color={theme => theme.palette.action.active}
        >
          {companyName}
        </ECTypography>
      </ECBox>
    );
  };

  const ModuleBadge = ({ module, moduleId }) => {
    if (!module || !moduleId) return null;

    const mapColors = {
      invoice: {
        bgcolor: theme => theme.palette.warning.outlinedHoverBackground,
        color: theme => theme.palette.warning.dark,
      },
      proposal: {
        bgcolor: theme => theme.palette.success.outlinedHoverBackground,
        color: theme => theme.palette.success.dark,
      },
      workorder: {
        bgcolor: theme => theme.palette.secondary.outlinedHoverBackground,
        color: theme => theme.palette.secondary.dark,
      },
      rfp: {
        bgcolor: theme => theme.palette.primary.outlinedHoverBackground,
        color: theme => theme.palette.flowKit.purple,
      },
    };

    return (
      <Chip
        label={`${startCase(module)} #${moduleId}`}
        size="small"
        sx={{
          bgcolor: mapColors[module]?.bgcolor,
          color: mapColors[module]?.color,
        }}
      />
    );
  };

  return (
    <ECBox key={key} display="flex" flexDirection="column">
      <ECBox display="flex" alignItems="center" gap={2}>
        <ECBox
          height={18}
          width={18}
          bgcolor={theme => theme.palette.primary.dark}
          borderRadius={9}
        />
        <ECBox display="flex" justifyContent="center" flexDirection="column">
          <ECBox display="flex" alignItems="center" gap={1}>
            <ECTypography variant="subtitle2">{fullName}</ECTypography>

            <ECTypography variant="body2">•</ECTypography>

            <ECTypography
              variant="body2"
              sx={{ opacity: 0.6 }}
              color={theme => theme.palette.text.secondary}
            >
              {author?.email || 'N/A'}
            </ECTypography>

            <ModuleBadge module={module} moduleId={moduleId} />
          </ECBox>

          <ECBox display="flex" alignItems="center" gap={1}>
            <ECBox
              display="flex"
              color={theme => theme.palette.text.secondary}
              sx={{ opacity: 0.6 }}
              gap={1}
            >
              <ECTypography variant="body2">
                {author?.jobTitle?.name || 'N/A'}
              </ECTypography>

              <ECTypography variant="body2">•</ECTypography>

              <ECTypography variant="body2">
                {author?.company?.name || 'N/A'}
              </ECTypography>

              <ECTypography variant="body2">•</ECTypography>

              <ECTypography variant="body2">
                {formatDateForComment(date)}
              </ECTypography>
            </ECBox>

            <ECLink
              fontSize={14}
              underline="always"
              fontWeight={500}
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              Users Notified
            </ECLink>
          </ECBox>
        </ECBox>
      </ECBox>

      <ECBox
        mt={1}
        display="flex"
        alignItems="center"
        borderRadius={1}
        maxWidth={600}
        py={1}
        px={2}
        bgcolor={theme =>
          author?.company?.companyType?.code === CompanyTypeCode.Customer
            ? theme.palette.primary.outlinedHoverBackground
            : theme.palette.grey[100]
        }
      >
        <ECTypography
          my={Array.isArray(formattedText) ? -2 : 0}
          variant="body2"
          sx={{ whiteSpace: 'pre-line' }}
        >
          {formattedText}
        </ECTypography>
      </ECBox>

      <ECConfirmationDialog
        open={isDeleteDialogOpen}
        title={'Delete Comment Confirmation'}
        text={'Are you sure you want to delete this comment?'}
        onClose={() => setIsDeleteDialogOpen(false)}
        acceptButtonTitle={'Delete'}
        acceptButtonColor={'error'}
        onCancelClick={() => setIsDeleteDialogOpen(false)}
        onAcceptClick={handleDelete}
      />

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ECBox display="flex" flexDirection="column">
          <ECStack
            direction="column"
            maxHeight={240}
            width={260}
            overflow="auto"
          >
            {recipients?.length ? (
              recipients?.map((recipient, index) => (
                <NotifiedUserItem
                  key={index}
                  index={index}
                  name={`${recipient.firstName} ${recipient.lastName}`}
                  companyName={recipient.company?.name}
                />
              ))
            ) : (
              <ECBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={2}
              >
                <ECTypography variant="body2">No users notified</ECTypography>
              </ECBox>
            )}
          </ECStack>
        </ECBox>
      </Popover>
    </ECBox>
  );
};
