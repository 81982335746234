import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import _ from 'lodash';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import {
  useGetBranchesListQuery,
  useGetBranchTypesQuery,
} from 'services/branchApi';
import { ECEasyFormFieldType } from 'app/components';
import {
  useGetOrganizationListQuery,
  useGetOrganizationParentListQuery,
} from 'services/organizationApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { OrganizationIdTypeEnum } from 'types/Organization';

const fancyFormElementsCreate = require('./fancy_form_config_step1_create.json');

interface Step1Props {
  existingData?: any;
  readOnly?: boolean;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}
export const Step1 = ({ existingData, onClose, onSubmit }: Step1Props) => {
  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;
      if (field.type === 'group') {
        return {
          ...field,
          subFields: field?.subFields?.map(handleExistingData),
        };
      }
      const isBillingField = field?.fieldName?.startsWith('billingAddress');
      const useSameAddress = existingData?.isBillingSameAsAddress?.includes(
        'Use same for Billing Address',
      );

      const fieldValue = _.get(existingData, field.fieldName);

      return {
        ...field,
        value: fieldValue,
        disabled: isBillingField && useSameAddress,
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields?.map(handleExistingData),
  );

  const { data: dataOrgLevelList, isSuccess: isSuccessOrgLevel } =
    useGetOrganizationParentListQuery();

  const { data: orgs } = useGetOrganizationListQuery();

  const orgOptions = useMemo(() => {
    if (!orgs) {
      return [];
    }

    let opt: any[] = [];

    opt.push({
      label: `Company: ${orgs[0].name}`,
      value: orgs[0].id,
      level: 0,
    });

    const addOrgOptions = (orgs, level = 1) => {
      orgs.forEach(org => {
        const isRegion =
          org.organizationTypeId === OrganizationIdTypeEnum.Region;
        const orgLabel = isRegion ? ' (Region)' : ' (District)';

        opt.push({ label: `${org.name} ${orgLabel}`, value: org.id, level });

        if (org.children) {
          addOrgOptions(org.children, level + 1);
        }
      });
    };

    addOrgOptions(orgs[0]?.children ?? []);

    return _.uniqBy(opt, 'value');
  }, [orgs]);

  useLayoutEffect(() => {
    const updateFieldWithQuery = (
      fieldName,
      queryHook,
      alias,
      queryParams: any = null,
    ) => {
      const field = formFields.find(field => field.fieldName === fieldName);
      if (field) {
        field.useQuery = queryHook;
        field.obAlias = alias;
        if (queryParams) field.queryParams = queryParams;
      }
    };

    updateFieldWithQuery('branchType', useGetBranchTypesQuery, 'name');
    updateFieldWithQuery(
      'copyTradeAssignmentLocationId',
      useGetBranchesListQuery,
      'brnch.name',
      { st: 1 },
    );

    if (isSuccessOrgLevel && dataOrgLevelList) {
      populateDropDownFields({
        responseData: orgOptions,
        createFormFields: formFields,
        isPopulateForChipsComponent: true,
        dataOptionsFieldName: 'label',
        dataValuesFieldName: 'value',
        formFieldName: 'organization',
      });
    }
    setFormFields([...formFields]);
  }, [isSuccessOrgLevel, dataOrgLevelList, orgOptions]);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      onSubmit?.({ ...existingData, ...data });
    };

    return [doSubmit, {}];
  }, [onSubmit]);

  const handleChange = (output: ECEasyFormFieldType[], fieldname) => {
    if (fieldname === 'isBillingSameAsAddress') {
      const isBillingSameAsAddressChecked = (
        output.find(field => field.fieldName === 'isBillingSameAsAddress')
          ?.value as string[]
      )?.includes('Use same for Billing Address');

      const newFields = _.clone(output) || [];

      const findField = (fields, groupName, fieldName) =>
        fields
          .find(field => field?.groupName === groupName)
          ?.subFields?.find(data => data?.fieldName === fieldName);

      // Billing Address Fields
      const billingCountryField = findField(
        newFields,
        'groupBillingAddressCodes',
        'billingAddress.countryCode',
      );
      const billingZipField = findField(
        newFields,
        'groupBillingAddressCodes',
        'billingAddress.zipCodePostalCode',
      );
      const billingCityField = findField(
        newFields,
        'groupBillingAddressCodes',
        'billingAddress.cityName',
      );
      const billingStateField = findField(
        newFields,
        'groupBillingAddressCodes',
        'billingAddress.stateProvinceCode',
      );
      const billingAddressLine1Field: any = newFields.find(
        field => field?.fieldName === 'billingAddress.line1',
      ) || { value: '' };
      const billingAddressLine2Field: any = newFields.find(
        field => field?.fieldName === 'billingAddress.line2',
      ) || { value: '' };

      // Mailing Address Fields
      const mailingZipField = findField(
        newFields,
        'cityZipStateGroup',
        'mailingAddress.zipCodePostalCode',
      );
      const mailingCityField = findField(
        newFields,
        'cityZipStateGroup',
        'mailingAddress.cityName',
      );
      const mailingStateField = findField(
        newFields,
        'cityZipStateGroup',
        'mailingAddress.stateProvinceCode',
      );
      const mailingAddressLine1Field = newFields.find(
        field => field?.fieldName === 'mailingAddress.line1',
      );
      const mailingAddressLine2Field = newFields.find(
        field => field?.fieldName === 'mailingAddress.line2',
      );

      const mailingCountryField = findField(
        newFields,
        'countryGroup',
        'mailingAddress.countryCode',
      );

      const copyMailingToBilling = () => {
        billingCountryField.value = mailingCountryField?.value;
        billingCountryField.disabled = true;
        billingZipField.value = mailingZipField?.value;
        billingZipField.disabled = true;
        billingCityField.value = mailingCityField?.value;
        billingCityField.disabled = true;
        billingStateField.value = mailingStateField?.value;
        billingStateField.disabled = true;
        billingAddressLine1Field.value = mailingAddressLine1Field?.value;
        billingAddressLine1Field.disabled = true;
        billingAddressLine2Field.value = mailingAddressLine2Field?.value;
        billingAddressLine2Field.disabled = true;
      };

      const clearBillingFields = () => {
        billingCountryField.value = '';
        billingZipField.value = '';
        billingCityField.value = '';
        billingStateField.value = '';
        billingAddressLine1Field.value = '';
        billingAddressLine2Field.value = '';

        billingCountryField.disabled = false;
        billingZipField.disabled = false;
        billingCityField.disabled = false;
        billingStateField.disabled = false;
        billingAddressLine1Field.disabled = false;
        billingAddressLine2Field.disabled = false;
      };

      // Update fields based on checkbox state
      if (
        billingCountryField &&
        billingZipField &&
        billingCityField &&
        billingStateField &&
        billingAddressLine1Field &&
        billingAddressLine2Field
      ) {
        if (isBillingSameAsAddressChecked) {
          copyMailingToBilling();
        } else {
          clearBillingFields();
        }
      }

      setFormFields([...newFields]);
    }
    if (fieldname === 'branchType') {
      const newFields = _.clone(output) || [];
      const branchTypeField = newFields.find(
        field => field.fieldName === 'branchType',
      );
      const branchTypeValue = branchTypeField?.value;
      if (branchTypeValue) {
        branchTypeField.value = branchTypeValue;
        setFormFields([...newFields]);
      }
    }
  };

  return (
    <ECEasyFormCreate
      useCreateMutation={submit}
      formConfig={fancyFormElementsCreate.config}
      formFields={formFields}
      onClose={onClose}
      onChange={handleChange}
    />
  );
};
