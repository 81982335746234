import { Fragment, useMemo } from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { camelCaseToWords } from 'utils/common';
import { isValidHistoryEntry } from '../ECHistoryEntry/historyUtil';
import { TradeAssignmentActivityList } from 'types/Activity';
import { ArrowForward } from '@mui/icons-material';

export interface ECAssetTradeProblemActivityProps {
  entry: TradeAssignmentActivityList;
  parseValue: (value: any, bodyKey: string, otherValue?: any) => any;
}

export interface TradeAndProblemsProps {
  tradeProblems: {
    trade: string;
    problems: Array<string>;
  };
}

const handlePositionShiftedArrayData = (
  key: string,
  beforeFields: any,
  bodyFields: any,
) => {
  if (
    key === 'problems' &&
    beforeFields &&
    bodyFields &&
    Array.isArray(bodyFields?.problems) &&
    Array.isArray(beforeFields?.problems)
  ) {
    const beforeData = beforeFields?.problems;
    const newData = bodyFields?.problems;
    const existInBothData = newData.reduce((acc, value) => {
      if (beforeData.includes(value)) {
        acc.push(value);
      }
      return acc;
    }, []);

    if (existInBothData.length) {
      const notInSamePosition = existInBothData.reduce((acc, value) => {
        if (beforeData.indexOf(value) !== newData.indexOf(value)) {
          acc.push({ value, position: beforeData.indexOf(value) });
        }
        return acc;
      }, []);
      if (notInSamePosition.length) {
        const samePositionValueArr = notInSamePosition.map(item => item.value);
        const serializedDistrict = newData.filter(
          item => !samePositionValueArr.includes(item),
        );
        for (const index in notInSamePosition) {
          const trackCount =
            notInSamePosition[index].position - serializedDistrict.length;
          if (trackCount > 0) {
            const toAdd = Array(trackCount).fill('');
            serializedDistrict.splice(
              notInSamePosition[index].position,
              0,
              ...toAdd,
              notInSamePosition[index].value,
            );
          } else {
            serializedDistrict.splice(
              notInSamePosition[index].position,
              0,
              notInSamePosition[index].value,
            );
          }
        }

        return {
          canUseParsedDistricts: notInSamePosition.length,
          notInSamePosition,
          newParsedDistrict: serializedDistrict,
        };
      }
    }
  }
  return {};
};

export const ECAssetTradeProblemActivity = ({
  entry,
  parseValue,
}: ECAssetTradeProblemActivityProps) => {
  const { beforeBody, body } = entry;

  const renderActivityWithArrayField = useMemo(() => {
    let { id, assetGroupId, ...otherBodyFields } = body ?? {};
    const {
      id: beforeId,
      assetGroupId: beforeAssetGroupId,
      ...otherBeforeFields
    } = beforeBody ?? {};

    const isFieldUpdated = (beforeValue: any, newValue: any) => {
      if (Array.isArray(beforeValue)) {
        return JSON.stringify(beforeValue) !== JSON.stringify(newValue);
      }

      return beforeValue !== newValue;
    };
    const isBodyFieldAvailable = !!Object.keys(otherBodyFields).length;
    const isBeforeFieldAvailable = !!Object.keys(otherBeforeFields).length;

    const loopThroughField = isBodyFieldAvailable
      ? otherBodyFields.hasOwnProperty('tradeProblems')
        ? otherBodyFields
        : isBeforeFieldAvailable
          ? otherBeforeFields
          : otherBodyFields
      : otherBeforeFields;

    const arrowAppearIndex =
      Object.keys(loopThroughField).length === 1
        ? 0
        : Math.ceil(Object.keys(loopThroughField).length / 2) - 1;

    return Object.keys(loopThroughField ?? {}).map((bodyKey, index) => {
      const isTradeProblemsField = bodyKey === 'tradeProblems';
      if (isTradeProblemsField) {
        const bodyTradeProblems = body?.[bodyKey];
        const beforeTradeProblems = beforeBody?.[bodyKey];
        return (loopThroughField.tradeProblems ?? []).map(
          (_, tradeProblemIndex) => {
            const bodyNestedFields = bodyTradeProblems?.[tradeProblemIndex];
            const beforeNestedFields = beforeTradeProblems?.[tradeProblemIndex];
            return (
              <Fragment key={`tradeProblem_${tradeProblemIndex}`}>
                {Object.keys(bodyNestedFields ?? beforeNestedFields ?? {}).map(
                  (nestedBodyKey, nestedIndex) => {
                    return (
                      <>
                        {isValidHistoryEntry(
                          beforeNestedFields ?? bodyNestedFields,
                          nestedBodyKey,
                        ) && (
                          <ECBox display="flex" width="100%">
                            <ECBox display="flex" flex={1} width={0}>
                              <ECTypography
                                variant="subtitle2"
                                minWidth="15vw"
                                width="15vw"
                                maxWidth="20vw"
                                color={theme => theme.palette.text.secondary}
                              >
                                {camelCaseToWords(nestedBodyKey)}
                                :&nbsp;
                              </ECTypography>

                              <ECBox
                                display="flex"
                                flex={1}
                                justifyContent="center"
                              >
                                <ECBox
                                  display="flex"
                                  flex={1}
                                  width={0}
                                  visibility={
                                    isBeforeFieldAvailable ||
                                    beforeNestedFields?.[nestedBodyKey]
                                      ? 'visible'
                                      : 'hidden'
                                  }
                                  marginLeft={2}
                                >
                                  <ECTypography
                                    variant="subtitle2"
                                    maxWidth="100%"
                                    color={theme => theme.palette.error.dark}
                                    sx={{
                                      textDecoration: 'line-through',
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    {isFieldUpdated(
                                      beforeNestedFields?.[nestedBodyKey],
                                      handlePositionShiftedArrayData(
                                        nestedBodyKey,
                                        beforeNestedFields,
                                        bodyNestedFields,
                                      )?.canUseParsedDistricts
                                        ? handlePositionShiftedArrayData(
                                            nestedBodyKey,
                                            beforeNestedFields,
                                            bodyNestedFields,
                                          )?.newParsedDistrict
                                        : bodyNestedFields?.[nestedBodyKey],
                                    ) &&
                                      parseValue(
                                        beforeNestedFields?.[nestedBodyKey],
                                        nestedBodyKey,
                                        handlePositionShiftedArrayData(
                                          nestedBodyKey,
                                          beforeNestedFields,
                                          bodyNestedFields,
                                        )?.canUseParsedDistricts
                                          ? handlePositionShiftedArrayData(
                                              nestedBodyKey,
                                              beforeNestedFields,
                                              bodyNestedFields,
                                            )?.newParsedDistrict
                                          : bodyNestedFields?.[nestedBodyKey],
                                      )}
                                  </ECTypography>
                                </ECBox>

                                <ECBox
                                  display="flex"
                                  visibility={
                                    bodyTradeProblems ? 'visible' : 'hidden'
                                  }
                                >
                                  <ArrowForward
                                    sx={theme => ({
                                      color:
                                        nestedIndex === arrowAppearIndex
                                          ? theme.palette.action.active
                                          : 'transparent',
                                      mx: 1,
                                    })}
                                    fontSize="small"
                                  />
                                </ECBox>

                                <ECBox
                                  display="flex"
                                  flex={1}
                                  width={0}
                                  justifyContent={'flex-start'}
                                >
                                  <ECTypography
                                    variant="subtitle2"
                                    maxWidth="100%"
                                    color={theme =>
                                      nestedBodyKey
                                        ?.toLowerCase()
                                        .includes('removed')
                                        ? theme.palette.error.dark
                                        : theme.palette.success.dark
                                    }
                                    sx={
                                      nestedBodyKey === 'problems'
                                        ? { whiteSpace: 'pre-wrap' }
                                        : undefined
                                    }
                                  >
                                    {parseValue(
                                      handlePositionShiftedArrayData(
                                        nestedBodyKey,
                                        beforeNestedFields,
                                        bodyNestedFields,
                                      )?.canUseParsedDistricts
                                        ? handlePositionShiftedArrayData(
                                            nestedBodyKey,
                                            beforeNestedFields,
                                            bodyNestedFields,
                                          )?.newParsedDistrict
                                        : bodyNestedFields?.[nestedBodyKey],
                                      nestedBodyKey,
                                      beforeNestedFields?.[nestedBodyKey],
                                    )}
                                  </ECTypography>
                                </ECBox>
                              </ECBox>
                            </ECBox>
                          </ECBox>
                        )}
                      </>
                    );
                  },
                )}
              </Fragment>
            );
          },
        );
      }

      return (
        <>
          {isValidHistoryEntry(otherBeforeFields, bodyKey) && (
            <ECBox display="flex" width="100%">
              <ECBox display="flex" flex={1} width={0}>
                <ECTypography
                  variant="subtitle2"
                  minWidth="15vw"
                  width="15vw"
                  maxWidth="20vw"
                  color={theme => theme.palette.text.secondary}
                >
                  {camelCaseToWords(bodyKey)}
                  :&nbsp;
                </ECTypography>

                <ECBox display="flex" flex={1} justifyContent="center">
                  <ECBox
                    display="flex"
                    flex={1}
                    width={0}
                    visibility={
                      isBeforeFieldAvailable || otherBeforeFields?.[bodyKey]
                        ? 'visible'
                        : 'hidden'
                    }
                    marginLeft={2}
                  >
                    <ECTypography
                      variant="subtitle2"
                      maxWidth="100%"
                      color={theme => theme.palette.error.dark}
                      sx={{
                        textDecoration: 'line-through',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {isFieldUpdated(
                        otherBeforeFields[bodyKey],
                        handlePositionShiftedArrayData(
                          bodyKey,
                          otherBeforeFields,
                          otherBodyFields,
                        )?.canUseParsedDistricts
                          ? handlePositionShiftedArrayData(
                              bodyKey,
                              otherBeforeFields,
                              otherBodyFields,
                            )?.newParsedDistrict
                          : otherBodyFields?.[bodyKey],
                      ) &&
                        parseValue(
                          otherBeforeFields?.[bodyKey],
                          bodyKey,
                          handlePositionShiftedArrayData(
                            bodyKey,
                            otherBeforeFields,
                            otherBodyFields,
                          )?.canUseParsedDistricts
                            ? handlePositionShiftedArrayData(
                                bodyKey,
                                otherBeforeFields,
                                otherBodyFields,
                              )?.newParsedDistrict
                            : otherBodyFields?.[bodyKey],
                        )}
                    </ECTypography>
                  </ECBox>

                  <ECBox
                    display="flex"
                    visibility={isBodyFieldAvailable ? 'visible' : 'hidden'}
                  >
                    <ArrowForward
                      sx={theme => ({
                        color:
                          index === arrowAppearIndex
                            ? theme.palette.action.active
                            : 'transparent',
                        mx: 1,
                      })}
                      fontSize="small"
                    />
                  </ECBox>

                  <ECBox
                    display="flex"
                    flex={1}
                    width={0}
                    justifyContent={'flex-start'}
                  >
                    <ECTypography
                      variant="subtitle2"
                      maxWidth="100%"
                      color={theme =>
                        bodyKey?.toLowerCase().includes('removed')
                          ? theme.palette.error.dark
                          : theme.palette.success.dark
                      }
                      sx={
                        bodyKey === 'districts' || bodyKey === 'roles'
                          ? { whiteSpace: 'pre-wrap' }
                          : undefined
                      }
                    >
                      {parseValue(
                        handlePositionShiftedArrayData(
                          bodyKey,
                          otherBeforeFields,
                          otherBodyFields,
                        )?.canUseParsedDistricts
                          ? handlePositionShiftedArrayData(
                              bodyKey,
                              otherBeforeFields,
                              otherBodyFields,
                            )?.newParsedDistrict
                          : otherBodyFields?.[bodyKey],
                        bodyKey,
                      )}
                    </ECTypography>
                  </ECBox>
                </ECBox>
              </ECBox>
            </ECBox>
          )}
        </>
      );
    });
  }, []);

  return <>{renderActivityWithArrayField}</>;
};
