import React from 'react';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import moment from 'moment';
import { WorkOrderCard } from 'types/WorkOrders';
import { ECBox, ECTypography } from 'app/components';
import { formatDateOnlyForTable } from 'utils/strings/formatDate';
import SortableWorkOrderCardItem from './SortableWorkOrderCardItem';
import { DispatchWorkOrderCard } from './DispatchWorkOrdersCard';
import { useDroppable } from '@dnd-kit/core';
import { WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';

interface DateWorkordersSectionProps {
  technicianName: string;
  technicianId: number;
  date: {
    date: string;
    workorders: WorkOrderCard[];
  };
  dragPanelId?: string;
  setPreventDND?: (value: boolean) => void;
  preventDND?: boolean;
}

export const workflowStatusToDisableDnd = [
  WorkflowStatusGroupName.Cancelled,
  WorkflowStatusGroupName.Complete,
  WorkflowStatusGroupName.SoftCompleted,
  WorkflowStatusGroupName.Unassigned,
]

const DateWorkordersSection: React.FC<DateWorkordersSectionProps> = ({
  technicianId,
  technicianName,
  date,
  dragPanelId,
  setPreventDND,
  preventDND,
}) => {
  const objectId = `${technicianId}|${technicianName}|${date.date}`;
  const { setNodeRef } = useDroppable({ id: objectId });

  return (
    <SortableContext
      id={objectId}
      items={date.workorders}
      strategy={verticalListSortingStrategy}
      disabled={preventDND}
    >
      <ECBox
        pr={1}
        ref={setNodeRef}
        sx={{
          position: 'relative',
        }}
      >
        <ECTypography variant="caption" sx={{ opacity: '0.5' }}>
          {moment(date.date).isSame(new Date(), 'day')
            ? 'Today'
            : formatDateOnlyForTable(date.date)}
        </ECTypography>
        {dragPanelId === objectId && (
          <ECBox
            key="dragPanelItem"
            display="flex"
            borderColor="#e1f5fe"
            boxShadow={2}
            p={1}
            borderRadius="0px 4px"
            bgcolor={theme => theme.palette.common.white}
            mb={1}
            maxWidth="auto"
            sx={{
              backgroundColor: '#0072ce15',
              height: '72px',
            }}
          >
            <ECBox display="flex" flexDirection="column" width="100%">
              <ECBox display="flex" alignItems="center"></ECBox>
            </ECBox>
          </ECBox>
        )}
        {date.workorders.length > 0 && (
          <SortableWorkOrderCardItem key={`${objectId}##`} id={`${objectId}##`}>
            <ECBox
              display="flex"
              sx={{
                backgroundColor: 'transparent',
                height: '5px',
              }}
            ></ECBox>
          </SortableWorkOrderCardItem>
        )}
        {date?.workorders?.map((workorder: WorkOrderCard) =>
          workorder && workorder?.id ? (
            <SortableWorkOrderCardItem
              key={workorder.id}
              id={workorder?.id?.toString()}
              disableDND={workflowStatusToDisableDnd.includes(workorder?.workflowStatusGroupName as WorkflowStatusGroupName)}
            >
              <DispatchWorkOrderCard
                workOrder={workorder}
                dragWOId={dragPanelId}
                setPreventDND={setPreventDND}
              />
            </SortableWorkOrderCardItem>
          ) : null,
        )}
      </ECBox>
    </SortableContext>
  );
};

export default DateWorkordersSection;
