import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { hasPermission } from 'utils/permissions';
import { P } from 'types/Permission';

export const useCustomerUser = () => {
  const user = useSelector((state: RootState) => state.user);
  return (
    user &&
    !hasPermission({
      userPermissions: user?.permissions.map(p => p.action),
      scopes: [P.SPMenu],
    })
  );
};
