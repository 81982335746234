import { PictureAsPdf } from '@mui/icons-material';
import { ECButton } from 'app/components';
import { debounce } from 'lodash';
import { P } from 'types/Permission';
import { generatePODetailsHTMLContent } from './generate-po-html-content';
import { useGetSupplierByIdQuery } from 'services/supplierApi';

const PRINT_DEBOUNCE_TIME = 1500;

export const PODownloadPDF = ({ purchaseOrder }: { purchaseOrder: any }) => {
  const { data: supplier } = useGetSupplierByIdQuery(
    Number(purchaseOrder?.supplier?.id),
    {
      skip: !purchaseOrder?.supplier?.id,
    },
  );

  const handlePrintActionClick = () => {
    if (!purchaseOrder || !supplier) return;
    const htmlContent = generatePODetailsHTMLContent(purchaseOrder, supplier);
    const newTab = window.open();
    if (newTab) {
      newTab.document.write(htmlContent);
      const debouncedPrint = debounce(() => {
        newTab.print();
      }, PRINT_DEBOUNCE_TIME);
      debouncedPrint();
    }
  };

  return (
    <ECButton
      endIcon={<PictureAsPdf />}
      variant="outlined"
      onClick={handlePrintActionClick}
      scopes={[P.EditPurchaseOrder]}
    >
      Download pdf
    </ECButton>
  );
};
