import {
  ECBox,
  ECButton,
  ECChip,
  ECFormControl,
  ECGrid,
  ECTypography,
} from 'app/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import * as _ from 'lodash';
import { ECBasicDateTimePicker } from 'app/components/ECDateTimePicker';
import moment from 'moment';
import {
  useUpdateWorkOrderEtaMutation,
  workOrdersApi,
} from 'services/workOrdersApi';
import { setSnackbar } from 'store/slice/page';

interface UpdateETAModalProps {
  selectedWO: any;
  onCancel: () => void;
  onClose: () => void;
  onChange?: (sp: any) => void;
  customAction?: () => void;
  isLoadingCustomAction?: boolean;
}

export const UpdateETAAction: React.FC<UpdateETAModalProps> = ({
  onClose,
  onCancel,
  selectedWO,
  customAction,
  isLoadingCustomAction,
}) => {
  const dispatch = useDispatch();
  const dateFormat = 'YYYY-MM-DD HH:mm';

  const defaultEtaDate = moment(selectedWO?.eta).format(dateFormat);

  const minDate = moment().format(dateFormat);

  const [modalEta, setModalEta] = useState<Date | string | undefined>(
    defaultEtaDate,
  );

  const [validationModalEta, setValidationModalEta] = useState('');

  const [
    doUpdateEta,
    {
      error: updateEtaError,
      isError: isUpdateEtaError,
      isLoading: isUpdateEtaLoading,
      isSuccess: isUpdateEtaSuccess,
      reset: resetUpdateEta,
    },
  ] = useUpdateWorkOrderEtaMutation();

  const handleSaveEta = (id: any) => () => {
    if (!modalEta) {
      setValidationModalEta('Please select a date and time');
      return;
    }

    if (new Date(modalEta) < new Date(minDate)) {
      setValidationModalEta('Please select a date and time in the future');
      return;
    }

    if (!validationModalEta) {
      doUpdateEta({ id: id, eta: modalEta ? new Date(modalEta) : modalEta });
    }
  };

  useEffect(() => {
    if (isUpdateEtaError && updateEtaError) {
      onClose();
      resetUpdateEta();
      dispatch(
        setSnackbar({
          severity: 'error',
          message: (updateEtaError as any)?.data?.message,
        }),
      );
    }
  }, [updateEtaError, isUpdateEtaError]);

  useEffect(() => {
    if (isUpdateEtaSuccess && !isUpdateEtaLoading) {
      customAction && customAction();
      dispatch(workOrdersApi.util.invalidateTags(['WorkOrderById']));
      resetUpdateEta();
      onClose();
      setModalEta(defaultEtaDate);
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Work Order was successfully updated.',
        }),
      );
    }
  }, [isUpdateEtaSuccess, isUpdateEtaLoading]);

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      sx={{
        width: '630px',
      }}
    >
      <ECBox
        sx={{
          padding: '2rem',
        }}
      >
        <ECTypography variant="h6" marginBottom="1rem">
          Confirm or Update ETA to{' '}
          <ECTypography component="span" variant="h6" sx={{ color: '#4CAF50' }}>
            Accept
          </ECTypography>{' '}
          a Work Order #{selectedWO?.id}
        </ECTypography>
        {!selectedWO?.id ? (
          <ECBox mb={1.5}>
            <ECCircularProgress size={20} />
          </ECBox>
        ) : (
          <ECFormControl sx={{ width: '100%', marginBottom: '1rem' }}>
            <ECGrid container spacing={1} key="updateETContainer">
              <ECGrid item xs={4}>
                <ECTypography
                  variant="body1"
                  sx={{ fontWeight: 500, fontSize: '16px' }}
                >
                  Original ETA:
                </ECTypography>
              </ECGrid>
              <ECGrid item xs={8}>
                <ECTypography
                  variant="body1"
                  sx={{ fontWeight: 400, fontSize: '16px' }}
                >
                  {selectedWO?.eta}
                </ECTypography>
              </ECGrid>
              <ECGrid item xs={4}>
                <ECTypography
                  variant="body1"
                  sx={{ fontWeight: 500, fontSize: '16px' }}
                >
                  Over Time Approved:
                </ECTypography>
              </ECGrid>
              <ECGrid item xs={8}>
                <ECTypography
                  variant="body1"
                  sx={{ fontWeight: 400, fontSize: '16px' }}
                >
                  {selectedWO?.overtimeApproved ? (
                    <ECChip
                      key="overtimeApprovedYes"
                      label="Yes"
                      sx={{
                        bgcolor: theme =>
                          theme.palette.success.outlinedHoverBackground,
                        color: theme => theme.palette.success.dark,
                      }}
                      variant="filled"
                    />
                  ) : (
                    <ECChip
                      key="overtimeApprovedYes"
                      label="No"
                      sx={{
                        bgcolor: theme =>
                          theme.palette.error.outlinedHoverBackground,
                        color: theme => theme.palette.error.dark,
                      }}
                      variant="filled"
                    />
                  )}
                </ECTypography>
              </ECGrid>
              {/* priority is not needed for now, we have to remove it from the UI to find the bad data.
              <ECGrid item xs={4}> 
                <ECTypography
                  variant="body1"
                  sx={{ fontWeight: 500, fontSize: '16px' }}
                >
                  Priority:
                </ECTypography>
              </ECGrid>
              <ECGrid item xs={8}>
                <ECTypography
                  variant="body1"
                  sx={{ fontWeight: 400, fontSize: '16px' }}
                >
                  <ECWorkflowPriorityBadge
                    priority={selectedWO?.priorityName as WorkflowPriority}
                  />
                </ECTypography>
              </ECGrid>*/}
              <ECGrid item xs={12}>
                <ECBasicDateTimePicker
                  fieldName={'newEta'}
                  helperText={validationModalEta}
                  error={!!validationModalEta}
                  sx={{ marginTop: '24px', width: '100%' }}
                  label={'New ETA'}
                  value={modalEta}
                  variant={'filled'}
                  readOnly={false}
                  disabled={false}
                  minDate={minDate}
                  onChange={dateTime => {
                    setModalEta(dateTime);
                    if (new Date(dateTime) < new Date(minDate)) {
                      setValidationModalEta(
                        'Please select a date and time in the future',
                      );
                    } else {
                      setValidationModalEta('');
                    }
                  }}
                />
              </ECGrid>
            </ECGrid>
          </ECFormControl>
        )}
      </ECBox>

      <ECBox
        display="flex"
        flexDirection="column"
        position={'sticky'}
        zIndex={99}
        bottom={0}
      >
        <ECBox display="flex" bgcolor="transparent">
          <ECGrid
            container
            spacing={2}
            sx={{
              flexDirection: 'row',
              justifyContent: 'right',
              paddingRight: '2rem',
            }}
          >
            <ECButton sx={{ height: '42px' }} onClick={onCancel}>
              Cancel
            </ECButton>
            <ECButton
              sx={{ height: '42px', minWidth: '64px', marginLeft: '16px' }}
              color="success"
              onClick={handleSaveEta(selectedWO?.id)}
              variant="contained"
            >
              {isUpdateEtaLoading ? <ECCircularProgress size={24} /> : 'Accept'}
            </ECButton>
          </ECGrid>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
