import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { useCallback, useEffect, useState } from 'react';
import { useUpdateServiceProvidersByTradeIdAndBranchIdMutation } from 'services/tradeApi';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { ECEasyFormFieldType } from 'app/components';

interface EditProps {
  row: any;
  onClose?: () => void;
}

const formConfigEdit = require('./fancy_form_config_edit.json');

export const Edit = ({ row, onClose }: EditProps) => {
  const [formFields, setFormFields] = useState(formConfigEdit.fields);
  const [isEmptyField, setIsEmptyField] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const spFormFieldIndex = formConfigEdit.fields.findIndex(
      field => field.fieldName === 'sp',
    );
    const tmpFormFields = [...formConfigEdit.fields];
    tmpFormFields[spFormFieldIndex].customParams = {
      tradeId: row.trade.id,
      branchId: row.branch.id,
    };
    setFormFields(tmpFormFields);
  }, [row]);

  const [
    doUpdateTradeAssignments,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateServiceProvidersByTradeIdAndBranchIdMutation();

  const handleChange = useCallback((output: ECEasyFormFieldType[]) => {
    const sp = output.find(field => field?.fieldName === 'sp')?.value;
    const emptySP =
      Array.isArray(sp) && sp.find(sp => sp.data === '' || sp.data === null);
    setIsEmptyField(!!emptySP);
  }, []);

  const useUpdate = useCallback(() => {
    const doUpdate = async data => {
      const emptySP = data.sp?.find(sp => sp.data === '');
      if (emptySP || isEmptyField) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('translation:pages.tradeAssignment.spRequiredForRank'),
          }),
        );
        return;
      }

      const newSPs = data.sp?.map(newSP => ({
        companyId: newSP.id,
        position: newSP.position,
        adminOnly: newSP.adminOnly,
      }));

      const filteredSPs = _.uniqBy(
        _.filter(newSPs, sp => !_.isNil(sp.companyId)),
        sp => sp.companyId,
      );

      doUpdateTradeAssignments({
        tradeId: row.trade.id,
        branchId: row.branch.id,
        spRank: filteredSPs,
      });
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset,
      },
    ];
  }, [
    row,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    reset,
    doUpdateTradeAssignments,
    isEmptyField,
  ]);

  return (
    <EditForm2
      row={row}
      formConfig={formConfigEdit.config}
      formFields={formFields}
      useUpdateMutation={useUpdate}
      onClose={onClose}
      isLoading={false}
      hideToolBar
      onlyEdit
      onChange={handleChange}
    />
  );
};
