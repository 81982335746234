import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECIconButton,
  ECRequirePermission,
} from 'app/components';
import { useCustomerUser } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import {
  proposalApi,
  useLazyGetProposalByIdQuery,
  useLazyGetProposalListQuery,
  useUpdateProposalStatusMutation,
} from 'services/proposalApi';
import { ForbiddenPage } from '../ForbiddenPage';
import _ from 'lodash';
import {
  WorkflowStatus,
  WorkflowStatusGroupName,
} from 'app/components/ECWorkflowStatusBadge';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { setFilterEndpoint, setSnackbar } from 'store/slice/page';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'index';
import { Popover } from '@mui/material';
import { ECUsersTableModal } from 'app/components/ECUsersTableModal';
import { Attachment, Close, Done, Edit } from '@mui/icons-material';
import { BulkEditDrawer } from './BulkEditDrawer';
import { P } from 'types/Permission';
import { bulkEditButtonStyle } from '../WorkOrdersPage';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { ECAttachmentsListPopover } from 'app/components/ECAttachmentsListPopover';
import ProposalActions from './ProposalActions';
import { PrposalActionEnum } from 'types/WorkflowStatus';

export function ProposalsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state?.user);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [row, setSelectedRow] = useState<any>(null);
  const [anchorElAttachment, setAnchorElAttachment] = useState<any>(null);
  const isAttachmentOpen = Boolean(anchorElAttachment);
  const [openActionPopover, setOpenActionPopover] = useState(false);
  const [actionPopoverData, setActionPopoverData] = useState<any>(null);
  const [anchorElPopover, setAnchorElPopover] = useState<any>(null);

  const [shouldTriggerLazyQuery, setShouldTriggerLazyQuery] = useState(false);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('proposal');
  const {
    data: categories,
    isFetching: isFetchingCategories,
    isSuccess: isSuccessCategories,
  } = useGetCategoryListQuery({});

  const [
    doUpdateStatus,
    { isLoading: isLoadingUpdateStatus, isSuccess: isSuccessUpdateAction },
  ] = useUpdateProposalStatusMutation();

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(
        proposalApi.util.invalidateTags(['Proposal', 'PendingProposals']),
      );

      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Proposal updated successfully.',
        }),
      );
    }
  }, [isSuccessUpdateAction]);

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/proposal'));
  }, []);

  const isCustomer = useCustomerUser();

  const statusFilterOptions = useMemo(() => {
    const uniqueStatuses = _.uniqBy(workflowStatuses, 'name');
    const orderedStatuses = _.orderBy(uniqueStatuses, 'name');
    return (
      orderedStatuses.map(status => ({
        id: status?.id,
        label: status.name,
        fieldName: status.name,
        isInitialSelected:
          status.name === WorkflowStatus.ProposalPendingApproval,
      })) || []
    );
  }, [workflowStatuses]);

  const categoryFilterOptions = useMemo(() => {
    return (
      _.orderBy(categories?.data, 'name')
        ?.filter(category => {
          return category?.module?.name !== 'Inventory';
        })
        ?.map(category => ({
          ...category,
          id: category?.id,
          label: category?.name,
          fieldName: category?.id?.toString(),
          isInitialSelected: true,
        })) || []
    );
  }, [categories]);

  useEffect(() => {
    setShouldTriggerLazyQuery(
      !isFetchingWorkflowStatuses &&
        !isFetchingCategories &&
        isSuccessWorkflowStatuses &&
        isSuccessCategories,
    );
  }, [
    isFetchingWorkflowStatuses,
    isFetchingCategories,
    isSuccessWorkflowStatuses,
    isSuccessCategories,
  ]);

  const onClickViewUsersTable = useCallback((row, event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedRow(row);
  }, []);

  const onCloseModal = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedRow(null);
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState(false);
  const [clearAllSelectedRows, setClearAllSelectedRows] = useState(false);

  const handleClose = useCallback(() => {
    setIsBulkEditDrawerOpen(false);
    setSelectedRows([]);
    setDisableEditButton(true);
    setClearAllSelectedRows(true);
  }, []);

  const handleSelectedRows = useCallback(rows => {
    const shouldDisable = rows.length === 0;
    setClearAllSelectedRows(shouldDisable);
    setDisableEditButton(shouldDisable);
    setSelectedRows(rows);
  }, []);

  const renderAttachmentButton = (row: any) => {
    return (
      <ECBox display="flex" justifyContent="center" width="100%" py={0.5}>
        <ECIconButton
          squared
          withBorder
          withWhiteBackground
          sx={{ color: theme => theme.palette.primary.main }}
          key={`attachment-${row.id}`}
          onClick={e => {
            setAnchorElAttachment(e.currentTarget);
            setSelectedRow(row);
          }}
        >
          <Attachment />
        </ECIconButton>
      </ECBox>
    );
  };

  const handleCloseAttachmentPopover = () => {
    setAnchorElAttachment(null);
  };

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  const renderActionButton = (row: any) => {
    return (
      <ECBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        key={`${row?.id}-approve-reject`}
      >
        {[WorkflowStatus.ProposalPendingApproval].includes(
          row?.workflowStatus?.name,
        ) &&
          row?.workflowStatus?.canApproveOrReject && (
            <>
              {row?.workflowStatus?.actions?.find(
                a =>
                  a?.name?.toLowerCase() === 'approve' ||
                  a?.statusGroupName === WorkflowStatusGroupName.Approved,
              ) && (
                <ECIconButton
                  size="medium"
                  onClick={() => {
                    const statusParam = row?.workflowStatus?.actions?.find(
                      status =>
                        status?.statusGroupName ===
                          WorkflowStatusGroupName.Approved ||
                        status?.name?.toLowerCase() === 'approve',
                    );
                    doUpdateStatus({
                      id: Number(row?.id),
                      statusTo: statusParam?.statusTo,
                      actionId: statusParam?.actionId,
                    });
                  }}
                  type="success"
                  withBorder
                  sx={theme => ({ borderColor: theme.palette.success })}
                  isLoading={isLoadingUpdateStatus}
                  id={`approve-${row?.id}`}
                >
                  <Done sx={theme => ({ color: theme.palette.success })} />
                </ECIconButton>
              )}
              <ECBox sx={{ width: '5px' }} />
              {row?.workflowStatus?.actions?.find(
                a =>
                  a?.name?.toLowerCase() === 'reject' ||
                  a?.statusGroupName === WorkflowStatusGroupName.Rejected,
              ) && (
                <ECIconButton
                  size="medium"
                  onClick={e => {
                    setAnchorElPopover(e.currentTarget);
                    setActionPopoverData({
                      type: PrposalActionEnum.Reject,
                      id: row?.id,
                      actions: row?.workflowStatus?.actions,
                      requestId: row?.requestId,
                    });
                    setOpenActionPopover(prevState => !prevState);
                  }}
                  type="error"
                  withBorder
                  sx={theme => ({ borderColor: theme.palette.error })}
                  id={`reject-${row?.id}`}
                  isLoading={isLoadingUpdateStatus}
                >
                  <Close sx={theme => ({ color: theme.palette.error })} />
                </ECIconButton>
              )}
            </>
          )}
      </ECBox>
    );
  };

  return (
    <React.Fragment>
      <ECDynamicPageTemplate
        pageTitle={t('translation:panelDrawer.proposals')}
        useLazyGetListQuery={useLazyGetProposalListQuery}
        useLazyGetByIdQuery={useLazyGetProposalByIdQuery}
        useCreateMutation={{}}
        dateFilterFieldsOptions={[
          {
            id: 1,
            label: 'Created Date',
            fieldName: 'prpsl.createdAt',
          },
          {
            id: 2,
            label: 'Approved Date',
            fieldName: 'prpsl.approvedDate',
          },
        ]}
        renderCustomActionComponent={(row: any) => renderActionButton(row)}
        statusFilterOptions={statusFilterOptions}
        categoryFilterOptions={categoryFilterOptions}
        statusFilterAlias="wrkflwstts.name"
        showStatusFilter
        categoryFilterAlias="ctgry.id"
        showCategoryFilter
        showDateFilter
        showAssignedToMeFilter
        showAssetTypeFilter={!!user?.assignedAssetTypes?.length}
        dateRangeDefaultSelectedOption="All Time"
        withDrawer={false}
        showHierarchyFilter
        enableExport
        exportModuleName="proposal"
        onClickViewUsersTable={onClickViewUsersTable}
        selectedChipUsersTable={row?.id}
        shouldTriggerLazyQuery={shouldTriggerLazyQuery}
        showColumnVisibilityFilter
        isDraggableColumns
        multiSelectTable
        selectRowsMaxCount={50}
        renderCustomAttachmentComponent={renderAttachmentButton}
        customMultiSelectActionButton={() => (
          <ECRequirePermission scopes={[P.ChangeProposalStatus]}>
            <ECButton
              variant="outlined"
              startIcon={<Edit />}
              disabled={disableEditButton}
              onClick={() => {
                if (!disableEditButton) {
                  setIsBulkEditDrawerOpen(true);
                }
              }}
              sx={bulkEditButtonStyle}
            >
              {t('translation:dynamicForm.bulkEdit')}
            </ECButton>
          </ECRequirePermission>
        )}
        clearSelection={clearAllSelectedRows}
        multiSelectedRows={handleSelectedRows}
      />
      <BulkEditDrawer
        isOpen={isBulkEditDrawerOpen}
        onClose={handleClose}
        selectedRows={selectedRows}
      />
      <Popover
        id={row?.id}
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          minHeight: '300px',
          minWidth: '300px',
        }}
      >
        <ECBox
          minHeight={150}
          minWidth={750}
          sx={{
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(50% - 6px)',
            },
          }}
          p={2}
        >
          <ECUsersTableModal module="proposal" id={row?.id} />
        </ECBox>
      </Popover>

      <ECAttachmentsListPopover
        moduleName="proposal"
        row={row}
        isAttachmentOpen={isAttachmentOpen}
        anchorElAttachment={anchorElAttachment}
        onCloseAttachmentPopover={handleCloseAttachmentPopover}
      />
      {openActionPopover && (
        <ProposalActions
          open={openActionPopover}
          handleClose={() => {
            setOpenActionPopover(false);
            setAnchorElPopover(null);
            setActionPopoverData(null);
          }}
          type={actionPopoverData?.type}
          anchorEl={anchorElPopover}
          data={actionPopoverData}
        />
      )}
    </React.Fragment>
  );
}
