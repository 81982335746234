import {
  InputLabel,
  FormHelperText,
  Alert,
  InputAdornment,
  GridProps,
  InputBaseComponentProps,
} from '@mui/material';
import { isNumeric } from 'utils/is_numeric';
import {
  Chip,
  ECAlert,
  ECBox,
  ECButton,
  ECCustomAlert,
  ECEasyTable,
  ECFailureWarranty,
  ECLink,
  ECRating,
  ECRequirePermission,
  FailureWarrantyOutput,
} from '..';
import {
  ECEasyFormFieldType,
  ECFormControl,
  ECFormLabel,
  ECFormControlLabel,
  ECFormGroup,
  ECEasyFormConfigType,
} from '.';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { ECAssetProblemTroubleshootCreate } from '../ECAssetProblemTroubleshootCreate';
import { ECAssetTradeProblemCreate } from '../ECAssetTradeProblemCreate';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECAutocompleteAsset } from '../ECAutocompleteAsset';
import { ECCard } from '../ECCard';
import { ECCheckbox } from '../ECCheckbox';
import { ECChip, presetColors } from '../ECChip';
import { ChipSearchOptions, ECChipAutocomplete } from '../ECChipAutocomplete';
import { ECColorPicker } from '../ECColorPicker';
import ECCurrencyField from '../ECCurrencyField';
import { ECBasicDatePicker } from '../ECDatePicker';
import { ECBasicDateTimePicker } from '../ECDateTimePicker';
import { ECEasyTransferList } from '../ECEasyTransferList';
import { ECFilePicker, ImagePreview } from '../ECFilePicker';
import { ECGrid } from '../ECGrid';
import { ECHourETA } from '../ECHourETA';
import { ECPopper } from '../ECPopper';
import { ECRadioGroup, ECRadio } from '../ECRadio';
import { ECRankField, Rank } from '../ECRankField';
import { ECRichTextField } from '../ECRichTextField';
import { ECMenuItem, ECSelect } from '../ECSelect';
import { ECStack } from '../ECStack';
import { ECSwitch } from '../ECSwitch';
import { ECTagInput } from '../ECTagInput';
import { ECTextField } from '../ECTextField';
import ECPasswordCheckList from '../ECPasswordCheckList';
import { ECBasicTimePicker } from '../ECTimePicker';
import { ECTypography } from '../ECTypography';
import { ECVideoField } from '../ECVideoField';
import {
  CostTableData,
  CostTableItem,
  ECWorkflowCostTable,
} from '../ECWorkflowCostTable';
import { FieldTypes } from './FieldTypes';
import { useTranslation } from 'react-i18next';
import { ECEasyFormGridItem } from './ECEasyFormGridItem';
import _, { last } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { ECOperatingHours } from '../ECOperatingHours';
import { ECMarkupMaterial } from '../ECMarkupMaterial';
import { Lookup } from 'types/Lookup';
import { normalizeZipcode } from 'utils/strings/zipcode';
import { ECPhoneField } from '../ECPhoneField';
import { ECAssetTradeRegionCreate } from '../ECAssetTradeRegionCreate';
import { COUNTRY_NAMES } from 'utils/address';
import { ECRadioWarrantyStartDate } from '../ECRadioWarrantyStartDate';
import { formatPhoneNumber } from 'utils/strings/phone';
import { cleanedPhoneNumber } from 'utils/strings/phone';
import { formatClearedDecimal } from 'utils/strings/formatDecimal';
import { ECAutocompletePaginated } from '../ECAutocompletePaginated';
import { ECServiceFrequency } from '../ECServiceFrequency';
import { ECCheckboxAutocomplete } from '../ECCheckboxAutocomplete';
import { ECAddComment } from '../ECAddComment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from 'utils/clipboard';
import { ECWarrantyPeriodList } from '../ECWarrantyPeriodList';
import { ECZipCodeField } from '../ECZipCodeField';
import {
  CanadaStates,
  MexicoStates,
  PuertoRicoStates,
  USStates,
  JamaicaStates,
  AutraliaStates,
} from 'utils/states-list';
import { ECCountryField } from '../ECCountryField';
import { ECBulkActivityView } from '../ECBulkActivityView';
import { ECStorageLocations } from '../ECStorageLocations';
import { ECAllocatedInventory } from '../ECAllocatedInventory';
import { ECInventoryItems } from '../ECInventoryItems';
import moment from 'moment';
import 'moment-duration-format';
import { CompanyConfigurationFields } from 'types/Permission';
import { ECBannerFilePicker } from '../ECBannerFilePicker';
import { ECAddCommentBulk } from '../ECAddCommentBulk';
import { ECInputTextField } from '../ECTextField/InputTextField';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { PMSetupAlert } from 'app/pages/PreventiveMaintenancePage/components/SetupAlert';
import { Language, OpenInNew } from '@mui/icons-material';
import { ECPurchaseOrderItems } from '../ECPurchaseOrderItems';

interface ECEasyFormSectionGridItemProps extends GridProps {}

export const ECEasyFormSectionGridItem = ({
  children,
  ...props
}: ECEasyFormSectionGridItemProps) => {
  return (
    <ECGrid item xs={12} {...props}>
      {children}
    </ECGrid>
  );
};

interface CellProps {
  parentOutput?: ECEasyFormFieldType[];
  output: ECEasyFormFieldType[];
  field: ECEasyFormFieldType;
  index: number;
  fields: ECEasyFormFieldType[];
  costTable?: CostTableData;
  costTableItems?: CostTableItem[];
  pattern?: string;
  existingData?: any;
  isReadOnlyForm: boolean;
  config: ECEasyFormConfigType;
  isLoading?: boolean;
  onDuplicateChip?: () => void;
  onLoadMoreData?: (fieldName: string, newValue?: string) => void;
  handleCostTableChange: (any: any) => void;
  setParentOutput?: (any: any) => void;
  setOutput: (any: any) => void;
  onChangeValue: (
    field: string,
    value: any,
    tableRowId?: number,
    tableRowField?: string,
  ) => void;
  onChangeSearch?: (value: string) => void;
  searchOptionsState?: ChipSearchOptions;
  sx?: any;
}

export function ECFormCell({
  parentOutput,
  output,
  field,
  index,
  fields,
  costTable,
  costTableItems,
  existingData,
  pattern,
  isReadOnlyForm,
  config,
  isLoading,
  onDuplicateChip,
  handleCostTableChange,
  onLoadMoreData,
  setParentOutput,
  setOutput,
  onChangeValue,
  onChangeSearch,
  searchOptionsState,
  sx,
}: CellProps) {
  const { t } = useTranslation();

  const {
    type,
    label = '',
    description,
    color,
    confirmLabel,
    placeholder = '',
    value,
    options,
    optionValues,
    chips,
    fieldName,
    disabled,
    fileType,
    noMoreDataToFetch,
    readOnly = false,
    onChange = undefined,
    onBlur = undefined,
    onValidatePassword,
    clearFieldsOnChange,
    clearOptionsOnChange,
    fullWidth = false,
    variant = 'filled',
    subFields,
    groupColumns,
    alignItems,
    borderless,
    noSpacing,
    required,
    shouldShowActiveInactiveStatusLabel,
    switchActiveLabel,
    switchInactiveLabel,
    validationMessage,
    populateOtherFields,
    originalOptions,
    showNaIfEmpty,
    orientation,
    altEmptyText,
    queryParams,
    optionNameAttribute,
    getOtherFieldValueAsParam,
    useQuery,
    shouldUseOriginalQueryParams,
    renderCustomOption,
    renderCustomSelectedValue,
    obAlias,
    sbAlias,
    selectedChipUsersTable,
    onClickViewUsersTable,
    optionalSx,
    forceShowLabel = false,
    showRankReadOnlyAsChips,
    shouldShowRankText,
    shouldShowAdminOnly,
    queryFnLabel,
    customParams,
    fieldButtonProps,
    withIcon,
  } = field;

  const isSelectedCheckbox = useRef([]);

  const createFieldLabel = useMemo(() => {
    return required && label ? `${label} *` : label ? label : '';
  }, [required, readOnly, disabled, label]);

  const baseSx =
    pattern === 'standard'
      ? {
          width: '100%',
          '& .MuiFilledInput-root': {
            bgcolor: theme => `${theme.palette.common.white} !important`,
            '&:hover': {
              bgcolor: theme => theme.palette.common.white,
            },
            '&.Mui-focused': {
              bgcolor: theme => theme.palette.common.white,
            },
            '&.Mui-focused:after': {
              bgcolor: theme => theme.palette.common.white,
            },
          },
          ...sx,
        }
      : {
          width: '100%',
          ...sx,
        };

  if (showNaIfEmpty && !value) {
    return (
      <ECTextField
        key={index}
        id={fieldName}
        name={fieldName}
        sx={{ ...baseSx }}
        inputProps={{
          readOnly,
        }}
        InputProps={{
          startAdornment: field.startAdornment && (
            <InputAdornment position="start">
              {field.startAdornment}
            </InputAdornment>
          ),
        }}
        variant={variant}
        label={createFieldLabel}
        placeholder={placeholder}
        value={altEmptyText || 'N/A'}
        disabled={disabled}
        InputLabelProps={altEmptyText || 'N/A' ? { shrink: true } : {}}
      />
    );
  }

  if (type === FieldTypes.Text || type === FieldTypes.MultiLineText) {
    const LinkyTextField: React.FC<InputBaseComponentProps> = ({
      value,
      ...props
    }) => (
      <ECTypography
        onClick={() => {
          window.open(`mailto:${value}`);
        }}
        style={{
          display: 'block',
          color: value ? '#1976d2' : '#999',
          cursor: 'pointer',
          width: '100%',
        }}
        {...props}
      >
        {value || ''}
      </ECTypography>
    );

    return (
      <ECTextField
        key={index}
        id={fieldName}
        name={fieldName}
        sx={{ ...baseSx, ...field.optionalSx }}
        inputProps={{
          maxLength: field.maxLength ? field.maxLength : undefined,
          readOnly,
        }}
        multiline={type === FieldTypes.MultiLineText}
        InputProps={{
          startAdornment: field.startAdornment && (
            <InputAdornment position="start">
              {field.startAdornment}
            </InputAdornment>
          ),
          endAdornment:
            (field.showCopyToClipboard && (
              <InputAdornment position="end">
                <ECButton
                  onClick={() => copyToClipboard(String(value || ''))}
                  size="small"
                >
                  <ContentCopyIcon />
                </ECButton>
              </InputAdornment>
            )) ||
            (field.showErrorIcon && <ErrorOutlineIcon color="error" />),
          inputComponent:
            field?.linkifyEmail && value?.trim() && readOnly
              ? LinkyTextField
              : undefined,
        }}
        error={!field.isValid && !!validationMessage}
        helperText={validationMessage ? validationMessage : field.helperText}
        variant={variant}
        label={createFieldLabel}
        placeholder={placeholder}
        value={
          optionValues
            ? options?.[
                optionValues?.findIndex(optionValue => optionValue === value) ||
                  0
              ]?.label ??
              options?.[
                optionValues?.findIndex(optionValue => optionValue === value) ||
                  0
              ]
            : value || ''
        }
        disabled={disabled}
        InputLabelProps={value ? { shrink: true } : {}}
        onBlur={() => {
          onBlur?.(value, field);
        }}
        onChange={event => {
          const currentOutput = _.cloneDeep(output);
          let v: number | string = event.target.value;
          if (field.isNumeric || field.onlyNumbers) {
            v = formatClearedDecimal(v, !field.onlyNumbers);
          }
          onChangeValue(fieldName, v);
          field.onChange?.(currentOutput, v);
        }}
        mask={field.mask}
      />
    );
  } else if (type === FieldTypes.SiteTime) {
    const onSiteTimeValue = Boolean(value)
      ? moment.duration(value, 'hours').format('h [hrs] mm [mins]')
      : '--';
    return (
      <ECTextField
        key={index}
        id={fieldName}
        name={fieldName}
        sx={{ ...baseSx }}
        inputProps={{
          maxLength: field.maxLength ? field.maxLength : undefined,
          readOnly,
        }}
        error={!field.isValid && !!validationMessage}
        helperText={validationMessage ? validationMessage : field.helperText}
        variant={variant}
        label={createFieldLabel}
        placeholder={placeholder}
        value={onSiteTimeValue}
        disabled={disabled}
        InputLabelProps={value ? { shrink: true } : {}}
        onBlur={() => {
          onBlur?.(value, field);
        }}
        mask={field.mask}
      />
    );
  } else if (type === FieldTypes.Phone) {
    return (
      <ECPhoneField
        key={index}
        id={fieldName}
        name={fieldName}
        sx={{ ...baseSx }}
        error={field.isValid === false}
        validationMessage={validationMessage}
        variant={variant}
        label={createFieldLabel}
        value={value as string}
        disabled={disabled}
        readOnly={readOnly}
        onChange={event => {
          const currentOutput = _.cloneDeep(output);
          const clearedValue = cleanedPhoneNumber(event.target.value);
          onChangeValue(fieldName, clearedValue);
          field.onChange?.(currentOutput, clearedValue);
        }}
      />
    );
  } else if (type === FieldTypes.LinkPhone) {
    const phoneNumber = formatPhoneNumber(value as string);
    const telLink = `tel:${phoneNumber}`;

    return (
      <ECBox display="flex" flexDirection="column" key={index}>
        <ECTypography variant="caption" color="text.secondary">
          {label}
        </ECTypography>
        <ECLink onClick={() => window.open(telLink, '_blank')} id="phone">
          {phoneNumber}
        </ECLink>
      </ECBox>
    );
  } else if (type === FieldTypes.Link) {
    if (withIcon) {
      return (
        <ECFormControl
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            ...optionalSx,
          }}
          key={index}
        >
          <ECLink
            id="hyperlink"
            target="_blank"
            href={
              value
                ? value.startsWith('http://') || value.startsWith('https://')
                  ? value
                  : `http://${value}`
                : ''
            }
          >
            <Language sx={{ marginRight: 1 }} /> WEBSITE
          </ECLink>
        </ECFormControl>
      );
    }

    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECBox
          borderBottom={1}
          borderColor={theme => theme.palette.other.divider}
          display="flex"
          flexDirection="column"
          mb={2}
          key={index}
        >
          <ECTypography variant="body1" color="text.secondary">
            {label}
          </ECTypography>
          <ECLink target="_blank" id="link" href={value as string}>
            <ECBox display="flex" justifyContent="space-between">
              {value as string}
              {!!value && (
                <ECBox display="flex" justifyContent="flex-end">
                  <OpenInNew />
                </ECBox>
              )}
            </ECBox>
          </ECLink>
        </ECBox>
      </ECFormControl>
    );
  } else if (type === FieldTypes.Address) {
    if (readOnly && value) {
      return (
        <ECBox display="flex" flexDirection="column" key={index}>
          <ECTypography variant="caption" color="text.secondary">
            {label}
          </ECTypography>
          <ECLink id="address" href={`http://maps.google.com/?q=${value}`}>
            {value as string}
          </ECLink>
        </ECBox>
      );
    }

    return (
      <ECTextField
        key={index}
        id={fieldName}
        name={fieldName}
        sx={{ ...baseSx }}
        inputProps={{
          maxLength: field.maxLength ? field.maxLength : undefined,
          readOnly,
        }}
        error={field.isValid === false}
        helperText={field.validationMessage ? field.validationMessage : null}
        variant={variant}
        label={createFieldLabel}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        InputLabelProps={value ? { shrink: true } : {}}
        onChange={event => {
          let v: number | string = event.target.value;
          if (v.length > 0 && field.isNumeric && isNumeric(v)) {
            v = Number(v);
          }
          onChangeValue(fieldName, v);
        }}
      />
    );
  }
  if (type === FieldTypes.AddressAutocomplete) {
    const parentFieldName = fieldName?.includes('.')
      ? fieldName?.split('.')?.[0] + '.'
      : '';

    let currentSelectedCountry;

    (parentOutput || output)?.forEach(item => {
      if (item.type === FieldTypes.Group) {
        item?.subFields?.forEach(subField => {
          if (subField.fieldName === `${parentFieldName}countryCode`) {
            currentSelectedCountry = subField.value;
          }
        });
      }
    });

    const otherAddressFields = [
      `${parentFieldName}zipCodePostalCode`,
      `${parentFieldName}cityName`,
      `${parentFieldName}stateProvinceCode`,
      `${parentFieldName}countryCode`,
    ];

    const setRequiredAddressFields = (line1: string) => {
      let isJamaicanAddress = false;
      const currentParentOutput = [...(parentOutput || output)];
      currentParentOutput?.forEach(item => {
        if (item.fieldName in otherAddressFields) {
          item.required = !!line1;
        }
        if (item.type === FieldTypes.Group) {
          item?.subFields?.forEach(subField => {
            if (otherAddressFields.includes(subField.fieldName)) {
              subField.required = !!line1;
            }

            if (subField.fieldName === `${parentFieldName}countryCode`) {
              isJamaicanAddress = subField.value === 'JM';
            }
          });
        }
      });

      currentParentOutput?.forEach(item => {
        if (item.fieldName === `${parentFieldName}zipCodePostalCode`) {
          item.required = !isJamaicanAddress && !!line1;
        }
        if (item.type === FieldTypes.Group) {
          item?.subFields?.forEach(subField => {
            if (subField.fieldName === `${parentFieldName}zipCodePostalCode`) {
              subField.required = !isJamaicanAddress && !!line1;
            }
          });
        }
      });

      return currentParentOutput;
    };

    setRequiredAddressFields(value);

    return (
      <PlacesAutocomplete
        key={index}
        value={value}
        searchOptions={{
          componentRestrictions: {
            country: currentSelectedCountry?.value || currentSelectedCountry,
          },
        }}
        onChange={address => {
          const eventValue = address;
          const currentOutput = [...output];

          if (clearFieldsOnChange) {
            currentOutput.forEach(item => {
              if (clearFieldsOnChange.includes(item.fieldName)) {
                item.value = '';
              }

              if (item.type === FieldTypes.Group) {
                item?.subFields?.forEach(subField => {
                  if (clearFieldsOnChange.includes(subField.fieldName)) {
                    subField.value = '';
                  }
                });
              }
            });
            setOutput(currentOutput);
          }

          if (eventValue.length > 0 && field.isNumeric) {
            const isNumber = isNumeric(eventValue);
            if (isNumber) {
              currentOutput[index].value = Number(eventValue);
              currentOutput[index].validationMessage = undefined;
              setOutput(currentOutput);
              onChangeValue(fieldName, eventValue);
            } else {
              setOutput(currentOutput);
              onChangeValue(fieldName, eventValue);
            }
          } else {
            currentOutput[index].value = eventValue;
            currentOutput[index].validationMessage = undefined;
            setOutput(currentOutput);
            onChangeValue(fieldName, eventValue);
          }

          const currentParentOutput = setRequiredAddressFields(address);

          setParentOutput?.(currentParentOutput);
        }}
        onSelect={async address => {
          const result = await geocodeByAddress(address);
          const addressComponents = result[0]['address_components'];
          const addressObject = {};
          let countryCode = '';
          addressComponents.forEach(component => {
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number': {
                addressObject[`${parentFieldName}line1`] =
                  `${component.long_name}`;
                break;
              }
              case 'route': {
                const line1 = addressObject[`${parentFieldName}line1`];
                addressObject[`${parentFieldName}line1`] = `${
                  line1 ? line1 + ' ' : ''
                }${component.long_name}`;
                break;
              }
              case 'postal_code': {
                addressObject[`${parentFieldName}zipCodePostalCode`] =
                  `${normalizeZipcode(component.long_name)}`;
                break;
              }
              case 'locality':
                addressObject[`${parentFieldName}cityName`] =
                  component.long_name;
                break;

              case 'administrative_area_level_1': {
                addressObject[`${parentFieldName}stateProvinceCode`] =
                  component.short_name;
                break;
              }
              case 'country':
                addressObject[`${parentFieldName}country`] =
                  component.long_name;
                addressObject[`${parentFieldName}countryCode`] =
                  component.short_name;

                countryCode = component.short_name;
                break;
            }
          });

          if (!addressObject[`${parentFieldName}line1`]?.length) {
            addressObject[`${parentFieldName}line1`] = address;
          }

          const currentParentOutput = [...(parentOutput || output)];
          if (clearFieldsOnChange) {
            currentParentOutput.forEach(item => {
              if (clearFieldsOnChange.includes((item as any).fieldName)) {
                (item as any).value = '';
              }
              if (item.type === FieldTypes.Group) {
                item?.subFields?.forEach(subField => {
                  if (
                    clearFieldsOnChange.includes((subField as any).fieldName)
                  ) {
                    (subField as any).value = '';
                  }
                });
              }
            });
            setParentOutput?.(currentParentOutput);
          }
          if (clearOptionsOnChange) {
            currentParentOutput.forEach(item => {
              if (clearOptionsOnChange.includes(item.fieldName)) {
                item.options = [];
                item.optionValues = [];
              }
              if (item.type === FieldTypes.Group) {
                item?.subFields?.forEach(subField => {
                  if (clearOptionsOnChange.includes(subField.fieldName)) {
                    subField.options = [];
                    subField.optionValues = [];
                  }
                });
              }
            });
            setParentOutput?.(currentParentOutput);
          }

          let isJamaicanAddress = false;
          currentParentOutput.forEach(item => {
            if (item.fieldName in addressObject) {
              item.value = addressObject[item.fieldName];
              item.validationMessage = undefined;
            }
            if (item.type === FieldTypes.Group) {
              item?.subFields?.forEach(subField => {
                if (subField.fieldName in addressObject) {
                  subField.value = addressObject[subField.fieldName];
                  subField.validationMessage = undefined;
                }
                if (subField.type === FieldTypes.Group) {
                  subField?.subFields?.forEach(subSubField => {
                    if (subSubField.fieldName in addressObject) {
                      subSubField.value = addressObject[subSubField.fieldName];
                      subSubField.validationMessage = undefined;
                    }
                  });
                }

                isJamaicanAddress = countryCode === 'JM';
              });
            }
          });

          currentParentOutput.forEach(item => {
            if (item.fieldName === `${parentFieldName}zipCodePostalCode`) {
              item.required = !isJamaicanAddress;
            }
            if (item.type === FieldTypes.Group) {
              item?.subFields?.forEach(subField => {
                if (
                  subField.fieldName === `${parentFieldName}zipCodePostalCode`
                ) {
                  subField.required = !isJamaicanAddress;
                }
              });
            }
          });

          setParentOutput?.(currentParentOutput);
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <ECTextField
              {...getInputProps({
                id: fieldName,
                name: fieldName,
                label: createFieldLabel,
                placeholder: placeholder || 'Search Places ...',
                disabled,
                autoComplete: false,
              })}
              sx={{ ...baseSx }}
              inputProps={{
                maxLength: field.maxLength ? field.maxLength : undefined,
                readOnly,
              }}
              error={field.isValid === false && !!validationMessage}
              helperText={
                field.validationMessage ? field.validationMessage : null
              }
              autoComplete={'off'}
              InputLabelProps={value ? { shrink: true } : {}}
              variant={variant}
            />
            <ECCard
              key={`${fieldName}AddressList`}
              sx={theme => ({
                position: 'absolute',
                backgroundColor: theme.palette.background.paper,
                zIndex: '1001',
              })}
            >
              {suggestions
                ?.filter(suggestion =>
                  COUNTRY_NAMES.includes(
                    last((suggestion.terms as any[]) || [])?.value,
                  ),
                )
                .map(suggestion => {
                  return (
                    <ECMenuItem
                      key={suggestion}
                      {...getSuggestionItemProps(suggestion, {})}
                    >
                      <ECTypography variant="inherit">
                        {suggestion.description}
                      </ECTypography>
                    </ECMenuItem>
                  );
                })}
            </ECCard>
          </>
        )}
      </PlacesAutocomplete>
    );
  } else if (type === FieldTypes.ZipCode) {
    let countryCode;
    (parentOutput || output).find(f => {
      if (f.fieldName === field.countryCodeFromField) {
        countryCode = (f.value as any)?.value || f.value;
      }

      if (f.type === FieldTypes.Group) {
        const countryCodeField = f.subFields?.find(
          sf => sf.fieldName === field.countryCodeFromField,
        );

        if (countryCodeField) {
          countryCode =
            (countryCodeField.value as any)?.value || countryCodeField.value;
        }
      }
    });

    return (
      <ECZipCodeField
        key={index}
        countryCode={countryCode}
        id={fieldName}
        name={fieldName}
        sx={{ ...baseSx }}
        error={field.isValid === false && !!validationMessage}
        validationMessage={validationMessage}
        variant={variant}
        label={createFieldLabel}
        value={value as string}
        disabled={disabled}
        readOnly={readOnly}
        onChange={event => {
          const currentOutput = _.cloneDeep(output);
          const clearedValue = event.target.value;
          onChangeValue(fieldName, clearedValue);
          field.onChange?.(currentOutput, clearedValue);
        }}
      />
    );
  } else if (type === FieldTypes.Country) {
    return (
      <ECCountryField
        key={index}
        id={fieldName}
        name={fieldName}
        sx={{ ...baseSx }}
        style={{ marginBottom: 0 }}
        error={field.isValid === false && !!validationMessage}
        validationMessage={validationMessage}
        variant={variant}
        placeholder={placeholder}
        label={createFieldLabel}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        onChange={newValue => {
          const currentOutput = _.cloneDeep(parentOutput || output);
          const clearedValue = newValue;
          if (clearFieldsOnChange) {
            currentOutput.forEach(item => {
              if (clearFieldsOnChange.includes(item.fieldName)) {
                item.value = '';
              }

              if (item.type === FieldTypes.Group) {
                item?.subFields?.forEach(subField => {
                  // check for nested group field
                  if (subField.type === FieldTypes.Group) {
                    subField?.subFields?.forEach(subSubField => {
                      if (clearFieldsOnChange.includes(subSubField.fieldName)) {
                        subSubField.value = '';
                      }
                    });
                  }

                  if (clearFieldsOnChange.includes(subField.fieldName)) {
                    subField.value = '';
                  }
                });
              }
            });
          }

          if (clearOptionsOnChange) {
            currentOutput.forEach(item => {
              if (clearOptionsOnChange.includes(item.fieldName)) {
                item.options = [];
                item.optionValues = [];
              }
              if (item.type === FieldTypes.Group) {
                item?.subFields?.forEach(subField => {
                  // need to check for nested groups as well
                  if (subField.type === FieldTypes.Group) {
                    subField?.subFields?.forEach(subSubField => {
                      if (
                        clearOptionsOnChange.includes(subSubField.fieldName)
                      ) {
                        subSubField.options = [];
                        subSubField.optionValues = [];
                      }
                    });
                  }
                  if (clearOptionsOnChange.includes(subField.fieldName)) {
                    subField.options = [];
                    subField.optionValues = [];
                  }
                });
              }
            });
          }

          let parentFieldName = '';
          if (fieldName.split('.').length > 1) {
            parentFieldName = fieldName.split('.')[0] + '.';
          }

          const mapStates = {
            US: USStates,
            PR: PuertoRicoStates,
            CA: CanadaStates,
            MX: MexicoStates,
            JM: JamaicaStates,
            AU: AutraliaStates,
          };

          const states = mapStates[newValue?.value?.toUpperCase()];

          const stateProvinceCodeFieldName = `${parentFieldName}stateProvinceCode`;
          currentOutput.forEach(item => {
            if (item.fieldName === stateProvinceCodeFieldName) {
              item.options = _.map(states, 'value');
              item.optionValues = _.map(states, 'value');
            }
            if (item.type === FieldTypes.Group) {
              item?.subFields?.forEach(subField => {
                if (subField?.fieldName === stateProvinceCodeFieldName) {
                  subField.options = _.map(states, 'value');
                  subField.optionValues = _.map(states, 'value');
                }
              });
            }
          });

          setParentOutput?.(currentOutput);
          onChangeValue(fieldName, clearedValue);
          field.onChange?.(currentOutput, clearedValue);
        }}
      />
    );
  }
  if (type === FieldTypes.PasswordCheckList) {
    return (
      <ECPasswordCheckList
        key={index}
        variant={variant}
        fieldName={fieldName}
        sx={{ ...baseSx }}
        inputProps={{
          maxLength: field.maxLength ? field.maxLength : undefined,
          readOnly,
        }}
        error={field.isValid === false}
        helperText={field.validationMessage ? field.validationMessage : null}
        label={createFieldLabel}
        confirmLabel={confirmLabel}
        placeholder={placeholder}
        value={value}
        onChange={value => {
          onChangeValue(fieldName, value);
        }}
        onValidatePassword={onValidatePassword}
      />
    );
  }
  if (
    [FieldTypes.Number, FieldTypes.Email, FieldTypes.Password].includes(type)
  ) {
    return (
      <ECInputTextField
        type={type}
        key={index}
        variant={variant}
        fieldName={fieldName}
        sx={{ ...baseSx }}
        inputProps={{
          maxLength: field.maxLength ? field.maxLength : undefined,
          readOnly,
        }}
        onBlur={() => {
          onBlur?.(value, field);
        }}
        error={field.isValid === false}
        helperText={field.validationMessage ? field.validationMessage : null}
        label={createFieldLabel}
        placeholder={placeholder}
        value={value}
        onChange={value => {
          onChangeValue(fieldName, value);
        }}
      />
    );
  } else if (type === FieldTypes.Select && options) {
    return (
      <ECFormControl
        variant={variant}
        sx={{ width: '100%' }}
        key={index}
        error={field.isValid === false}
      >
        <ECBox display="flex" alignItems="center" gap={2}>
          <InputLabel id={label}>{createFieldLabel}</InputLabel>
          <ECSelect
            id={fieldName}
            name={fieldName}
            sx={{
              ...baseSx,
              flex: 1,
            }}
            hiddenLabel={!createFieldLabel}
            placeholder={placeholder}
            error={field.isValid === false}
            label={createFieldLabel}
            labelId={label}
            value={value ?? ''}
            disabled={disabled}
            readOnly={readOnly}
            renderValue={value => {
              const optionIndex =
                optionValues?.findIndex(optionValue => optionValue === value) ??
                -1;

              const optionLabel =
                options?.[optionIndex]?.label ?? options?.[optionIndex];
              const colorName = options?.[optionIndex]?.colorName;

              return (
                <ECBox
                  display="flex"
                  alignItems="center"
                  key={`${optionIndex}-${optionLabel}`}
                >
                  {colorName && (
                    <ECBox
                      height="30px"
                      width="3px"
                      bgcolor={
                        presetColors.find(color => color.title === colorName)
                          ?.color
                      }
                      mr={1}
                    />
                  )}
                  <ECTypography>{optionLabel}</ECTypography>
                </ECBox>
              );
            }}
            onChange={event => {
              const eventValue = event.target.value as string;
              const currentOutput = _.cloneDeep(output);
              currentOutput[index].value = eventValue;
              if (populateOtherFields && originalOptions) {
                const selectedIndex =
                  optionValues?.findIndex(
                    optionValue => optionValue === event.target.value,
                  ) || -1;
                const originalOptionValue = originalOptions[selectedIndex];
                populateOtherFields.forEach(otherField => {
                  onChangeValue(
                    otherField.fieldNameToChange,
                    _.get(originalOptionValue, otherField.optionValueToSend),
                  );
                });
              }
              onChangeValue(fieldName, event.target.value);
              //TODO: those onChange events must happen AFTER the output is properly updated,
              // as a sideEffect at parent level.
              field.onChange?.(currentOutput, eventValue);
            }}
          >
            {!field.required && !field?.creationMode && (
              <ECMenuItem value={-1}>
                <ECBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ECTypography>Unselected</ECTypography>
                </ECBox>
              </ECMenuItem>
            )}
            {options.length > 0 ? (
              options.map((option, index) => {
                return (
                  <ECMenuItem
                    key={index}
                    value={optionValues ? optionValues[index] : option}
                  >
                    <ECBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {option.colorName && (
                        <ECBox
                          height="30px"
                          width="3px"
                          bgcolor={
                            presetColors.find(
                              color => color.title === option.colorName,
                            )?.color
                          }
                          mr={1}
                        />
                      )}
                      <ECTypography>{option.label ?? option}</ECTypography>
                    </ECBox>
                  </ECMenuItem>
                );
              })
            ) : (
              <ECMenuItem disabled>No options available</ECMenuItem>
            )}
          </ECSelect>

          {field.endText && (
            <ECTypography maxWidth="50%">{field.endText}</ECTypography>
          )}
        </ECBox>

        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
        {field.helperText &&
          (!field.showHelperTextWhenOptionSelected ||
            field.showHelperTextWhenOptionSelected === value) && (
            <FormHelperText
              sx={theme => ({
                color: theme.palette.info.main,
                marginTop: '-0.5em',
              })}
            >
              {field.helperText}
            </FormHelperText>
          )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.TransferList) {
    return (
      <ECEasyTransferList
        key={index}
        isObjectArray={true}
        initialData={{
          checked: [],
          left: [], //notObjectArrays(data, role.permissions),
          right: [], //role.permissions,
        }}
        dataPath={'name'}
        onDataChange={data => {
          //transferListData = data;
        }}
        isLoading={false}
        leftTitle={undefined}
        rightTitle={undefined}
      />
    );
  } else if (type === FieldTypes.ChipAutocomplete && Array.isArray(options)) {
    const opt: Chip[] = field?.autoSearch
      ? searchOptionsState?.options || []
      : options;

    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECChipAutocomplete
          sx={baseSx}
          loading={field?.isLoading}
          options={opt}
          title={label}
          values={Array.isArray(value) ? (value as Chip[]) : []}
          getOptionLabel={option => option?.label || option?.text || option}
          placeholder={readOnly ? '' : placeholder}
          variant={variant}
          freeSolo={field?.freeSolo}
          onChange={newValue => {
            onChangeValue(fieldName, newValue);
          }}
          onChangeSearch={onChangeSearch}
          searchString={
            field?.autoSearch ? searchOptionsState?.searchString : undefined
          }
          readOnly={readOnly}
          errorMessage={field.validationMessage}
        />
      </ECFormControl>
    );
  } else if (type === FieldTypes.Autocomplete && options) {
    let countryCode: string = '';
    (parentOutput || output).find(f => {
      if (f.fieldName === field.countryCodeFromField) {
        countryCode = (f.value as any)?.value || f.value;
      }

      if (f.type === FieldTypes.Group) {
        const countryCodeField = f.subFields?.find(
          sf => sf.fieldName === field.countryCodeFromField,
        );

        if (countryCodeField) {
          countryCode =
            (countryCodeField.value as any)?.value || countryCodeField.value;
        }
      }
    });

    countryCode = countryCode?.toUpperCase();

    const mapStates = {
      US: USStates,
      PR: PuertoRicoStates,
      CA: CanadaStates,
      MX: MexicoStates,
      JM: JamaicaStates,
      AU: AutraliaStates,
    };

    const states = mapStates[countryCode];

    const optionsCountry = _.map(states, 'value');

    return (
      <ECFormControl
        sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}
        key={index}
      >
        <ECBox
          sx={
            field.actionButton
              ? {
                  width: 'auto',
                  flexGrow: 1,
                  paddingRight: '1rem',
                }
              : {
                  width: '100%',
                }
          }
        >
          <ECAutocompleteStyled
            id={fieldName}
            disablePortal
            sx={{ ...baseSx, ...optionalSx }}
            value={value ?? ''}
            disabled={disabled}
            options={options?.length ? options : optionsCountry}
            groupBy={option => option?.group}
            getOptionLabel={option =>
              option?.label ||
              option?.text ||
              option?.name ||
              (field?.freeSolo && field?.autoSelect && option) ||
              (typeof option === 'string' ? option : '') ||
              ''
            }
            renderOption={(props, option) => {
              const label =
                option?.label ||
                option?.text ||
                option?.name ||
                (typeof option === 'string' && option) ||
                '';
              return (
                <li
                  {...props}
                  key={option.key || option.id}
                  style={{
                    paddingLeft: option?.level
                      ? `${option.level * 40}px`
                      : undefined,
                  }}
                >
                  {label}
                </li>
              );
            }}
            readOnly={readOnly}
            freeSolo={field?.freeSolo}
            autoSelect={field?.autoSelect}
            openOnFocus={field?.openOnFocus}
            PopperComponent={props => <ECPopper {...props} />}
            renderInput={params => {
              field.onChangeAutoCompleteSearchText?.(
                params.inputProps.value ?? '',
              );
              return (
                <ECTextField
                  {...params}
                  id={fieldName}
                  name={fieldName}
                  placeholder={placeholder}
                  label={createFieldLabel || placeholder}
                  variant={variant}
                  error={field.isValid === false && !!validationMessage}
                  helperText={validationMessage}
                />
              );
            }}
            onChange={(event, newValue) => {
              onChangeValue(fieldName, newValue);
            }}
          />
        </ECBox>
        {field.helperText && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.info.main,
            })}
          >
            {field.helperText}
          </FormHelperText>
        )}
        {field.actionButton && (
          <ECButton
            variant={field.actionButton.variant || 'contained'}
            sx={{
              mb: '1rem',
              width: field.actionButton.width || 'auto',
            }}
            startIcon={field.actionButton.icon || null}
            onClick={field.actionButton.onClick}
          >
            {field.actionButton.label}
          </ECButton>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.CheckboxAutocomplete && options) {
    return (
      <React.Fragment key={index}>
        <ECCheckboxAutocomplete
          key={index}
          options={options}
          label={label}
          values={Array.isArray(value) ? value : []}
          getOptionLabel={option =>
            option?.label || option?.text || option?.name || ''
          }
          placeholder={readOnly ? '' : placeholder}
          onChange={newValue => {
            onChangeValue(fieldName, newValue);
          }}
          errorMessage={field.validationMessage}
          readOnly={readOnly}
        />
      </React.Fragment>
    );
  } else if (type === FieldTypes.AutocompleteAsset && options) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECAutocompleteAsset
          sx={baseSx}
          fieldName={fieldName}
          value={value}
          disabled={disabled}
          options={options}
          readOnly={readOnly}
          isReadOnlyForm={isReadOnlyForm}
          placeholder={placeholder}
          variant={variant}
          isLoading={isLoading}
          onLoadMoreData={onLoadMoreData}
          noMoreDataToFetch={noMoreDataToFetch}
          onChange={newValue => {
            if (populateOtherFields) {
              populateOtherFields.forEach(otherField => {
                onChangeValue(
                  otherField.fieldNameToChange,
                  _.get(newValue, otherField.optionValueToSend),
                );
              });
            }

            onChangeValue(fieldName, newValue);
          }}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (
    type === FieldTypes.SelectPaginated &&
    (readOnly || disabled || !!useQuery)
  ) {
    const otherFieldValueAsParam =
      getOtherFieldValueAsParam &&
      output.find(o => o.fieldName === getOtherFieldValueAsParam)?.value?.id;
    const finalQueryParams =
      getOtherFieldValueAsParam && otherFieldValueAsParam
        ? {
            ...queryParams,
            [getOtherFieldValueAsParam]: otherFieldValueAsParam,
          }
        : queryParams;
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECAutocompletePaginated
          sx={{ ...baseSx, ...optionalSx }}
          fieldName={fieldName}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          isReadOnlyForm={isReadOnlyForm}
          placeholder={placeholder}
          label={createFieldLabel}
          variant={variant}
          isLoading={isLoading}
          onLoadMoreData={onLoadMoreData}
          queryParams={finalQueryParams}
          shouldSkipQuery={
            getOtherFieldValueAsParam && !otherFieldValueAsParam ? true : false
          }
          useQuery={useQuery}
          shouldUseOriginalQueryParams={shouldUseOriginalQueryParams}
          renderCustomOption={renderCustomOption}
          renderCustomSelectedValue={renderCustomSelectedValue}
          obAlias={obAlias}
          sbAlias={sbAlias}
          noMoreDataToFetch={noMoreDataToFetch}
          optionNameAttribute={optionNameAttribute}
          onChange={newValue => {
            if (populateOtherFields) {
              populateOtherFields.forEach(otherField => {
                onChangeValue(
                  otherField.fieldNameToChange,
                  _.get(newValue, otherField.optionValueToSend),
                );
              });
            }
            if (clearFieldsOnChange) {
              const currentOutput = _.cloneDeep(output);
              currentOutput.forEach(item => {
                if (clearFieldsOnChange.includes((item as any).fieldName)) {
                  (item as any).value = [];
                }
                if (item.type === FieldTypes.Group) {
                  item?.subFields?.forEach(subField => {
                    if (
                      clearFieldsOnChange.includes((subField as any).fieldName)
                    ) {
                      (subField as any).value = [];
                    }
                  });
                }
              });
              setOutput(currentOutput);
            }
            onChangeValue(fieldName, newValue);
          }}
          multiple={field.multiple}
          error={field.isValid === false}
          validationMessage={field.validationMessage}
          helperText={field.helperText}
          filterOptionsIds={field.filterOptionsIds}
          filterOptionsFn={field.filterOptionsFn}
          extraSearchField={field.extraSearchField}
          extraSearchFieldGroup={field.extraSearchFieldGroup}
          extraSearchFieldsAlias={field.extraSearchFieldsAlias}
        />
      </ECFormControl>
    );
  } else if (type === FieldTypes.Radio && options) {
    return (
      <ECFormControl sx={{ ...baseSx, px: 1.5 }} key={index}>
        <ECFormLabel id={label}>{createFieldLabel}</ECFormLabel>
        <ECRadioGroup
          row
          id={fieldName}
          name={fieldName}
          value={value}
          onChange={event => {
            const eventValue = (event.target as HTMLInputElement).value;
            onChangeValue(fieldName, eventValue);
          }}
        >
          <ECBox
            display="flex"
            flexDirection={
              field.orientation === 'horizontal' ? 'row' : 'column'
            }
            width="100%"
          >
            {options.map(option => {
              const isSelected =
                option?.id === output[index].value ||
                option === output[index].value ||
                option === value ||
                option?.id === value;

              return (
                <ECFormControlLabel
                  control={
                    <ECRadio
                      disabled={field.disabled || option?.disabled}
                      checked={isSelected}
                    />
                  }
                  sx={{
                    my: 1,
                    '& .MuiFormControlLabel-label': { width: '100%' },
                  }}
                  value={option?.id || option}
                  label={
                    <ECBox
                      display="flex"
                      width="100%"
                      border={borderless ? 0 : isSelected ? 2 : 1}
                      borderRadius={1}
                      p={borderless ? 0 : 1}
                      px={borderless ? 0 : 2}
                      color={field.color ?? option?.color ?? 'black'}
                      borderColor={theme =>
                        isSelected
                          ? theme.palette.primary.main
                          : theme.palette.action.disabled
                      }
                    >
                      <ECTypography>{option?.label || option}</ECTypography>
                    </ECBox>
                  }
                />
              );
            })}
          </ECBox>
        </ECRadioGroup>
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.checkboxWithCustomOptions && options) {
    return (
      <ECFormControl sx={{ ...baseSx, px: 1.5 }} key={index}>
        <ECFormLabel id={label}>{createFieldLabel}</ECFormLabel>
        <ECBox
          display="flex"
          flexDirection={field.orientation === 'horizontal' ? 'row' : 'column'}
          width="100%"
          gap={2}
        >
          {options?.map((option, optionIndex) => {
            if (option?.visible === false) {
              return null;
            }

            if (!Array.isArray(value)) {
              onChangeValue(fieldName, [value]);
            }

            const isSelected =
              output[index].value?.includes(option?.id) ||
              output[index].value?.includes(option) ||
              value?.includes(option) ||
              value?.includes(option?.id);

            const allowComment =
              option?.id === 'addCommentsOption' &&
              (output[index].value.includes('flagsOption') ||
                output[index].value.includes('nteOption') ||
                output[index].value.includes('categoryOption'));

            // Starts

            return (
              <ECBox display="flex" flexDirection="row" width="100%" gap={1}>
                <ECBox>
                  <ECCheckbox
                    sx={{ alignSelf: 'flex-start' }}
                    id={option?.id}
                    onChange={e => {
                      if (
                        e.target.checked === false &&
                        value?.includes(option?.id)
                      ) {
                        const index = value?.indexOf(option.id);
                        value.splice(index, 1);
                        onChangeValue(fieldName, value);
                      } else {
                        const prevValue = Array.isArray(value)
                          ? value
                          : [value];
                        const itemToAdd = [...prevValue, option.id];

                        if (option?.id === 'statusOption' && e.target.checked) {
                          itemToAdd?.splice(0, itemToAdd?.length - 1);
                        }

                        if (
                          itemToAdd?.length > 1 &&
                          itemToAdd?.includes('statusOption') &&
                          Array.isArray(itemToAdd)
                        ) {
                          const index = itemToAdd.indexOf('statusOption');
                          itemToAdd?.splice(index, 1);
                        } else if (
                          Array.isArray(itemToAdd) &&
                          itemToAdd?.length > 1 &&
                          !itemToAdd?.includes('addCommentsOption')
                        ) {
                          itemToAdd?.splice(0, 1);
                        } else if (
                          Array.isArray(itemToAdd) &&
                          itemToAdd?.length > 2
                        ) {
                          itemToAdd?.splice(0, itemToAdd?.length - 1);
                        }

                        onChangeValue(fieldName, itemToAdd || []);
                      }
                    }}
                    checked={isSelected}
                  />
                </ECBox>
                <ECBox
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  mt={0.5}
                >
                  {option.fields?.map((field, fieldIndex) => {
                    const isLabelField =
                      field.type === 'section' && fieldIndex === 0;

                    if (field.visible === false) {
                      return null;
                    }

                    return (
                      <ECBox
                        sx={{
                          cursor: isLabelField ? 'pointer' : undefined,
                        }}
                        onClick={() => {
                          if (isLabelField) {
                            const prevValue = Array.isArray(value)
                              ? value
                              : [value];
                            const itemToAdd = [...prevValue, option.id];

                            if (option?.id === 'statusOption' && true) {
                              itemToAdd?.splice(0, itemToAdd?.length - 1);
                            }

                            if (
                              itemToAdd?.length > 1 &&
                              itemToAdd?.includes('statusOption') &&
                              Array.isArray(itemToAdd)
                            ) {
                              const index = itemToAdd.indexOf('statusOption');
                              itemToAdd?.splice(index, 1);
                            } else if (
                              Array.isArray(itemToAdd) &&
                              itemToAdd?.length > 1 &&
                              !itemToAdd?.includes('addCommentsOption')
                            ) {
                              itemToAdd?.splice(0, 1);
                            } else if (
                              Array.isArray(itemToAdd) &&
                              itemToAdd?.length > 2
                            ) {
                              itemToAdd?.splice(0, itemToAdd?.length - 1);
                            }

                            onChangeValue(fieldName, itemToAdd || []);
                          }
                        }}
                      >
                        <ECFormCell
                          sx={{
                            opacity:
                              isSelected || isLabelField || allowComment
                                ? 1
                                : 0.3,
                            pointerEvents:
                              isSelected ||
                              (isSelectedCheckbox.current as string[]).includes(
                                option.id,
                              )
                                ? undefined
                                : 'none',
                          }}
                          index={fieldIndex}
                          field={field}
                          output={option.fields}
                          fields={fields}
                          isReadOnlyForm={isReadOnlyForm}
                          config={field.config}
                          handleCostTableChange={() => {}}
                          setOutput={newOutput => {
                            setOutput(prevOutput => {
                              prevOutput[index].options[optionIndex].fields =
                                newOutput;
                              return prevOutput;
                            });
                          }}
                          existingData={existingData}
                          onChangeValue={(field, value) => {
                            const newOutput = [...output];
                            const fieldToChange =
                              newOutput?.[index]?.options?.[optionIndex]
                                ?.fields?.[fieldIndex];
                            if (fieldToChange) {
                              fieldToChange.value = value;
                            }
                            setOutput(newOutput);
                            onChangeValue(field, value);
                          }}
                        />
                      </ECBox>
                    );
                  })}
                </ECBox>
              </ECBox>
            );

            // Ends
          })}
        </ECBox>
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.RadioWithCustomOptions && options) {
    return (
      <ECFormControl sx={{ ...baseSx, px: 1.5 }} key={index}>
        <ECFormLabel id={label}>{createFieldLabel}</ECFormLabel>
        <ECRadioGroup row id={fieldName} name={fieldName} value={value}>
          <ECBox
            display="flex"
            flexDirection={
              field.orientation === 'horizontal' ? 'row' : 'column'
            }
            width="100%"
            gap={2}
          >
            {options?.map((option, optionIndex) => {
              if (option?.visible === false) {
                return null;
              }

              const isSelected =
                option?.id === output[index].value ||
                option === output[index].value ||
                option === value ||
                option?.id === value;

              return (
                <ECBox display="flex" flexDirection="row" width="100%" gap={1}>
                  <ECRadio
                    sx={{ alignSelf: 'flex-start' }}
                    onChange={() => onChangeValue(fieldName, option.id)}
                    checked={isSelected}
                  />
                  <ECBox
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    mt={0.5}
                  >
                    {option.fields?.map((field, fieldIndex) => {
                      const isLabelField =
                        field.type === 'section' && fieldIndex === 0;

                      if (field.visible === false) {
                        return null;
                      }

                      return (
                        <ECBox
                          sx={{
                            cursor: isLabelField ? 'pointer' : undefined,
                          }}
                          onClick={() =>
                            isLabelField
                              ? onChangeValue(fieldName, option.id)
                              : {}
                          }
                        >
                          <ECFormCell
                            sx={{
                              opacity: isSelected || isLabelField ? 1 : 0.3,
                              pointerEvents: isSelected ? undefined : 'none',
                            }}
                            index={fieldIndex}
                            field={field}
                            output={option.fields}
                            fields={fields}
                            isReadOnlyForm={isReadOnlyForm}
                            config={field.config}
                            handleCostTableChange={() => {}}
                            setOutput={newOutput => {
                              setOutput(prevOutput => {
                                prevOutput[index].options[optionIndex].fields =
                                  newOutput;

                                return prevOutput;
                              });
                            }}
                            existingData={existingData}
                            onChangeValue={(field, value) => {
                              const newOutput = [...output];
                              const fieldToChange =
                                newOutput?.[index]?.options?.[optionIndex]
                                  ?.fields?.[fieldIndex];
                              if (fieldToChange) {
                                fieldToChange.value = value;
                              }
                              setOutput(newOutput);
                              onChangeValue(field, value);
                            }}
                          />
                        </ECBox>
                      );
                    })}
                  </ECBox>
                </ECBox>
              );
            })}
          </ECBox>
        </ECRadioGroup>
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.sectionWithCustomOptions && options) {
    return (
      <ECFormControl sx={{ ...baseSx, px: 1.5 }} key={index}>
        <ECFormLabel id={label}>{createFieldLabel}</ECFormLabel>
        <ECBox
          display="flex"
          flexDirection={field.orientation === 'horizontal' ? 'row' : 'column'}
          width="100%"
          gap={2}
        >
          {options?.map((option, optionIndex) => {
            if (option?.visible === false) {
              return null;
            }

            return (
              <ECBox display="flex" flexDirection="column" width="100%" gap={1}>
                {option.fields?.map((field, fieldIndex) => {
                  const isLabelField =
                    field.type === 'section' && fieldIndex === 0;

                  if (field.visible === false) {
                    return null;
                  }

                  return (
                    <ECBox
                      sx={{
                        cursor: isLabelField ? 'pointer' : undefined,
                      }}
                      onClick={() =>
                        isLabelField ? onChangeValue(fieldName, option.id) : {}
                      }
                    >
                      <ECFormCell
                        sx={{
                          opacity: 1,
                        }}
                        index={fieldIndex}
                        field={field}
                        output={option.fields}
                        fields={fields}
                        isReadOnlyForm={isReadOnlyForm}
                        config={field.config}
                        handleCostTableChange={() => {}}
                        setOutput={newOutput => {
                          setOutput(prevOutput => {
                            prevOutput[index].options[optionIndex].fields =
                              newOutput;

                            return prevOutput;
                          });
                        }}
                        existingData={existingData}
                        onChangeValue={(field, value) => {
                          const newOutput = [...output];
                          const fieldToChange =
                            newOutput?.[index]?.options?.[optionIndex]
                              ?.fields?.[fieldIndex];
                          if (fieldToChange) {
                            fieldToChange.value = value;
                          }
                          setOutput(newOutput);
                          onChangeValue(field, value);
                        }}
                      />
                    </ECBox>
                  );
                })}
              </ECBox>
            );
          })}
        </ECBox>
      </ECFormControl>
    );
  } else if (type === FieldTypes.RadioSPList) {
    if (options?.length) {
      return (
        <ECFormControl sx={{ ...baseSx, px: 1.5 }} key={index}>
          <ECFormLabel id={label}>{createFieldLabel}</ECFormLabel>
          <ECRadioGroup
            row
            id={fieldName}
            name={fieldName}
            value={value}
            onChange={event => {
              const eventValue = (event.target as HTMLInputElement).value;
              onChangeValue(fieldName, eventValue);
            }}
          >
            <ECBox display="flex" flexDirection="column" width="100%">
              {options.map(option => {
                const isSelected = option?.id == value;
                return (
                  <>
                    <ECFormControlLabel
                      control={<ECRadio />}
                      sx={{
                        my: 1,
                        '& .MuiFormControlLabel-label': { width: '100%' },
                      }}
                      value={option?.id}
                      label={
                        <ECBox
                          width="100%"
                          border={borderless ? 0 : isSelected ? 2 : 1}
                          borderRadius={1}
                          p={borderless ? 0 : 1}
                          px={borderless ? 0 : 2}
                          borderColor={theme =>
                            isSelected
                              ? theme.palette.primary.main
                              : theme.palette.action.disabled
                          }
                        >
                          <>
                            <ECTypography>{option?.name}</ECTypography>
                            {!!option?.serviceRequestPopup && isSelected && (
                              <ECAlert severity="error">
                                <ECTypography fontWeight={600}>
                                  Service Provider requires a call for a Service
                                  Request
                                </ECTypography>
                                <ECBox display="flex" alignItems="center" m={1}>
                                  <ECTypography>
                                    1. Please call {option?.name}
                                    {option?.phone && ':'}
                                  </ECTypography>
                                  {option?.phone && (
                                    <ECTypography fontWeight={600}>
                                      &nbsp;
                                      {formatPhoneNumber(option?.phone)}
                                    </ECTypography>
                                  )}
                                </ECBox>
                                <ECTypography m={1}>
                                  2. After contacting the Service Provider,
                                  please complete the Service Request.
                                </ECTypography>
                              </ECAlert>
                            )}
                          </>
                        </ECBox>
                      }
                    />
                  </>
                );
              })}
            </ECBox>
          </ECRadioGroup>
          {field.validationMessage && (
            <FormHelperText
              sx={theme => ({
                color: theme.palette.error.main,
              })}
            >
              {field.validationMessage}
            </FormHelperText>
          )}
        </ECFormControl>
      );
    } else {
      return (
        <ECFormControl sx={{ ...baseSx, px: 1.5 }} key={index}>
          <ECFormLabel id={label}>
            {t('translation:createServiceRequest.noServiceProviders')}
          </ECFormLabel>
        </ECFormControl>
      );
    }
  } else if (type === FieldTypes.RadioWarrantyStartDate && options) {
    const updatedOptions = options.map((option, i) => {
      if (
        option &&
        option.value === undefined &&
        fields &&
        fields[index] &&
        fields[index].options &&
        fields?.[index]?.options?.[i]
      ) {
        return {
          ...option,
          value: fields?.[index]?.options?.[i].value,
        };
      }
      return option;
    });

    return (
      <ECFormControl sx={{ ...baseSx, px: 1.5 }} key={index}>
        <ECFormLabel id={label}>{createFieldLabel}</ECFormLabel>
        <ECRadioWarrantyStartDate
          id={fieldName}
          name={fieldName}
          options={updatedOptions as any[]}
          onChange={warrantyStartData => {
            if (!_.isEqual(warrantyStartData, value)) {
              onChangeValue(fieldName, warrantyStartData);
            }
          }}
          onChangeOptionValue={(option, warrantyStartData) => {
            if (
              !_.isEqual(
                option,
                options.find(opt => opt.fieldName === option.fieldName),
              )
            ) {
              onChangeValue(option.fieldName, option.value);
            }
            if (warrantyStartData) {
              onChangeValue(fieldName, warrantyStartData);
            }
          }}
          defaultValue={value as any}
          variant={variant}
          placeholder={placeholder}
          orientation={orientation}
          validationMessage={field.validationMessage}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.Checkbox && options) {
    const GridComponent = fullWidth
      ? ECEasyFormSectionGridItem
      : ECEasyFormGridItem;

    const valueAsArray = Array.isArray(value)
      ? (value as string[])
      : ([] as string[]);
    const getCheckBoxOptionalSx = () => {
      if (field?.boxType === 'primary') {
        return {
          border: '1px solid blue',
          p: 2,
          borderColor: '#64b5f6',
          backgroundColor: '#e3f2fd',
          borderRadius: '8px',
          ...(field.optionalSx && typeof field.optionalSx === 'object'
            ? field.optionalSx
            : {}),
        };
      }
      return {
        ...(field.optionalSx && typeof field.optionalSx === 'object'
          ? field.optionalSx
          : {}),
      };
    };

    return (
      <GridComponent
        config={config}
        key={index}
        optionalSx={getCheckBoxOptionalSx}
      >
        <ECFormGroup sx={{ ...baseSx, px: 1.5 }}>
          {createFieldLabel && (
            <ECFormLabel id={label}>{createFieldLabel}</ECFormLabel>
          )}
          {options.map((option: string, checkboxIndex: number) => {
            const isChecked = valueAsArray.includes(option);
            return (
              <ECFormControlLabel
                key={`${option}-${checkboxIndex}`}
                control={
                  <ECCheckbox
                    id={fieldName}
                    name={fieldName}
                    value={value}
                    checked={isChecked}
                    onChange={
                      isReadOnlyForm || readOnly
                        ? undefined
                        : event => {
                            let newCheckedValues;
                            const valueArray = _.flatten([output[index].value]);
                            if (event.target.checked) {
                              newCheckedValues = [
                                ...((valueArray || []) as string[]),
                                option,
                              ];
                            } else {
                              newCheckedValues = (
                                (valueArray || []) as string[]
                              ).filter(checkedValue => checkedValue !== option);
                            }

                            const currentOutput = [...output];
                            currentOutput[index].value = newCheckedValues;
                            setOutput(currentOutput);
                            onChangeValue?.(fieldName, newCheckedValues);
                            onChange?.(output, newCheckedValues);
                          }
                    }
                    disableRipple={isReadOnlyForm || readOnly}
                    disabled={disabled}
                    sx={{
                      '&.Mui-checked': {
                        color: theme =>
                          isReadOnlyForm || readOnly
                            ? theme.palette.action.disabled
                            : theme.palette.primary.main,
                      },
                    }}
                  />
                }
                label={option}
              />
            );
          })}
          {field.validationMessage && (
            <FormHelperText>{field.validationMessage}</FormHelperText>
          )}
          {field?.helperText && (
            <FormHelperText sx={field.helperTextOptionalSx}>
              {field?.helperText}
            </FormHelperText>
          )}
        </ECFormGroup>
      </GridComponent>
    );
  } else if (type === FieldTypes.Switch) {
    const valueLabel =
      switchActiveLabel || switchInactiveLabel
        ? value
          ? switchActiveLabel || t('translation:switch.active')
          : switchInactiveLabel || t('translation:switch.inactive')
        : shouldShowActiveInactiveStatusLabel || label === 'Status'
          ? value
            ? t('translation:switch.active')
            : t('translation:switch.inactive')
          : value
            ? t('translation:switch.yes')
            : t('translation:switch.no');
    return (
      <ECFormGroup key={index} sx={{ ...optionalSx }}>
        <ECTypography variant="caption" color="text.secondary">
          {createFieldLabel}
        </ECTypography>
        <ECStack direction="row" spacing={1} alignItems="center">
          {readOnly || disabled ? (
            <ECBox width="100%" display="flex" flexDirection="column" gap={1}>
              <ECBox>
                <ECChip
                  label={valueLabel}
                  variant="filled"
                  sx={{
                    bgcolor: theme =>
                      value
                        ? theme.palette.success.outlinedHoverBackground
                        : theme.palette.error.outlinedHoverBackground,
                    color: theme =>
                      value
                        ? theme.palette.success.dark
                        : theme.palette.error.dark,
                  }}
                />
              </ECBox>
              <ECBox
                width="100%"
                height="1px"
                bgcolor={theme => theme.palette.other.divider}
              />
            </ECBox>
          ) : (
            <>
              <ECSwitch
                id={fieldName}
                name={fieldName}
                checked={value ? true : false}
                value={value}
                disabled={readOnly || disabled}
                onChange={e => {
                  onChangeValue(fieldName, e.target.checked);
                  onChange?.(output, e.target.checked);
                }}
              />
              <ECTypography
                sx={theme => ({
                  color: disabled
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary,
                })}
              >
                {valueLabel}
              </ECTypography>
            </>
          )}
        </ECStack>
        {field?.helperText && (
          <FormHelperText sx={field.helperTextOptionalSx}>
            {field?.helperText}
          </FormHelperText>
        )}
      </ECFormGroup>
    );
  } else if (type === FieldTypes.DatePicker) {
    return (
      <React.Fragment key={index}>
        <ECBasicDatePicker
          key={index}
          readOnly={readOnly}
          fieldName={fieldName}
          error={field.isValid === false}
          helperText={field.validationMessage ? field.validationMessage : null}
          sx={{ ...baseSx }}
          label={createFieldLabel}
          value={value}
          minDate={field.minDate}
          maxDate={field.maxDate}
          disabled={disabled}
          onChange={date => {
            const eventValue = date;
            onChangeValue(fieldName, eventValue);
            onChange?.(output, eventValue);
          }}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: field.validationMessage
                ? theme.palette.error.main
                : undefined,
              marginLeft: '14px',
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </React.Fragment>
    );
  } else if (type === FieldTypes.TimePicker) {
    return (
      <React.Fragment key={index}>
        <ECBasicTimePicker
          fieldName={fieldName}
          error={field.isValid === false}
          helperText={field.validationMessage ? field.validationMessage : null}
          sx={{ ...baseSx }}
          label={createFieldLabel}
          value={value}
          inputProps={{
            step: 1800,
          }}
          onChange={time => {
            const eventValue = time;
            onChangeValue(fieldName, eventValue);
          }}
        />
        {field.validationMessage && (
          <FormHelperText>{field.validationMessage}</FormHelperText>
        )}
      </React.Fragment>
    );
  } else if (type === FieldTypes.DateTimePicker) {
    return (
      <React.Fragment key={index}>
        <ECBasicDateTimePicker
          fieldName={fieldName}
          error={field.isValid === false}
          helperText={field.validationMessage ? field.validationMessage : null}
          sx={{ ...baseSx }}
          label={createFieldLabel}
          value={value}
          variant={variant}
          readOnly={readOnly}
          disabled={disabled}
          onChange={dateTime => {
            const eventValue = dateTime;
            onChangeValue(fieldName, eventValue);
          }}
        />
        {field.validationMessage && (
          <FormHelperText>{field.validationMessage}</FormHelperText>
        )}
      </React.Fragment>
    );
  } else if (type === FieldTypes.FilePicker) {
    return (
      <React.Fragment key={index}>
        <ECFilePicker
          {...field}
          key={index}
          label={createFieldLabel}
          fieldName={fieldName}
          type={fileType}
          sx={{ ...baseSx, width: '100%', height: 56 }}
          onChange={files => {
            onChangeValue(fieldName, files);
            onChange?.(output, files);
          }}
          images={value as ImagePreview[]}
          isReadOnly={readOnly}
          disabledUpload={
            field.maxLength ? value.length >= field.maxLength : undefined
          }
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </React.Fragment>
    );
  } else if (type === FieldTypes.BannerFilePicker) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECBannerFilePicker
          label={field.label}
          value={field.value}
          onChange={files => {
            onChangeValue(fieldName, files);
            onChange?.(output, files);
          }}
          maxLength={field.maxLength}
          fieldName={fieldName}
        />
      </ECFormControl>
    );
  } else if (type === FieldTypes.ColorPicker) {
    return (
      <ECColorPicker
        key={index}
        label={createFieldLabel}
        placeholder={placeholder}
        onChange={color => {
          onChangeValue(fieldName, color);
        }}
        defaultColor={value as string}
        presetColors={presetColors}
        variant={variant}
        disabled={readOnly}
      />
    );
  } else if (type === FieldTypes.TextArea) {
    return (
      <>
        <ECTextField
          id={fieldName}
          key={index}
          name={fieldName}
          sx={{ ...baseSx }}
          multiline={true}
          inputProps={{
            maxLength: field.maxLength ? field.maxLength : undefined,
            minLength: field.minLength ? field.minLength : undefined,
            readOnly,
          }}
          minRows={readOnly ? undefined : 5}
          error={field.isValid === false}
          helperText={field.validationMessage ? field.validationMessage : null}
          label={createFieldLabel}
          placeholder={placeholder}
          value={value}
          variant={variant}
          onChange={event => {
            const eventValue = event.target.value;
            const currentOutput = [...output];
            currentOutput[index].value = eventValue;
            setOutput(currentOutput);
            onChangeValue(fieldName, eventValue);
          }}
        />
        {field.helperText && (
          <FormHelperText>{field.helperText}</FormHelperText>
        )}
      </>
    );
  } else if (type === FieldTypes.ChipsList && chips) {
    return (
      <React.Fragment key={index}>
        <ECTagInput
          initialTags={chips}
          onChange={tags => {
            onChangeValue(fieldName, tags);
          }}
          label={createFieldLabel}
          placeholder={placeholder}
          onDuplicate={onDuplicateChip}
          disabled={readOnly}
          readOnly={readOnly}
          forceShowLabel={forceShowLabel}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </React.Fragment>
    );
  } else if (type === FieldTypes.Section) {
    return (
      <ECEasyFormSectionGridItem
        sx={{ ...baseSx, ...optionalSx }}
        key={index}
        gap={4}
      >
        <ECTypography
          variant="h6"
          color={color ? color : theme => theme.palette.text.primary}
        >
          {label}
        </ECTypography>

        {description && (
          <ECTypography variant="body1">{description}</ECTypography>
        )}
      </ECEasyFormSectionGridItem>
    );
  } else if (type === FieldTypes.Label) {
    return (
      <ECEasyFormSectionGridItem
        sx={{ marginBottom: 2, ...optionalSx }}
        key={index}
      >
        <ECTypography>{label}</ECTypography>
      </ECEasyFormSectionGridItem>
    );
  } else if (type === FieldTypes.AssetTradeProblem) {
    return (
      <ECAssetTradeProblemCreate
        key={index}
        existingData={existingData}
        onChange={data => {
          onChangeValue(fieldName, data);
        }}
        errorMessage={field.validationMessage}
      />
    );
  } else if (type === FieldTypes.TradeRegion) {
    return (
      <ECAssetTradeRegionCreate
        key={index}
        onChange={data => {
          onChangeValue(fieldName, data);
        }}
      />
    );
  } else if (type === FieldTypes.AssetProblemTroubleshoot) {
    return (
      <ECAssetProblemTroubleshootCreate
        key={index}
        value={value}
        onChange={data => {
          onChangeValue(fieldName, data);
        }}
        errorMessage={field.validationMessage}
      />
    );
  } else if (type === FieldTypes.FailureWarranty) {
    return (
      <ECFailureWarranty
        key={index}
        variant={variant}
        onChange={data => {
          onChangeValue(fieldName, data);
        }}
        label={label}
        defaultValue={value as FailureWarrantyOutput[]}
        readOnly={readOnly}
        visible={field.visible}
        required={field.required}
        options={options as Lookup[]}
        isLoading={isLoading}
        isError={field.isValid === false}
      />
    );
  } else if (type === FieldTypes.RichText) {
    return (
      <ECRichTextField
        key={index}
        label={createFieldLabel}
        placeholder={placeholder}
        readOnlyContent={(value as string) ?? ''}
        defaultValue={(value as string) ?? ''}
        isReadOnly={readOnly}
        onChange={data => {
          onChangeValue(fieldName, data);
        }}
        isSendButtonVisible={false}
      />
    );
  } else if (type === FieldTypes.Video) {
    return (
      <ECVideoField
        key={index}
        label={createFieldLabel}
        placeholder={placeholder}
        onChange={data => {
          onChangeValue(fieldName, data);
        }}
        videoUrl={value as string}
        isReadOnly={readOnly}
      />
    );
  } else if (type === FieldTypes.HourEta) {
    return (
      <ECHourETA
        key={index}
        onChange={etaObject => {
          onChangeValue(fieldName, etaObject);
        }}
        onChangeOptionValue={etaObject => {
          if (field.optionValueField)
            onChangeValue(field.optionValueField, etaObject.timeframe);
        }}
        maxLength={field.maxLength}
        value={field.value as number}
        optionValue={
          field.optionValueField &&
          output.find(otpt => otpt.fieldName === field.optionValueField)?.value
        }
        readOnly={readOnly}
        options={field.options}
        field={field}
        variant={variant}
        baseSx={baseSx}
        required={field.required}
      />
    );
  } else if (type === FieldTypes.Currency) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECCurrencyField
          id={fieldName}
          style={{
            width: '100%',
            marginBottom: 0,
          }}
          readOnly={readOnly}
          disableUnderline={field?.disableUnderline}
          label={createFieldLabel}
          value={value as number}
          onChange={(event, value) => {
            const currentOutput = _.cloneDeep(output);

            onChangeValue(fieldName, value);
            field.onChange?.(currentOutput, value);
          }}
          disabled={disabled}
          baseSx={baseSx}
          variant={variant}
          validationMessage={field.validationMessage}
          error={field.validationMessage ? true : false}
        />
        {field.helperText && (
          <FormHelperText>{field.helperText}</FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.Rank && options) {
    return (
      <ECRankField
        key={index}
        ranks={value as Rank[]}
        options={options}
        onChange={data => {
          onChangeValue(fieldName, data);
        }}
        label={createFieldLabel}
        isReadOnly={readOnly}
        showReadOnlyAsChips={showRankReadOnlyAsChips}
        shouldShowRankText={shouldShowRankText}
        shouldShowAdminOnly={shouldShowAdminOnly}
        maxLength={field.maxLength}
        queryFnLabel={queryFnLabel}
        placeholder={placeholder}
        fieldName={fieldName}
        params={customParams}
      />
    );
  } else if (type === FieldTypes.CostTable && costTable && costTableItems) {
    return (
      <ECWorkflowCostTable
        key={index}
        isEditing={!isReadOnlyForm}
        allowExceeding={field.allowExceeding}
        readOnly={readOnly}
        nte={costTable.nte}
        showIncurred={costTable.showIncurred}
        editingTotalCost={costTable.editingTotalCost}
        openZeroTotalInfo={costTable.openZeroTotalInfo}
        onClickAwayZeroTotalInfo={costTable.onClickAwayZeroTotalInfo}
        itemsRepair={costTableItems}
        itemsInventory={costTable?.itemsInventory}
        purchaseOrderItems={costTableItems?.find(
          item => item?.dataPath === 'purchaseOrderItems',
        )}
        shouldHideCostTable={costTable.shouldHideCostTable}
        shouldShowAddCredit={costTable.shouldShowAddCredit}
        creditAmount={costTable.creditAmount}
        totalWithDiscountAmount={costTable.totalWithDiscountAmount}
        invoiceId={costTable.invoiceId}
        shouldShowAddCreditButton={costTable.shouldShowAddCreditButton}
        onChange={handleCostTableChange}
      />
    );
  } else if (type === FieldTypes.UsersTable) {
    return (
      <ECFormGroup key={index}>
        <ECTypography variant="caption" color="text.secondary">
          {createFieldLabel}
        </ECTypography>
        <ECChip
          id="chip-popover-users-table"
          variant="filled"
          label={
            (value as any)?.length > 0
              ? `${(value as any)?.length} users`
              : '0 users'
          }
          color={readOnly ? undefined : 'rgba(0, 114, 206, 1)'} //selectedChipUsersTable ? 'rgba(0, 114, 206, 1)' : undefined}
          textColor="white"
          sx={{
            maxWidth: '85px',
            padding: 0,
          }}
          onClick={e => {
            onClickViewUsersTable?.(field, e);
          }}
        />
      </ECFormGroup>
    );
  } else if (type === FieldTypes.Group && subFields) {
    return (
      <ECGrid
        container
        alignItems={alignItems}
        spacing={noSpacing ? 0 : 2}
        key={index}
        sx={field.optionalSx}
      >
        {subFields.map((field, subFieldIndex) => (
          <ECEasyFormGridItem
            config={{ ...config, cols: groupColumns || 1 }}
            optionalSx={{ marginBottom: 1, ...field.optionalSx }}
            scopes={field.scopes}
            key={subFieldIndex}
          >
            <ECFormCell
              field={field}
              index={subFieldIndex}
              parentOutput={output}
              output={output?.[index]?.subFields || []}
              fields={fields}
              costTable={costTable}
              costTableItems={costTableItems}
              pattern={pattern}
              isReadOnlyForm={isReadOnlyForm}
              config={config}
              handleCostTableChange={handleCostTableChange}
              setParentOutput={setOutput}
              setOutput={newOutput => {
                setOutput(prevOutput => {
                  prevOutput[index].subFields = newOutput;

                  return [...prevOutput];
                });
              }}
              existingData={existingData}
              onDuplicateChip={onDuplicateChip}
              onChangeValue={onChangeValue}
            />
          </ECEasyFormGridItem>
        ))}
      </ECGrid>
    );
  } else if (type === FieldTypes.Info) {
    return (
      <ECTypography
        mb={2}
        sx={{ display: 'flex', width: '100%' }}
        variant="subtitle2"
        key={index}
      >
        <Alert
          sx={{ width: '100%', fontWeight: 500 }}
          severity="info"
          variant="outlined"
        >
          {t(`translation:pages.${value}`)}
        </Alert>
      </ECTypography>
    );
  } else if (type === FieldTypes.MaterialMarkup) {
    let materialMarkupValue = _.clone(value);
    if (!Array.isArray(materialMarkupValue)) {
      if (readOnly) {
        return <React.Fragment key={index}></React.Fragment>;
      } else {
        materialMarkupValue = [];
      }
    }
    const sortedValues = [...materialMarkupValue].sort(
      (a, b) => a.range - b.range,
    );
    if (readOnly)
      return (
        <ECBox key={index}>
          {sortedValues.map((material, ind, array) => {
            return (
              <ECBox display={'flex'} margin={1} key={ind}>
                <ECTextField
                  id={fieldName}
                  name={fieldName}
                  sx={{ ...baseSx }}
                  inputProps={{
                    maxLength: field.maxLength ? field.maxLength : undefined,
                    readOnly,
                  }}
                  error={field.isValid === false}
                  helperText={
                    field.validationMessage ? field.validationMessage : null
                  }
                  variant={variant}
                  label={t('translation:form.markupMaterial.range')}
                  placeholder={t('translation:form.markupMaterial.range')}
                  value={`${
                    array[ind - 1]?.range + 0.01 || 0.0
                  } - ${material.range.toFixed(2)}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={value ? { shrink: true } : {}}
                />

                <ECTextField
                  id={fieldName}
                  name={fieldName}
                  sx={{ ...baseSx }}
                  inputProps={{
                    maxLength: field.maxLength ? field.maxLength : undefined,
                    readOnly,
                  }}
                  error={field.isValid === false}
                  helperText={
                    field.validationMessage ? field.validationMessage : null
                  }
                  variant={variant}
                  label={t('translation:form.markupMaterial.percentMarkup')}
                  placeholder={t(
                    'translation:form.markupMaterial.percentMarkup',
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  value={material.percentage}
                  InputLabelProps={value ? { shrink: true } : {}}
                />
              </ECBox>
            );
          })}
        </ECBox>
      );

    return (
      <React.Fragment key={index}>
        <ECMarkupMaterial
          values={sortedValues}
          field={field}
          baseSx={baseSx}
          options={options}
          readOnly={readOnly}
          variant={variant}
          onChange={etaObject => {
            onChangeValue(fieldName, etaObject);
          }}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </React.Fragment>
    );
  } else if (type === FieldTypes.OperatingHours) {
    const newValue = Array.isArray(value) ? [...value] : [];
    return (
      <React.Fragment key={index}>
        <ECOperatingHours
          values={newValue}
          field={field}
          baseSx={baseSx}
          options={options}
          variant={variant}
          readOnly={readOnly}
          onChange={etaObject => {
            onChangeValue(fieldName, etaObject);
          }}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </React.Fragment>
    );
  } else if (type === FieldTypes.ServiceFrequency) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECServiceFrequency
          serviceMonths={field.serviceMonths}
          dayOfMonth={field.dayOfMonth}
          dayBetweenService={field.daysBetweenService}
          resetState={field.resetState}
          onSelectedValuesChange={values => {
            const currentOutput = [...output];
            if (values.dayOfMonth) {
              currentOutput[index].dayOfMonth = values.dayOfMonth;
            }
            if (values.serviceMonths) {
              currentOutput[index].serviceMonths = values.serviceMonths;
            }
            if (values.daysBetweenService) {
              currentOutput[index].daysBetweenService =
                values.daysBetweenService;
            }

            if (
              JSON.stringify(currentOutput[index].value) !==
              JSON.stringify(values)
            ) {
              currentOutput[index].value = values as any;
              setOutput(currentOutput);
              onChangeValue(fieldName, values);
            }
          }}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.AddComment) {
    return (
      <ECFormControl sx={{ ...sx, width: '100%' }} key={index}>
        <ECAddComment
          variant={field.variant}
          disabled={disabled}
          moduleId={field.moduleId}
          moduleName={field.moduleName}
          required={field.required}
          placeholder={field.placeholder}
          validationMessage={field.validationMessage}
          maxLength={field.maxLength}
          useRichTextEditor={field.useRichTextEditor}
          customTitle={field.customTitle}
          isSendButtonVisible={field.isSendButtonVisible}
          onSelectedValuesChange={value => {
            const currentOutput = [...output];
            if (value.moduleId) {
              currentOutput[index].moduleId = value.moduleId;
            }
            if (value.moduleName) {
              currentOutput[index].moduleName = value.moduleName;
            }
            if (value.selectedUsers) {
              currentOutput[index].selectedUsers = value.selectedUsers;
            }
            if (value.comment) {
              currentOutput[index].comment = value.comment;
            }
            if (!_.isEqual(currentOutput[index].value, value)) {
              currentOutput[index].value = value as any;
              setOutput(currentOutput);
              onChangeValue(fieldName, value);
            }
          }}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.AddCommentBulk) {
    return (
      <ECFormControl sx={{ ...sx, width: '100%' }} key={index}>
        <ECAddCommentBulk
          variant={field.variant}
          disabled={disabled}
          moduleIds={field.moduleIds}
          moduleName={field.moduleName}
          required={field.required}
          placeholder={field.placeholder}
          validationMessage={field.validationMessage}
          maxLength={field.maxLength}
          useRichTextEditor={field.useRichTextEditor}
          customTitle={field.customTitle}
          onSelectedValuesChange={value => {
            const currentOutput = [...output];
            if (value.moduleIds) {
              currentOutput[index].moduleIds = value.moduleIds;
            }
            if (value.moduleName) {
              currentOutput[index].moduleName = value.moduleName;
            }
            if (value.selectedUsers) {
              currentOutput[index].selectedUsers = value.selectedUsers;
            }
            if (value.comment) {
              currentOutput[index].comment = value.comment;
            }
            if (!_.isEqual(currentOutput[index].value, value)) {
              currentOutput[index].value = value as any;
              setOutput(currentOutput);
              onChangeValue(fieldName, value);
            }
          }}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.WarrantyPeriod) {
    return (
      <ECWarrantyPeriodList
        warrantyPeriodOptions={field.warrantyPeriodOptions}
      />
    );
  } else if (type === FieldTypes.BulkActivityView) {
    return (
      <ECBulkActivityView moduleName={field.moduleName} value={field.value} />
    );
  } else if (type === FieldTypes.Alert) {
    if (field?.title || field?.description || field?.items) {
      return (
        <ECCustomAlert
          severity={field.severity}
          color={field.color}
          variant={field.variant}
          sx={optionalSx}
          title={field.title}
          description={field.description}
          items={field.items}
        >
          {field.label}
        </ECCustomAlert>
      );
    }
    return (
      <ECAlert
        severity={field.severity}
        color={field.color}
        variant={field.variant}
        sx={optionalSx}
      >
        {field.label}
      </ECAlert>
    );
  } else if (type === FieldTypes.PMSetupAlert) {
    return <PMSetupAlert />;
  } else if (type === FieldTypes.StorageLocations) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECStorageLocations
          required={field.required}
          validationMessage={field.validationMessage}
          placeholder={field.placeholder}
          label={field.label}
          options={field.options}
          onChange={value => {
            const currentOutput = [...output];
            if (!_.isEqual(currentOutput[index].value, value)) {
              currentOutput[index].value = value as any;
              setOutput(currentOutput);
              onChangeValue(fieldName, value);
            }
          }}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.InventoryItems) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECInventoryItems
          required={field.required}
          validationMessage={field.validationMessage}
          placeholder={field.placeholder}
          label={field.label}
          options={field.options}
          onChange={value => {
            const currentOutput = [...output];
            if (!_.isEqual(currentOutput[index].value, value)) {
              currentOutput[index].value = value as any;
              setOutput(currentOutput);
              onChangeValue(fieldName, value);
            }
          }}
          allocatedInventoryItems={field.allocatedInventoryItems}
          value={field.value}
        />
        {field.validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
              marginTop: '-0.5em',
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  } else if (type === FieldTypes.AllocatedInventory) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECRequirePermission
          companyConfigScopes={[CompanyConfigurationFields.EnableInventory]}
        >
          <ECAllocatedInventory
            label={field.label}
            placeholder={field.placeholder}
            allocatedItems={field.value}
            onButtonClicked={field.actionButtonClicked}
            shouldHideAllocateButton={field.hideAllocateButton}
            onChange={value => {
              const currentOutput = [...output];
              if (!_.isEqual(currentOutput[index].value, value)) {
                currentOutput[index].value = value as any;
                setOutput(currentOutput);
                onChangeValue(fieldName, value);
              }
            }}
          />
        </ECRequirePermission>
      </ECFormControl>
    );
  } else if (type === FieldTypes.Rating) {
    return (
      <ECFormControl sx={{ width: '100%', ...optionalSx }} key={index}>
        <ECBox display="flex" gap={1}>
          <ECTypography>{field.label}:</ECTypography>
          <ECRating
            value={field.value}
            onChange={value => {
              onChangeValue(fieldName, value);
            }}
            readOnly={field.readOnly}
            precision={0.5}
            disabled={field.disabled}
          />
        </ECBox>
      </ECFormControl>
    );
  } else if (type === FieldTypes.Custom) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        {field.value as any}
      </ECFormControl>
    );
    // for Editable Table we use the options as the CONFIG
    // editing is not yet done, will be added on ticket DEV-17495
  } else if (type === FieldTypes.EditableTable && field.tableConfig) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        {field.label && (
          <ECTypography variant="h5" mb={1}>
            {field.label}
          </ECTypography>
        )}
        <ECEasyTable
          config={field.tableConfig}
          data={value}
          headerHeight={50}
          isEditable={!field.readOnly}
          headerBackgroundColor="#fff"
          hideSearchHeadContent
          onChange={(value, col, row) => {
            onChangeValue(fieldName, value, row.id, col);
          }}
          disableTableContent={field.disabled}
          shouldNotUseActiveFilter
        />
      </ECFormControl>
    );
  } else if (type === FieldTypes.FieldButton && fieldButtonProps) {
    return (
      <ECButton
        key={index}
        variant={fieldButtonProps.variant}
        onClick={fieldButtonProps.onClick}
        disabled={fieldButtonProps.disabled}
        endIcon={
          fieldButtonProps.iconPosition === 'end' && fieldButtonProps.icon
        }
        startIcon={
          fieldButtonProps.iconPosition === 'start' && fieldButtonProps.icon
        }
        size={fieldButtonProps.size}
        fullWidth={fieldButtonProps.fullWidth}
      >
        {fieldButtonProps.label}
      </ECButton>
    );
  } else if (type === FieldTypes.PurchaseOrderItems) {
    return (
      <ECFormControl sx={{ width: '100%' }} key={index}>
        <ECPurchaseOrderItems
          label={field.label}
          placeholder={field.placeholder}
          options={options}
          readOnly={field.readOnly}
          disabled={field.disabled}
          value={field.value}
          onChange={value => {
            const currentOutput = [...output];
            if (!_.isEqual(currentOutput[index].value, value)) {
              currentOutput[index].value = value as any;
              setOutput(currentOutput);
              onChangeValue(fieldName, value);
            }
          }}
          errorMessage={field.validationMessage}
        />
        {field.validationMessage && !field.value?.length && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {field.validationMessage}
          </FormHelperText>
        )}
      </ECFormControl>
    );
  }
  return <></>;
}
