import { QueryParams } from 'types/QueryParams';
import {
  ApprovalAction,
  ApprovalListFlat,
  ApprovalRoleType,
  GetApprovalHistoryParams,
  GetStepsParams,
  GetValidateParams,
} from 'types/Approval';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Approval'],
});

export const approvalApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getApprovalList: builder.query<BaseType<ApprovalListFlat[]>, QueryParams>({
      query: params => {
        return {
          url: 'approval',
          params,
        };
      },
      transformResponse: (response: BaseType<ApprovalListFlat[]>) => {
        return {
          ...response,
          data: response?.data?.map(d => ({
            ...d,
            amount: d.amount?.toString(),
            name: d.title,
          })),
        };
      },
      providesTags: ['Approval'],
    }),
    createApproval: builder.mutation<void, any>({
      query: ({ ...body }) => {
        const updateBody = {
          ...body,
        };

        return {
          url: 'approval',
          method: 'post',
          body: updateBody,
        };
      },
      invalidatesTags: ['Approval'],
    }),
    updateApproval: builder.mutation<void, any>({
      query: ({ ...body }) => {
        const updateBody = {
          ...body,
          approval: {
            autoApprovalLimitInvoice: Number(
              body.approval.autoApprovalLimitInvoice,
            ),
            autoApprovalOnProposal: body.approval.autoApprovalOnProposal,
            autoApprovalLimitProposal: Number(
              body.approval.autoApprovalLimitProposal,
            ),
            requireStepsInvoice: body.approval.requireStepsInvoice,
            requireStepsProposal: body.approval.requireStepsProposal,
            amount: body.approval.amount,
            branchTypeIds: body.approval.branchTypeIds,
            description: body.approval.description,
            id: body.approval.id,
            requestCategory: body.approval.requestCategory,
            status: body.approval.status,
            title: body.approval.title,
          },
        };

        updateBody.steps = updateBody.steps.map(step => {
          if (step.isNew) {
            delete step.id;
            delete step.isNew;
          }
          return step;
        });

        return {
          url: `approval/${body.approval.id}`,
          method: 'put',
          body: updateBody,
        };
      },
      invalidatesTags: ['Approval'],
    }),

    getApprovalActions: builder.query<ApprovalAction[], void>({
      query: () => {
        return {
          url: 'approval/actions',
        };
      },
      providesTags: ['Approval'],
    }),

    getApprovalRoleTypes: builder.query<ApprovalRoleType[], void>({
      query: () => {
        return {
          url: 'approval/roletypes',
        };
      },
      providesTags: ['Approval'],
    }),

    getApprovalSteps: builder.query<any, GetStepsParams>({
      query: ({ id, module }) => {
        return {
          url: `approval/${module.toLowerCase()}/${id}/steps`,
        };
      },
      providesTags: ['Approval'],
    }),
    getValidateDuplicateApproval: builder.query<
      { isDuplicate: boolean },
      GetValidateParams
    >({
      query: body => {
        return {
          url: 'approval/check-duplicate',
          method: 'put',
          body,
        };
      },
    }),
    getApprovalHistory: builder.query<any, GetApprovalHistoryParams>({
      query: ({ id, module }) => {
        return {
          url: `approval/history/${module.toLowerCase()}/${id}`,
        };
      },
      providesTags: ['Approval'],
    }),
  }),
});

export const {
  useGetApprovalListQuery,
  useLazyGetApprovalListQuery,
  useUpdateApprovalMutation,
  useCreateApprovalMutation,
  useGetApprovalActionsQuery,
  useGetApprovalRoleTypesQuery,
  useGetApprovalStepsQuery,
  useLazyGetApprovalStepsQuery,
  useLazyGetValidateDuplicateApprovalQuery,
  useGetApprovalHistoryQuery,
} = approvalApi;
