import {
  CommentCreate,
  CommentDelete,
  CommentList,
  CommentListParams,
  CommentUpdate,
} from 'types/Comment';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';

import { emptyApi } from './emptyApi';
import { MODULES_TO_NOT_USE_REQUEST_ID } from 'app/components/ECDynamicPageTemplate/NoteTab';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Comment'],
});

export const commentApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getCommentList: builder.query<
      BaseType<CommentList[]>,
      CommentListParams & QueryParams
    >({
      query: ({ requestId, module, ...params }) => {
        if (!requestId) {
          return {
            url: '',
          };
        }
        if (MODULES_TO_NOT_USE_REQUEST_ID.includes(module!)) {
          return {
            url: `comment/${module}/${requestId}`,
            params,
          };
        }

        return {
          url: `comment/request/${requestId}`,
          params,
        };
      },
      providesTags: ['Comment'],
    }),
    createComment: builder.mutation<void, CommentCreate>({
      query: ({
        requestId,
        module,
        moduleResourceId,
        recipientIds,
        ...body
      }) => {
        if (MODULES_TO_NOT_USE_REQUEST_ID.includes(module!)) {
          return {
            url: `comment/${module}/${requestId}`,
            method: 'post',
            body: {
              ...body,
              id: requestId,
              module: 'asset',
              subModule: module,
              recipientIds,
              subModuleRefId: moduleResourceId,
            },
          };
        }
        if (!MODULES_TO_NOT_USE_REQUEST_ID.includes(module!)) {
          return {
            url: `${module}/request/${requestId}/comment`,
            method: 'post',
            body: {
              ...body,
              id: requestId,
              module: 'request',
              subModule: module,
              recipientIds,
              subModuleRefId: moduleResourceId,
            },
          };
        }
        return {
          url: `comment/request/${requestId}`,
          method: 'post',
          body: {
            ...body,
            id: requestId,
            module: 'request',
            subModule: module,
            recipientIds,
            subModuleRefId: moduleResourceId,
          },
        };
      },
      invalidatesTags: ['Comment'],
    }),
    updateComment: builder.mutation<void, CommentUpdate>({
      query: ({ requestId, module, moduleResourceId, ...body }) => {
        return {
          url: `comment/request/${requestId}`,
          method: 'put',
          body: {
            ...body,
            module: 'request',
            subModule: module,
            subModuleRefId: moduleResourceId,
          },
        };
      },
      invalidatesTags: ['Comment'],
    }),
    deleteComment: builder.mutation<void, CommentDelete>({
      query: ({ requestId, ...body }) => {
        return {
          url: `comment/request/${requestId}`,
          method: 'delete',
          body,
        };
      },
      invalidatesTags: ['Comment'],
    }),
  }),
});

export const {
  useGetCommentListQuery,
  useLazyGetCommentListQuery,
  useUpdateCommentMutation,
  useCreateCommentMutation,
  useDeleteCommentMutation,
} = commentApi;
