import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  BaseQueryFn,
  FetchArgs,
  QueryDefinition,
} from '@reduxjs/toolkit/query';
import { useGetCompanyServiceProvidersDropdownListQuery } from 'services/serviceProviderApi';
import { useGetServiceProvidersByTradeIdAndBranchIdQuery } from 'services/tradeApi';
import { useGetTroubleshootTipsQuery } from 'services/troubleshootingTipsApi';
import { QueryParams } from 'types/QueryParams';

export type RankFieldQueryHookType = UseQuery<
  QueryDefinition<
    QueryParams | any,
    BaseQueryFn<string | FetchArgs | any, unknown, FetchBaseQueryError>,
    any,
    any,
    'emptyApi'
  >
>;

type RankFieldOptionsFnMapType = {
  [key: string]: {
    hook: RankFieldQueryHookType | undefined;
    hookSt?: string;
    obAlias: string;
    filterHook?: RankFieldQueryHookType | undefined;
    filterKey?: string;
    dataKey?: string;
    inGroupTitle?: string;
    outGroupTitle?: string;
  };
};

const RankFieldOptionsFnMap: RankFieldOptionsFnMapType = {
  useGetCompanyServiceProvidersDropdownListQuery: {
    hook: useGetCompanyServiceProvidersDropdownListQuery,
    hookSt: '1',
    obAlias: 'cmpny.name',
    filterHook: useGetServiceProvidersByTradeIdAndBranchIdQuery,
    filterKey: 'id',
    dataKey: 'id',
    inGroupTitle: 'Assigned to this trade/region',
    outGroupTitle: 'All Service Providers',
  },
  useGetTroubleshootTipsQuery: {
    hook: useGetTroubleshootTipsQuery,
    obAlias: 'name',
  },
};

export const getRankFieldHook = (hookName?: string) => {
  const hookFn = RankFieldOptionsFnMap[hookName || ''];
  
  return hookFn;
};
