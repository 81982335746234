import {
  ColType,
  ECEasyFormFieldType,
  ECEasyTableConfigType,
} from 'app/components';
import * as _ from 'lodash';
import { ApiTableConfig } from 'types/ApiTableConfig';

interface PopulateDropDownFieldsProps {
  responseData: any;
  responseConfig?: ApiTableConfig;
  createFormFields?: ECEasyFormFieldType[];
  editFormFields?: ECEasyFormFieldType[];
  dataOptionsFieldName?: string;
  dataValuesFieldName?: string;
  isPopulateForChipsComponent?: boolean;
  formFieldName: string;
  defaultSelectedOption?: number;
  enabledAfterPopulate?: boolean;
  dontChangeValues?: boolean;
  populateOriginalOptions?: boolean;
}

export const populateDropDownFields = ({
  responseData,
  responseConfig,
  createFormFields,
  editFormFields,
  dataOptionsFieldName = '',
  dataValuesFieldName = '',
  formFieldName,
  defaultSelectedOption,
  isPopulateForChipsComponent = false,
  enabledAfterPopulate = true,
  dontChangeValues = false,
  populateOriginalOptions = false,
}: PopulateDropDownFieldsProps) => {
  const data = Array.isArray(responseData)
    ? responseData
    : (responseData as any).data;

  const newCreateFormFields = _.clone(createFormFields) || [];
  const newEditFormFields = _.clone(editFormFields) || [];

  const createSelectField = newCreateFormFields?.find(
    field => field.fieldName === formFieldName,
  );
  const editFormSelectField = newEditFormFields?.find(
    field => field.fieldName === formFieldName,
  );
  const createSelectFieldIndex = newCreateFormFields?.findIndex(
    field => field.fieldName === formFieldName,
  );
  const editFormSelectFieldIndex = newEditFormFields?.findIndex(
    field => field.fieldName === formFieldName,
  );

  if (createSelectField) {
    createSelectField.options =
      _.reject(
        isPopulateForChipsComponent
          ? data.map(d => ({
              id: _.get(d, dataValuesFieldName),
              label: _.get(d, dataOptionsFieldName),
              colorName: _.get(d, 'color'),
              level: _.get(d, 'level'),
            }))
          : data?.map(d => _.get(d, dataOptionsFieldName, d)),
        _.isNil,
      ) || [];
    createSelectField.optionValues =
      _.reject<any>(
        data?.map(d => _.get(d, dataValuesFieldName, d)),
        _.isNil,
      ) || [];
    if (defaultSelectedOption !== undefined) {
      createSelectField.value =
        createSelectField.optionValues[defaultSelectedOption];
    }
    if (populateOriginalOptions) {
      createSelectField.originalOptions = [...data];
    }
    createSelectField.disabled = enabledAfterPopulate
      ? false
      : createSelectField.disabled;

    if (responseConfig) {
      const { page, take, totalCount } = responseConfig.pagination;
      const noMoreDataToFetch = (+page + 1) * +take >= totalCount;
      createSelectField.noMoreDataToFetch = noMoreDataToFetch;
    }
  }

  if (editFormSelectField) {
    editFormSelectField.options =
      _.reject(
        isPopulateForChipsComponent
          ? data.map(d => ({
              id: _.get(d, dataValuesFieldName),
              label: _.get(d, dataOptionsFieldName),
              colorName: _.get(d, 'color'),
            }))
          : data?.map(d => _.get(d, dataOptionsFieldName, d)),
        _.isNil,
      ) || [];
    editFormSelectField.optionValues =
      _.reject<any>(
        data?.map(d => _.get(d, dataValuesFieldName, d)),
        _.isNil,
      ) || [];
    if (!dontChangeValues) {
      editFormSelectField.value = editFormSelectField.optionValues?.[0] ?? null;
    }
    if (populateOriginalOptions) {
      editFormSelectField.originalOptions = [...data];
    }
    editFormSelectField.disabled = enabledAfterPopulate
      ? false
      : editFormSelectField.disabled;

    if (responseConfig) {
      const { page, take, totalCount } = responseConfig.pagination;
      const noMoreDataToFetch = (+page + 1) * +take >= totalCount;
      editFormSelectField.noMoreDataToFetch = noMoreDataToFetch;
    }
  }

  if (
    createSelectFieldIndex &&
    createSelectFieldIndex >= 0 &&
    createSelectField
  ) {
    newCreateFormFields[createSelectFieldIndex] = createSelectField;
  }

  if (
    editFormSelectFieldIndex &&
    editFormSelectFieldIndex >= 0 &&
    editFormSelectField
  ) {
    newEditFormFields[editFormSelectFieldIndex] = editFormSelectField;
  }

  return [[...(newCreateFormFields ?? [])], [...(newEditFormFields ?? [])]];
};

interface PopulateAutoCompleteFieldsProps {
  responseData: any;
  createFormFields?: ECEasyFormFieldType[];
  editFormFields?: ECEasyFormFieldType[];
  dataOptionsFieldName?: string;
  dataValuesFieldName?: string;
  dataGroupFieldName?: string;
  formFieldName: string;
  defaultSelectedOption?: number;
  enabledAfterPopulate?: boolean;
  dontChangeValues?: boolean;
}

/** This Method should be use for Autocomplete field, it can take group field name, and group
 * elements together with a title . */
export const populateAutoCompleteFields = ({
  responseData,
  createFormFields,
  editFormFields,
  dataOptionsFieldName = '',
  dataValuesFieldName = '',
  dataGroupFieldName = '',
  formFieldName,
  defaultSelectedOption,
  enabledAfterPopulate = true,
  dontChangeValues = false,
}: PopulateAutoCompleteFieldsProps) => {
  const data =
    (Array.isArray(responseData)
      ? responseData
      : (responseData as any).data
    )?.map(data => {
      return {
        label: data[dataOptionsFieldName],
        id: data[dataValuesFieldName],
        name: data[dataOptionsFieldName],
        group: data[dataGroupFieldName],
      };
    }) ?? [];

  const newCreateFormFields = _.clone(createFormFields) || [];
  const newEditFormFields = _.clone(editFormFields) || [];

  const createSelectField = newCreateFormFields?.find(
    field => field.fieldName === formFieldName,
  );
  const editFormSelectField = newEditFormFields?.find(
    field => field.fieldName === formFieldName,
  );
  const createSelectFieldIndex = newCreateFormFields?.findIndex(
    field => field.fieldName === formFieldName,
  );
  const editFormSelectFieldIndex = newEditFormFields?.findIndex(
    field => field.fieldName === formFieldName,
  );
  if (createSelectField) {
    createSelectField.options = data;
    createSelectField.optionValues = data.map(sp => sp.id);
    if (defaultSelectedOption !== undefined) {
      createSelectField.value =
        createSelectField.options![defaultSelectedOption];
    }

    createSelectField.disabled = enabledAfterPopulate
      ? false
      : createSelectField.disabled;
  }

  if (editFormSelectField) {
    editFormSelectField.options = data;
    editFormSelectField.optionValues = data.map(sp => sp.id);
    if (!dontChangeValues) {
      editFormSelectField.value = editFormSelectField.optionValues?.[0] ?? null;
    }
    editFormSelectField.disabled = enabledAfterPopulate
      ? false
      : editFormSelectField.disabled;
  }

  if (
    createSelectFieldIndex &&
    createSelectFieldIndex >= 0 &&
    createSelectField
  ) {
    newCreateFormFields[createSelectFieldIndex] = createSelectField;
  }

  if (
    editFormSelectFieldIndex &&
    editFormSelectFieldIndex >= 0 &&
    editFormSelectField
  ) {
    newEditFormFields[editFormSelectFieldIndex] = editFormSelectField;
  }

  return [[...(newCreateFormFields ?? [])], [...(newEditFormFields ?? [])]];
};

export function getConfigStateFromApiOrMock(
  data: any,
  initialConfig: ECEasyTableConfigType,
  t: any,
) {
  const totalResults =
    data?.config?.pagination?.totalCount ??
    initialConfig.pagination.totalResults;
  let cols = initialConfig.cols;
  if (data?.config?.fields) {
    cols = data.config.fields.map(field => {
      if (field.type === ColType.Collapsible) {
        return field;
      } else if (field.type === ColType.ChipsList) {
        const splited = field.name.split('.');
        const fieldName = splited[0];
        const chipTitleDataPath = splited[1];
        return {
          ...field,
          fieldName,
          dataPath: fieldName,
          title: t(`translation:table.cols.${field.label}`, {
            defaultValue: field.label,
          }),
          chipTitleDataPath,
        };
      }
      return {
        ...field,
        fieldName: field.fieldName || field.name,
        dataPath: field.dataPath || field.name,
        title: t(`translation:table.cols.${field.label}`, {
          defaultValue: field.label,
        }),
      };
    });
  }
  const newHeader = data?.config?.header ?? initialConfig?.header;
  const newConfigState = {
    ...initialConfig,
    pagination: {
      ...initialConfig.pagination,
      totalResults,
    },
    header: newHeader,
    cols,
  };
  return newConfigState;
}
