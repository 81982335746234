import { useCallback, useLayoutEffect, useState } from 'react';
import { get } from 'lodash';
import { ECEasyFormFieldType } from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { useGetSuppliersListForDropdownQuery } from 'services/supplierApi';

const fancyFormElementsCreate = require('./fancy_form_config_step1_create.json');

interface Step1Props {
  existingData?: any;
  readOnly?: boolean;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

export const Step1 = ({ existingData, onClose, onSubmit }: Step1Props) => {
  useLayoutEffect(() => {
    const suppliersField = formFields.find(
      field => field.fieldName === 'supplier',
    );
    suppliersField.useQuery = useGetSuppliersListForDropdownQuery;
    suppliersField.queryParams = {
      sb: 'splr.name',
      ob: 'splr.name',
      o: 'a',
    };

    const purchaseOrderItemsField = formFields.find(
      field => field.fieldName === 'purchaseOrderItems',
    );

    purchaseOrderItemsField.disabled = !existingData?.supplier?.id;

    setFormFields([...formFields]);
  }, []);

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      const value = get(existingData, field.fieldName);
      return {
        ...field,
        value,
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields?.map(handleExistingData),
  );

  const submit = useCallback(() => {
    const doSubmit = async data => {
      const hasPOItemWithDuplicatePartNumber = data.purchaseOrderItems.some(
        item => item?.isDuplicatedPartNumber,
      );

      if (hasPOItemWithDuplicatePartNumber) {
        return;
      }

      onSubmit?.(data);
    };

    return [doSubmit, {}];
  }, [onSubmit]);

  const handleChange = useCallback((output, fieldName) => {
    const supplierField = output.find(field => field.fieldName === 'supplier');

    const purchaseOrderItemsField = output.find(
      field => field.fieldName === 'purchaseOrderItems',
    );

    purchaseOrderItemsField.disabled = !supplierField?.value;

    setFormFields([...output]);
  }, []);

  return (
    <ECEasyFormCreate
      useCreateMutation={submit}
      hideToolBar
      showTitle={false}
      formConfig={fancyFormElementsCreate.config}
      formFields={formFields}
      onClose={onClose}
      onChange={handleChange}
    />
  );
};
