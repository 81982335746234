import { ECAutocompleteAssetItem } from 'app/components/ECAutocompleteAssetItem';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import _ from 'lodash';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useGetServiceRequestBranchesListQuery } from 'services/branchApi';
import { useGetServiceRequestAssetsListQuery } from 'services/assetApi';
import { ECEasyFormFieldType } from 'app/components';

const fancyFormElementsCreate = require('./fancy_form_config_step1_create.json');

interface Step1Props {
  existingData?: any;
  readOnly?: boolean;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

export const Step1 = ({ existingData, onClose, onSubmit }: Step1Props) => {
  useLayoutEffect(() => {
    const branchField = formFields.find(field => field.fieldName === 'branch');
    branchField.useQuery = useGetServiceRequestBranchesListQuery;
    branchField.queryParams = {
      sb: 'brnch.name',
      ob: 'brnch.name',
      st: 1,
      withoutOnHold: 1,
    };

    const assetField = formFields.find(field => field.fieldName === 'asset');
    assetField.useQuery = useGetServiceRequestAssetsListQuery;
    assetField.renderCustomOption = option => {
      return <ECAutocompleteAssetItem asset={option} />;
    };
    assetField.renderCustomSelectedValue = value => {
      return <ECAutocompleteAssetItem asset={value} />;
    };
    assetField.queryParams = {
      sb: 'assetComb.name',
      sg: 0,
      sglo: 'or',
      branchId: existingData?.branch?.id,
    };
    assetField.extraSearchFieldsAlias = [
      'assetComb.id',
      'assetType.name',
      'qrCode',
    ];

    setFormFields([...formFields]);
  }, []);

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      return {
        ...field,
        value: _.get(existingData, field.fieldName),
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields?.map(handleExistingData),
  );

  const submit = useCallback(() => {
    const doSubmit = async data => {
      onSubmit?.(data);
    };

    return [doSubmit, {}];
  }, [onSubmit]);

  const handleChange = useCallback((output, fieldName) => {
    const branchField = output.find(field => field.fieldName === 'branch');
    const assetField = output.find(field => field.fieldName === 'asset');

    if (fieldName === 'branch') {
      assetField.queryParams = {
        sb: 'assetComb.name',
        sg: 0,
        sglo: 'or',
        branchId: branchField.value?.id,
      };
      assetField.extraSearchFieldsAlias = [
        'assetComb.assetType',
        'assetComb.qrCode',
      ];

      assetField.value = null;
    }

    setFormFields([...output]);
  }, []);

  return (
    <ECEasyFormCreate
      useCreateMutation={submit}
      formConfig={fancyFormElementsCreate.config}
      formFields={formFields}
      onClose={onClose}
      onChange={handleChange}
    />
  );
};
