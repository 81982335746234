import * as React from 'react';

import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  ECListItemIcon,
  ECListItemText,
  ECListItemButton,
  ECCollapse,
} from '..';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';
import { usePanelDrawerExpandedItem } from 'app/sections/PanelDrawer/context/panel-drawer-expanded-item.context';
import { countOccurrencesOfChar } from 'utils/strings/count-occurrences-of-char';
import { ECBox } from '../ECBox';
import { usePanelDrawerSecondLevelExpandedItem } from 'app/sections/PanelDrawer/context/panel-drawer-second-level-expanded-item.context';
import { BoxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { P, CompanyConfigurationFields } from 'types/Permission';
import { requirePermissionWrapper } from 'app/hoc/require-permission';

export const drawerWidth = 370;
export const drawerWidthClosed = 65;
const drawerSecondLevelWidth = 304;

const openedMixin = (theme: Theme, width: number = drawerWidth): CSSObject => ({
  width: width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, width: number): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(width)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(width > 0 ? width + 1 : width)} + 1px)`,
  },
});

export const ECDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open',
})<DrawerProps>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme, 7),
    '& .MuiDrawer-paper': closedMixin(theme, 7),
  }),
  '& .MuiListItemButton-root.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },
}));

export const ECDrawerSecondLevel = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open',
})<DrawerProps>(({ theme, open }) => ({
  width: drawerSecondLevelWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, drawerSecondLevelWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerSecondLevelWidth),
  }),
  ...(!open && {
    ...closedMixin(theme, 0),
    '& .MuiDrawer-paper': closedMixin(theme, 0),
  }),
  '& .MuiListItemButton-root.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },
}));

interface ECDrawerItemProps {
  title: string;
  icon?: React.ReactNode;
  uri: string;
  open?: boolean;
  isSecondLevelDrawer?: boolean;
  isRootMenu?: boolean;
  scopes?: P[];
  companyConfigScopes?: CompanyConfigurationFields[];
}

export const DrawerItem = ({
  title,
  uri,
  icon,
  open,
  isSecondLevelDrawer,
  scopes,
  isRootMenu: isRootMenuFromProps,
}: ECDrawerItemProps) => {
  const navigate = useNavigate();
  const match = useMatch('/panel/*');
  const location = useLocation();

  const { dispatch } = usePanelDrawerExpandedItem();
  const { dispatch: dispatchSecondLevel } =
    usePanelDrawerSecondLevelExpandedItem();

  const isSelected = useMemo<boolean>(
    () => location.pathname.includes(`${match?.pathnameBase}/${uri}`),
    [location, match, uri],
  );

  // ignore sp/ subpath for depth count because it does not make it a submenu
  const uriIgnoreSp = uri.replaceAll('sp/', '');
  const depth = useMemo<number>(
    () => countOccurrencesOfChar('/', uriIgnoreSp),
    [uriIgnoreSp],
  );
  const isSubmenu = useMemo(
    () => depth >= 1 && !isSecondLevelDrawer && !isRootMenuFromProps,
    [depth, isSecondLevelDrawer],
  );
  const isRootMenu = useMemo(
    () => depth === 0 || isRootMenuFromProps,
    [depth, isRootMenuFromProps],
  );

  const handleClick = () => {
    navigate(`${match?.pathnameBase}/${uri}`);
    if (isRootMenu) {
      dispatch({ type: 'close' });
    } else if (isSecondLevelDrawer) {
      dispatchSecondLevel({ type: 'close' });
    }
  };

  return (
    <ECBox>
      <ECListItemButton
        key={title}
        onClick={handleClick}
        selected={isSelected}
        sx={{
          margin: 0,
          '&:hover': {
            backgroundColor: theme =>
              isSubmenu
                ? theme.palette.grey[300]
                : theme.palette.other.snackbarBackground,
          },
          backgroundColor: theme =>
            isSubmenu
              ? isSelected
                ? `${theme.palette.grey[300]} !important`
                : theme.palette.grey[100]
              : isSelected
                ? `${theme.palette.primary.main} !important`
                : theme.palette.marketingSuggestion.marketingSuggestion,
        }}
      >
        {!isSubmenu && !!icon && (
          <ECListItemIcon
            sx={{ minHeight: 28, color: theme => theme.palette.common.white }}
          >
            {icon}
          </ECListItemIcon>
        )}
        {(open || isSecondLevelDrawer) && (
          <ECListItemText
            primary={title}
            sx={{
              pl: isSubmenu ? 2 : 0,
            }}
            primaryTypographyProps={{
              fontWeight: 'bold',
              fontSize: isSubmenu && !isSecondLevelDrawer ? '0.875rem' : '1rem',
              color: theme =>
                isSubmenu
                  ? theme.palette.text.primary
                  : theme.palette.primary.contrastText,
            }}
          />
        )}
      </ECListItemButton>
    </ECBox>
  );
};

export const ECDrawerItem =
  requirePermissionWrapper<ECDrawerItemProps>(DrawerItem);

interface ECCollapseDrawerItemProps extends BoxProps {
  text: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  isDrawerOpen?: boolean;
  depth?: number;
  shouldOpenSideMenu?: boolean;
  isSecondLevelDrawer?: boolean;
  onClick?: () => void;
}

export const CollapseDrawerItem = ({
  children,
  icon,
  text,
  isDrawerOpen,
  depth = 0,
  shouldOpenSideMenu = false,
  isSecondLevelDrawer = false,
  onClick,
  ...boxProps
}: ECCollapseDrawerItemProps) => {
  const { t } = useTranslation();

  const {
    state: { expandedItemTitle },
    dispatch: dispatchFirstLevel,
  } = usePanelDrawerExpandedItem();

  const {
    state: { expandedItemTitle: expandedItemTitleSecondLevel },
    dispatch: dispatchSecondLevel,
  } = usePanelDrawerSecondLevelExpandedItem();

  const isExpanded = useMemo(
    () =>
      isSecondLevelDrawer
        ? expandedItemTitleSecondLevel === text
        : expandedItemTitle === text,
    [
      expandedItemTitle,
      expandedItemTitleSecondLevel,
      isSecondLevelDrawer,
      text,
    ],
  );

  const isRootMenu = useMemo(
    () => depth === 0 && !isSecondLevelDrawer,
    [depth, isSecondLevelDrawer],
  );

  const handleClick = () => {
    onClick?.();

    if (shouldOpenSideMenu) {
      dispatchFirstLevel({
        type: 'expand',
        payload: { expandedItemTitle: text, openSecondLevelDrawer: true },
      });
      return;
    }

    if (
      expandedItemTitle === t('translation:panelDrawer.configuration') &&
      isRootMenu
    ) {
      dispatchFirstLevel({
        type: 'expand',
        payload: { expandedItemTitle: text },
      });
      dispatchFirstLevel({ type: 'hideSideMenu' });
      dispatchSecondLevel({ type: 'close' });
      return;
    }

    if (isExpanded) {
      if (isSecondLevelDrawer) {
        dispatchSecondLevel({
          type: 'close',
          payload: { expandedItemTitle: text },
        });
      } else {
        dispatchFirstLevel({
          type: 'close',
          payload: { expandedItemTitle: text },
        });
      }
    } else {
      if (isSecondLevelDrawer) {
        dispatchSecondLevel({
          type: 'expand',
          payload: { expandedItemTitle: text },
        });
      } else {
        dispatchFirstLevel({
          type: 'expand',
          payload: { expandedItemTitle: text },
        });
      }
    }
  };

  return (
    <ECBox pl={isSecondLevelDrawer ? 0 : 0} {...boxProps}>
      <ECListItemButton
        sx={{
          margin: 0,
          '&:hover': {
            backgroundColor: theme =>
              isExpanded
                ? `${theme.palette.primary.main} !important`
                : theme.palette.other.snackbarBackground,
          },
          backgroundColor: theme =>
            isExpanded
              ? theme.palette.primary.main
              : theme.palette.marketingSuggestion.marketingSuggestion,
        }}
        onClick={handleClick}
      >
        {!isSecondLevelDrawer && (
          <ECListItemIcon
            sx={{
              color: theme => theme.palette.primary.contrastText,
            }}
          >
            {' '}
            {icon}{' '}
          </ECListItemIcon>
        )}
        {(isDrawerOpen || isSecondLevelDrawer) && (
          <ECListItemText
            primaryTypographyProps={{
              fontWeight: 'bold',
              fontSize: '1rem',
              color: theme => theme.palette.primary.contrastText,
            }}
            primary={text}
            sx={{ pl: depth * 2 }}
          />
        )}
        {(isDrawerOpen || isSecondLevelDrawer) && (
          <>
            {' '}
            {isExpanded ? (
              <ExpandLess
                sx={{
                  color: theme => theme.palette.background.default,
                }}
                fontSize={'small'}
              />
            ) : (
              <ExpandMore
                sx={{
                  color: theme => theme.palette.background.default,
                }}
                fontSize={'small'}
              />
            )}
          </>
        )}
      </ECListItemButton>
      <ECCollapse
        sx={{
          backgroundColor: theme =>
            isExpanded ? theme.palette.action.activatedOpacity : 'transparent',
        }}
        in={isExpanded}
        timeout="auto"
        unmountOnExit
      >
        {(isDrawerOpen || isSecondLevelDrawer) && children}
      </ECCollapse>
    </ECBox>
  );
};

export const ECCollapseDrawerItem =
  requirePermissionWrapper<ECCollapseDrawerItemProps>(CollapseDrawerItem);
