import {
  Lookup,
  LookupSIC,
  LookupWarrantyPeriod,
  LookupData,
  Timezone,
  Settings,
  LookupPeriod,
  LookupCountry,
} from 'types/Lookup';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';

import { emptyApi } from './emptyApi';

// if need tags use below
// const apiWithTag = emptyApi.enhanceEndpoints({
//   addTagTypes: [
//     '',
//   ]
// });

export const lookupApi = emptyApi.injectEndpoints({
  endpoints: builder => ({
    getOptions: builder.query<LookupData, any>({
      query: endpoint => endpoint,
      // providesTags: ['User'],
    }),

    getTimezones: builder.query<Timezone[], void>({
      query: () => ({
        url: 'lookup/timezone',
      }),
    }),

    getEtas: builder.query<Lookup[], void>({
      query: () => ({
        url: 'lookup/eta',
      }),
    }),

    getSettingsBulletin: builder.query<Settings[], void>({
      query: () => ({
        // using auth because no need permissions
        url: 'bulletin',
      }),
    }),

    getTaxClassifications: builder.query<Lookup[], void>({
      query: () => ({
        url: 'lookup/tax-classification',
      }),
    }),

    getWarrantyPeriods: builder.query<LookupWarrantyPeriod[], void>({
      query: () => ({
        url: 'lookup/period/warranty',
      }),
    }),

    getDeclineTypes: builder.query<Lookup[], void>({
      query: () => ({
        url: 'lookup/declineTypes',
      }),
    }),

    getCompletionTimeUnit: builder.query<LookupWarrantyPeriod[], void>({
      query: () => ({
        url: 'lookup/period/payment',
      }),
    }),

    getResolutions: builder.query<Lookup[], void>({
      query: () => ({
        url: 'lookup/resolution',
      }),
    }),

    getInventoryStorageTypes: builder.query<Lookup[], void>({
      query: () => ({
        url: 'lookup/storage/types',
      }),
    }),

    getWorkOrderAutomationPreference: builder.query<Lookup[], void>({
      query: () => ({
        url: 'lookup/workorder/automation/preference',
      }),
    }),

    getPeriod: builder.query<LookupPeriod[], void>({
      query: () => ({
        url: 'lookup/period',
      }),
    }),
    getSICs: builder.query<BaseType<LookupSIC[]>, QueryParams>({
      query: params => {
        return {
          url: 'lookup/sics',
          params,
        };
      },
      transformResponse: (response: BaseType<LookupSIC[]>) => {
        const dataWithName = response.data.map(row => ({
          ...row,
          name: row.code,
        }));

        return {
          ...response,
          data: dataWithName as LookupSIC[],
        };
      },
    }),
    getIndustries: builder.query<BaseType<Lookup[]>, QueryParams>({
      query: params => {
        return {
          url: 'lookup/industries',
          params,
        };
      },
    }),
    getCountriesForDropdown: builder.query<LookupCountry[], void>({
      query: () => {
        return {
          url: 'lookup/country/drop-down',
        };
      },
    }),
    getOnHoldReasons: builder.query<Lookup[], void>({
      query: () => {
        return {
          url: 'lookup/on-hold-reasons',
        };
      },
      keepUnusedDataFor: 15 * 60,
    }),
    getRejectWarrantyReasons: builder.query<Lookup[], void>({
      query: () => {
        return {
          url: 'lookup/reject-warranty-reason',
        };
      },
      keepUnusedDataFor: 15 * 60,
    }),
    getLaborItems: builder.query<any[], void>({
      query: () => {
        return {
          url: 'lookup/labor-travel-items',
        };
      },
    }),
    getInvoiceRejectionReasons: builder.query<Lookup[], void>({
      query: () => ({
        url: 'lookup/declineTypes',
      }),
    }),
  }),
});

export const {
  useGetOptionsQuery,
  useLazyGetOptionsQuery,
  useGetTimezonesQuery,
  useLazyGetTimezonesQuery,
  useGetTaxClassificationsQuery,
  useLazyGetTaxClassificationsQuery,
  useGetWarrantyPeriodsQuery,
  useLazyGetWarrantyPeriodsQuery,
  useGetDeclineTypesQuery,
  useLazyGetDeclineTypesQuery,
  useGetResolutionsQuery,
  useLazyGetResolutionsQuery,
  useGetCompletionTimeUnitQuery,
  useLazyGetCompletionTimeUnitQuery,
  useGetSettingsBulletinQuery,
  useGetInventoryStorageTypesQuery,
  useGetWorkOrderAutomationPreferenceQuery,
  useGetPeriodQuery,
  useGetSICsQuery,
  useLazyGetSICsQuery,
  useGetIndustriesQuery,
  useLazyGetIndustriesQuery,
  useGetEtasQuery,
  useLazyGetEtasQuery,
  useGetCountriesForDropdownQuery,
  useLazyGetCountriesForDropdownQuery,
  useGetOnHoldReasonsQuery,
  useLazyGetOnHoldReasonsQuery,
  useGetRejectWarrantyReasonsQuery,
  useGetLaborItemsQuery,
  useGetInvoiceRejectionReasonsQuery,
} = lookupApi;
