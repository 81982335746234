import { useCallback, useLayoutEffect } from 'react';
import { ECEasyFormCreate } from 'app/components';
import { useGetProblemListByAssetIdQuery } from 'services/problemApi';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { useGetSPsForServiceRequestQuery } from 'services/tradeApi';
import { useGetCategoryListQuery } from 'services/categoryApi';

interface Step2Props {
  existingData?: any;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step2_create.json');

export const Step2 = ({ existingData, onClose, onSubmit }: Step2Props) => {
  const isGeneralAsset = existingData?.asset?.isGeneralAsset;

  useLayoutEffect(() => {
    // populate problem field with query
    const problemField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'problemId',
    );
    problemField.useQuery = useGetProblemListByAssetIdQuery;
    problemField.queryParams = {
      id: existingData.asset?.id,
      st: 1,
      isGeneralAsset,
    };

    // find priority and category fields group
    const priorityAndCategoryGroupField = fancyFormElementsCreate.fields.find(
      field => field.groupName === 'priorityAndCategoryGroup',
    ).subFields;
    // populate priority field query
    const priorityField = priorityAndCategoryGroupField.find(
      field => field.fieldName === 'priority',
    );
    priorityField.useQuery = useGetPrioritiesListQuery;
    priorityField.queryParams = {
      st: 1,
      ob: 'prrt.name',
      o: 'desc',
    };
    const asset = existingData?.asset;
    const id = asset?.priorityId ?? null;
    const name = asset?.priorityName ?? null;
    priorityField.value = { id, name };

    // populate category field query
    const categoryField = priorityAndCategoryGroupField.find(
      field => field.fieldName === 'category',
    );
    categoryField.useQuery = useGetCategoryListQuery;
    categoryField.queryParams = {
      st: 1,
    };
    categoryField.obAlias = 'rqstctgry.name';
    categoryField.sbAlias = 'rqstctgry.name';
    categoryField.filterOptionsFn = category => category?.name !== 'Inventory';

    const serviceProviderField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'serviceProvider',
    );
    serviceProviderField.useQuery = useGetSPsForServiceRequestQuery;
    serviceProviderField.queryParams = {
      assetId: !isGeneralAsset ? existingData?.asset?.id : undefined,
      generalAssetId: isGeneralAsset ? existingData?.asset?.id : undefined,
      branchId: isGeneralAsset ? existingData?.branch?.id : undefined,
    };
    serviceProviderField.multiple = true;
    serviceProviderField.value = existingData?.serviceProvider ?? [];
  }, []);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      onSubmit?.({ ...existingData, ...data });
    };

    return [doSubmit, {}];
  }, [onSubmit]);

  return (
    <ECEasyFormCreate
      useCreateMutation={submit}
      onClose={onClose}
      formConfig={fancyFormElementsCreate.config}
      formFields={fancyFormElementsCreate.fields}
      existingData={existingData}
    />
  );
};
