import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useLazyGetAllPurchaseOrderListQuery } from 'services/purchaseOrderApi';
import { NewPO } from './NewPO';
import { useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';

export const PurchaseOrders = ({ marginTop = true }) => {
  const { t } = useTranslation();

  const [shouldTriggerLazyQuery, setShouldTriggerLazyQuery] = useState(false);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('purchase-order');

  useEffect(() => {
    setShouldTriggerLazyQuery(
      !isFetchingWorkflowStatuses && isSuccessWorkflowStatuses,
    );
  }, [isFetchingWorkflowStatuses, isSuccessWorkflowStatuses]);

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.groupId)
            ?.join(','),
          isInitialSelected: true,
        }))
      : undefined;
  }, [workflowStatuses]);

  const statusFilterOptionsWithDuplicatesRemoved = useMemo(() => {
    return statusFilterOptions?.filter(
      (status, index, self) =>
        index === self.findIndex(t => t.label === status.label),
    );
  }, [statusFilterOptions]);

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:pages.purchaseOrders.purchaseOrderList')}
        useLazyGetListQuery={useLazyGetAllPurchaseOrderListQuery}
        useCreateMutation={undefined}
        useUpdateMutation={undefined}
        editFormConfig={[] as any}
        editFormFields={[] as any}
        createFormConfig={undefined as any}
        createFormFields={undefined as any}
        detailsConfig={[] as any}
        detailsFields={[] as any}
        onRowClick={null as any}
        onDrawerClose={null as any}
        showOnlyAttachmentsTab={true}
        marginTop={marginTop}
        customContent={{
          header: <NewPO />,
        }}
        shouldTriggerLazyQuery={shouldTriggerLazyQuery}
        statusFilterOptions={statusFilterOptionsWithDuplicatesRemoved}
        statusFilterAlias="wrkflwstts.groupNameId"
        showStatusFilter
        showOnlyDetailsTab
        statusFilterInitialSelected
        shouldCacheLazyQuery
        withDrawer={false}
        showColumnVisibilityFilter
        enableExport
        enableTableCsvExport
        moduleName="purchase-orders"
        conditionalLinearProgressColor
      />
    </>
  );
};
