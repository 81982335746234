import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import { ECBox } from '../ECBox';

interface ForwardArrowProps {
  isVisible: boolean;
}

const ForwardArrow: React.FC<ForwardArrowProps> = ({ isVisible }) => (
  <ECBox display="flex" visibility={isVisible ? 'visible' : 'hidden'}>
    <ArrowForward
      sx={theme => ({
        color: theme.palette.action.active,
        mx: 1,
      })}
      fontSize="small"
    />
  </ECBox>
);

export default ForwardArrow;
