import { BrowserRouter } from 'react-router-dom';
import AppNavigatorWrapped from './AppNavigatorWrapped';

const packageJson = require('../../../../package.json');

const AppNavigator = () => {
  return (
    <BrowserRouter basename={packageJson.homepage}>
      <AppNavigatorWrapped />
    </BrowserRouter>
  );
};

export default AppNavigator;
