import {
  ECBox,
  ECButton,
  ECEasyForm,
  ECIconButton,
  ECInsightsButton,
  ECInsightsDrawer,
  ECWorkflowTemplate,
  FieldTypes,
} from 'app/components';
import { useParams } from 'react-router';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import {
  useCreateProposalMutation,
  useGetProposalNextStatusListQuery,
  useUpdateProposalMutation,
  useUpdateProposalStatusMutation,
} from 'services/proposalApi';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { WorkflowNextStatus, StatusActions } from 'types/WorkflowStatus';
import {
  useGetAndCalculateAssetInsightsQuery,
  useGetAssetWarrantyQuery,
} from 'services/assetApi';
import { useTranslation } from 'react-i18next';
import { approvalApi, useGetApprovalHistoryQuery } from 'services/approvalApi';
import { useDispatch } from 'react-redux';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { setSnackbar } from 'store/slice/page';
import { ECUsersTableModal } from 'app/components/ECUsersTableModal';
import { Popover } from '@mui/material';
import { ECModal } from 'app/components/ECModal';
import ContactInfoIcon from '@mui/icons-material/PermContactCalendar';
import { AutoAwesome, Close } from '@mui/icons-material';
import { moduleApi, useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { ECApprovalHistoryButton } from 'app/components/ECApprovalHistory/ECApprovalHistoryButton';
import { WorkflowStatus } from 'app/components/ECWorkflowStatusBadge';
import moment from 'moment';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import _ from 'lodash';
import { requestForPricingApi } from 'services/requestForPricingApi';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');
const fancyFormRejectProposal = require('./fancy_form_config_reject.json');

export function ProposalDetailsPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<any[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAssetInsightsClicked, setIsAssetInsightsClicked] =
    useState<any>(false);
  const hasEditProposalPermission = useHasPermission([P.EditProposal]);

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const { data: proposal } = useGetModuleDetailsByIdQuery(
    { id: id || '', moduleName: 'proposal' },
    {
      skip: !id,
    },
  );

  const { data: categoryListData } = useGetCategoryListQuery(
    { st: 1 },
    {
      skip: !isDetailsTab,
    },
  );

  const dropdownCategoryList = useMemo(() => {
    if (categoryListData) {
      const categories = categoryListData?.data
        ?.filter(category => {
          return category?.module?.name !== 'Inventory';
        })
        ?.map(category => ({
          label: category.name,
          data: category.id,
        }));

      // if current category is not on list, add it
      if (
        proposal?.category &&
        proposal?.categoryId &&
        !categories?.some(category => category.data === proposal?.categoryId)
      ) {
        categories?.push({
          label: proposal?.category,
          data: proposal?.categoryId,
        });
      }
      return categories;
    } else {
      return [];
    }
  }, [categoryListData, proposal?.category, proposal?.categoryId]);

  const [proposalSummaryData, setProposalSummaryData] = useState<any>({});
  const [cancelEdit, setCancelEdit] = useState<boolean>(false);
  const [openReject, setOpenReject] = useState(false);
  const [showSPContactInfo, setShowSPContactInfo] = useState<boolean>(false);

  useEffect(() => {
    setFormFields(
      fancyFormElementsEdit.fields.map(field => {
        if (field.type === 'group') {
          return field.subFields.map(subField => {
            if (proposal?.mutableFields?.includes(subField.fieldName)) {
              subField.readOnly = false;
            } else {
              subField.readOnly = true;
            }
          });
        }

        if (proposal?.mutableFields?.includes(field.fieldName)) {
          field.readOnly = false;
        } else {
          field.readOnly = true;
        }

        return field;
      }),
    );
  }, [proposal?.mutableFields]);

  const { data: proposalNextStatus } = useGetProposalNextStatusListQuery(
    Number(id) || 0,
    { skip: !id || !isDetailsTab },
  );

  const { data: approvalHistory, isSuccess: isSuccessApprovalHistory } =
    useGetApprovalHistoryQuery(
      {
        id: Number(id) || 0,
        module: 'proposal',
      },
      {
        skip: !id || !isDetailsTab,
      },
    );

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  //const [assignedToSelected, setAssignedToSelected] = useState<any>(null);

  const onClickViewUsersTable = useCallback((field, event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    //setAssignedToSelected(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
    //setAssignedToSelected(false);
  }, []);

  useEffect(() => {
    const assignedToField =
      formElementsDetails?.data?.fields[0]?.subFields?.find(
        x => x.fieldName === 'assignedTo',
      );
    if (assignedToField) {
      assignedToField.onClickViewUsersTable = onClickViewUsersTable;
    }
  }, [onClickViewUsersTable]);

  // useEffect to add the view approval history button to the form
  useEffect(() => {
    // DETAILS FORM
    const approvalHistoryField = formElementsDetails.data?.fields
      ?.find(field => field.groupName === 'customButtonsAndStatus')
      ?.subFields?.find(f => f.fieldName === 'approvalHistoryButton');

    if (approvalHistoryField) {
      approvalHistoryField.value = (
        <ECApprovalHistoryButton approvalHistory={approvalHistory} />
      );
    }

    formElementsDetails.data.fields = [...formElementsDetails.data.fields];
  }, [approvalHistory]);

  useEffect(() => {
    if (!proposal?.id) return;

    const fields = formElementsDetails.data?.fields || [];
    const completionField = fields.find(
      field => field.fieldName === 'completionTimeByUnit',
    );

    if (completionField) {
      completionField.value =
        Number(proposal.completionTime) > 0
          ? `${proposal.completionTime} ${proposal.completionPeriod}`
          : '--';
    }

    formElementsDetails.data.fields = [...fields];
  }, [proposal]);

  useEffect(() => {
    // EDIT FORM
    const approvalHistoryField = fancyFormElementsEdit.fields
      ?.find(field => field.groupName === 'customButtonsAndStatus')
      ?.subFields?.find(f => f.fieldName === 'approvalHistoryButton');

    if (approvalHistoryField) {
      approvalHistoryField.value = (
        <ECApprovalHistoryButton approvalHistory={approvalHistory} disabled />
      );
    }
  }, []);

  const assignedToUsers = useMemo(() => {
    return approvalHistory?.currentAssignees?.map(assignee => {
      return {
        fullName: assignee.fullName,
        jobTitle: assignee.jobTitle,
        email: assignee.email,
      };
    });
  }, [isSuccessApprovalHistory, approvalHistory]);

  const [
    doUpdateStatus,
    { isLoading: isLoadingActions, isSuccess: isSuccessUpdateAction },
  ] = useUpdateProposalStatusMutation();

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(approvalApi.util.invalidateTags(['Approval']));
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(
        requestForPricingApi.util.invalidateTags([
          'RfpItemProposals',
          'RFPStatusList',
          'RfpItem',
        ]),
      );
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Proposal status updated successfully',
        }),
      );
    }
  }, [isSuccessUpdateAction]);

  useEffect(() => {
    // needs to insert value into moduleId field of reject form to load the notifier users
    if (proposal?.id) {
      const addCommentField = fancyFormRejectProposal.fields.find(
        f => f.fieldName === 'rejectReason',
      );

      if (addCommentField) {
        addCommentField.moduleId = proposal?.id;
      }
    }
  }, [proposal?.id, fancyFormRejectProposal]);

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
    { skip: !isDetailsTab || !isEditMode },
  );

  const handleChangeSummaryData = useCallback(
    (id: string) => (value: any) => {
      setProposalSummaryData(prev => ({ ...prev, [id]: value }));
    },
    [],
  );

  useEffect(() => {
    if (flagsData && isSuccessFlag) {
      let editSelectField = fancyFormElementsEdit.fields.find(
        field => field.fieldName === 'flagsIds',
      );
      editSelectField.options =
        flagsData?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      editSelectField.optionValues = flagsData?.data.map(d => d.label) || [];

      fancyFormElementsEdit.fields = [...fancyFormElementsEdit.fields];
      setFormFields(fancyFormElementsEdit.fields);
    }
  }, [isSuccessFlag, flagsData]);

  const [
    doUpdateProposal,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateProposalMutation();

  useEffect(() => {
    if (
      (!isLoadingActions && isSuccessUpdateAction) ||
      (!isUpdateLoading && isUpdateSuccess)
    ) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
    }
  }, [
    isLoadingActions,
    isSuccessUpdateAction,
    isUpdateLoading,
    isUpdateSuccess,
  ]);

  const [
    doCreateProposal,
    {
      data: createProposalData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreateProposal,
    },
  ] = useCreateProposalMutation();

  const useUpdateProposal = useCallback(() => {
    const doUpdate = async data => {
      if (!proposal?.id) {
        return;
      }

      const flagsIds = data.flagsIds?.map(flag => flag?.id);

      delete data.flagsIds;

      doUpdateProposal({
        flagsIds,
        ...data,
        requestCategoryId: proposalSummaryData.categoryId,
      });
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetCreateProposal,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    resetCreateProposal,
    proposal?.id,
    proposalSummaryData.categoryId,
    doUpdateProposal,
    proposalSummaryData,
  ]);

  const moduleName = useMemo(() => {
    return proposal?.assetId === proposal?.generalAssetId
      ? 'general-asset'
      : 'asset';
  }, [proposal?.assetId, proposal?.generalAssetId]);

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName,
      moduleId: proposal?.assetId ?? 0,
      category: AttachmentCategory.Photo,
    },
    { skip: !isDetailsTab || !proposal?.assetId },
  );

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(proposal?.assetId, {
    skip: !isDetailsTab && !proposal?.assetId,
  });

  const costTableData: any = useMemo(
    () =>
      proposal && {
        showIncurred: true,
        itemsRepair: [
          {
            label: 'Labor',
            value: proposal.labor,
            dataPath: 'labor',
            readOnly: !proposal?.mutableFields?.includes('labor'),
          },
          {
            label: 'Material',
            value: proposal.material,
            dataPath: 'material',
            readOnly: !proposal?.mutableFields?.includes('material'),
          },
          {
            label: 'Freight',
            value: proposal.freight,
            dataPath: 'freight',
            readOnly: !proposal?.mutableFields?.includes('freight'),
          },
          {
            label: 'Tax',
            value: proposal.tax,
            dataPath: 'tax',
            readOnly: !proposal?.mutableFields?.includes('tax'),
          },
          {
            label: 'Other',
            value: proposal.other,
            dataPath: 'other',
            readOnly: !proposal?.mutableFields?.includes('other'),
          },
          {
            label: 'Incurred',
            value: proposal.incurred,
            dataPath: 'incurred',
            hidden: true,
            readOnly: !proposal?.mutableFields?.includes('incurred'),
          },
        ],
      },
    [proposal],
  );

  const updateStatus = useCallback(
    async (data: WorkflowNextStatus) => {
      if (data.name === StatusActions.REJECT) {
        setOpenReject(true);
        return;
      }

      doUpdateStatus({
        id: Number(id),
        statusTo: data.statusTo,
        actionId: data.actionId,
      });
    },
    [doUpdateStatus, id],
  );

  const initializeSummaryFields = useCallback(() => {
    setProposalSummaryData({
      proposalStatus: proposal?.workflowStatusName,
      proposalTitle: proposal?.title ?? '',
      locationName: proposal?.branchName ?? '',
      assetName: proposal?.assetName ?? '',
      serviceProvider: proposal?.spCompanyName ?? '',
      proposalAmount: proposal?.amount?.toString() ?? '',
      proposalId: proposal?.id?.toString() ?? '',
      categoryId: proposal?.categoryId,
    });
    setCancelEdit(false);
  }, [proposal, setProposalSummaryData]);

  useEffect(() => {
    if (proposal) {
      initializeSummaryFields();
    }
  }, [proposal]);

  const { data: assetInsights, isLoading: isLoadingAssetInsights } =
    useGetAndCalculateAssetInsightsQuery(
      {
        id: proposal?.assetId || 0,
      },
      {
        skip:
          !proposal?.assetId ||
          !isDetailsTab ||
          proposal?.generalAssetId ||
          proposal?.pmId,
      },
    );

  const renderInsightsButton = useMemo(() => {
    return (
      <ECInsightsButton
        onClick={() => {
          setIsAssetInsightsClicked(true);
        }}
        title={t('assetInsights.buttonTitle')}
        status={assetInsights?.bookValue}
        icon={<AutoAwesome />}
      />
    );
  }, [assetInsights]);

  const summaryData = useMemo(() => {
    return [
      {
        id: 'proposalStatus',
        label: 'Proposal Status',
        data: proposal?.workflowStatusName,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'proposalTitle',
        label: 'Proposal Title',
        data: proposal?.title ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'locationName',
        label: 'Location',
        data: proposal?.branchName ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'assetName',
        label: 'Asset',
        data: proposal?.assetName ?? '',
        type: SummaryFieldTypes.Text,
        extraComponent:
          !proposal?.pmId && !proposal?.generalAssetId
            ? renderInsightsButton
            : null,
      },
      {
        id: 'serviceProvider',
        label: 'Service Provider',
        data: proposal?.spCompanyName ?? '',
        type: SummaryFieldTypes.Text,
        icon: <ContactInfoIcon fontSize="large" />,
        actionTitle: 'Contact Info',
        onIconClick: () => {
          setShowSPContactInfo(true);
        },
      },
      {
        id: 'proposalAmount',
        label: 'Proposal Amount',
        data: proposal?.amount?.toString() ?? '',
        type: SummaryFieldTypes.Currency,
      },
      {
        id: 'proposalId',
        label: 'Proposal ID',
        data: proposal?.id?.toString() ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'category',
        label: 'Category',
        data: proposalSummaryData.categoryId,
        type: SummaryFieldTypes.Select,
        isEditable: proposal?.mutableFields?.includes('requestCategoryId'),
        onChange: optionData => {
          handleChangeSummaryData('categoryId')(optionData?.data);
        },
        options: dropdownCategoryList,
        required: true,
      },
    ];
  }, [
    proposal,
    dropdownCategoryList,
    proposalSummaryData,
    handleChangeSummaryData,
    renderInsightsButton,
  ]);

  const detailsData = useMemo(() => {
    const approvedDate =
      proposal?.workflowStatusName === WorkflowStatus.Approved
        ? moment(proposal.approvedDate).format('MM/DD/YYYY')
        : '-';
    // if BE returns assigneTo empty it means that the field should not be shown
    return {
      ...proposal,
      assignedTo: proposal?.assignedTo ? assignedToUsers : null,
      approvedDate,
    };
  }, [proposal, assignedToUsers]);

  const handleRejectProposal = useCallback(
    (formData, fieldData, clearOnSuccess?: () => void) => {
      const rejectReasonField = fieldData.find(
        data => data.fieldName === 'rejectReason',
      );
      const rejectReason = rejectReasonField?.comment;

      const recipientIds = rejectReasonField?.selectedUsers?.map(
        user => user.nodeId,
      );

      const rejectActionId = proposalNextStatus?.find(
        action => action.name === StatusActions.REJECT,
      )?.actionId;

      if (!rejectActionId) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Reject action not found. Please try again.',
          }),
        );
        return;
      }

      doUpdateStatus({
        id: Number(id),
        actionId: rejectActionId,
        declineReason: rejectReason,
        recipientIds,
      });
    },
    [doUpdateStatus, id, proposalNextStatus],
  );

  const onCancelReject = () => {
    setOpenReject(false);
  };

  const rejectModal = useMemo(() => {
    return (
      <ECEasyForm
        pattern="modal"
        config={fancyFormRejectProposal.config}
        fields={fancyFormRejectProposal.fields}
        isSendingData={isLoadingActions}
        isLoading={isLoadingActions}
        isLoadingForm={false}
        onFormSubmit={handleRejectProposal}
        saveButtonColor="error"
        customDrawerTitle={`Please add Rejection Reason to Reject Proposal #${proposal?.id}`}
        additionalActions={
          <ECButton type="button" variant="text" onClick={onCancelReject}>
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    );
  }, [onCancelReject, handleRejectProposal]);

  const handleClose = useCallback(() => {
    setIsAssetInsightsClicked(false);
  }, []);

  const proposalLocation = useMemo(() => {
    return proposal?.branchId
      ? {
          href: proposal.branchId?.toString(),
          text: proposal.branchName || proposal.branchId?.toString(),
        }
      : undefined;
  }, [proposal]);

  return (
    <>
      {proposal && (
        <>
          <ECWorkflowTemplate
            tabsAsLinks
            title={
              t('translation:workflow.sidebar.proposal') +
                ' ' +
                proposal?.id?.toString() ?? ''
            }
            summaryData={summaryData}
            editConfig={fancyFormElementsEdit.config}
            editFields={formFields}
            detailsConfig={formElementsDetails.data.config}
            detailsFields={formElementsDetails.data.fields}
            detailsData={detailsData}
            workflowStatus={proposal.workflowStatusName}
            imgSrc={assetProfileAttachment?.url}
            profileAttachment={assetProfileAttachment}
            moduleName={moduleName}
            moduleId={id}
            warrantyBadgeType={assetWarranty?.[0]?.status}
            useUpdateMutation={useUpdateProposal}
            cancelEdit={cancelEdit}
            onEditButtonClick={setIsEditMode}
            onExitEditModeWithoutSave={initializeSummaryFields}
            asset={
              proposal?.generalAssetId || proposal?.pmId
                ? undefined
                : {
                    href: proposal?.assetId?.toString() ?? '',
                    text: proposal.assetName,
                  }
            }
            generalAsset={
              proposal?.generalAssetId && !proposal?.pmId
                ? {
                    href: proposal?.generalAssetId?.toString() ?? '',
                    text:
                      proposal.assetName ||
                      proposal?.generalAssetId?.toString(),
                  }
                : undefined
            }
            workOrder={{
              href: proposal?.workorderId?.toString() ?? '',
              text: proposal.workorderName,
            }}
            pm={
              proposal?.pmId
                ? {
                    href: proposal?.pmId?.toString() ?? '',
                    text: proposal?.pmName || proposal?.pmId?.toString() || '',
                  }
                : undefined
            }
            invoices={{
              content: proposal.invoices.map(invoice => ({
                href: invoice?.id?.toString(),
                text: invoice.id.toString(),
              })),
            }}
            proposals={{
              content: _.uniqBy(proposal.proposals, 'id')?.map(proposal => ({
                href: (proposal as any).id.toString(),
                text: (proposal as any).title,
              })),
            }}
            rfps={
              proposal?.rfpId
                ? {
                    content: [
                      {
                        href: proposal?.rfpId?.toString() ?? '',
                        text: proposal?.rfpId?.toString() ?? '',
                      },
                    ],
                  }
                : undefined
            }
            costTableData={costTableData}
            checkNothingToUpdateEditForm={false}
            actions={proposalNextStatus}
            onActionClick={updateStatus}
            isLoadingActions={isLoadingActions}
            isEditAllowed={hasEditProposalPermission[0]}
            location={proposalLocation}
          />
          {isDetailsTab && (
            <>
              <ECModal
                isOpen={openReject && !isSuccessUpdateAction}
                onClose={() => setOpenReject(false)}
                noPadding
              >
                {rejectModal}
              </ECModal>
              <ECModal
                isOpen={showSPContactInfo}
                onClose={() => setShowSPContactInfo(false)}
                noPadding
              >
                <ECBox
                  bgcolor={theme => theme.palette.common.white}
                  sx={{
                    position: 'relative',
                    padding: '20px',
                    borderRadius: '8px',
                  }}
                >
                  <ECIconButton
                    onClick={() => setShowSPContactInfo(false)}
                    style={{ position: 'absolute', top: 10, right: 10 }}
                  >
                    <Close />
                  </ECIconButton>
                  <ECBox
                    display="flex"
                    flexDirection="column"
                    sx={{
                      width: '500px',
                    }}
                    p={1}
                  >
                    <ECEasyForm
                      pattern="filled"
                      fullHeight={false}
                      config={{
                        variant: 'filled',
                        title: 'Service Provider Contact Info',
                        subtitle: '',
                        titleStyle: {
                          fontSize: '24px',
                          fontWeight: 500,
                        },
                        cols: 1,
                      }}
                      fields={[
                        {
                          type: FieldTypes.Text,
                          label: 'Company Name',
                          isNumeric: false,
                          fieldName: 'serviceProviderName',
                          value: proposal?.serviceProviderName,
                          variant: 'standard',
                          overrideRowValue: true,
                          readOnly: true,
                        },
                        {
                          type: FieldTypes.Group,
                          fieldName: 'group',
                          groupColumns: 2,
                          subFields: [
                            {
                              type: FieldTypes.Phone,
                              label: 'Phone',
                              readOnly: true,
                              fieldName: 'serviceProviderPhone',
                              variant: 'standard',
                              overrideRowValue: true,
                              value: proposal?.serviceProviderPhone,
                            },
                            {
                              type: FieldTypes.Text,
                              label: 'EXT',
                              readOnly: true,
                              fieldName: 'serviceProviderPhoneExt',
                              variant: 'standard',
                              overrideRowValue: true,
                              value: proposal?.serviceProviderPhoneExt,
                            },
                            {
                              type: FieldTypes.Phone,
                              label: '24 hr Phone',
                              readOnly: true,
                              variant: 'standard',
                              overrideRowValue: true,
                              fieldName: 'serviceProviderPhone24',
                              value: proposal?.serviceProviderPhone24,
                            },
                            {
                              type: FieldTypes.Text,
                              label: '24 hr Ext',
                              readOnly: true,
                              variant: 'standard',
                              overrideRowValue: true,
                              fieldName: 'serviceProviderPhone24Ext',
                              value: proposal?.serviceProviderPhone24Ext,
                            },
                          ],
                        },
                        {
                          type: FieldTypes.Text,
                          label: 'Email',
                          placeholder: 'Email',
                          variant: 'standard',
                          overrideRowValue: true,
                          value: proposal?.serviceProviderEmail,
                          readOnly: true,
                          fieldName: 'serviceProviderEmail',
                        },
                      ]}
                      isSendingData={false}
                      isLoadingForm={false}
                      showSaveButton={false}
                    />
                  </ECBox>
                </ECBox>
              </ECModal>
              <Popover
                id={'popover-users-table'}
                open={open}
                anchorEl={anchorEl}
                onClose={onCloseModal}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  minHeight: '300px',
                  minWidth: '300px',
                }}
              >
                <ECBox
                  minHeight={150}
                  minWidth={650}
                  maxHeight={400}
                  sx={{
                    '&::before': {
                      backgroundColor: 'white',
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      width: 12,
                      height: 12,
                      top: -6,
                      transform: 'rotate(45deg)',
                      left: 'calc(50% - 6px)',
                    },
                  }}
                  p={2}
                >
                  <ECUsersTableModal
                    module="proposal"
                    preExistingUsers={assignedToUsers}
                    id={proposal?.id}
                  />
                </ECBox>
              </Popover>
            </>
          )}
          {!isLoadingAssetInsights && (
            <ECInsightsDrawer
              isOpen={isAssetInsightsClicked}
              onClose={handleClose}
              insightsData={assetInsights || null}
              asset={{
                name: proposal?.assetName,
                id: proposal?.assetId,
                photo: assetProfileAttachment?.url,
              }}
            />
          )}
        </>
      )}
    </>
  );
}
