import ECPrintCard from 'app/components/ECPrintCard';
import { cloneDeep, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generateInvoiceDetailsHTMLContent } from 'utils/geneate-html-content';
import { useLazyGetCommentListQuery } from 'services/commentApi';

const PRINT_DEBOUNCE_TIME = 1500;

export default function InvoicePrintCard({
  anchorEl,
  open,
  invoice,
  assetObject,
  itemsInventory,
  handleClose,
  handleSetAnchorElPrint,
}) {
  const [shouldIncludeComments, setShouldIncludeComments] = useState(false);
  const [triggerComment, { data: comments }] = useLazyGetCommentListQuery();

  const isInventoryEnabled = useMemo(
    () => itemsInventory && !!itemsInventory.length,
    [itemsInventory],
  );

  const printContent = useCallback((htmlContent: string) => {
    handleSetAnchorElPrint(null);
    const newTab = window.open();
    if (newTab) {
      newTab.document.write(htmlContent);
      debounce(() => newTab.print(), PRINT_DEBOUNCE_TIME)();
    }
  }, []);

  useEffect(() => {
    if (shouldIncludeComments && comments) {
      setShouldIncludeComments(false);
      const commentList = cloneDeep(comments?.data || []);
      const htmlContent = generateInvoiceDetailsHTMLContent(
        invoice,
        assetObject,
        isInventoryEnabled,
        itemsInventory,
        true,
        commentList,
      );
      printContent(htmlContent);
    }
  }, [
    shouldIncludeComments,
    comments,
    invoice,
    assetObject,
    isInventoryEnabled,
    itemsInventory,
    printContent,
  ]);

  const handlePrintActionClick = useCallback(
    (includeComments: boolean) => {
      setShouldIncludeComments(includeComments);
      if (includeComments) {
        triggerComment({
          requestId: String(invoice?.requestId),
          module: 'invoice',
          t: 100,
        });
      } else {
        const htmlContent = generateInvoiceDetailsHTMLContent(
          invoice,
          assetObject,
          isInventoryEnabled,
          itemsInventory,
        );
        printContent(htmlContent);
      }
    },
    [
      handleSetAnchorElPrint,
      triggerComment,
      invoice,
      assetObject,
      isInventoryEnabled,
      itemsInventory,
      printContent,
    ],
  );

  return (
    <ECPrintCard
      open={open}
      handleClose={handleClose}
      handlePrintActionClick={handlePrintActionClick}
      module={'invoice'}
      anchorEl={anchorEl}
    />
  );
}
