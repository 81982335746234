import {
  ECBox,
  ECEasyTable,
  ECGrid,
  ECTextField,
  ECList,
  ECListItemText,
  ECListItemButton,
  ECEasyTableConfigType,
  initTableConfig,
  ECPaper,
  ECEasyTableDataRequestParamsType,
  ECButton,
  ECInfiniteScroll,
} from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetBranchesListQuery } from 'services/branchApi';
import { useLazyGetTradeAssignmentByBranchIdQuery } from 'services/tradeApi';
import { Branch } from 'types/Branch';
import { getConfigStateFromApiOrMock } from 'utils/pageUtils';
import { Edit } from './Edit';
import { Copy } from './Copy';
import { useSelector } from 'react-redux';
import { RootState } from 'index';

import { CopyTradeAssignment } from './CopyTradeAssignment';
import { debounce } from 'lodash';
import { StyleConstants } from 'styles/StyleConstants';

export function TradeAssignmentByBranchPage() {
  const { t } = useTranslation();

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [branchTrigger, branchResult] = useLazyGetBranchesListQuery();
  const { data: branchesData } = branchResult;

  const [trigger, result] = useLazyGetTradeAssignmentByBranchIdQuery();

  const { data: tradeAssigmnents, isSuccess: isSuccessTradeAssigments } =
    result;

  const [selectedBranch, setSelectedBranch] = useState<Branch | undefined>(
    undefined,
  );

  const [configState, setConfigState] =
    useState<ECEasyTableConfigType>(initTableConfig);
  const [search, setSearch] = useState('');
  const [row, setRow] = useState<any>();
  const [openedDrawer, setOpenedDrawer] = useState<
    'edit' | 'copy' | 'copyTradeAssignment' | undefined
  >(undefined);
  const [params, setParams] = useState<ECEasyTableDataRequestParamsType>({
    pagination: {
      currentPage: 0,
      perPage: 50,
    },
    sort: {
      fieldName: '',
      value: '',
    },
  });

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  useEffect(() => {
    setConfigState(
      getConfigStateFromApiOrMock(tradeAssigmnents, configState, t),
    );
  }, [tradeAssigmnents]);

  const isLargeScreen = window.innerHeight > 800;
  const fetchSize = isLargeScreen ? 60 : 20;

  // initial trigger for selecting the first branch
  useEffect(() => {
    branchTrigger({
      t: fetchSize,
      o: 'd',
      ob: '',
      sb: 'brnch.name',
      st: 1,
    });
  }, [isInitialLoad, isLargeScreen]);

  useEffect(() => {
    if (branchesData && branchesData.data && branchesData.data.length > 0) {
      if (isInitialLoad) {
        setSelectedBranch(branchesData?.data?.[0]);
        setIsInitialLoad(false);
      }
    }
  }, [branchesData, isInitialLoad]);

  const handleRowPress = (row: any) => {
    setRow(row);
    handleOpenEditDrawer();
  };
  const handleDuplicatePress = (row: any) => {
    setRow(row);
    handleOpenCopyDrawer();
  };

  const handleBranchSelect = useCallback((branch: Branch) => {
    setSelectedBranch(branch);
  }, []);

  useEffect(() => {
    if (selectedBranch?.id) {
      trigger({
        id: selectedBranch.id,
        t: params.pagination.perPage,
        p: params.pagination.currentPage,
      });
    }
  }, [selectedBranch, params]);

  useEffect(() => {
    if (selectedBranch?.id) {
      trigger({
        id: selectedBranch.id,
        t: params.pagination.perPage,
        p: params.pagination.currentPage,
        o: params.sort?.value === 'ASC' ? 'a' : 'd',
        ob: params.sort?.fieldName || 'trd.name',
        sb: activeFilter?.filterFields?.[0]?.fieldName,
        s: activeFilter?.filterFields?.[0]?.fieldName
          ? `*${activeFilter?.filterFields?.[0]?.value
              ?.toString()
              ?.toLowerCase()}*`
          : '',
      });
    }
  }, [selectedBranch, params, activeFilter]);

  const handleSearchChange = useCallback(
    debounce(event => {
      setSearch(event.target.value);
    }, 300),
    [],
  );

  const handleCloseDrawer = () => setOpenedDrawer(undefined);
  const handleOpenEditDrawer = () => setOpenedDrawer('edit');
  const handleOpenCopyDrawer = () => setOpenedDrawer('copy');
  const handleOpenCopyTradeAssignmentDrawer = () =>
    setOpenedDrawer('copyTradeAssignment');

  const handleDataRequest = useCallback(
    (params?: ECEasyTableDataRequestParamsType) => {
      if (params) {
        setParams(params);
      }
    },
    [],
  );

  const handleCopyTradeAssignmentClick = useCallback(() => {
    handleOpenCopyTradeAssignmentDrawer();
  }, [selectedBranch]);

  const renderItem = useMemo(
    () => (branch: Branch, index: any) => {
      return (
        <ECListItemButton
          key={index}
          selected={selectedBranch?.id === branch.id}
          onClick={() => handleBranchSelect(branch)}
        >
          <ECListItemText primary={branch.name} />
        </ECListItemButton>
      );
    },
    [selectedBranch, handleBranchSelect],
  );

  return (
    <ECBox display="flex" flexDirection="column" flex={1}>
      <ECGrid mt={0} overflow="hidden" container spacing={1}>
        <ECGrid mt={0} pt={0} item xs={3}>
          <ECBox p={2}>
            <ECTextField
              fullWidth
              placeholder="Search..."
              onChange={e => handleSearchChange(e)}
            />
          </ECBox>
          <ECInfiniteScroll
            queryFn={useLazyGetBranchesListQuery}
            ContainerComponent={ECList}
            containerProps={{ noPadding: true }}
            queryParams={{
              t: fetchSize,
              o: 'd',
              ob: '',
              sb: 'brnch.name',
              st: 1,
            }}
            renderItem={renderItem}
            wrapperProps={{
              height: 'calc(100vh - 350px)',
            }}
            searchParam={search}
          />
        </ECGrid>

        <ECDrawerDetails
          open={!!openedDrawer}
          anchor="right"
          onClose={handleCloseDrawer}
        >
          <ECPaper
            sx={{
              height: '100%',
              paddingTop: StyleConstants.NAV_BAR_HEIGHT,
              boxShadow: 'none',
            }}
            role="presentation"
          >
            {openedDrawer === 'edit' && (
              <Edit row={row} onClose={handleCloseDrawer} />
            )}
            {openedDrawer === 'copy' && (
              <Copy row={row} onClose={handleCloseDrawer} />
            )}
            {openedDrawer === 'copyTradeAssignment' && (
              <CopyTradeAssignment
                selectedBranch={selectedBranch!}
                onClose={handleCloseDrawer}
              />
            )}
          </ECPaper>
        </ECDrawerDetails>

        <ECGrid item xs={9} p={2}>
          {isSuccessTradeAssigments && configState.cols.length > 0 && (
            <ECEasyTable
              config={configState}
              data={tradeAssigmnents?.data}
              onDataRequest={handleDataRequest}
              isLoading={false}
              onRowPress={handleRowPress}
              onDuplicatePress={handleDuplicatePress}
              headerChildren={
                <ECButton
                  onClick={handleCopyTradeAssignmentClick}
                  variant="outlined"
                >
                  Copy Trade Assignment
                </ECButton>
              }
              enableExport
              exportModuleName="tradeassignmentbybranch"
              currentQueryParams={{
                o: params.sort?.value === 'ASC' ? 'a' : 'd',
                ob: params.sort?.fieldName || 'trd.name',
                sb: activeFilter?.filterFields?.[0]?.fieldName,
                p: params.pagination.currentPage,
                t: params.pagination.perPage,
                s: activeFilter?.filterFields?.[0]?.fieldName
                  ? `*${activeFilter?.filterFields?.[0]?.value
                      ?.toString()
                      ?.toLowerCase()}*`
                  : '',
              }}
              exportBranchId={selectedBranch?.id}
              shouldNotUseActiveFilter
            />
          )}
        </ECGrid>
      </ECGrid>
    </ECBox>
  );
}
