import { ECTabContainer } from '../../components';
import { StyleConstants } from 'styles/StyleConstants';
import { P } from 'types/Permission';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { PurchaseOrders } from '../PurchaseOrders';

export function PurchaseOrdersTabsPage() {
  const isCustomer = useCustomerUser();
  const { t } = useTranslation();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:pages.purchaseOrders.title')} - %s`}
        defaultTitle={t('translation:pages.purchaseOrders.title')}
      />
      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'purchase-orders',
            label: 'Purchase Orders',
            content: <PurchaseOrders marginTop={false} />,
            scopes: [P.GetPurchaseOrder],
            link: 'purchase-orders',
          },
          {
            value: 'suppliers',
            label: 'Suppliers',
            content: <Outlet />,
            scopes: [P.GetSupplier],
            link: 'suppliers',
          },
        ]}
      />
    </>
  );
}
