import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';

interface StrikethroughTextProps {
  formattedBeforeText: string;
  isVisible: boolean;
}

const StrikethroughText: React.FC<StrikethroughTextProps> = ({
  formattedBeforeText,
  isVisible,
}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <ECBox display="flex" flex={1} width={0} justifyContent="flex-end">
      <ECTypography
        variant="subtitle2"
        maxWidth="100%"
        color={theme => theme.palette.error.dark}
        sx={{
          textDecoration: 'line-through',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      >
        {formattedBeforeText}
      </ECTypography>
    </ECBox>
  );
};

export default StrikethroughText;
