import { ECDynamicPageTemplate } from 'app/components';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useLazyGetServiceProviderCustomerListQuery,
  useUpdateServiceProviderCustomerMutation,
} from 'services/serviceProviderApi';

import { loadPageScopes } from 'utils/pageScopes';
import { setFilterEndpoint } from 'store/slice/page';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function SPCustomersManagementPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('serviceProviderCustomers');
    dispatch(setFilterEndpoint('/filter/customer-management'));
  }, []);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:panelDrawer.customers')}
      useLazyGetListQuery={useLazyGetServiceProviderCustomerListQuery}
      useCreateMutation={() => false}
      useUpdateMutation={useUpdateServiceProviderCustomerMutation}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      marginTop={false}
      withDrawer={false}
      showStatusActiveFilter
      statusFilterInitialSelected
      statusAlias="cmpny.status"
      enableExport
      exportModuleName="customer"
      shouldNotUseActiveFilter
    />
  );
}
