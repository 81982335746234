import { ECTableCell, ECTableRow } from '../ECTable';
import { CostLaborItem } from 'types/CostLaborItem';
import { ServiceProviderRatesAndTerms } from 'types/ServiceProviderTypes';
import { LaborItemsSubTableCharges } from './labor-items-sub-table-charges';
import { ECTypography } from '../ECTypography';
import { useEffect } from 'react';

const TRAVEL_CHARGES_NAMES = ['Travel Time', 'Trip Charge', 'Truck Charge'];

interface Props {
  costLaborItems: CostLaborItem[];
  ratesAndTerms: ServiceProviderRatesAndTerms;
  isEditing?: boolean;
  onChange: (value: any, label: string) => void;
}

export const LaborItemsSubTable = ({
  costLaborItems = [],
  ratesAndTerms,
  isEditing,
  onChange,
}: Props) => {
  const travelCharges = costLaborItems?.filter(item =>
    TRAVEL_CHARGES_NAMES.includes(item?.costType?.name),
  );
  const laborCharges = costLaborItems?.filter(
    item => !TRAVEL_CHARGES_NAMES.includes(item?.costType?.name),
  );

  useEffect(() => {
    if (isEditing && !costLaborItems?.length) {
      onChange(
        [
          ...costLaborItems,
          {
            costType: {
              name: 'Regular',
            },
            unitCost: 0,
            unitCount: 0,
            totalCost: 0,
          },
        ],
        'Labor Charge',
      );
    }
  }, [isEditing]);

  if (!costLaborItems?.length && !isEditing) {
    return null;
  }

  return (
    <>
      <LaborItemsSubTableCharges
        name="Labor"
        allCharges={costLaborItems}
        charges={laborCharges}
        ratesAndTerms={ratesAndTerms}
        isEditing={isEditing}
        onChange={onChange}
      />

      {!!travelCharges?.length && (
        <>
          <ECTableRow
            sx={{
              bgcolor: theme => `${theme.palette.action.hover} !important`,
            }}
          >
            <ECTableCell colSpan={2} sx={{ border: 0, px: 2 }}>
              <ECTypography
                sx={theme => ({ color: theme.palette.text.secondary })}
              >
                Travel
              </ECTypography>
            </ECTableCell>
          </ECTableRow>

          <LaborItemsSubTableCharges
            name="Travel"
            allCharges={costLaborItems}
            charges={travelCharges}
            ratesAndTerms={ratesAndTerms}
            isEditing={isEditing}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
};
