import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { MonetizationOn } from '@mui/icons-material';
import { ECBox, ECButton } from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ECDrawerDetailsContent } from 'app/components/ECDrawerDetailsContent';
import { StyleConstants } from 'styles/StyleConstants';
import { NewPOInvoiceForm } from './NewPOInvoiceForm';
import { PurchaseOrderStatusType } from 'app/components/ECPurchaseOrderItemsTable';

const statusToShowCreateInvoice = [
  PurchaseOrderStatusType.RECONCILED,
  PurchaseOrderStatusType.PARTIALLY_RECONCILED,
];

export const NewPOInvoice = forwardRef(({ purchaseOrder }: any, ref) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openDrawer: () => {
        setOpen(true);
      },
    }),
    [],
  );

  if (!statusToShowCreateInvoice?.includes(purchaseOrder?.workflowStatusName)) {
    return null;
  }

  return (
    <ECDrawerDetails open={open} anchor="right" onClose={() => setOpen(false)}>
      <ECBox pt={StyleConstants.NAV_BAR_HEIGHT} minHeight="100vh">
        <ECDrawerDetailsContent
          data={{}}
          EditForm={
            <NewPOInvoiceForm
              purchaseOrder={purchaseOrder}
              onClose={handleClose}
            />
          }
          showOnlyDetailsTab={true}
          onClose={() => setOpen(false)}
          editSelected={true}
          showEditButton={false}
          isCreateNewStep={true}
          hasTopPadding={false}
        />
      </ECBox>
    </ECDrawerDetails>
  );
});
