import { ECBox, ECButton, ECTextField, ECTypography } from 'app/components';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { ECModal } from '../ECModal';
import { useState } from 'react';

interface Props {
  title?: string;
  description?: string;
  isLoading?: boolean;
  isOpen?: boolean;
  isDestructiveForm?: boolean;
  withReasonTextField?: boolean;
  onConfirm: (reason?: string) => void;
  onCancel: () => void;
}

export const ECConfirmWorkflowStatusChangeModal = ({
  title,
  description,
  isLoading,
  isOpen,
  isDestructiveForm,
  withReasonTextField,
  onConfirm,
  onCancel,
}: Props) => {
  const [reason, setReason] = useState('');

  const [errors, setErrors] = useState<string[]>([]);

  return (
    <ECModal isOpen={isOpen} onClose={onCancel} noPadding noPaddingBottom>
      <ECBox p={3} sx={{ width: '30rem', maxWidth: '100%' }}>
        <ECTypography variant="h6">{title}</ECTypography>
        {description && <ECTypography pt={1}>{description}</ECTypography>}

        {!!withReasonTextField && (
          <ECBox display="flex" width="100%" py={1}>
            <ECTextField
              fullWidth
              variant="filled"
              onChange={e => setReason(e.target.value)}
              placeholder="Reason*"
              label="Reason*"
              id="reason"
              error={errors.includes('reason')}
              helperText={
                errors.includes('reason') ? 'This field is required' : ''
              }
            />
          </ECBox>
        )}
      </ECBox>
      <ECBox
        display="flex"
        justifyContent="flex-end"
        gap={2}
        p={2}
        bgcolor={theme => theme.palette.action.hover}
      >
        <ECButton onClick={onCancel}>Cancel</ECButton>
        <ECButton
          onClick={() => {
            if (withReasonTextField && !reason) {
              setErrors(['reason']);
              return;
            }

            onConfirm(reason);
          }}
          variant="contained"
          color={isDestructiveForm ? 'error' : undefined}
        >
          {isLoading ? (
            <ECCircularProgress
              size={'2rem'}
              sx={{ color: 'background.paper' }}
            />
          ) : (
            'Confirm'
          )}
        </ECButton>
      </ECBox>
    </ECModal>
  );
};
