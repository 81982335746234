import {
  useLazyGetJobTitlesListQuery,
  useCreateJobTitleMutation,
  useUpdateJobTitleMutation,
  useGetJobTitlesRolesQuery,
} from 'services/jobTitleApi';
import { ECDynamicPageTemplate, FieldTypes } from 'app/components';
import { loadPageScopes } from 'utils/pageScopes';
import { JobTitlesRolesTab } from './JobTitlesRolesTab';
import { P } from 'types/Permission';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { JobTitleMutate, JobTitleRoles } from 'types/JobTitle';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import {
  setFilterEndpoint,
  setCleanActiveFilter,
  setActiveFilter,
} from 'store/slice/page';
import { useGetControlledRolesQuery } from 'services/roleApi';
import { useGetInternalTechsCompaniesQuery } from 'services/userApi';
import { useGetProfileQuery } from 'services/profileApi';
import { ActivityType } from 'types/Activity';
import { historyApi } from 'services/historyApi';

const editFormElements = require('./fancy_form_config_edit.json');
const createFormElements = require('./fancy_form_config_create.json');
const detailsFormElements = require('./fancy_form_config_details.json');

export function JobTitlesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('jobTitle');
    dispatch(setFilterEndpoint('/filter/jobtitles'));
    dispatch(setCleanActiveFilter());
  }, []);

  const [detailsFormFields, setDetailsFormFields] = useState(
    detailsFormElements.fields,
  );
  const [editFormFields, setEditFormFields] = useState(editFormElements.fields);
  const [createFormFields, setCreateFormFields] = useState(
    createFormElements.fields,
  );
  const [isInitialFieldsPopulated, setIsInitialFieldsPopulated] =
    useState(false);
  const [row, setRow] = useState<any>();

  const { data: controlledRolesList, isSuccess: isSuccessControlledRolesList } =
    useGetControlledRolesQuery();

  const { data: rolesList } = useGetJobTitlesRolesQuery();
  const [jobTitleSelectedRolesList, setJobTitleSelectedRolesList] = useState<
    JobTitleRoles[]
  >([]);

  const resetRow = useCallback(() => {
    setJobTitleSelectedRolesList([]);
    setRow({});
  }, []);

  useEffect(() => {
    if (!_.isEmpty(row)) {
      const tempDetailsFileds = [...detailsFormFields];
      const tempEditFileds = [...editFormFields];
      controlledRolesList?.forEach(roleItem => {
        const detailsItemWithFieldName = tempDetailsFileds.find(
          item => item.fieldName === roleItem.name,
        );
        detailsItemWithFieldName.value =
          row[roleItem.name] === 1 ? true : false;

        const editItemWithFieldName = tempEditFileds.find(
          item => item.fieldName === roleItem.name,
        );
        editItemWithFieldName.value = row[roleItem.name] === 1 ? true : false;
      });
      setDetailsFormFields(tempDetailsFileds);
      setEditFormFields(tempEditFileds);
      const rolesList: JobTitleRoles[] = row?.roles?.map(element => ({
        id: element.id,
        name: element.name,
        isControlled: element.isControlled,
      }));
      setJobTitleSelectedRolesList(rolesList);
    }
  }, [row]);

  const handleAddNewClick = () => {
    setJobTitleSelectedRolesList(
      rolesList?.data?.filter(
        r => !controlledRolesList?.find(cr => cr.id === r.id),
      ),
    );
  };

  useEffect(() => {
    if (isSuccessControlledRolesList && controlledRolesList) {
      if (!isInitialFieldsPopulated) {
        const initialCreateFields: any[] = [...createFormElements.fields];
        const initialDetailsFields: any[] = [...detailsFormElements.fields];
        const initialEditFields: any[] = [...editFormElements.fields];
        controlledRolesList?.forEach(item => {
          initialCreateFields.push({
            type: FieldTypes.Switch,
            label: item.name,
            placeholder: item.name,
            options: [false, true],
            value:
              item.code === 'TROUBLESHOOTING' || item.code === 'DIRECT_NOTES',
            overrideRowValue: true,
            fieldName: item.name,
          });
          initialDetailsFields.push({
            type: FieldTypes.Switch,
            label: item.name,
            placeholder: item.name,
            options: [false, true],
            value: true,
            overrideRowValue: true,
            readOnly: true,
            fieldName: item.name,
          });
          initialEditFields.push({
            type: FieldTypes.Switch,
            label: item.name,
            placeholder: item.name,
            options: [false, true],
            value: true,
            overrideRowValue: true,
            fieldName: item.name,
          });
        });
        setCreateFormFields(initialCreateFields);
        setDetailsFormFields(initialDetailsFields);
        setEditFormFields(initialEditFields);
        setIsInitialFieldsPopulated(true);
      }
    }
  }, [isSuccessControlledRolesList, controlledRolesList, rolesList]);

  const [
    doUpdateJobTitle,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateJobTitleMutation();

  const getControlledRoleIds = useCallback(
    jobTitleRoot => {
      if (!controlledRolesList) return [];
      return controlledRolesList
        .filter(role => jobTitleRoot[role.name] === 1)
        .map(r => r.id);
    },
    [controlledRolesList],
  );

  const useUpdateJobTitle = useCallback(() => {
    const doUpdate = async data => {
      const newRolesIds: number[] = [
        ...getControlledRoleIds(data),
        ...jobTitleSelectedRolesList.map(jtr => jtr.id),
      ];

      const previousRolesIds = [
        ...getControlledRoleIds(row),
        ...row.roles.map(role => role.id),
      ];

      const rolesIdsToAdd = _.difference(newRolesIds, previousRolesIds);
      const rolesIdsToRemove = _.difference(previousRolesIds, newRolesIds);

      const body: JobTitleMutate = {
        name: data.name,
        id: data.id,
        nte: 0,
        status: data.status,
        rolesIdsToAdd,
        rolesIdsToRemove,
      };
      doUpdateJobTitle(body);
    };
    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isLoadingUpdate,
    isUpdateSuccess,
    resetUpdate,
    doUpdateJobTitle,
    controlledRolesList,
    jobTitleSelectedRolesList,
    updateData,
  ]);

  const [
    doCreateJobTitle,
    {
      data: createData,
      isError: isCreateError,
      error: createError,
      isLoading: isLoadingCreate,
      isSuccess: isCreateSuccess,
      reset: resetCreate,
    },
  ] = useCreateJobTitleMutation();

  const useCreateJobTitle = useCallback(() => {
    const doCreate = data => {
      const rolesIds: number[] = [
        ...getControlledRoleIds(data),
        ...jobTitleSelectedRolesList.map(jtr => jtr.id),
      ];

      const body: JobTitleMutate = {
        name: data.name,
        nte: 0,
        status: data.status,
        rolesIds,
      };
      doCreateJobTitle(body);
    };
    return [
      doCreate,
      {
        data: createData,
        isError: isCreateError,
        error: createError,
        isLoading: isLoadingCreate,
        isSuccess: isCreateSuccess,
        reset: resetCreate,
      },
    ];
  }, [
    isCreateError,
    createError,
    isLoadingCreate,
    isCreateSuccess,
    resetCreate,
    doCreateJobTitle,
    controlledRolesList,
    createData,
    jobTitleSelectedRolesList,
  ]);

  const handleSelectedRolesChange = (role, action) => {
    if (action === 'checked') {
      setJobTitleSelectedRolesList([
        ...jobTitleSelectedRolesList,
        {
          id: role.id,
          name: role.name,
          isControlled: role.isControlled,
        },
      ]);
    } else {
      const newData = [...jobTitleSelectedRolesList];
      setJobTitleSelectedRolesList(newData.filter(item => item.id !== role.id));
    }
  };

  const { data: companyDetails, isSuccess: isSuccessCompanyDetails } =
    useGetProfileQuery();

  const userCompanyObject = useMemo(() => {
    if (isSuccessCompanyDetails && companyDetails) {
      return {
        label: companyDetails?.name,
        fieldName: companyDetails?.name,
        name: companyDetails?.name,
        id: companyDetails?.id,
      };
    }
    return null;
  }, [companyDetails, isSuccessCompanyDetails]);

  useEffect(() => {
    if (isSuccessCompanyDetails && companyDetails) {
      dispatch(
        setActiveFilter({
          manufacturer: [userCompanyObject],
          isSelectAllManufacturers: false,
        }),
      );
    }
  }, [companyDetails, isSuccessCompanyDetails]);

  useEffect(() => {
    if (!isLoadingUpdate && isUpdateSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isUpdateSuccess, isLoadingUpdate]);

  useEffect(() => {
    if (!isLoadingCreate && isCreateSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isLoadingCreate, isCreateSuccess]);

  return (
    <ECDynamicPageTemplate
      pageTitle={'Job Title'}
      useLazyGetListQuery={useLazyGetJobTitlesListQuery}
      moduleName="jobtitle"
      useCreateMutation={useCreateJobTitle}
      useUpdateMutation={useUpdateJobTitle}
      showStatusActiveFilter={true}
      statusFilterInitialSelected
      defaultSortConfig={{ fieldName: 'jbttle.name', value: 'ASC' }}
      editFormConfig={editFormElements.config}
      editFormFields={editFormFields}
      createFormConfig={createFormElements.config}
      createFormFields={createFormFields}
      detailsConfig={detailsFormElements.config}
      detailsFields={detailsFormFields}
      enableExport={true}
      downloadFileName="jobs.csv"
      exportModuleName="jobtitle"
      marginTop={false}
      hasDetails={true}
      module="Job Titles"
      onRowClick={setRow}
      onDrawerClose={resetRow}
      onAddNewClick={handleAddNewClick}
      additionalTabs={[
        {
          value: '1',
          label: t('translation:pages.jobTitles.roles'),
          additionalTabFieldnameData: 'roles',
          scopes: [P.EditJobTitle],
          editable: true,
          content: (
            <JobTitlesRolesTab
              data={[]}
              handleSelectedRolesChange={handleSelectedRolesChange}
              jobTitleSelectedRolesList={jobTitleSelectedRolesList}
              options={
                rolesList?.data?.filter(
                  r => !controlledRolesList?.find(cr => cr.id === r.id),
                ) || []
              }
            />
          ),
        },
      ]}
      showOnlyadditionalTabs={true}
      showManufacturerFilter
      useManufacturerFilterQuery={useGetInternalTechsCompaniesQuery}
      userCompany={userCompanyObject}
      shouldNotUseActiveFilter
      showActivityButton
      activityType={ActivityType.JobTitle}
      showDrawerDetailTitle={false}
    />
  );
}
