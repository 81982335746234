import { Profile } from 'types/Profile';
import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['UserProfile'],
});

export const userProfileApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getUserProfile: builder.query<Profile, void>({
      query: () => 'user/profile',
      keepUnusedDataFor: 5,
      providesTags: ['UserProfile'],
    }),
  }),
});

export const { useGetUserProfileQuery, useLazyGetUserProfileQuery } =
  userProfileApi;
